import React from 'react';

const Copy = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.44"
      height="13"
      viewBox="0 0 11.44 13"
      {...props}
    >
      <path
        id="icons8-copy"
        d="M7.64,2A1.04,1.04,0,0,0,6.6,3.04v8.32A1.04,1.04,0,0,0,7.64,12.4H14.4a1.04,1.04,0,0,0,1.04-1.04V5.38a.52.52,0,0,0-.152-.368l-2.86-2.86A.52.52,0,0,0,12.06,2Zm4.16.99,2.65,2.65H12.32a.52.52,0,0,1-.52-.52ZM5.04,4.6A1.04,1.04,0,0,0,4,5.64v8.32A1.04,1.04,0,0,0,5.04,15H11.8a1.04,1.04,0,0,0,1.04-1.04v-.52H7.64a2.082,2.082,0,0,1-2.08-2.08V4.6Z"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};

export default Copy;
