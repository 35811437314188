import * as notificationTypes from 'services/apiService/notificationTypes';

const defaultNotifications = {
  success: notificationTypes.COMMAND_SET_REQUEST_SUCCESS,
};

const clientRoute = {
  pushReauthClientCommand: params => ({
    url: '/command/set/reauth',
    method: 'PUT',
    data: params,
    notifications: {
      fail: notificationTypes.REQUEST_ERROR,
      omitErrorNotification: false,
    },
  }),

  /** новая версия отправки команд */
  setClientCommand: ({
    command,
    notifications = defaultNotifications,
    ...params
  }) => ({
    url: `/command/set/${command}`,
    method: 'PUT',
    data: params,
    notifications,
  }),
};

export default clientRoute;
