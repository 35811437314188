import React from 'react';

const Settings = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.338"
    height="20.401"
    viewBox="0 0 16.338 20.401"
    {...props}
  >
    <g id="file_sent" transform="translate(-47.998 -177.6)">
      <path
        id="Subtraction_20"
        data-name="Subtraction 20"
        d="M18273.652,19096H18273a2,2,0,0,1-2-2v-16a2.027,2.027,0,0,1,2-2h8l6,6v4a12.082,12.082,0,0,0-1.5-.094c-.152,0-.311,0-.5.01V19083h-5v-5h-7v16h1.152a11.812,11.812,0,0,0-.5,2Z"
        transform="translate(-18223 -18898.4)"
      />
      <path
        id="sent_ile"
        d="M15.2,7.332,13.868,6,8.2,11.668l5.668,5.668L15.2,16l-4.327-4.336Z"
        transform="translate(70.336 182.801) rotate(90)"
        fill="#ff4f12"
      />
    </g>
  </svg>
);

export default Settings;
