import React from 'react';

const VersionsList = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15.304"
    viewBox="0 0 16 15.304"
    {...props}
  >
    <path
      id="versions"
      d="M8.609,3a.348.348,0,0,0-.348.348V17.957a.348.348,0,0,0,.348.348h9.043A.347.347,0,0,0,18,17.957V3.348A.348.348,0,0,0,17.652,3ZM5.13,4.391a.348.348,0,0,0-.348.348V16.565a.348.348,0,0,0,.348.348H7.565V4.391ZM2.348,5.783A.348.348,0,0,0,2,6.13v9.043a.348.348,0,0,0,.348.348H4.087V5.783Z"
      transform="translate(-2 -3)"
    />
  </svg>
);

export default VersionsList;
