import {
  baseURL,
  xmfClientAccessKey,
} from 'shared/utils/axiosInstance/helpers';
import {
  getItemFromLocalStorage,
  AUTH_TOKEN,
} from 'helpers/localStorage';

export const getUrl = (id) => {
  const authToken = getItemFromLocalStorage(AUTH_TOKEN);
  return `${baseURL}/download/${id}?token=${authToken}&xmf-client-access-key=${xmfClientAccessKey}`;
};
export const getPublicUrl = (id, linkId) => `${baseURL}/public/download/${linkId}/${id}`;

export const VIDEO_TYPE = 'video';

// eslint-disable-next-line no-useless-escape
export const checkIsImage = (contentType = '') => /([a-zA-Z0-9\s_\\.\-\(\):])+(.gif|.jpe?g|.tiff|.png|.webp|.bmp)/.test(contentType);

export const checkIsVideo = (contentType = '') => contentType === VIDEO_TYPE;
