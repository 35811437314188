import { selectControls } from '../selectors/controls';
import { selectCommonColors } from '../selectors/common';

// const mobileStyle = {
//   enabled: '#7800FF',
//   shadow: '#DADADA',
// };

export const getCheckboxStyle = (theme) => {
  // if (theme.isMobile) {
  //   return mobileStyle;
  // }

  const {
    enabled,
    // shadow,
  } = selectControls(theme);

  const { gray600 } = selectCommonColors(theme);

  return {
    enabled,
    shadow: gray600,
  };
};
