import * as types from './types';

const initialState = {
  count: 0,
  list: [],
  sorting: [],
  isFetching: false,
  isError: false,
  error: null,
};

const ACTION_HANDLERS = {
  [types.LOAD_USER_EVENT_LOG]: state => ({
    ...state,
    isFetching: true,
    isError: false,
  }),
  [types.LOAD_USER_EVENT_LOG_SUCCESS]: (state, { payload = {}, params = {} }) => {
    const { list = [], count = 0, sorting } = payload;
    const { page = 0 } = params;

    const newState = {
      ...state,
      count,
      sorting,
      list: page > 0 ? [...state.list, ...list] : list,
      isFetching: false,
    };

    return newState;
  },
  [types.LOAD_USER_EVENT_LOG_FAIL]: (state, { error }) => {
    const newState = {
      ...state,
      isFetching: false,
      isError: true,
      error,
    };
    return newState;
  },
};

const logs = (state = initialState, action) => {
  const nextState = ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;

  return nextState;
};

export default logs;
