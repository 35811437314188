import React from 'react';

const File = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="30"
      viewBox="0 0 24 30"
      {...props}
    >
      <path
        id="icons8-file"
        d="M7,2A3.02,3.02,0,0,0,4,5V29a3.02,3.02,0,0,0,3,3H25a3.02,3.02,0,0,0,3-3V11L19,2ZM7,5H17.5v7.5H25V29H7Z"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};

export default File;
