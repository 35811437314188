import React from 'react';

const LinkPin = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      {...props}
    >
      <path
        id="icons8-lock"
        d="M10,1A4.526,4.526,0,0,0,5.5,5.571v.762A1.516,1.516,0,0,0,4,7.857v7.619A1.516,1.516,0,0,0,5.5,17h9A1.516,1.516,0,0,0,16,15.476V7.857a1.516,1.516,0,0,0-1.5-1.524V5.571A4.526,4.526,0,0,0,10,1Zm0,1.524a2.961,2.961,0,0,1,3,3.048v.762H7V5.571A2.961,2.961,0,0,1,10,2.524Zm0,7.619a1.524,1.524,0,1,1-1.5,1.524A1.516,1.516,0,0,1,10,10.143Z"
        transform="translate(-4 -1)"
      />
    </svg>
  );
};

export default LinkPin;
