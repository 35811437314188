import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from 'store/reducers/createRootReducer';

import { history } from './history';

let store;

const configureStore = (initialState = {}) => {
  if (store) {
    return store;
  }

  const middlewares = [
    thunk,
    routerMiddleware(history),
  ];

  store = createStore(
    createRootReducer(history),
    initialState,
    compose(applyMiddleware(...middlewares)),
  );

  return store;
};

export default configureStore;
