import 'core-js';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'polyfills-ie9';
import 'core-js/es/map';
import 'core-js/features/map';
import 'core-js/es/array';

// Array
import 'mdn-polyfills/Array.from';
import 'mdn-polyfills/Array.of';
import 'mdn-polyfills/Array.prototype.fill';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Array.prototype.findIndex';
import 'mdn-polyfills/Array.prototype.includes';

// Object
import 'mdn-polyfills/Object.assign';
import 'mdn-polyfills/Object.entries';
import 'mdn-polyfills/Object.values';

// String
import 'mdn-polyfills/String.prototype.includes';
import 'mdn-polyfills/String.prototype.repeat';
import 'mdn-polyfills/String.prototype.startsWith';
import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/String.prototype.padStart';
import 'mdn-polyfills/String.prototype.padEnd';

// Node
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Node.prototype.before';
import 'mdn-polyfills/Node.prototype.after';
import 'mdn-polyfills/Node.prototype.remove';
import 'mdn-polyfills/Node.prototype.replaceWith';
// NodeList
import 'mdn-polyfills/NodeList.prototype.forEach';

// Element
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/Element.prototype.toggleAttribute';
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Element.prototype.classList';

// Events
import 'mdn-polyfills/MouseEvent';
import 'mdn-polyfills/CustomEvent';

// Number
import 'mdn-polyfills/Number.isInteger';
import 'mdn-polyfills/Number.isNaN';

import React from 'react';
import ReactDOM from 'react-dom';

import 'helpers/i18n';
import withAppData from 'hocs/withAppData';
import watchRerenders from 'utils/watchRerenders';

import App from './App';

if (process.env.NODE_ENV !== 'production') {
  watchRerenders();
}

// TODO: add service worker
// TODO: add lazy loading on webpack

const AppRoot = withAppData(App);

ReactDOM.render(<AppRoot />, document.getElementById('root'));
