import React from 'react';

const User = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      {...props}
    >
      <path
        id="user"
        d="M8.5,3a2.444,2.444,0,1,0,2.444,2.444A2.454,2.454,0,0,0,8.5,3Zm0,1.222A1.222,1.222,0,1,1,7.278,5.444,1.213,1.213,0,0,1,8.5,4.222Zm0,5.5a9.746,9.746,0,0,0-3.392.67,5.1,5.1,0,0,0-1.4.778A1.724,1.724,0,0,0,3,12.472V14H14V12.472a1.724,1.724,0,0,0-.713-1.3,5.1,5.1,0,0,0-1.4-.778A9.746,9.746,0,0,0,8.5,9.722Zm0,1.222a8.856,8.856,0,0,1,2.945.584,4.1,4.1,0,0,1,1.061.581c.231.192.272.323.272.363v.306H4.222v-.306c0-.04.041-.171.272-.363a4.1,4.1,0,0,1,1.061-.581A8.856,8.856,0,0,1,8.5,10.944Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export default User;
