import axios from 'axios';
import { defaultConfig } from './helpers';

const initAxiosInstance = (config = {}) => {
  const instance = axios.create({ ...defaultConfig, ...config });

  return instance;
};

export default initAxiosInstance;
