import { extendedConnect } from 'store/utils/extendedConnect';
import { getSystemTitle } from 'store/reducers/settings/selectors';
import { getLogoLinks } from 'store/reducers/settings/selectors';

const mapStateToProps = state => ({
  systemTitle: getSystemTitle(state),
  logos: getLogoLinks(state),
});

const mapDispatchToProps = {};

export default children =>
  extendedConnect(mapStateToProps, mapDispatchToProps)(children);
