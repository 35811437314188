import React from 'react';

const EmptyCheckbox = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      id="unchecked_checkbox"
      d="M4.778,3A1.791,1.791,0,0,0,3,4.778V17.222A1.791,1.791,0,0,0,4.778,19H17.222A1.791,1.791,0,0,0,19,17.222V4.778A1.791,1.791,0,0,0,17.222,3Zm0,1.778H17.222V17.222H4.778Z"
      transform="translate(-3 -3)"
    />
  </svg>
);

export default EmptyCheckbox;
