import { getLabelWithLang } from 'helpers/getLabelWithLang';

export const getTranslateKeys = initialStateData => Object
  .entries(initialStateData)
  .reduce((prevTranslateKeys, [currentKey, currentValue]) => {
    const newTranslateKeys = [...prevTranslateKeys];
    if (typeof currentValue === 'object') {
      newTranslateKeys.push({
        stateKey: currentKey,
        name: currentValue.name,
        translateKey: currentValue.translateKey,
      });
    }
    return newTranslateKeys;
  }, []);

export const getTranslatedData = (initialStateData, initialReduceObj) => {
  const translateKeys = getTranslateKeys(initialStateData);
  const translatedData = translateKeys.reduce((prevData, currentData) => {
    const { stateKey, translateKey, name } = currentData;
    const newData = {
      ...prevData,
      [stateKey]: {
        ...prevData[stateKey],
        name: getLabelWithLang(translateKey, name),
      },
    };
    return newData;
  }, initialReduceObj);
  return translatedData;
};
