import React from 'react';

const Reports = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      // fill="#8E8B92"
      d="M10.188 3.697h-4.38c-.603 0-1.096.509-1.096 1.13v9.048c0 .622.493 1.131 1.095 1.131h6.572c.602 0 1.095-.509 1.095-1.13V7.09l-3.286-3.393zM7.45 13.31H6.355V9.352H7.45v3.958zm2.19 0H8.546v-2.828h1.096v2.828zm2.191 0h-1.095v-1.697h1.095v1.697zm-2.19-5.655v-3.11l3.012 3.11H9.64z"
    />
    <path
      // fill="#8E8B92"
      d="M9.64 7.655v-3.11l3.013 3.11H9.64z"
    />
    <path
      // fill="#8E8B92"
      fillRule="evenodd"
      d="M3.52.945h6.64v1.5H3.49v9.031h-1.5v-8.98-.02c0-.123 0-.256.01-.37.01-.13.036-.301.126-.478a1.25 1.25 0 01.546-.546c.177-.09.349-.117.478-.127.115-.01.248-.01.37-.01z"
      clipRule="evenodd"
    />
  </svg>
);

export default Reports;
