import React from 'react';

const Download = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="download"
        d="M17.222,11v6.222H4.778V11H3v6.222A1.783,1.783,0,0,0,4.778,19H17.222A1.783,1.783,0,0,0,19,17.222V11Zm-5.333.6,2.3-2.293,1.253,1.253L11,15,6.556,10.556,7.809,9.3l2.3,2.293V3h1.778Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export default Download;
