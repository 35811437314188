import * as authTypes from './types';

const initialState = {
  shouldLogoutOnError: false,
};

const ACTION_HANDLERS = {
  [authTypes.EMIT_LOGOUT_ON_ERROR]: state => ({
    ...state,
    shouldLogoutOnError: true,
  }),
  [authTypes.ON_LOGOUT]: state => ({
    ...state,
    shouldLogoutOnError: false,
  }),
};

export default (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};
