/* eslint-disable no-param-reassign */
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import pLimit from 'p-limit';
import { debounce } from 'lodash';

import { getLabelWithLang } from 'helpers/getLabelWithLang';
import { FILE, FOLDER } from 'helpers/constants/subjects';
import * as notificationTypes from 'services/apiService/notificationTypes';

import {
  getFormData,
  getRelations,
  getUploadFuncParams,
  getParentFolderId,
  isFolderisFetching,
  getUploadingFilesData,
  getDataByRelations,
  TOP_LEVEL,
  validateFileSize,
} from './helpers';

const prepareFilesDownload = async () => {
  // eslint-disable-next-line no-console
  console.error('Error! Preparing files via context is not supported. See "helpers/download".');
};

const prepareUnauthFilesDownload = async () => {
  // eslint-disable-next-line no-console
  console.error('Error! Preparing files via context is not supported. See "helpers/download".');
};

const CONCURRENCY = 5;

const FilesTransferContext = createContext();

const FilesTransferContextProvider = (props) => {
  const {
    // redux funcs
    uploadFiles,
    uploadUnauthFiles,
    replyFiles,
    pushNotification,

    isAuthorized,
    children,
  } = props;

  // const [infoOrVersionError, setInfoOrVersionError] = useState(false);

  // UPLOAD

  const [uploadingFiles, setUploadingFiles] = useState({});

  // DEBUG
  // useEffect(() => {
  //   if (!printed && Object.keys(uploadingFiles).length) {
  //     const json = JSON.stringify(uploadingFiles);
  //     console.log('FilesTransferContextProvider_useEffect', { json });
  //     printed = true;
  //   }
  // });

  // при смене пользователя нужно очищать историю загрузок
  useEffect(() => {
    setUploadingFiles({});
  }, [isAuthorized]);

  const sendNotification = useCallback((type, message) => {
    const notification = {
      id: `FILE_UPLOAD_${Date.now()}`,
      type,
    };

    if (type === notificationTypes.REQUEST_ERROR) {
      notification.data = { message: (message || getLabelWithLang('upload_failed', 'Ошибка загрузки')) };
    }

    pushNotification(notification);
  }, [pushNotification]);

  /** обновление состояния - добавление новой пачки загружаемых файлов */
  const appendUploadingFile = useCallback((timestamp, uploadingFiles) => {
    setUploadingFiles(prevState => ({
      ...prevState,
      [timestamp]: uploadingFiles,
    }));
  }, [setUploadingFiles]);

  /** Устанваливаем фаг isFetching загрузившемуся файлу, в случае ошибки isError: true */
  const setFileUploaded = useCallback((file, timestamp, isError) => {
    const { path } = file;
    setUploadingFiles((prevState) => {
      const { data, uploadParentId } = prevState[timestamp];
      const fileId = `${uploadParentId}/${path}`.replace('//', '/');
      const newData = {
        ...data,
        [fileId]: {
          ...data[fileId],
          isFetching: false,
          isError,
        },
      };
      // если есть родительская папка и она в состоянии загрузки
      const parentFolderId = getParentFolderId(fileId);
      if (parentFolderId && newData[parentFolderId] && newData[parentFolderId].isFetching) {
        newData[parentFolderId].isFetching = isFolderisFetching(parentFolderId, newData);
      }
      return {
        ...prevState,
        [timestamp]: {
          ...prevState[timestamp],
          data: newData,
        },
      };
    });
  }, [setUploadingFiles]);

  /** обновление прогресса загрузки в общей стректуре */
  const updateProgress = useCallback(debounce((event, timestamp, file) => {
    const { loaded } = event;
    const { path } = file;
    setUploadingFiles((prevState) => {
      const { data, uploadParentId } = prevState[timestamp];
      const fileId = `${uploadParentId}/${path}`.replace('//', '/');
      return {
        ...prevState,
        [timestamp]: {
          ...prevState[timestamp],
          loadedSize: loaded,
          data: {
            ...data,
            [fileId]: {
              ...data[fileId],
              loadedSize: loaded,
            },
          },
        },
      };
    });
  }, 100), [setUploadingFiles]);

  /** проставляет флаг завершения загрузки по всему дереву */
  const setAllFilesUploaded = useCallback((timestamp) => {
    setUploadingFiles((prevState) => {
      const { data } = prevState[timestamp];
      const newData = { ...data };
      Object.values(newData).forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.isFetching = false;
      });
      return {
        ...prevState,
        [timestamp]: {
          ...prevState[timestamp],
          data: newData,
          isFetching: false,
        },
      };
    });
  }, [setUploadingFiles]);

  /** Загружает файл  */
  const uploadNextFile = useCallback(async (
    file,
    params,
    timestamp,
    progressFunc,
  ) => {
    const { isPbl } = params;
    const uploadFunc = isPbl ? uploadUnauthFiles : uploadFiles;
    const formData = getFormData(file);
    const uploadFuncParams = getUploadFuncParams(params, formData, progressFunc);
    const task = uploadFunc(uploadFuncParams);
    task
      // .finally(() => console.log('_newUpload', file.path))
      .then(() => setFileUploaded(file, timestamp, false))
      .catch(() => setFileUploaded(file, timestamp, true));

    return task;
  }, [setFileUploaded, uploadFiles, uploadUnauthFiles]);

  /** Новая функция загрузки файлов
   * каждый файл в отдельном потоке, отслеживает прогресс по каждому файлу,
   * обновляя структуру загружаемых файлов возвращает Promise */
  const uploadAll = useCallback(async (params, timestamp) => {
    sendNotification(notificationTypes.UPLOAD_FILES_REQUEST);

    const { files } = params;

    // https://github.com/sindresorhus/p-limit
    // параллельная загрузка файлов с ограничением в 5 одновременных соединений с сервером.
    const limit = pLimit(CONCURRENCY);
    const tasks = files.map(file => limit(() => uploadNextFile(
      file,
      params,
      timestamp,
      event => updateProgress(event, timestamp, file),
    )));

    return Promise.all(tasks)
      .then(() => setAllFilesUploaded(timestamp))
      .then(() => sendNotification(notificationTypes.UPLOAD_FILES_REQUEST_SUCCESS))
      .catch(() => sendNotification(notificationTypes.REQUEST_ERROR));
  }, [sendNotification, setAllFilesUploaded, updateProgress, uploadNextFile]);

  const emitUploadFiles = useCallback((params) => {
    const {
      folder, // guid
      files, // []
    } = params;

    if (!validateFileSize(files)) {
      sendNotification(notificationTypes.REQUEST_ERROR, getLabelWithLang('max_upload_size', 'Максимально 2GB. Для большего объема используйте версию с клиентом MFlash.'));
      return;
    }

    const timestamp = Date.now();

    const uploadingFilesData = getUploadingFilesData(folder, files);

    const {
      filesFormData, // форма для отправки
      uploadingData, // объект ключ - значение (ключ - полный путь с именем папки или файла) (значение - инфа по файлу или папке)
      paths, // массив полных путей с именами файлов
      generalSize, // общий объем
      generalCount, // кол-во файлов (без папок)
    } = uploadingFilesData;

    const relations = getRelations(folder, uploadingData);

    // основная структура хранения информации о загружаемых файлах
    // пачка файлов маппится на timestamp
    // ХЗ для чего было выбрано такое и почему она так сложно собирается, но сейчас все переписывать опасно
    // Надо приспособиться использовать ее
    const nextUploadingFiles = {
      data: uploadingData,
      relations,
      isFetching: true,
      isWatching: true,
      uploadParentId: folder,
      size: generalSize,
      count: generalCount,
      loadedSize: 0,
    };

    // В этот объект НЕ попадают данные формы.
    // Фактически, это нужно только для отображения загружаемых файлов в интерфесе.
    // На процесс и результат загрузки не влияет.
    appendUploadingFile(timestamp, nextUploadingFiles);

    // (старая схема)
    // данные закинуты в стейт (nextUploadingFiles)
    // далее нужно вызвать АПИ с параметрами и дождаться загрузки.
    // После переключить флаг у пачки или удалить саму пачку, если в процессе
    // загрузки был нажат крестик
    filesFormData.append('paths', JSON.stringify(paths));

    return uploadAll(params, timestamp);
  }, [appendUploadingFile, sendNotification, uploadAll]);

  /** проставляет isWatching: false для выбранного файла */
  const removeWatchingByTimestamp = useCallback((timestamp) => {
    setUploadingFiles((prevState) => {
      if (!prevState[timestamp]) {
        return prevState;
      }

      return {
        ...prevState,
        [timestamp]: {
          ...prevState[timestamp],
          isWatching: false,
        },
      };
    });
  }, [setUploadingFiles]);

  /** получить массив файлов и папок верхнего уровня
   * файлы будут отображаться в полупрозрачнмм цвете */
  const getUploadingTopLevelByFolderId = useCallback((folderId) => {
    const result = Object.values(uploadingFiles)
      .reduce((currentUploadingFiles, uploadData) => {
        // если вся пачка файлов уже загружена
        if (!uploadData.isFetching) {
          return currentUploadingFiles;
        }
        // поиск верхнеуровневых файлов и папок
        Object.values(uploadData.data)
          .forEach((item) => {
            // если верхреуровневый объект еще в процессе загрузки
            if (item.parentId === folderId && item.isFetching) {
              let needAdd = true;
              // если это папка
              if (item.type === FOLDER) {
                const { name } = item;
                Object.values(uploadData.data)
                  .forEach((file) => {
                    // если есть хотя бы один уже загруженный файл в этой папке,
                    // то сама папка уже пришла через pooling и ее не надо добавлять для отображения
                    if (file.type === FILE && !file.isFetching) {
                      const [, folderName] = file.id.split('/').filter(x => x);
                      if (folderName === name) {
                        needAdd = false;
                      }
                    }
                  });
              }
              if (needAdd) {
                currentUploadingFiles.push(item);
              }
            }
          });
        return currentUploadingFiles;
      }, []);
    return result;
  }, [uploadingFiles]);

  /** Возвращает структуру данных для отображения суммарной информации о всех загрузках */
  const getGeneralUploadingData = useCallback(() => {
    const initData = {
      loadedCount: 0,
      loadedSize: 0,
      count: 0,
      size: 0,
      speed: 0, // пока не используется
      timeLeft: 0, // пока не используется
    };
    const result = Object
      .values(uploadingFiles)
      .reduce((current, filesDataByTimestamp) => {
        const { data } = filesDataByTimestamp;
        Object.values(data)
          .filter(file => file && file.type === FILE)
          .forEach((file) => {
            const { isFetching, loadedSize, size } = file;
            current.count += 1;
            current.size += size;
            current.loadedSize += loadedSize;
            // файл считается загруженным, если у него не установлен флаг загружки
            // и при этом кол - во загруженных байт > 0
            if (!isFetching && loadedSize > 0) {
              current.loadedCount += 1;
            }
          });
        return current;
      }, initData);

    // кол-во загруженных байт по файлу в событии onprogress приходит немного больше,
    // чем общий размер, который отдает проводник.
    // по этому суммарный загруженный объем по файлу может получиться больше размера файла.
    if (result.loadedSize > result.size) {
      result.loadedSize = result.size;
    }
    return result;
  }, [uploadingFiles]);

  const getUploadingDataByTimestamp = useCallback(() => {
    const uploadingDataByTimestamp = Object.entries(uploadingFiles)
      .reduce(
        (currentUploadingData, uploadEntry) => {
          const [timestamp, uploadData] = uploadEntry;

          const { data, relations, isFetching, isError, isWatching } = uploadData;

          if (!isWatching) {
            return currentUploadingData;
          }

          const aggregatedData = getDataByRelations({
            relationKey: TOP_LEVEL,
            relations,
            data,
          });

          currentUploadingData[timestamp] = {
            data: aggregatedData,
            isError,
            isFetching,
          };

          return currentUploadingData;
        },
        {},
      );

    const sortedData = Object.keys(uploadingDataByTimestamp)
      .sort((a, b) => b - a)
      .map((timestamp) => {
        const { data, isError = false, isFetching } = uploadingDataByTimestamp[
          timestamp
        ];

        return {
          timestamp,
          data,
          isError,
          isFetching,
        };
      });

    return sortedData;
  }, [uploadingFiles]);

  const emitReplyFiles = useCallback((params) => {
    const {
      files,
      deletedFilesIds = [],
      receiver,
      message,
    } = params;

    if (!validateFileSize(files)) {
      sendNotification(notificationTypes.REQUEST_ERROR, getLabelWithLang('max_upload_size', 'Максимально 2GB. Для большего объема используйте версию с клиентом MFlash.'));
      return;
    }

    const { filesFormData } = files.reduce(
      (currentData, file, fileIndex) => {
        if (!deletedFilesIds.includes(fileIndex)) {
          currentData.filesFormData.append(`files[${fileIndex}]`, file);
        }
        return currentData;
      },
      { filesFormData: new FormData() },
    );

    filesFormData.append('receiver', String(receiver));
    filesFormData.append('message', message || '');

    return replyFiles({ files: filesFormData });
  }, [replyFiles, sendNotification]);

  const value = useMemo(() => ({
    // UPLOAD

    // src/mobile/.../DirectoriesBrowseModule.js
    // src/mobile/.../PublicLinkModule.js
    // src/mobile/.../ShareFilesModule.js
    // src/web/.../FileManager.js
    // src/web/.../FileUploadContent.js
    // src/web/.../OldModalLayout.js
    // src/web/.../UploadFilesModal.js
    uploadFiles: emitUploadFiles,

    // src/web/.../FileManager.js
    getUploadingTopLevelByFolderId,

    // src/mobile/.../DownloadsModule.js
    // SidePanel/.../DownloadsSidePanel.js
    getGeneralUploadingData,

    // src/mobile/.../DownloadsModule.js
    // SidePanel/.../DownloadsSidePanel.js
    getUploadingDataByTimestamp,

    // src/mobile/.../DownloadsModule.js
    // SidePanel/.../DownloadsSidePanel.js
    removeWatchingByTimestamp,

    // src/mobile/...ReplyModule.js
    // src/web/.../OldModalLayout.js
    // src/web/.../ReplyModal.js
    replyFiles: emitReplyFiles,

    // DOWNLOAD
    prepareFilesDownload,
    prepareUnauthFilesDownload,
    // INFO
    // infoOrVersionError,
    // setInfoOrVersionError,
  }), [
    emitReplyFiles,
    emitUploadFiles,
    getGeneralUploadingData,
    getUploadingDataByTimestamp,
    getUploadingTopLevelByFolderId,
    removeWatchingByTimestamp,
  ]);

  return (
    <FilesTransferContext.Provider value={value}>
      {children}
    </FilesTransferContext.Provider>
  );
};

FilesTransferContextProvider.propTypes = {
  uploadFiles: PropTypes.func.isRequired,
  replyFiles: PropTypes.func.isRequired,
  uploadUnauthFiles: PropTypes.func.isRequired,
  children: PropTypes.any,
  pushNotification: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
};

export { FilesTransferContext as default, FilesTransferContextProvider };

/**
---------------------------------------------------------------------------------------------------
emitUploadFiles:
  params:
    files: [
      // https://developer.mozilla.org/ru/docs/Web/API/File
      File() {
              lastModified: 1593596000000
              lastModifiedDate: Wed Jul 01 2020 12:33:20 GMT+0300 (Москва, стандартное время) {}
              name: "тестовый файл 22.pdf"
              path: "/Корневая папка 0/Корневая папка 1/Вложенная папка 1/тестовый файл 22.pdf"
              size: 647570
              type: "application/pdf"
              webkitRelativePath: ""
      }
    ]
    firstPath: "flash"
    flashId: "ff490a37-9c2e-11eb-9355-86128f9c4130"
    folder: "ff4c7d5a-9c2e-11eb-9355-86128f9c4130"
    parentType: "flash"
---------------------------------------------------------------------------------------------------
 */
