import React from 'react';

const Trash = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100.8"
      height="112"
      viewBox="0 0 100.8 112"
      {...props}
    >
      <path
        id="icons8-remove"
        d="M42.2,2,36.6,7.6H3V18.8H103.8V7.6H70.2L64.6,2ZM10.645,30,19.2,104.277A11.237,11.237,0,0,0,30.3,114H76.489A11.247,11.247,0,0,0,87.6,104.233L96.155,30Z"
        transform="translate(-3 -2)"
      />
    </svg>
  );
};

export default Trash;
