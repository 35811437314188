import React from 'react';

const Distribute = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.846"
      height="14"
      viewBox="0 0 11.846 14"
      {...props}
    >
      <path
        id="download"
        d="M9.923,1a.539.539,0,0,0-.538.538V3.692h1.077V1.538A.539.539,0,0,0,9.923,1Zm.538,2.692v6.777L11.7,9.235a.538.538,0,0,1,.761.761L10.3,12.15a.538.538,0,0,1-.761,0L7.389,10a.538.538,0,1,1,.761-.761l1.235,1.235V3.692H5.077A1.077,1.077,0,0,0,4,4.769v9.154A1.077,1.077,0,0,0,5.077,15h9.692a1.077,1.077,0,0,0,1.077-1.077V4.769a1.077,1.077,0,0,0-1.077-1.077Z"
        transform="translate(-4 -1)"
      />
    </svg>
  );
};

export default Distribute;
