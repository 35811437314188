export const defaultConfig = {
  validateStatus(status) {
    return status >= 200 && status < 300;
  },
  maxContentLength: 300000,
};

export const createBaseUrl = (host, port, url = '') => {
  const baseUrl = `${host}${port ? `:${port}` : ''}/${url}`;

  return baseUrl;
};

export const baseURL = ((envs) => {
  const {
    REACT_APP_API_HOST: host,
    REACT_APP_API_PORT: port,
    REACT_APP_API_PATH: path,
  } = envs;

  return createBaseUrl(host, port, path);
})(process.env);

export const xmfClientAccessKey = ((envs) => {
  const { REACT_APP_XMF_CLIENT_ACCESS_KEY } = envs;
  return REACT_APP_XMF_CLIENT_ACCESS_KEY;
})(process.env);
