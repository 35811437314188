import { extendedConnect } from 'store/utils/extendedConnect';
import { getIsAuthorized } from 'store/reducers/auth/selectors';
import {
  uploadFiles,
  replyFiles,
  uploadUnauthFiles,
} from 'store/reducers/files/actions';
import { pushNotification } from 'store/reducers/clientNotifications/actions';

const mapStateToProps = state => ({
  isAuthorized: getIsAuthorized(state.auth.auth),
});

const mapDispatchToProps = {
  uploadFiles,
  replyFiles,
  uploadUnauthFiles,
  pushNotification,
};

export default Component => extendedConnect(mapStateToProps, mapDispatchToProps)(Component);
