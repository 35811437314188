import React from 'react';

const NonSync = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      id="non_sync"
      d="M9.441,6.166V4.24a7.645,7.645,0,0,0-1.733.691L9.09,6.313c.12-.046.23-.1.35-.147Zm9.217,5.207a7.3,7.3,0,0,0-2.175-5.2L18.657,4h-5.53V9.53l2.065-2.065a5.533,5.533,0,0,1,1.622,3.908,5.441,5.441,0,0,1-.47,2.194l1.382,1.382A7.3,7.3,0,0,0,18.657,11.373ZM4.16,4,2.86,5.3,5.035,7.475a7.308,7.308,0,0,0,1.051,9.1L3.911,18.747h5.53v-5.53L7.376,15.281a5.516,5.516,0,0,1-1-6.461l7.447,7.447a6.773,6.773,0,0,1-.7.313v1.926a7.576,7.576,0,0,0,2.055-.885L17.56,20l1.3-1.3Z"
      transform="translate(-2.86 -4)"
    />
  </svg>
);

export default NonSync;
