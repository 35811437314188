import React from 'react';

const EmptyUpload = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="113"
    height="113"
    viewBox="0 0 113 113"
    {...props}
  >
    <g id="Group_824" data-name="Group 824" transform="translate(-1674 -414)">
      <circle
        id="Ellipse_30"
        data-name="Ellipse 30"
        cx="56.5"
        cy="56.5"
        r="56.5"
        transform="translate(1674 414)"
        fill="#ececec"
      />
      <path
        id="Subtraction_14"
        data-name="Subtraction 14"
        d="M-18980.408-352.815H-19037v-5h51.588l5,5Zm3.412-.827,0,0-4.17-4.17h4.172v4.172Zm-25-16.673h-10V-384.4l10,10v4.083Zm0-8.324,0,0-10-10v-4.177h-4.178l-2.639-2.638,11.814-16.108,13.752,18.746h-8.748v14.178Z"
        transform="translate(20737.994 852.565)"
        fill="#f9faf9"
      />
      <rect
        id="Rectangle_322"
        data-name="Rectangle 322"
        width="90"
        height="3"
        transform="translate(1700.799 434.447) rotate(45)"
        fill="#fafbfa"
      />
    </g>
  </svg>
);

export default EmptyUpload;
