import { getLabelWithLang } from 'helpers/getLabelWithLang';

export default {
  storage: {
    id: 1,
    type: 'storage',
    name: getLabelWithLang('flash_list', 'Виртуальные накопители'),
    translateKey: 'flash_list',
    icon: 'virtual',
    parentId: null,
  },
  favorite: {
    id: 3,
    type: 'favorite',
    name: getLabelWithLang('added_to_favorites', 'Добавленные в избранное'),
    translateKey: 'added_to_favorites',
    icon: 'favorite',
    parentId: 'storage',
  },
  created: {
    id: 4,
    type: 'created',
    name: getLabelWithLang('me_created', 'Созданное мной'),
    translateKey: 'me_created',
    icon: 'personal',
    parentId: 'storage',
  },
  available: {
    id: 5,
    type: 'available',
    name: getLabelWithLang('shared_to_me', 'Доступные мне'),
    translateKey: 'shared_to_me',
    icon: 'people',
    parentId: 'storage',
  },
  basket: {
    id: 6,
    type: 'basket',
    name: getLabelWithLang('trash', 'Корзина'),
    translateKey: 'trash',
    icon: 'trash',
    parentId: 'storage',
  },
  exchange: {
    id: 2,
    type: 'exchange',
    name: getLabelWithLang('exchange', 'Обмен файлами'),
    translateKey: 'exchange',
    icon: 'share',
    parentId: 'exchange',
  },
  received: {
    id: 7,
    type: 'received',
    name: getLabelWithLang('mailbox_inbox', 'Полученные'),
    translateKey: 'mailbox_inbox',
    icon: 'arrow',
    parentId: 'exchange',
  },
  sent: {
    id: 8,
    type: 'sent',
    name: getLabelWithLang('mailbox_outbox', 'Отправленные'),
    translateKey: 'mailbox_outbox',
    icon: 'arrow',
    parentId: 'exchange',
  },
  publiclink: {
    id: 9,
    type: 'publiclink',
    name: getLabelWithLang('public_link', 'Публичные ссылки'),
    translateKey: 'public_link',
    icon: 'send',
    parentId: 'publiclink',
  },
};
