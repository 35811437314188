import React from 'react';

const Load = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10.666"
      viewBox="0 0 16 10.666"
      {...props}
    >
      <path
        id="upload"
        d="M12.469,6.336A4.661,4.661,0,0,0,4.172,5,4.339,4.339,0,0,0,.044,9.958a4.429,4.429,0,0,0,4.432,3.708H7.333v-4H5.89a.278.278,0,0,1-.2-.475L7.8,7.082a.278.278,0,0,1,.393,0l2.11,2.11a.278.278,0,0,1-.2.475H8.666v4h3.511a3.774,3.774,0,0,0,3.8-3.251A3.671,3.671,0,0,0,12.469,6.336Z"
        transform="translate(0 -3)"
      />
    </svg>
  );
};

export default Load;
