export default {
  COLLABORA_INIT: 'COLLABORA_INIT',

  WEB_OFFICE_ACCESS_TOKEN: 'WEB_OFFICE_ACCESS_TOKEN',
  WEB_OFFICE_ACCESS_TOKEN_SUCCESS: 'WEB_OFFICE_ACCESS_TOKEN_SUCCESS',
  WEB_OFFICE_ACCESS_TOKEN_FAIL: 'WEB_OFFICE_ACCESS_TOKEN_FAIL',

  WEB_OFFICE_FILE_LOCK: 'WEB_OFFICE_FILE_LOCK',
  WEB_OFFICE_FILE_LOCK_SUCCESS: 'WEB_OFFICE_FILE_LOCK_SUCCESS',
  WEB_OFFICE_FILE_LOCK_FAIL: 'WEB_OFFICE_FILE_LOCK_FAIL',
};