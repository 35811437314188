import * as registrationSettingsTypes from './types';

// TODO: decompose for inner reducers with combineReducers
const ACTION_HANDLERS = {
  [registrationSettingsTypes.GET_REGISTRATION_SETTINGS]: state => ({
    ...state,
    data: null,
    isFetching: true,
  }),
  [registrationSettingsTypes.GET_REGISTRATION_SETTINGS_SUCCESS]: (
    state,
    { payload },
  ) => ({
    ...state,
    data: payload,
    isFetching: false,
    isLoaded: true,
  }),
  [registrationSettingsTypes.GET_REGISTRATION_SETTINGS_FAIL]: state => ({
    ...state,
    data: null,
    isFetching: false,
  }),
};

const defaultState = {
  data: null,
  isFetching: false,
  isLoaded: false,
};

export default (state = defaultState, action) => (
  ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state
);
