import React from 'react';

const ArrowDown = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="arrow_down"
        d="M4,12l1.41,1.41L11,7.83V20h2V7.83l5.58,5.59L20,12,12,4Z"
        transform="translate(20 20) rotate(180)"
      />
    </svg>
  );
};

export default ArrowDown;
