import React from 'react';

const EmptyTrash = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="125.262"
    height="112"
    viewBox="0 0 125.262 112"
    {...props}
  >
    <g
      id="Component_205_1"
      data-name="Component 205 – 1"
      transform="translate(0.631)"
    >
      <path
        id="icons8-remove"
        d="M42.2,2,36.6,7.6H3V18.8H103.8V7.6H70.2L64.6,2ZM10.645,30,19.2,104.277A11.237,11.237,0,0,0,30.3,114H76.489A11.247,11.247,0,0,0,87.6,104.233L96.155,30Z"
        transform="translate(11.1 -2)"
      />
      <line
        id="Line_69"
        data-name="Line 69"
        x2="123"
        y2="100"
        transform="translate(0 6.5)"
        fill="none"
        strokeWidth="2"
      />
      <line
        id="Line_70"
        data-name="Line 70"
        x2="123"
        y2="100"
        transform="translate(1 4.5)"
        fill="none"
        strokeWidth="2"
        stroke="#f8f8f8"
      />
    </g>
  </svg>
);

export default EmptyTrash;
