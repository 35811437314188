/**
 * @description creating async actions for requesting data through redux
 *
 * @return function (dispatch)
 */
export const createRequestAction = actionCreator => (args) => {
  const {
    types = [],
    apiRequest,
    ...restProperties
  } = actionCreator(args);

  if (!Array.isArray(types) || types.length !== 3 || !apiRequest) {
    // eslint-disable-next-line no-console
    console.error('Action dispatch error: invalid format of request action!');
    return;
  }

  const [
    REQUEST,
    REQUEST_SUCCESS,
    REQUEST_FAIL,
  ] = types;

  return dispatch => new Promise((resolve, reject) => {
    dispatch({ type: REQUEST, ...restProperties });

    apiRequest()
      .then(({ data: payload }) => {
        dispatch({
          type: REQUEST_SUCCESS,
          payload,
          ...restProperties,
        });
        return resolve(payload);
      })
      .catch((error) => {
        dispatch({
          type: REQUEST_FAIL,
          error,
          ...restProperties,
        });
        return reject(error);
      });
  });
};
