export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
export const CHECK_USER_FAIL = 'CHECK_USER_FAIL';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const LOGOUT_ALL = 'LOGOUT_ALL';
export const LOGOUT_ALL_SUCCESS = 'LOGOUT_ALL_SUCCESS';
export const LOGOUT_ALL_FAIL = 'LOGOUT_ALL_FAIL';

export const AUTHORIZE = 'AUTHORIZE';
export const DEAUTHORIZE = 'DEAUTHORIZE';

export const RESET_USER_CACHE = 'RESET_USER_CACHE';

// export const CHECK_TWO_FACTOR_AUTH = 'CHECK_TWO_FACTOR_AUTH';
// export const CHECK_TWO_FACTOR_AUTH_SUCCESS = 'CHECK_TWO_FACTOR_AUTH_SUCCESS';
// export const CHECK_TWO_FACTOR_AUTH_FAIL = 'CHECK_TWO_FACTOR_AUTH_FAIL';

// export const SEND_PIN = 'SEND_PIN';
// export const SEND_PIN_SUCCESS = 'SEND_PIN_SUCCESS';
// export const SEND_PIN_FAIL = 'SEND_PIN_FAIL';

export const AUTO_LOGIN = 'AUTO_LOGIN';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const AUTO_LOGIN_FAIL = 'AUTO_LOGIN_FAIL';

export const LOGOUT_ON_ERROR = 'LOGOUT_ON_ERROR';
export const CLEAR_USER = 'CLEAR_USER';

export const LOGIN_INSTANCE = 'LOGIN_INSTANCE';
export const LOGIN_INSTANCE_SUCCESS = 'LOGIN_INSTANCE_SUCCESS';
export const LOGIN_INSTANCE_FAIL = 'LOGIN_INSTANCE_FAIL';
