import React from 'react';

const VersionsEmptyControl = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="129.317"
    height="116.505"
    viewBox="0 0 129.317 116.505"
    {...props}
  >
    <g id="empty_version_control" transform="translate(-1670.341 -362.747)">
      <path
        id="icons8-versions"
        d="M51.357,7.37A2.447,2.447,0,0,0,49.13,9.826V113a2.461,2.461,0,0,0,2.457,2.457h63.869A2.461,2.461,0,0,0,117.913,113V9.6a2.447,2.447,0,0,0-2.457-2.226h-64.1Zm2.687,4.913H113v98.261H54.043ZM26.561,17.2a2.456,2.456,0,0,0-2,2.457v83.521a2.461,2.461,0,0,0,2.457,2.457h17.2v-4.913H29.478V22.109H44.217V17.2H26.561ZM6.909,27.022a2.456,2.456,0,0,0-2,2.457V93.348A2.461,2.461,0,0,0,7.37,95.8H19.652V90.891H9.826V31.935h9.826V27.022H6.909Z"
        transform="translate(1671.087 355.489)"
      />
      <g id="Group_677" data-name="Group 677" transform="translate(0.5)">
        <line
          id="Line_99"
          data-name="Line 99"
          x2="128"
          y2="112"
          transform="translate(1670.5 363.5)"
          fill="none"
          stroke="#f9f9f9"
          strokeWidth="2"
        />
        <line
          id="Line_69"
          data-name="Line 69"
          x2="128"
          y2="112"
          transform="translate(1670.5 366.5)"
          fill="none"
          stroke="#dadad9"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export default VersionsEmptyControl;
