import { getLabelWithLang } from 'helpers/getLabelWithLang';

export default {
  data: {
    storage: {
      id: 1,
      type: 'storage',
      translateKey: 'flash_list',
      name: getLabelWithLang('flash_list', 'Виртуальные накопители'),
      icon: 'virtual',
      parentId: null,
    },
    favorite: {
      id: 3,
      type: 'favorite',
      translateKey: 'added_to_favorites',
      name: getLabelWithLang('added_to_favorites', 'Добавленные в избранное'),
      icon: 'favorite',
      parentId: 'storage',
    },
    created: {
      id: 4,
      type: 'created',
      translateKey: 'me_created',
      name: getLabelWithLang('me_created', 'Созданное мной'),
      icon: 'personal',
      parentId: 'storage',
    },
    available: {
      id: 5,
      type: 'available',
      translateKey: 'shared_to_me',
      name: getLabelWithLang('shared_to_me', 'Доступные мне'),
      icon: 'people',
      parentId: 'storage',
    },
    basket: {
      id: 6,
      type: 'basket',
      translateKey: 'trash',
      name: getLabelWithLang('trash', 'Корзина'),
      icon: 'trash',
      parentId: 'storage',
    },
    exchange: {
      id: 2,
      type: 'exchange',
      translateKey: 'exchange',
      name: getLabelWithLang('exchange', 'Обмен файлами'),
      icon: 'share',
      parentId: null,
    },
    received: {
      id: 7,
      type: 'received',
      translateKey: 'mailbox_inbox',
      name: getLabelWithLang('mailbox_inbox', 'Полученные'),
      icon: 'arrow',
      parentId: 'exchange',
    },
    sent: {
      id: 8,
      type: 'sent',
      translateKey: 'mailbox_outbox',
      name: getLabelWithLang('mailbox_outbox', 'Отправленные'),
      icon: 'arrow',
      parentId: 'exchange',
    },
    publiclink: {
      id: 14,
      type: 'publiclink',
      translateKey: 'public_link',
      name: getLabelWithLang('public_link', 'Публичные ссылки'),
      icon: 'send',
      parentId: null,
    },
    publiclinkSubFolder: {
      id: 12,
      type: 'publiclink',
      translateKey: 'public_link',
      name: getLabelWithLang('public_link', 'Публичные ссылки'),
      icon: 'send',
      parentId: 'publiclink',
    },
    unauthpubliclink: {
      id: 13,
      type: 'unauthpubliclink',
      translateKey: 'public_link',
      name: getLabelWithLang('public_link', 'Публичная ссылка'),
      icon: 'send',
      parentId: null,
    },
    // ]
    //   id: 9,
    //   type: 'receivedSubFolder',
    //   name: 'Полученные подпапки',
    //   icon: 'arrow',
    //   parentId: null,
    // ,
  },
  isFetchingList: false,
  isFetchingDirectionList: false,
  isFetchingSubdirectionList: false,
  isFetchingBasket: false,
  isFetchingDeleteFlash: false,
  isFirstTimeLoad: true,
  isLoaded: true,
  errorMessage: '',
  count: 5,
};
