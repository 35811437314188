import React from 'react';

const EmptyDevices = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125.262"
      height="103.552"
      viewBox="0 0 125.262 103.552"
      {...props}
    >
      <g
        id="Group_417"
        data-name="Group 417"
        transform="translate(-1101.869 -307.724)"
      >
        <g
          id="Component_205_1"
          data-name="Component 205 – 1"
          transform="translate(1102 308.5)"
        >
          <path
            id="icons8-multiple-devices"
            d="M18.833,4a9.487,9.487,0,0,0-9.417,9.417V60.5a9.487,9.487,0,0,0,9.417,9.417H0v9.417H61.208V60.5H18.833V13.417h84.75A9.487,9.487,0,0,0,94.167,4ZM80.042,22.833a9.426,9.426,0,0,0-9.417,9.417V79.333a9.426,9.426,0,0,0,9.417,9.417h23.542A9.426,9.426,0,0,0,113,79.333V32.25a9.426,9.426,0,0,0-9.417-9.417Zm0,9.417h23.542V74.625H80.042Z"
            transform="translate(0 4.5)"
            fill="#ececec"
          />
          <line
            id="Line_69"
            data-name="Line 69"
            x2="123"
            y2="100"
            transform="translate(0.5 2)"
            fill="none"
            stroke="#ececec"
            strokeWidth="2"
          />
          <line
            id="Line_70"
            data-name="Line 70"
            x2="123"
            y2="100"
            transform="translate(1.5)"
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default EmptyDevices;
