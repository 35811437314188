import React from 'react';

const GuideSettings = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 21 21">
    <defs>
      <filter
        id="guide_settings"
        x="0"
        y="0"
        width="21"
        height="21"
        filterUnits="userSpaceOnUse"
      >
        <feOffset input="SourceAlpha" />
        <feGaussianBlur stdDeviation="1.5" result="blur" />
        <feFlood floodColor="#d7baf9" floodOpacity="0.4" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#guide_settings)">
      <path
        id="guide_settings-2"
        data-name="guide_settings"
        d="M6.747,2l-.3,1.514a4.876,4.876,0,0,0-1.37.775l-1.48-.5L2.172,6.213,3.357,7.225A4.723,4.723,0,0,0,3.288,8a4.8,4.8,0,0,0,.069.775h0L2.172,9.788,3.6,12.213l1.479-.5a4.877,4.877,0,0,0,1.371.773L6.747,14H9.6l.3-1.514a4.87,4.87,0,0,0,1.37-.775l1.48.5,1.425-2.425L12.987,8.775a4.366,4.366,0,0,0,0-1.548h0l1.185-1.014L12.746,3.787l-1.479.5A4.876,4.876,0,0,0,9.9,3.514L9.6,2ZM7.753,3.2H8.59l.237,1.2.637.237a3.643,3.643,0,0,1,1.028.579l.527.424,1.173-.4.419.711-.937.8.107.66h0a3.188,3.188,0,0,1,0,1.165l-.108.66.937.8-.418.713-1.172-.4-.528.425a3.637,3.637,0,0,1-1.027.579h0l-.637.237-.237,1.2H7.753l-.237-1.2-.637-.237a3.645,3.645,0,0,1-1.028-.579l-.527-.424-1.173.4-.419-.711.938-.8-.109-.657h0A3.634,3.634,0,0,1,4.509,8a3.548,3.548,0,0,1,.054-.582l.109-.66-.938-.8.419-.713,1.173.4.527-.425a3.643,3.643,0,0,1,1.028-.579L7.516,4.4Zm.418,2.4A2.4,2.4,0,1,0,10.614,8,2.431,2.431,0,0,0,8.172,5.6Zm0,1.2A1.2,1.2,0,1,1,6.951,8,1.205,1.205,0,0,1,8.172,6.8Z"
        transform="translate(2.33 2.5)"
      />
    </g>
  </svg>
);

export default GuideSettings;
