import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { getLocalStorageItem } from 'helpers/getLocalStorageItem';

const MainContentLayoutContext = createContext();

const MainContentLayoutState = ({ children }) => {
  const defaultLayout = 'fileManager';
  const [layout, setLayout] = useState();
  const [layoutHistory, setLayoutHistory] = useState([]);
  const [tableView, setTableView] = useState(getLocalStorageItem('tableView', false));
  const [sizeView, setSizeView] = useState(getLocalStorageItem('sizeView', 100));

  const handleLayout = layout => (layout ? setLayout(layout) : setLayout(defaultLayout));

  const [copyConflictDetails, setCopyConflictDetails] = useState({});
  const [deviceHelpShow, setDeviceHelpShow] = useState(false);
  const [isCopyMoveModalOpened, copyMoveOpened] = useState(false);

  const setModalOpened = (value) => {
    copyMoveOpened(value);
    if (!value) {
      document.body.style.cursor = 'unset';
    }
  };

  const value = useMemo(() => ({
    defaultLayout,
    layout,
    setLayout: handleLayout,
    layoutHistory,
    setLayoutHistory,
    tableView,
    setTableView,
    sizeView,
    setSizeView,
    setModalOpened,
    isCopyMoveModalOpened,
    copyConflictDetails,
    setCopyConflictDetails,
    deviceHelpShow,
    setDeviceHelpShow,
  }), [
    copyConflictDetails,
    deviceHelpShow,
    isCopyMoveModalOpened,
    layout,
    layoutHistory,
    sizeView,
    tableView,
  ]);

  return (
    <MainContentLayoutContext.Provider value={value}>
      {children}
    </MainContentLayoutContext.Provider>
  );
};

MainContentLayoutState.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};

MainContentLayoutState.defaultProps = {
  children: {},
};

export { MainContentLayoutContext as default, MainContentLayoutState };
