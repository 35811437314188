import React from 'react';

const Eye = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="10.909"
    viewBox="0 0 15 10.909"
    {...props}
  >
    <path
      id="eye"
      d="M8.5,4C3.045,4,1,9.455,1,9.455s2.045,5.455,7.5,5.455S16,9.455,16,9.455,13.955,4,8.5,4Zm0,2.045A3.409,3.409,0,1,1,5.091,9.455,3.409,3.409,0,0,1,8.5,6.045Zm0,1.364a2.045,2.045,0,1,0,2.045,2.045A2.045,2.045,0,0,0,8.5,7.409Z"
      transform="translate(-1 -4)"
    />
  </svg>
);

export default Eye;
