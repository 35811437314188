import React from 'react';

const Copy = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      {...props}
    >
      <path
        id="copy"
        d="M12.316,1H3.474A1.469,1.469,0,0,0,2,2.455V12.636H3.474V2.455h8.842Zm-.737,2.909H6.421A1.462,1.462,0,0,0,4.955,5.364L4.947,15.545A1.462,1.462,0,0,0,6.414,17h8.113A1.469,1.469,0,0,0,16,15.545V8.273ZM6.421,15.545V5.364h4.421V9h3.684v6.545Z"
        transform="translate(-2 -1)"
      />
    </svg>
  );
};

export default Copy;
