import React from 'react';

const Send = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      viewBox="0 0 16 8"
      {...props}
    >
      <path
        id="send"
        d="M14,7H10.8V8.6H14a2.4,2.4,0,1,1,0,4.8H10.8V15H14a4,4,0,1,0,0-8ZM9.2,13.4H6A2.4,2.4,0,0,1,6,8.6H9.2V7H6a4,4,0,1,0,0,8H9.2ZM6.8,10.2h6.4v1.6H6.8Z"
        transform="translate(-2 -7)"
      />
    </svg>
  );
};

export default Send;
