import * as notificationTypes from 'services/apiService/notificationTypes';
import { addAcceptLanguageHeader } from 'services/helpers';

const authRoute = {
  getRegistrationSettings: ({ registrationToken, step }) => {
    const request = {
      url: '/registration',
      method: 'POST',
      data: {
        reg_token: registrationToken,
        step,
      },
    };
    return addAcceptLanguageHeader(request);
  },

  setRegistrationSettings: ({ registrationToken, step, ...fields }) => {
    const request = {
      url: '/registration/set',
      method: 'POST',
      data: {
        reg_token: registrationToken,
        step,
        ...fields,
      },
      notifications: {
        omitErrorNotification: true,
      },
    };
    return addAcceptLanguageHeader(request);
  },

  checkRegistrationSettings: ({ registrationToken, step, ...fields }) => {
    const request = {
      url: '/registration/check',
      method: 'POST',
      data: {
        reg_token: registrationToken,
        step,
        ...fields,
      },
      notifications: {
        omitErrorNotification: true,
      },
    };
    return addAcceptLanguageHeader(request);
  },

  login: (param) => {
    const {
      login,
      password,
      lang,
      client = null,
      wui = null,
      pbl = null,
      check = false,
    } = param;
    const request = {
      url: '/auth',
      method: 'POST',
      data: {
        login,
        password,
        lang,
        client,
        pbl,
        wui,
        check,
      },
      notifications: {
        omitErrorNotification: true,
      },
    };
    return addAcceptLanguageHeader(request);
  },

  logout: () => ({
    url: '/clear',
    method: 'POST',
  }),

  logoutAll: () => ({
    url: '/clear/all',
    method: 'POST',
    notifications: {
      success: notificationTypes.LOGOUT_ALL_SUCCESS,
      omitErrorNotification: true,
    },
  }),

  resetPassword: ({ login }) => ({
    url: '/password/reset',
    method: 'POST',
    data: {
      login,
    },
    notifications: {
      omitErrorNotification: true,
    },
  }),

  changePassword: ({ oldPassword, newPassword }) => ({
    url: '/password/change',
    method: 'POST',
    data: {
      current_password: oldPassword,
      new_password: newPassword,
    },
    notifications: {
      success: notificationTypes.PASSWORD_CHANGE_REQUEST_SUCCESS,
      omitErrorNotification: true,
    },
  }),

  checkTwoFactorAuth: (params) => {
    const request = {
      url: '/twofa',
      method: 'GET',
      params,
      notifications: {
        omitErrorNotification: true,
      },
    };
    return addAcceptLanguageHeader(request);
  },

  sendPin: ({ pin, login, pbl }) => {
    const request = {
      url: '/auth/twofa',
      method: 'POST',
      data: {
        pin,
        login,
        pbl,
      },
      notifications: {
        omitErrorNotification: true,
      },
    };
    return addAcceptLanguageHeader(request);
  },

  autoLogin: ({ token }) => ({
    url: '/auth/check',
    method: 'POST',
    data: {
      token,
    },
  }),
};

export default authRoute;
