import { getLocalStorageItem } from 'helpers/getLocalStorageItem';
import { isEqual } from 'helpers/string';

export const REMEMBER_USERS = 'rememberUsers';
export const AUTH_TOKEN = 'authToken';
export const REFRESH_TOKEN = 'refreshToken';

export function getItemFromLocalStorage(key) {
  if (!key) {
    return null;
  }
  const item = localStorage.getItem(key.toString());
  return JSON.parse(item);
}

export function setItemInLocalStorage(key, value) {
  if (!key) {
    return;
  }
  localStorage.setItem(key.toString(), JSON.stringify(value));
}

export function removeItemFromLocalStorage(key) {
  if (!key) {
    return;
  }
  localStorage.removeItem(key.toString());
}

export function isItemInLocalStorage(key) {
  if (!key) {
    return;
  }
  return Boolean(getItemFromLocalStorage(key.toString()));
}

const getRememberUserByLogin = login => getLocalStorageItem(REMEMBER_USERS, [])
  .find(u => isEqual(u.login, login, true));

const getRememberUserByEmail = email => getLocalStorageItem(REMEMBER_USERS, [])
  .find(u => isEqual(u.email, email, true));

export const getRememberUser = loginOrEmail => getRememberUserByLogin(loginOrEmail)
  || getRememberUserByEmail(loginOrEmail) || {};

export const addOrUpdateRememberUser = (user = {}) => {
  const filtredUsers = getLocalStorageItem(REMEMBER_USERS, [])
    .filter(u => !isEqual(u.login, user.login, true));
  setItemInLocalStorage(REMEMBER_USERS, [...filtredUsers, user]);
};
