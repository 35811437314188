import { compose } from 'redux';

import withRedux from 'hocs/withRedux';
// import withClientNotifications from 'hocs/withClientNotifications';
import withTheme from 'hocs/withTheme';
import withContexts from 'hocs/withGeneralContexts';
import withTitle from 'hocs/withTitle';

const withAppData = Component =>
  compose(
    // withClientNotifications,
    withRedux,
    withTheme,
    withContexts,
    withTitle,
  )(Component);

export default withAppData;
