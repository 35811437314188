export const getReducedCloudData = (data, parentId = 'storage') => {
  return data.reduce(
    (prev, { code, ...itemProps }) => ({
      ...prev,
      [code]: {
        id: code,
        ...itemProps,
        type: 'cloud',
        icon: 'cloud',
        parentId,
      },
    }),
    {},
  );
};

export const getFolderData = (
  { code, ...itemProps },
  parentId,
  stateData = {},
) => ({
  id: code,
  ...(stateData[code] || {}),
  ...itemProps,
  type: 'folder',
  icon: 'folder',
  parentId,
});

export const getReducedFolderData = (data, parentId, stateData = {}) => {
  return data.reduce(
    (prev, { code, ...itemProps }) => ({
      ...prev,
      [code]: getFolderData({ code, ...itemProps }, parentId, stateData),
    }),
    {},
  );
};

export const getReducedFileData = (data, parentId) => {
  return data.reduce(
    (prev, { code, ...itemProps }) => ({
      ...prev,
      [code]: {
        id: code,
        ...itemProps,
        type: 'file',
        icon: 'file',
        parentId,
      },
    }),
    {},
  );
};

export const getReducedFilesData = files => {
  const filesByCode = files.reduce((currentFilesByCode, file) => {
    const {
      parent_folder_uuid: parentFolderUuid,
      flash_uuid: flashUuid,
      in_root: inRoot,
      code,
    } = file;

    const parentId = inRoot ? flashUuid : parentFolderUuid;

    // eslint-disable-next-line no-param-reassign
    currentFilesByCode[code] = {
      id: code,
      ...file,
      type: 'file',
      icon: 'file',
      parentId,
    };

    return currentFilesByCode;
  }, {});

  return filesByCode;
};

export const getReducedProfileData = data => {
  return Object.entries(data).reduce((prev, [code, user]) => {
    const { fio: name, write_access: writeAccess, flashs, ...itemProps } = user;

    return {
      ...prev,
      [code]: {
        id: code,
        name,
        writeAccess,
        ...itemProps,
        type: 'profile',
        icon: 'profile',
        parentId: 'storage',
      },
      ...getReducedCloudData(flashs, code, true),
    };
  }, {});
};
