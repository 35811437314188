import apiClient from 'utils/axiosInstance';

import { createService } from 'services/helpers';

import auth from './routes/auth';
import flash from './routes/flash';
import settings from './routes/settings';
import user from './routes/user';
import actions from './routes/actions';
import trash from './routes/trash';
import share from './routes/share';
import general from './routes/general';
import client from './routes/client';
import files from './routes/files';
import tree from './routes/tree';
import webOffice from './routes/webOffice';

const serviceRoutes = {
  auth,
  flash,
  settings,
  user,
  actions,
  trash,
  share,
  general,
  client,
  files,
  tree,
  webOffice,
};

const apiService = createService({
  routes: serviceRoutes,
  apiClient,
  shouldLogout: true,
});

export default apiService;
