import * as authTypes from 'store/reducers/auth/types';
import types from '../constants';

const initialState = {};

const ACTION_HANDLERS = {
  // [types.SWITCH_VIEWED_NOTIFICATIONS]: (state, { payload }) => {
  //   const { ids } = payload;
  //   if (ids.length) {
  //     const newState = { ...state };
  //     ids.forEach((id) => {
  //       newState[id].viewed = true;
  //     });
  //     return { ...newState };
  //   }

  //   return { ...state };
  // },
  [types.GET_MESSAGES_SUCCESS]: (state, { payload }) => {
    const {
      new: newArr = [],
      old: oldArr = [],
    } = payload;

    const data = [...newArr, ...oldArr];
    const newState = state;

    if (!data.length) {
      return state;
    }

    data.forEach((item) => {
      newState[item.id] = item;
    });
    return {
      ...newState,
    };
  },
  [types.SET_MESSAGE_MARKED_SUCCESS]: (state, { params }) => {
    const { items } = params;

    if (items.length) {
      const newState = { ...state };
      items.forEach((id) => {
        newState[id].viewed = true;
      });
      return { ...newState };
    }

    return { ...state };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

export const byId = (state = initialState, action) => (ACTION_HANDLERS[action.type]
  ? ACTION_HANDLERS[action.type](state, action)
  : state);

export default byId;
