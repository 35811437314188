import React from 'react';

const Office = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.029"
      height="18.989"
      viewBox="0 0 19.029 18.989"
      {...props}
    >
      <g transform="translate(0.5 0.5)">
        <path
          d="M19812.025,1881.989h-9.865v-14h9.869v14Zm-3.85-2h2v-2h-2Zm-4.008,0h2v-2h-2Zm4.008-4h2v-2h-2Zm-4.008,0h2v-2h-2Zm4.008-3.988h2v-2h-2Zm-4.008,0h2v-2h-2Zm-10.168,5.991V1864h9.865v2h-3.854v11.993Zm2.01-2h2v-2h-2Zm0-3.993h2v-2.009h-2Zm0-4h2v-2h-2Z"
          transform="translate(-19794 -1864)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default Office;
