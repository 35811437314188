export default {
  REQUEST_FLASH_LIST: 'REQUEST_FLASH_LIST',
  REQUEST_FLASH_LIST_SUCCESS: 'REQUEST_FLASH_LIST_SUCCESS',
  REQUEST_FLASH_LIST_FAIL: 'REQUEST_FLASH_LIST_FAIL',

  REQUEST_FLASH_CREATE: 'REQUEST_FLASH_CREATE',
  REQUEST_FLASH_CREATE_SUCCESS: 'REQUEST_FLASH_CREATE_SUCCESS',
  REQUEST_FLASH_CREATE_FAIL: 'REQUEST_FLASH_CREATE_FAIL',

  REQUEST_FLASH_EDIT: 'REQUEST_FLASH_EDIT',
  REQUEST_FLASH_EDIT_SUCCESS: 'REQUEST_FLASH_EDIT_SUCCESS',
  REQUEST_FLASH_EDIT_FAIL: 'REQUEST_FLASH_EDIT_FAIL',

  REQUEST_FLASH_DIRECTION_LIST: 'REQUEST_FLASH_DIRECTION_LIST',
  REQUEST_FLASH_DIRECTION_LIST_SUCCESS: 'REQUEST_FLASH_DIRECTION_LIST_SUCCESS',
  REQUEST_FLASH_DIRECTION_LIST_FAIL: 'REQUEST_FLASH_DIRECTION_LIST_FAIL',

  REQUEST_FLASH_SUBDIRECTION_LIST: 'REQUEST_FLASH_SUBDIRECTION_LIST',
  REQUEST_FLASH_SUBDIRECTION_LIST_SUCCESS: 'REQUEST_FLASH_SUBDIRECTION_LIST_SUCCESS',
  REQUEST_FLASH_SUBDIRECTION_LIST_FAIL: 'REQUEST_FLASH_SUBDIRECTION_LIST_FAIL',

  REQUEST_TRASH_LIST: 'REQUEST_TRASH_LIST',
  REQUEST_TRASH_LIST_SUCCESS: 'REQUEST_TRASH_LIST_SUCCESS',
  REQUEST_TRASH_LIST_FAIL: 'REQUEST_TRASH_LIST_FAIL',

  REQUEST_FLASH_DELETE: 'REQUEST_FLASH_DELETE',
  REQUEST_FLASH_DELETE_SUCCESS: 'REQUEST_FLASH_DELETE_SUCCESS',
  REQUEST_FLASH_DELETE_FAIL: 'REQUEST_FLASH_DELETE_FAIL',

  REQUEST_FLASH_INFO: 'REQUEST_FLASH_INFO',
  REQUEST_FLASH_INFO_SUCCESS: 'REQUEST_FLASH_INFO_SUCCESS',
  REQUEST_FLASH_INFO_FAIL: 'REQUEST_FLASH_INFO_FAIL',

  REQUEST_SET_FLASH_VERSIONED: 'REQUEST_SET_FLASH_VERSIONED',
  REQUEST_SET_FLASH_VERSIONED_SUCCESS: 'REQUEST_SET_FLASH_VERSIONED_SUCCESS',
  REQUEST_SET_FLASH_VERSIONED_FAIL: 'REQUEST_SET_FLASH_VERSIONED_FAIL',

  RENAME_ITEM: 'RENAME_ITEM',

  DELETE_ITEM: 'DELETE_ITEM',

  RESTORE_VERSION: 'RESTORE_VERSION',
  RESTORE_VERSION_SUCCESS: 'RESTORE_VERSION_SUCCESS',
  RESTORE_VERSION_FAIL: 'RESTORE_VERSION_FAIL',

  DELETE_VERSION: 'DELETE_VERSION',
  DELETE_VERSION_SUCCESS: 'DELETE_VERSION_SUCCESS',
  DELETE_VERSION_FAIL: 'DELETE_VERSION_FAIL',

  SEND_LINK_MODERATION: 'SEND_LINK_MODERATION',
  SEND_LINK_MODERATION_SUCCESS: 'SEND_LINK_MODERATION_SUCCESS',
  SEND_LINK_MODERATION_FAIL: 'SEND_LINK_MODERATION_FAIL',

  UPDATE_FLASH_PROPERTY: 'UPDATE_FLASH_PROPERTY',
  UPDATE_FLASH_PROPERTY_SUCCESS: 'UPDATE_FLASH_PROPERTY_SUCCESS',
  UPDATE_FLASH_PROPERTY_FAIL: 'UPDATE_FLASH_PROPERTY_FAIL',

  COPY_FLASH: 'COPY_FLASH',
  COPY_FLASH_SUCCESS: 'COPY_FLASH_SUCCESS',
  COPY_FLASH_FAIL: 'COPY_FLASH_FAIL',
};
