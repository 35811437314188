import * as authTypes from 'store/reducers/auth/types';

import types from './constants';

const initialState = {};

const ACTION_HANDLERS = {
  [types.REQUEST_DIRECTORY_CREATE_SUCCESS]: state => {
    return { ...state };
  },
  [types.REQUEST_DIRECTORY_COPY_MOVE_SUCCESS]: state => {
    return { ...state };
  },
  [types.REQUEST_FILE_INFO_SUCCESS]: state => {
    return { ...state };
  },
  [types.REQUEST_DIRECTORY_DELETE_SUCCESS]: state => {
    return { ...state };
  },
  [types.REQUEST_DIRECTORY_RENAME_SUCCESS]: state => {
    return { ...state };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
  // [authTypes.LOGOUT_ALL_SUCCESS]: () => initialState,
  // [authTypes.LOGOUT_ALL_FAIL]: () => initialState,
};

export const directories = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};
