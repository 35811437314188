import React from 'react';

const ModerationGridDenied = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <filter
          id="Ellipse_3"
          x="0"
          y="0"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="moderation_grid_denied" transform="translate(-662 -196)">
        <g transform="matrix(1, 0, 0, 1, 662, 196)" filter="url(#Ellipse_3)">
          <circle
            id="Ellipse_3-2"
            data-name="Ellipse 3"
            cx="8"
            cy="8"
            r="8"
            transform="translate(3 3)"
            fill="#e14444"
          />
        </g>
        <path
          id="Path_73"
          data-name="Path 73"
          d="M12.906,5.8l-.8-.8L8.953,8.157,5.8,5,5,5.8,8.157,8.953,5,12.11l.8.8L8.953,9.749l3.157,3.157.8-.8L9.749,8.953Z"
          transform="translate(664 198.133)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default ModerationGridDenied;
