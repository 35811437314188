import React from 'react';

const AddContext = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.666"
      height="12.666"
      viewBox="0 0 12.666 12.666"
      {...props}
    >
      <path
        id="add"
        d="M8.333,2a6.333,6.333,0,1,0,6.333,6.333A6.333,6.333,0,0,0,8.333,2Zm2.533,6.966h-1.9v1.9H7.7v-1.9H5.8V7.7H7.7V5.8H8.966V7.7h1.9Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export default AddContext;
