import React from 'react';

import Composer from 'helpers/wrapComposer';

import { ModalParamsProvider } from 'contexts/ModalParamsContext';
import { MainContentLayoutState } from 'contexts/MainContentLayoutContext';
import { LeftSidebarWidthParamsState } from 'contexts/LeftSidebarWidthParamsContext';
import { HeaderMainHeightState } from 'contexts/HeaderMainHeightContext';
import { StorageRoutingState } from 'contexts/StorageRoutingContext';
import { LeftSidebarViewBlockState } from 'contexts/LeftSidebarViewBlockContext';
import { SidePanelState } from 'contexts/SidePanelContext';
import { FilesTransferContextProvider } from 'shared/contexts/FilesTransferContext';
import { PopupNotificationState } from 'contexts/PopupNotificationContext';
import { PreviewContextProvider } from 'shared/contexts/PreviewContext';
import { ChangePasswordState } from 'web/contexts/ChangePasswordContext';
import { PopoverContextProvider } from 'contexts/PopoverContext';
import { RouteParamsState } from 'contexts/RouteParamsContext';
import { DragDropContextProvider } from 'contexts/DragDropContext';
import { NotificationsContextProvider } from 'shared/contexts/NotificationsContext';
import { TabsContextProvider } from 'mobile/contexts/TabsContext';

// TODO: check, should I refactor this
const withGeneralContexts = Component => props => (
  <Composer
    components={[
      ModalParamsProvider,
      MainContentLayoutState,
      LeftSidebarWidthParamsState,
      HeaderMainHeightState,
      StorageRoutingState,
      LeftSidebarViewBlockState,
      SidePanelState,
      RouteParamsState,
      FilesTransferContextProvider,
      NotificationsContextProvider,
      PopupNotificationState,
      ChangePasswordState,
      PreviewContextProvider,
      PopoverContextProvider,
      DragDropContextProvider,
      TabsContextProvider,
    ]}
  >
    <Component {...props} />
  </Composer>
);

export default withGeneralContexts;
