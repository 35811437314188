import * as authTypes from 'store/reducers/auth/types';

// import byIdTypes from './constants';

const initialState = {};

const defaultState = { ...initialState };

const ACTION_HANDLERS = {
  [authTypes.AUTO_LOGIN_SUCCESS]: () => defaultState,
  [authTypes.AUTO_LOGIN_FAIL]: () => defaultState,
  [authTypes.LOGOUT_SUCCESS]: () => defaultState,
  [authTypes.LOGOUT_FAIL]: () => defaultState,
};

export const byId = (state = defaultState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byId;
