import * as types from './types';

const initialState = {
  selected: null,
};

const ACTION_HANDLERS = {
  [types.SAVE_SELECTED_INSTANCE]: (state, { payload }) => ({
    ...state,
    selected: payload,
  }),
  [types.CLEAR_SELECTED_INSTANCE]: state => ({
    ...state,
    selected: null,
  }),
};

export default (state = initialState, action) => (
  ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state
);
