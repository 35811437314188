import React from 'react';

const PreviewFileListArrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      id="file_list"
      d="M13.667,3H4.333A1.335,1.335,0,0,0,3,4.333v9.333A1.335,1.335,0,0,0,4.333,15h9.333A1.335,1.335,0,0,0,15,13.667V4.333A1.335,1.335,0,0,0,13.667,3ZM9,11.276,5.674,7.95l.943-.943L9,9.391,11.388,7l.943.943Z"
      transform="translate(-3 -3)"
      // opacity="0.3"
    />
  </svg>
);

export default PreviewFileListArrow;
