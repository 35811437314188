import * as authTypes from 'store/reducers/auth/types';
import * as eventsTypes from 'store/reducers/events/types';

import * as flashInfoTypes from './constants';

const initialState = {};

const ACTION_HANDLERS = {
  [flashInfoTypes.GET_FLASH_INFO_SUCCESS]: (state, { payload, flashId }) => ({
    ...state,
    [flashId]: payload,
  }),
  [eventsTypes.POOL_DATA_SUCCESS]: (state, { payload }) => {
    const { drives } = payload;

    if (!drives || drives.length === 0) {
      return state;
    }

    return drives.reduce((currentFlashesInfo, flashInfo) => {
      currentFlashesInfo[flashInfo.code] = flashInfo;

      return currentFlashesInfo;
    }, state);
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

export default (state = {}, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};
