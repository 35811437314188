import React from 'react';

const SubFolders = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.283"
      height="14.027"
      viewBox="0 0 16.283 14.027"
      {...props}
    >
      <path
        id="send_folders"
        d="M-16440-1915.987s1.133-3.013,4.145-3.013,4.141,3.013,4.141,3.013-1.131,3.013-4.141,3.013S-16440-1915.987-16440-1915.987Zm2.264,0a1.881,1.881,0,0,0,1.881,1.882,1.881,1.881,0,0,0,1.883-1.882,1.881,1.881,0,0,0-1.883-1.882A1.881,1.881,0,0,0-16437.736-1915.987Zm.75,0a1.13,1.13,0,0,1,1.131-1.131,1.128,1.128,0,0,1,1.127,1.131,1.128,1.128,0,0,1-1.127,1.131A1.13,1.13,0,0,1-16436.986-1915.987Zm-9.613.19a1.406,1.406,0,0,1-1.4-1.4v-8.4a1.406,1.406,0,0,1,1.4-1.4h4.2l1.4,1.4h5.6a1.4,1.4,0,0,1,1.4,1.4v3.744a10.475,10.475,0,0,0-7.756,4.652v0Z"
        transform="translate(16447.998 1927)"
      />
    </svg>
  );
};

export default SubFolders;
