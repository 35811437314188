import React from 'react';

const Once = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        id="once"
        d="M15.444,3H4.556A1.56,1.56,0,0,0,3,4.556V15.444A1.56,1.56,0,0,0,4.556,17H15.444A1.56,1.56,0,0,0,17,15.444V4.556A1.56,1.56,0,0,0,15.444,3Zm0,12.444H4.556V4.556H15.444ZM10,13.889h1.556V6.111H8.444V7.667H10Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export default Once;
