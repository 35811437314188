import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const RouteParamsContext = createContext();

const RouteParamsState = ({ children }) => {
  const [routeParams, setRouteParams] = useState({});

  const value = useMemo(() => ({
    routeParams,
    setRouteParams,
  }), [routeParams]);

  return (
    <RouteParamsContext.Provider value={value}>
      {children}
    </RouteParamsContext.Provider>
  );
};

RouteParamsState.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};

RouteParamsState.defaultProps = {
  children: [],
};

export { RouteParamsContext as default, RouteParamsState };
