import React, { useState } from 'react';
import { getFilterUrl } from 'shared/elements/StyledSvg/helpers'
import { createGuid } from 'helpers/createGuid';

const FileManagerPSD = props => {
  // Хак для того, чтобы компонент не терял id в Safari в момент ререндера svg
  const [rectId] = useState(createGuid());
  const filter = getFilterUrl(rectId);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="58.963"
      height="71"
      viewBox="0 0 58.963 71"
      {...props}
    >
      <defs>
        <filter
          id={rectId}
          x="0"
          y="0"
          width="58.963"
          height="71"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood floodOpacity="0.122" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="fm_psd" transform="translate(-28.498 6.5)">
        <g
          transform="matrix(1, 0, 0, 1, 28.5, -6.5)"
          filter={filter}
        >
          <path
            id="Intersection_4-2"
            data-name="Intersection 4"
            d="M22663,20628.418a5,5,0,0,1-5-5v-46a5,5,0,0,1,5-5h24.908l14.051,13.338v38.295a5,5,0,0,1-4.963,4.367Z"
            transform="translate(-22650.5 -20565.92)"
            fill="#5174d2"
          />
        </g>
        <path
          id="Path_440"
          data-name="Path 440"
          d="M39.32,14.82H29V4.5Z"
          transform="translate(38.345 -2.288)"
          fill="#dbdbdb"
        />
        <text
          id="PSD"
          transform="translate(57.335 37.998)"
          fill="#fefefe"
          fontSize="11"
          fontFamily="SegoeUI-Bold, Roboto Bold"
          fontWeight="700"
          letterSpacing="0.033em"
        >
          <tspan x="-10.88" y="0">
            PSD
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default FileManagerPSD;
