import React from 'react';

const Apple = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.492"
      height="19"
      viewBox="0 0 15.492 19"
      {...props}
    >
      <path
        id="apple"
        d="M18.737,6.837a4.292,4.292,0,0,0-2.1,3.646A4.293,4.293,0,0,0,19.222,14.3a10.244,10.244,0,0,1-1.338,2.708c-.769,1.17-1.623,2.351-2.919,2.351-1.233,0-1.676-.78-3.1-.78-1.528,0-1.96.78-3.13.78-1.3,0-2.213-1.243-3.024-2.4a11.842,11.842,0,0,1-1.981-6.186,6.52,6.52,0,0,1,.8-3.415,4.763,4.763,0,0,1,3.941-2.4c1.243-.042,2.35.854,3.109.854.727,0,2.087-.854,3.625-.854A4.226,4.226,0,0,1,18.737,6.837ZM11.477,4.708a3.753,3.753,0,0,1,.959-2.919A4.045,4.045,0,0,1,15.3.356a4.126,4.126,0,0,1-1.054,2.982A3.238,3.238,0,0,1,11.477,4.708Z"
        transform="translate(-3.73 -0.356)"
      />
    </svg>
  );
};

export default Apple;
