export const getPrevFileId = (files, currentFileId) => {
  if (!currentFileId || files.length === 0) {
    return null;
  }

  const currentFileIndex = files.findIndex(
    fileId => fileId === currentFileId,
  );

  if (currentFileIndex === -1 || currentFileIndex === 0) {
    return null;
  }

  const prevFileId = files[currentFileIndex - 1];
  return prevFileId;
};

export const getNextFileId = (files, currentFileId) => {
  if (!currentFileId || files.length === 0) {
    return null;
  }

  const currentFileIndex = files.findIndex(
    fileId => fileId === currentFileId,
  );

  if (currentFileIndex === -1 || currentFileIndex === files.length - 1) {
    return null;
  }

  const nextFileId = files[currentFileIndex + 1];
  return nextFileId;
};
