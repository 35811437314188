import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Portal } from '@material-ui/core';

import StyledSvg from 'elements/StyledSvg';
import { main } from 'helpers/theme/colors';

import useStyles from './styles';

const DEFAULT_SPINNER_SIZE = 74;

const Spinner = (props) => {
  const {
    isHidden = false,
    backgroundColor = main.spinnerBackground,
    fullscreen = false,
    size = DEFAULT_SPINNER_SIZE,
  } = props;

  const { loaderWrap } = useStyles({ isHidden, backgroundColor, fullscreen });

  // eslint-disable-next-line no-console
  // console.log('Spinner', props);

  return useMemo(() => {
    if (isHidden) {
      return null;
    }

    const Component = (
      <Box className={loaderWrap}>
        <StyledSvg icon="loader" iconSize={size} />
      </Box>
    );

    return fullscreen
      ? (<Portal>{Component}</Portal>)
      : Component;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fullscreen,
    isHidden,
    loaderWrap,
    size,
    window.location.pathname,
  ]);
};

Spinner.propTypes = {
  isHidden: PropTypes.bool,
  backgroundColor: PropTypes.string,
  fullscreen: PropTypes.bool,
  size: PropTypes.number,
};

export default Spinner;
