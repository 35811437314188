import React from 'react';

const SimpleArrowUp = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.5"
    height="3.75"
    viewBox="0 0 7.5 3.75"
    {...props}
  >
    <path
      id="accordion"
      d="M7,10l3.75,3.75L14.5,10Z"
      transform="translate(14.5 13.75) rotate(180)"
    />
  </svg>
);

export default SimpleArrowUp;
