import React from 'react';

const FatalError = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113.043"
      height="100"
      viewBox="0 0 113.043 100"
      {...props}
    >
      <path
        id="icons8-error"
        d="M58.522,3A8.693,8.693,0,0,0,50.5,8.341L3.308,89.727v.008A8.682,8.682,0,0,0,10.7,103q.306,0,.611-.025l.017.025h94.4l.017-.034q.305.028.611.034a8.689,8.689,0,0,0,7.388-13.273l-.068-.119-.008-.008L66.547,8.341A8.693,8.693,0,0,0,58.522,3ZM53.248,39.345H63.8l-.875,28.142h-8.8Zm5.29,36.744c3.574,0,5.715,1.923,5.715,5.205,0,3.222-2.141,5.138-5.715,5.138-3.6,0-5.757-1.916-5.757-5.138C52.781,78.012,54.934,76.089,58.539,76.089Z"
        transform="translate(-2 -3)"
        fill="#ff4f12"
        opacity="0.31"
      />
    </svg>
  );
};

export default FatalError;
