// ===Flashes notifications===

// накопитель создан
export const CREATE_FLASH_REQUEST_SUCCESS = 'CREATE_FLASH_REQUEST_SUCCESS';
// накопитель отредактирован
export const FLASH_EDIT_REQUEST_SUCCESS = 'FLASH_EDIT_REQUEST_SUCCESS';
// накопитель скопирован
export const FLASH_COPY_REQUEST_SUCCESS = 'FLASH_COPY_REQUEST_SUCCESS';
// накопитель добавлен в Избранные
export const FLASH_ADD_TO_FAVOURITE_REQUEST_SUCCESS = 'FLASH_ADD_TO_FAVOURITE_REQUEST_SUCCESS';
// накопитель удален из Избранных
export const FLASH_REMOVE_FROM_FAVOURITE_REQUEST_SUCCESS = 'FLASH_REMOVE_FROM_FAVOURITE_REQUEST_SUCCESS';
// накопитель удален
export const FLASH_DELETE_REQUEST_SUCCESS = 'FLASH_DELETE_REQUEST_SUCCESS';

// ===Files notifications===

// файл создан
export const CREATE_FILE_REQUEST_SUCCESS = 'CREATE_FILE_REQUEST_SUCCESS';
// папка создана
export const CREATE_FOLDER_REQUEST_SUCCESS = 'CREATE_FOLDER_REQUEST_SUCCESS';

// файлы начали загружаться
export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
// загрузка файлов завершена
export const UPLOAD_FILES_REQUEST_SUCCESS = 'UPLOAD_FILES_REQUEST_SUCCESS';
// файлы готовятся к скачиванию
export const DOWNLOAD_PREPARE_SUCCESS = 'DOWNLOAD_PREPARE_SUCCESS';
// файлы начали скачиваться
export const DOWNLOAD_FILES_REQUEST = 'DOWNLOAD_FILES_REQUEST';
// скачивание файлов завершено
export const DOWNLOAD_FILES_REQUEST_SUCCESS = 'DOWNLOAD_FILES_REQUEST_SUCCESS';
// файлы отправлены
export const FILES_SEND_REQUEST_SUCCESS = 'FILES_SEND_REQUEST_SUCCESS';
// публичные ссылки отправлены
export const PUBLIC_LINKS_SEND_REQUEST_SUCCESS = 'PUBLIC_LINKS_SEND_REQUEST_SUCCESS';
// файлы скопированы
export const FILES_COPY_REQUEST_SUCCESS = 'FILES_COPY_REQUEST_SUCCESS';
export const FILES_MOVE_REQUEST_SUCCESS = 'FILES_MOVE_REQUEST_SUCCESS';
// файлы перемещены в корзину
export const FILES_MOVE_TO_TRASH_REQUEST_SUCCESS = 'FILES_MOVE_TO_TRASH_REQUEST_SUCCESS';
// файлы удалены (безвозвратно)
export const FILES_DELETE_REQUEST_SUCCESS = 'FILES_DELETE_REQUEST_SUCCESS';
// публичные ссылки удалены
export const PUBLIC_LINK_DELETE_REQUEST_SUCCESS = 'PUBLIC_LINK_DELETE_REQUEST_SUCCESS';
// файлы удалены (из обмена файлов)
export const SHARE_DELETE_REQUEST_SUCCESS = 'SHARE_DELETE_REQUEST_SUCCESS';
// файлы восстановлены
export const FILES_RESTORE_REQUEST_SUCCESS = 'FILES_RESTORE_REQUEST_SUCCESS';

// корзина очищена
export const TRASH_CLEAN_REQUEST_SUCCESS = 'TRASH_CLEAN_REQUEST_SUCCESS';

// ===Versions notifications===

// версия файла восстановлена
export const FILES_VERSIONS_RESTORE_REQUEST_SUCCESS = 'FILES_VERSIONS_RESTORE_REQUEST_SUCCESS';
// версия файла удалена
export const FILES_VERSIONS_DELETE_REQUEST_SUCCESS = 'FILES_VERSIONS_DELETE_REQUEST_SUCCESS';

// ===Settings notifications===

// пароль изменен
export const PASSWORD_CHANGE_REQUEST_SUCCESS = 'PASSWORD_CHANGE_REQUEST_SUCCESS';
// вы вышли со всех устройств
export const LOGOUT_ALL_SUCCESS = 'LOGOUT_ALL_SUCCESS';
// пользователь приглашен
export const USER_INVITE_REQUEST_SUCCESS = 'USER_INVITE_REQUEST_SUCCESS';

// ===Sync notifications===

// отправлена команда клиенту
export const COMMAND_SET_REQUEST_SUCCESS = 'COMMAND_SET_REQUEST_SUCCESS';

// настройки изменены
export const SETTINGS_UPDATED = 'SETTINGS_UPDATED';

// настройки успешно сохранены
export const SETTINGS_UPDATED_REQUEST_SUCCESS = 'SETTINGS_UPDATED_REQUEST_SUCCESS';

// обновление получено - sync and more
export const POOL_EVENT_SUCCESS = 'POOL_EVENT_SUCCESS';

// устройство очищено
export const DEVICE_CLEARED_SUCCESS = 'DEVICE_CLEARED_SUCCESS';

export const REQUEST_ERROR = 'REQUEST_ERROR';

export const TRASH_IS_ALREADY_EMPTY = 'TRASH_IS_ALREADY_EMPTY';

/** Снять замок */
export const DELETE_WEB_OFFICE_FILE_LOCK = 'DELETE_WEB_OFFICE_FILE_LOCK';
export const DELETE_WEB_OFFICE_FILE_LOCK_SUCCESS = 'DELETE_WEB_OFFICE_FILE_LOCK_SUCCESS';
export const DELETE_WEB_OFFICE_FILE_LOCK_FAIL = 'DELETE_WEB_OFFICE_FILE_LOCK_FAIL';

/** Collabora web office */
export const GET_WEB_OFFICE_ACCESS_TOKEN_FAIL = 'GET_WEB_OFFICE_ACCESS_TOKEN_FAIL';
export const GET_WEB_OFFICE_CHECK_MY_LOCK_FAIL = 'GET_WEB_OFFICE_CHECK_MY_LOCK_FAIL';
/** Ссылка на скачивание: «Если загрузка не началась, перейдите по ссылке» */
export const DOWNLOAD_LINK = 'DOWNLOAD_LINK';
export const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION';
