// import * as notificationTypes from 'services/apiService/notificationTypes';

const actionsRoute = {
  // webOfficeUnauthRequest: ({  }) => ({
  //   baseURL:'',
  //   url: ``,
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   },
  //   params: {
  //   },
  //   data: {
  //   },
  //   notifications: {
  //     request: notificationTypes.UPLOAD_FILES_REQUEST,
  //     success: notificationTypes.UPLOAD_FILES_REQUEST_SUCCESS,
  //   },
  // }),
};

export default actionsRoute;
