import { extendedConnect } from 'store/utils/extendedConnect';

import {
  getSelectedFileId,
  getNextSelectedFileId,
  getPrevSelectedFileId,
  getPreparedFiles,
  getParentFolderType,
  getFilesByKeys,
} from 'store/reducers/previews/selectors';

import {
  openPreview,
  closePreview,
  preparePreviewFiles,
  downloadPreviewFile,
  setParentFolderType,
  unauthPreparePreviewFiles,
  unauthDownloadPreviewFile,
  downloadPreviewVideoFile,
} from 'store/reducers/previews/actions';

const mapStateToProps = (state) => {
  const selectedFileId = getSelectedFileId(state);
  const nextFileId = getNextSelectedFileId(state);
  const prevFileId = getPrevSelectedFileId(state);

  const availableFilesIds = [selectedFileId, nextFileId, prevFileId];

  const previewFilesData = getFilesByKeys(state, availableFilesIds);
  return {
    preparedFiles: getPreparedFiles(state),
    selectedFileId,
    availableFilesIds,
    previewFilesData,
    parentFolderType: getParentFolderType(state),
  };
};

const mapDispatchToProps = {
  openPreview,
  closePreview,
  preparePreviewFiles,
  downloadPreviewFile,
  setParentFolderType,
  unauthPreparePreviewFiles,
  unauthDownloadPreviewFile,
  downloadPreviewVideoFile,
};

export default children => extendedConnect(mapStateToProps, mapDispatchToProps)(children);
