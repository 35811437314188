export const FILES_UPLOAD = 'FILES_UPLOAD';
export const FILES_UPLOAD_SUCCESS = 'FILES_UPLOAD_SUCCESS';
export const FILES_UPLOAD_FAIL = 'FILES_UPLOAD_FAIL';

export const FILES_REPLY = 'FILES_REPLY';
export const FILES_REPLY_SUCCESS = 'FILES_REPLY_SUCCESS';
export const FILES_REPLY_FAIL = 'FILES_REPLY_FAIL';

export const FILES_UNAUTH_UPLOAD = 'FILES_UNAUTH_UPLOAD';
export const FILES_UNAUTH_UPLOAD_SUCCESS = 'FILES_UNAUTH_UPLOAD_SUCCESS';
export const FILES_UNAUTH_UPLOAD_FAIL = 'FILES_UNAUTH_UPLOAD_FAIL';
