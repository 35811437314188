import React from 'react';

const OpenSidePanel = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="open-pane"
        d="M4.778,3A1.791,1.791,0,0,0,3,4.778V17.222A1.791,1.791,0,0,0,4.778,19H17.222A1.791,1.791,0,0,0,19,17.222V4.778A1.791,1.791,0,0,0,17.222,3Zm0,1.778h8v5.333H10.111V7.444L6.556,11l3.556,3.556V11.889h2.667v5.333h-8Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export default OpenSidePanel;
