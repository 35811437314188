import React from 'react';

const Level = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.498"
      height="8.498"
      viewBox="0 0 7.498 8.498"
      {...props}
    >
      <path
        id="level"
        d="M11.5,9.5l-3,3-.71-.71L9.583,10H4V4H5V9H9.583L7.789,7.209,8.5,6.5Z"
        transform="translate(-4 -4)"
      />
    </svg>
  );
};

export default Level;
