import { combineReducers } from 'redux';
import byFilters from './byFilters';
import byId from './byId';
import flashesInfo from './flashesInfo';

export default combineReducers({
  byFilters,
  byId,
  flashesInfo,
});
