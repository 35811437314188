/* eslint-disable react/prop-types */
import React, { createContext, useState, useMemo, useCallback } from 'react';

const LeftSidebarViewBlockContext = createContext();

const LeftSidebarViewBlockState = ({ children }) => {
  // open notifications tab
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  // switch for comments / notifications
  const [activeNotes, setActiveNotes] = useState('notifications');
  // switch for modes
  const [isActiveCheckMode, setActiveCheckMode] = useState(false);
  const [isActiveViewMode, setActiveViewMode] = useState(false);

  const handleActiveSubMenu = useCallback((subMenu) => {
    if (!subMenu && !activeSubMenu) {
      return;
    }
    setActiveSubMenu(subMenu);
  }, [activeSubMenu]);

  const value = useMemo(() => ({
    activeSubMenu,
    handleActiveSubMenu,
    activeNotes,
    setActiveNotes,
    isActiveCheckMode,
    setActiveCheckMode,
    isActiveViewMode,
    setActiveViewMode,
  }), [
    activeNotes,
    activeSubMenu,
    handleActiveSubMenu,
    isActiveCheckMode,
    isActiveViewMode,
  ]);

  return (
    <LeftSidebarViewBlockContext.Provider value={value}>
      {children}
    </LeftSidebarViewBlockContext.Provider>
  );
};

export { LeftSidebarViewBlockContext as default, LeftSidebarViewBlockState };
