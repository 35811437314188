import React from 'react';

const Contacts = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.005"
      height="14"
      viewBox="0 0 12.005 14"
      {...props}
    >
      <path
        id="contacts_list"
        d="M7.658,4a1,1,0,0,0-1,1V6.667H6.324a.333.333,0,1,0,0,.667H7.658a.334.334,0,0,0,.288-.158.323.323,0,0,1,.046.158.334.334,0,0,1-.333.333h-1v1H6.324a.333.333,0,1,0,0,.667H7.658a.334.334,0,0,0,.288-.158.323.323,0,0,1,.046.158.334.334,0,0,1-.333.333h-1v1H6.324a.333.333,0,1,0,0,.667H7.658a.334.334,0,0,0,.288-.158.323.323,0,0,1,.046.158.333.333,0,0,1-.333.333h-1v1H6.324a.333.333,0,1,0,0,.667H7.658a.334.334,0,0,0,.288-.158.323.323,0,0,1,.046.158.333.333,0,0,1-.333.333h-1v1H6.324a.333.333,0,1,0,0,.667H7.658a.334.334,0,0,0,.288-.158.323.323,0,0,1,.046.158.333.333,0,0,1-.333.333h-1V17a1,1,0,0,0,1,1h9.333a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1Zm5.167,3.667L12.991,8c1.09,0,1.138,1.419.849,1.771.078.022.152.141.129.331-.044.353-.2.443-.292.451a2.233,2.233,0,0,1-.353.781v.673c.285.753,2.245.542,2.333,1.993H9.324c.089-1.452,2.049-1.241,2.333-1.993,0-.312.016-.673.016-.673h-.016a1.292,1.292,0,0,1-.353-.781c-.1-.008-.247-.1-.292-.451a.327.327,0,0,1,.129-.331C10.689,8.8,11.259,7.667,12.824,7.667Z"
        transform="translate(-5.986 -4)"
      />
    </svg>
  );
};

export default Contacts;
