import React from 'react';

const ViewMore = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        id="view_more"
        d="M14,2A12,12,0,1,0,26,14,12.014,12.014,0,0,0,14,2ZM8,15.5A1.5,1.5,0,1,1,9.5,14,1.5,1.5,0,0,1,8,15.5Zm6,0A1.5,1.5,0,1,1,15.5,14,1.5,1.5,0,0,1,14,15.5Zm6,0A1.5,1.5,0,1,1,21.5,14,1.5,1.5,0,0,1,20,15.5Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export default ViewMore;
