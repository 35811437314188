import React from 'react';

const Preview = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17.6"
      viewBox="0 0 16 17.6"
      {...props}
    >
      <path
        id="preview"
        d="M5.6,2A1.6,1.6,0,0,0,4,3.6V16.4A1.6,1.6,0,0,0,5.6,18H7.928a6.29,6.29,0,0,1-.572-.911l-.33-.689H5.6V3.6h5.6v4h4v4.039a8.214,8.214,0,0,1,1.6.3V6.8L12,2Zm8.8,11.2a6.224,6.224,0,0,0-5.6,3.2,6.224,6.224,0,0,0,5.6,3.2A6.224,6.224,0,0,0,20,16.4,6.224,6.224,0,0,0,14.4,13.2Zm0,1.6a1.6,1.6,0,1,1-1.6,1.6A1.6,1.6,0,0,1,14.4,14.8Z"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};

export default Preview;
