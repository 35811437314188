import React from 'react';

const NotificationEmpty = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.846"
      height="12"
      viewBox="0 0 9.846 12"
      {...props}
    >
      <path
        id="alert"
        d="M8.923,14.5a1.234,1.234,0,0,0,1.231-1.231H7.692A1.234,1.234,0,0,0,8.923,14.5Zm3.692-3.692V7.731A3.743,3.743,0,0,0,9.846,3.842V3.423A.923.923,0,0,0,8,3.423v.418A3.745,3.745,0,0,0,5.231,7.731v3.077L4,12.038v.615h9.846v-.615Zm-1.231.615H6.462V7.731A2.523,2.523,0,0,1,8.923,4.962a2.523,2.523,0,0,1,2.462,2.769Z"
        transform="translate(-4 -2.5)"
      />
    </svg>
  );
};

export default NotificationEmpty;
