import React from 'react';

const MoreVert = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="16"
      viewBox="0 0 4 16"
      {...props}
    >
      <path
        id="menu_vert"
        d="M12,8a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,8Zm0,2a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,16Z"
        transform="translate(-10 -4)"
      />
    </svg>
  );
};

export default MoreVert;
