/* eslint-disable react/prop-types */
import React, { createContext, useState, useMemo } from 'react';

const DragDropContext = createContext();

const DragDropContextProvider = ({ children }) => {
  const [isTipVisible, setTipVisible] = useState(false);
  const [cursorTipText, setCursorTipText] = useState('');
  const [itemsLength, setItemsLength] = useState(1);
  const [positionY, setPositionY] = useState(null);
  const [positionX, setPositionX] = useState(null);

  const value = useMemo(() => ({
    isTipVisible,
    setTipVisible,
    cursorTipText,
    setCursorTipText,
    positionX,
    positionY,
    setPositionY,
    setPositionX,
    itemsLength,
    setItemsLength,
  }), [
    cursorTipText,
    isTipVisible,
    itemsLength,
    positionX,
    positionY,
  ]);

  return (
    <DragDropContext.Provider value={value}>
      {children}
    </DragDropContext.Provider>
  );
};

export { DragDropContext as default, DragDropContextProvider };
