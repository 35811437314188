import { combineReducers } from 'redux';
import byFilters from './byFilters';
import byId from './byId';
import page from './page';

export default combineReducers({
  byFilters,
  byId,
  page,
});
