import React from 'react';

const CloseSync = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.366"
      height="18.365"
      viewBox="0 0 11.366 18.365"
      {...props}
    >
      <path
        id="Subtraction_3"
        data-name="Subtraction 3"
        d="M-2437.592,23.477h0l-1-3.09a6.005,6.005,0,0,1-.873.064,6.026,6.026,0,0,1-1.375-.159,6,6,0,0,1-2.668-1.41,6,6,0,0,1-1.641-2.532h1.427a4.652,4.652,0,0,0,3.408,2.692,4.679,4.679,0,0,0,.846.077c.152,0,.305-.008.457-.022l-2.82-8.677a4.661,4.661,0,0,0-.936.727l1.415,1.415h-3.771V8.789l1.414,1.414a5.991,5.991,0,0,1,1.459-1.076l-1.208-3.721.9-.295,5.871,18.07-.9.294Zm3.778-3.373,0,0-1.412-1.413a5.988,5.988,0,0,1-1.51,1.1l-.418-1.288a4.647,4.647,0,0,0,.986-.756l-1.415-1.414h3.771V20.1Zm-1.4-7.559h0a4.652,4.652,0,0,0-3.408-2.692,4.667,4.667,0,0,0-.848-.078,4.657,4.657,0,0,0-.511.028l-.418-1.287a5.994,5.994,0,0,1,.93-.073,5.988,5.988,0,0,1,1.374.16,6,6,0,0,1,2.668,1.409,6,6,0,0,1,1.642,2.532Z"
        transform="translate(2445.152 -5.112)"
        // fill="#8e8b92"
      />
    </svg>
  );
};

export default CloseSync;
