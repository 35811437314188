import React from 'react';

const Preview = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      {...props}
    >
      <g id="radiobutton_fill" transform="translate(-431 -737)">
        <g
          id="Rectangle_57"
          data-name="Rectangle 57"
          transform="translate(431 737)"
          fill="#f8f8f8"
          stroke="#dadada"
          strokeWidth="1"
        >
          <rect width="19" height="19" rx="9.5" stroke="none" />
          <rect x="0.5" y="0.5" width="18" height="18" rx="9" fill="none" />
        </g>
        <rect
          id="Rectangle_183"
          data-name="Rectangle 183"
          width="5"
          height="5"
          rx="2.5"
          transform="translate(438 744)"
        //   fill="#7800ff"
        />
      </g>
    </svg>
  );
};

export default Preview;
