import React from 'react';

const Rotate = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.798"
      height="19.798"
      viewBox="0 0 19.798 19.798"
      {...props}
    >
      <path
        id="sync"
        d="M9,0,6.333,2.667,9,5.333v-2a4.654,4.654,0,0,1,4.354,6.331l1.01,1.009A5.989,5.989,0,0,0,9,2ZM3.635,5.327A5.989,5.989,0,0,0,9,14v2l2.667-2.667L9,10.667v2A4.654,4.654,0,0,1,4.646,6.336Z"
        transform="translate(21.92 9.192) rotate(135)"
      />
    </svg>
  );
};

export default Rotate;
