import React, {
  createContext,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

const NotificationsContext = createContext();

const NotificationsContextProvider = (props) => {
  const { children } = props;

  const [checkedIds, setCheckedIds] = useState([]);
  const [preparingIds, setPreparingIds] = useState([]);
  const [sentIds, setSentIds] = useState([]);
  const uncheckModeItems = [];

  // const setResultNotifications = (item) => {
  //   const isAlreadyInList = uncheckModeItems.find(el => el === item);
  //   if (!isAlreadyInList) {
  //     uncheckModeItems.push(item);
  //   }
  // };

  const value = useMemo(() => ({
    checkedIds,
    setCheckedIds,
    sentIds,
    setSentIds,
    uncheckModeItems,
    preparingIds,
    setPreparingIds,
    // setResultNotifications,
  }), [
    checkedIds,
    sentIds,
    uncheckModeItems,
    preparingIds,
    setPreparingIds,
  ]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsContextProvider.propTypes = {
  children: PropTypes.any,
};

export { NotificationsContext as default, NotificationsContextProvider };
