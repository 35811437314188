import React from 'react';

const EmptyLinks = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113"
      height="113"
      viewBox="0 0 113 113"
      {...props}
    >
      <g id="Group_812" data-name="Group 812" transform="translate(-1106 -297)">
        <circle
          id="Ellipse_30"
          data-name="Ellipse 30"
          cx="56.5"
          cy="56.5"
          r="56.5"
          transform="translate(1106 297)"
          opacity="0.05"
          // fill="black"
        />
        <path
          id="Union_1"
          data-name="Union 1"
          d="M12108,8184.414l1.415-1.415,7.41,7.411a5.32,5.32,0,0,1,3.979-1.77h21.4l16.052,16.049v17.179h0a27.383,27.383,0,0,0-5.348-1v-13.507h-13.375v-13.375H12120.8v.4l31.813,31.813a21.566,21.566,0,0,1,6.429,1.62,20.55,20.55,0,0,1,5.712,3.67,19.5,19.5,0,0,1,4.2,5.3,17.354,17.354,0,0,1-2.348,3.4l9.278,9.278-1.415,1.415Zm33.559,61.405a20.636,20.636,0,0,1-5.8-3.679,19.562,19.562,0,0,1-4.254-5.351,18.82,18.82,0,0,1,3.443-4.584,20.637,20.637,0,0,1,11.591-5.84l5.329,5.329a5.351,5.351,0,1,0,3.713,5.095,5.3,5.3,0,0,0-.257-1.639l8.067,8.067a21.181,21.181,0,0,1-13.164,4.272A22.3,22.3,0,0,1,12141.558,8245.819Zm-20.755-3.679a5.359,5.359,0,0,1-5.351-5.351v-41.51l5.351,5.351v36.159h4.77l1.1,2.3a21.438,21.438,0,0,0,1.913,3.046v0Z"
          transform="translate(-10975.138 -7864.638)"
          fill="#fff"
          opacity="0.648"
        />
      </g>
    </svg>
  );
};

export default EmptyLinks;
