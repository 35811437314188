import React from 'react';

const Storage = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="20.134"
      height="13"
      viewBox="0 0 20.134 13"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="20.134" height="13" fill="none" />
        </clipPath>
      </defs>
      <g id="clouds" clipPath="url(#clip-path)">
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M7.708,12H6.474l1.368-.47L7.785,10.5A2.068,2.068,0,0,0,9.444,8.25,2.046,2.046,0,0,0,7.708,6H6.841V5.624C6.841,3.35,5.412,1.5,3.657,1.5a2.722,2.722,0,0,0-1.949.865c-.469-.084-.876-.149-.88-.15L0,2.6A4.2,4.2,0,0,1,3.657,0C5.723,0,7.512,1.905,7.911,4.53,9.419,4.665,10.6,6.3,10.6,8.25,10.6,10.318,9.3,12,7.708,12Z"
          transform="translate(9.532)"
          fill="#c7cbd6"
        />
        <path
          id="Path_51"
          data-name="Path 51"
          d="M14.189,8.429A5.494,5.494,0,0,0,3.923,6.963a4.4,4.4,0,0,0,.477,8.77h9.533a3.656,3.656,0,0,0,.257-7.3Zm-.257,5.837H4.4A2.933,2.933,0,1,1,4.4,8.4H4.92a4.033,4.033,0,0,1,7.912,1.1v.367h1.1a2.2,2.2,0,1,1,0,4.4Z"
          transform="translate(0 -2.733)"
          // fill="#f4f5f8"
        />
      </g>
    </svg>
  );
};

export default Storage;
