import { combineReducers } from 'redux';
import byFilters from './byFilters';
import byId from './byId';
import original from './original';

export default combineReducers({
  byFilters,
  byId,
  original,
});
