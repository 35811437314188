import React from 'react';

const SubFiles = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.494"
      height="16"
      viewBox="0 0 14.494 16"
      {...props}
    >
      <path
        id="send_file"
        d="M-16441.8-1914.014s1.131-3.013,4.145-3.013,4.145,3.013,4.145,3.013-1.131,3.013-4.145,3.013S-16441.8-1914.014-16441.8-1914.014Zm2.262,0a1.881,1.881,0,0,0,1.883,1.882,1.881,1.881,0,0,0,1.883-1.882,1.881,1.881,0,0,0-1.883-1.882A1.881,1.881,0,0,0-16439.535-1914.014Zm.756,0a1.13,1.13,0,0,1,1.127-1.131,1.133,1.133,0,0,1,1.131,1.131,1.133,1.133,0,0,1-1.131,1.131A1.129,1.129,0,0,1-16438.779-1914.014Zm-7.867.991a1.378,1.378,0,0,1-1.355-1.4V-1925.6a1.379,1.379,0,0,1,1.355-1.4h5.395l4.053,4.194v4.491a9.007,9.007,0,0,0-6.393,5.292Z"
        transform="translate(16448.002 1927.001)"
      />
    </svg>
  );
};

export default SubFiles;
