import React from 'react';

const UpLevelNav = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        id="Path_477"
        data-name="Path 477"
        d="M36,11.6A8.4,8.4,0,1,1,27.6,20,8.4,8.4,0,0,1,36,11.6m0,36c11.88,0,24.4,5.84,24.4,8.4v4.4H11.6V56c0-2.56,12.52-8.4,24.4-8.4M36,4A16,16,0,1,0,52,20,16,16,0,0,0,36,4Zm0,36C25.32,40,4,45.36,4,56V68H68V56C68,45.36,46.68,40,36,40Z"
        transform="translate(-4 -4)"
        fill="#000000"
      />
    </svg>
  );
};

export default UpLevelNav;
