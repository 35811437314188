import * as notificationTypes from 'services/apiService/notificationTypes';

const filesRoute = {
  uploadFiles: ({ files, folder, onUploadProgress }) => ({
    url: '/upload',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      folder,
    },
    data: files,
    onUploadProgress,
  }),

  replyFiles: ({ files }) => ({
    url: '/upload',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: files,
  }),

  downloadPrepare: ({ ids, type, group }) => ({
    url: '/download/prepare',
    method: 'POST',
    data: group ? { group } : { file: ids, type },
    notifications: {
      success: notificationTypes.DOWNLOAD_PREPARE_SUCCESS,
    },
  }),

  download: ({ id }) => ({
    url: `/download/${id}`,
    method: 'GET',
    responseType: 'blob',
    notifications: {
      request: notificationTypes.DOWNLOAD_FILES_REQUEST,
      success: notificationTypes.DOWNLOAD_FILES_REQUEST_SUCCESS,
    },
  }),

  previewDownload: ({ id }) => ({
    url: `/download/${id}`,
    method: 'GET',
    responseType: 'blob',
  }),
};

export default filesRoute;
