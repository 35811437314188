/* eslint-disable no-param-reassign */
import { FILE, FOLDER } from 'helpers/constants/subjects';

/** максимальный размер загружаемого файла в байтах */
const MAX_UPLOAD_FILE_SIZE = 2147483648;
// const MAX_UPLOAD_FILE_SIZE = 102400;

export const TOP_LEVEL = 'topLevel';

/** подготавливает FormData() для отправки файла на сервер  */
export const getFormData = (file) => {
  const { path } = file;
  const formData = new FormData();
  formData.append('files[0]', file);
  formData.append('paths', JSON.stringify([path]));
  return formData;
};

/** формирование дерева из путей */
export const getRelations = (folder, uploadingData) => {
  const initData = {};
  const result = Object
    .entries(uploadingData)
    .reduce((currentRelations, fileEntry) => {
      const [fileId, fileData] = fileEntry;
      const { parentId, parentFolderId } = fileData;
      const relationKey = parentId === folder ? TOP_LEVEL : parentFolderId;

      if (currentRelations[relationKey]) {
        currentRelations[relationKey].push(fileId);

        return currentRelations;
      }

      currentRelations[relationKey] = [fileId];
      return currentRelations;
    }, initData);

  return result;
};

/** сформировать параметры для запроса к АПИ */
export const getUploadFuncParams = (uploadedFilesParam, filesFormData, onUploadProgress) => {
  const {
    folder, // guid
    flashId, // guid
    parentType, // "folder" (string)
    firstPath, // ?
    isPbl, // bool
  } = uploadedFilesParam;

  const baseParams = {
    files: filesFormData,
    folder,
    onUploadProgress,
  };

  if (isPbl) {
    return {
      ...baseParams,
      linkId: flashId,
    };
  }

  return {
    ...baseParams,
    flashId,
    parentType,
    firstPath,
  };
};

/** вернуть ключ ролительской папки */
export const getParentFolderId = (fileId) => {
  const parts = fileId.split('/').filter(_ => _);
  // есть промежуточные папки
  if (parts.length > 2) {
    parts.pop();
    return parts.join('/');
  }
  return null;
};

/** если все дочерние файлы загружены, то папка тоже считается загруженной */
export const isFolderisFetching = (folderId, data) => {
  let isFetching = false;
  if (folderId && data) {
    Object.values(data)
      .filter(_ => _.id && _.id !== folderId && _.id.includes(folderId))
      .forEach((item) => {
        if (item.isFetching) {
          isFetching = true;
        }
      });
  }
  return isFetching;
};

/** обработка путей */
const getUploadingFilesPathData = (folder, currentUploadingData, parsedPath) => {
  const initData = {
    uploadingData: currentUploadingData.uploadingData,
    parentId: folder,
  };

  const result = parsedPath.reduce((currentPathData, folderName) => {
    const itemId = `${currentPathData.parentId}/${folderName}`;

    if (currentPathData.uploadingData[itemId]) {
      currentPathData.parentId = itemId;
      return currentPathData;
    }

    const addingFolderData = {
      parentId: null,
      parentFolderId: null,
      name: folderName,
      isFetching: true,
      id: itemId,
      size: 0,
      loadedSize: 0,
      type: FOLDER,
    };

    const isTopLevel = currentPathData.parentId === folder;
    const parentIdKey = isTopLevel ? 'parentId' : 'parentFolderId';
    addingFolderData[parentIdKey] = currentPathData.parentId;
    currentPathData.uploadingData[itemId] = addingFolderData;
    currentPathData.parentId = itemId;
    return currentPathData;
  }, initData);
  return result;
};

/** подготовка структуры загружаемых файлов для хранения в стейте */
export const getUploadingFilesData = (folder, files) => {
  const initData = {
    filesFormData: new FormData(),
    uploadingData: {},
    paths: [],
    generalSize: 0,
    generalCount: 0,
  };

  const result = files.reduce((currentData, file, fileIndex) => {
    const { path, name, size } = file;

    currentData.filesFormData.append(`files[${fileIndex}]`, file);
    // eslint-disable-next-line no-param-reassign
    currentData.paths.push(file.path);

    // split by '/' to get location
    const parsedPath = path.split('/').filter(_ => _);

    if (parsedPath.length === 1) {
      const addingData = {
        parentId: folder,
        parentFolderId: null,
        name,
        id: name,
        size,
        isFetching: true,
        loadedSize: 0,
        type: FILE,
      };

      currentData.generalCount += 1;
      currentData.generalSize += size;
      currentData.uploadingData[`${folder}/${name}`] = addingData;
      return currentData;
    }

    const fileName = parsedPath.pop();

    const uploadingFilesPathData = getUploadingFilesPathData(folder, currentData, parsedPath);

    const {
      uploadingData: updatedUploadingData,
      parentId,
    } = uploadingFilesPathData;

    const deepFileId = `${parentId}/${fileName}`;

    updatedUploadingData[deepFileId] = {
      parentId: null,
      parentFolderId: parentId,
      name: fileName,
      id: deepFileId,
      size,
      isFetching: true,
      loadedSize: 0,
      type: FILE,
    };
    currentData.generalCount += 1;
    currentData.generalSize += size;
    currentData.uploadingData = updatedUploadingData;
    return currentData;
  }, initData);

  return result;
};

export const getDataByRelations = (params) => {
  const {
    relationKey,
    relations,
    data,
  } = params;

  if (!relations[relationKey]) {
    return [];
  }

  return relations[relationKey].reduce((currentAggregatedData, childKey) => {
    const aggregatedDataItem = data[childKey];

    if (aggregatedDataItem.type === FOLDER && relations[childKey]) {
      const itemChildren = getDataByRelations({
        relationKey: childKey,
        data,
        relations,
      });

      aggregatedDataItem.children = itemChildren;
    }

    currentAggregatedData.push(aggregatedDataItem);

    return currentAggregatedData;
  }, []);
};

/** проверка размеров загружаемых файлов */
export const validateFileSize = (files = []) => {
  const violation = files
    .map(file => file.size)
    .filter(size => size > MAX_UPLOAD_FILE_SIZE);

  return violation.length === 0;
};
