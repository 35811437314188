import { createService } from 'services/helpers';

import { unauthAxiosInstance as unauthApiClient } from 'utils/axiosInstance';

import share from './routes/share';
import files from './routes/files';
import general from './routes/general';
import webOffice from './routes/webOffice';
import client from './routes/client';
import actions from './routes/actions';

const serviceRoutes = {
  share,
  files,
  general,
  webOffice,
  client,
  actions,
};

const unauthApiService = createService({
  routes: serviceRoutes,
  apiClient: unauthApiClient,
  shouldLogout: false,
});

export default unauthApiService;
