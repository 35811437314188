import React from 'react';

const ModerationAwaiting = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="moderation_table_awaiting"
        d="M10.051,2a8.038,8.038,0,0,0-8,7.2H3.673a6.431,6.431,0,0,1,12.755,0h1.622A8.039,8.039,0,0,0,10.051,2Zm-8,8.806a7.938,7.938,0,0,0,1.782,4.258l1.142-1.137a6.371,6.371,0,0,1-1.3-3.121Zm13.043,3.146a6.48,6.48,0,0,1-1.139,1.129l.813,1.388a8.111,8.111,0,0,0,1.717-1.687Zm-8.98,1.105L4.97,16.2a8.055,8.055,0,0,0,3.709,1.7l.115-1.611A6.42,6.42,0,0,1,6.114,15.057Zm6.455.833a6.434,6.434,0,0,1-2.17.5L10.283,18a8.013,8.013,0,0,0,3.1-.724Z"
        transform="translate(-2.051 -2)"
      />
    </svg>
  );
};

export default ModerationAwaiting;
