import React from 'react';

const Notification = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.66"
      height="15.825"
      viewBox="0 0 12.66 15.825"
      {...props}
    >
      <path
        id="notify"
        d="M10.33,2A1.187,1.187,0,0,0,9.143,3.187v.55A4.745,4.745,0,0,0,5.582,8.33v4.747l-1.215.915h0a.791.791,0,0,0,.425,1.459H15.869a.791.791,0,0,0,.425-1.459l-1.216-.915V8.33a4.746,4.746,0,0,0-3.561-4.593v-.55A1.187,1.187,0,0,0,10.33,2ZM8.748,16.243a1.582,1.582,0,0,0,3.165,0Z"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};

export default Notification;
