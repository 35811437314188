import React from 'react';
import { Provider } from 'react-redux';

import store from 'store';
// import ReduxContext from 'web/contexts/ReduxContext';

const withRedux = (Component) => (props) => (
  <Provider
    store={store}
    // context={ReduxContext}
  >
    <Component {...props} />
  </Provider>
);

export default withRedux;
