import { createSelector } from 'reselect';
import {
  selectLinkById,
  selectLinkByUuid,
} from 'store/reducers/share/selectors';
import { getParentFolderType } from 'store/reducers/previews/selectors';

export const selectFlashes = ({ flashes }) => flashes;

export const selectFlashById = ({ flashes }, id) => {
  return flashes.byId.data[id];
};

export const getFileByKey = (state, id, parentFolderType) => {
  const folderType = parentFolderType || getParentFolderType(state);

  const [folderTypeName, folderTypeAdditional] = (folderType || '').split('_');

  if (folderTypeName === 'flash' || folderTypeName === 'basket') {
    return selectFlashById(state, id);
  }

  if (folderTypeName === 'share') {
    return selectLinkById(state, `${folderTypeAdditional}_${id}`);
  }

  if (
    folderTypeName === 'publiclink' ||
    folderTypeName === 'unauthpubliclink' ||
    folderType === 'unauthpubliclinkSubfolder'
  ) {
    return selectLinkByUuid(state, id);
  }
};

const selectFlashesGroupData = (state, groupKey) => {
  const { flashes } = state;
  const flashIds = flashes.byFilters[groupKey] || [];
  const groupData = flashes.byId.data[groupKey] || {};
  return { flashIds, groupData, flashes };
};

const selectIds = (state, ids) => {
  return ids;
};

export const selectFlashesListIsFetching = ({ flashes }) =>
  flashes.byId.isFetchingList;

export const selectFlashesDirectionIsFetching = ({ flashes }) =>
  flashes.byId.isFetchingDirectionList;

export const selectFlashesSubdirectionIsFetching = ({ flashes }) =>
  flashes.byId.isFetchingSubdirectionList;

export const selectBasketIsFetching = ({ flashes }) =>
  flashes.byId.isFetchingBasket;

export const getFlashesByGroupKey = createSelector(
  selectFlashesGroupData,
  ({ flashIds, groupData, flashes }) => {
    const groupDataItems = flashIds.map(flashId => flashes.byId.data[flashId]);

    return { ...groupData, items: groupDataItems };
  },
);

export const getFlashesElementsLengthByIds = createSelector(
  selectFlashes,
  selectIds,
  (flashes, ids) => {
    const resultLength = ids.reduce((prev, itemId) => {
      return prev + (flashes.byFilters[itemId] || []).length;
    }, 0);

    return resultLength;
  },
);

export const selectCount = ({ flashes }) => {
  return flashes.byId.count;
};

export const getFlashControls = ({ flashes }) => {
  return flashes.byId.data.folderControls;
};

export const getFileById = ({ flashes }, fileId) => {
  const file = flashes.byId.data[fileId] || null;

  return file;
};

export const getFilesByIds = createSelector(
  selectFlashes,
  selectIds,
  (flashes, ids) => {
    const { data: filesData } = flashes.byId;

    const files = ids.map(fileId => (fileId && filesData[fileId]) || null);

    return files;
  },
);

export const getIsFlashListLoaded = ({ flashes }) => {
  const { isLoaded } = (flashes && flashes.byId) || {};

  return isLoaded;
};

export const selectHasFlashFavorite = ({ flashes }, flashId) => {
  return (flashes.byFilters.favorite || []).includes(flashId);
};
