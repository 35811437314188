import * as notificationTypes from 'services/apiService/notificationTypes';

const generalRoute = {
  pooling: cancelTokenSource => ({
    url: '/pooling',
    method: 'GET',
    notifications: {
      success: notificationTypes.POOL_EVENT_SUCCESS,
    },
    cancelToken: cancelTokenSource.token,
  }),

  getClientState: () => ({
    url: '/client',
    method: 'GET',
  }),
};

export default generalRoute;
