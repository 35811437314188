import React from 'react';

const ViewMore = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="112"
      viewBox="0 0 112 112"
      {...props}
    >
      <path
        id="password"
        d="M102.556,3H15.444A12.468,12.468,0,0,0,3,15.444v87.111A12.468,12.468,0,0,0,15.444,115h87.111A12.468,12.468,0,0,0,115,102.556V15.444A12.468,12.468,0,0,0,102.556,3ZM85.93,74.556a6.185,6.185,0,0,1-8.483,2.285L65.222,69.768V83.889a6.222,6.222,0,0,1-12.444,0V69.768L40.552,76.84A6.229,6.229,0,0,1,34.33,66.049L46.556,59,34.33,51.951A6.229,6.229,0,0,1,40.552,41.16l12.226,7.073V34.111a6.222,6.222,0,1,1,12.444,0V48.232L77.448,41.16A6.229,6.229,0,0,1,83.67,51.951L71.444,59,83.67,66.049A6.226,6.226,0,0,1,85.93,74.556Zm0,0"
        transform="translate(-3 -3)"
        opacity="0.1"
      />
    </svg>
  );
};

export default ViewMore;
