import React from 'react';

const Exit = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      id="exit"
      d="M4.556,3A1.567,1.567,0,0,0,3,4.556V15.444A1.567,1.567,0,0,0,4.556,17H15.444A1.567,1.567,0,0,0,17,15.444V4.556A1.567,1.567,0,0,0,15.444,3Zm0,1.556H15.444V8.9L12.883,6.339l-1.1,1.1,1.783,1.783h-5.9v1.556h5.9l-1.783,1.783,1.1,1.1L15.444,11.1v4.345H4.556Z"
      transform="translate(-3 -3)"
    />
  </svg>
);

export default Exit;
