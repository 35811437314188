import React from 'react';

const Lock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="85.333"
      height="112"
      viewBox="0 0 85.333 112"
    >
      <path
        id="lock"
        d="M46.667,1a31.928,31.928,0,0,0-32,32v5.333A10.687,10.687,0,0,0,4,49v53.333A10.687,10.687,0,0,0,14.667,113h64a10.687,10.687,0,0,0,10.667-10.667V49A10.687,10.687,0,0,0,78.667,38.333V33A31.929,31.929,0,0,0,46.667,1Zm0,10.667A20.885,20.885,0,0,1,68,33v5.333H25.333V33A20.884,20.884,0,0,1,46.667,11.667ZM25.333,70.333A5.333,5.333,0,1,1,20,75.667,5.344,5.344,0,0,1,25.333,70.333Zm21.333,0a5.333,5.333,0,1,1-5.333,5.333A5.344,5.344,0,0,1,46.667,70.333Zm21.333,0a5.333,5.333,0,1,1-5.333,5.333A5.344,5.344,0,0,1,68,70.333Zm0,0"
        transform="translate(-4 -1)"
        opacity="0.1"
      />
    </svg>
  );
};

export default Lock;
