import React from 'react';

const Sort = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.444"
      height="16"
      viewBox="0 0 12.444 16"
      {...props}
    >
      <path
        id="sort"
        d="M14.778,15.453V9.222H13v6.231H10.333L13.889,19l3.556-3.547ZM8.556,3,5,6.547H7.667v6.231H9.444V6.547h2.667Zm6.222,12.453V9.222H13v6.231H10.333L13.889,19l3.556-3.547ZM8.556,3,5,6.547H7.667v6.231H9.444V6.547h2.667Z"
        transform="translate(-5 -3)"
      />
    </svg>
  );
};

export default Sort;
