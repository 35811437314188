import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// import { baseURL, xmfClientAccessKey } from 'utils/axiosInstance/helpers';
import { languageConst } from 'shared/helpers/language';

// https://github.com/i18next/i18next-http-backend
const backendOptions = ((envs) => {
  const {
    PUBLIC_URL,
    NODE_ENV,
  } = envs;

  if (NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Warning! The language settings of the DEVELOPMENT environment are used!');
    return {
      loadPath: `${PUBLIC_URL}/languages/%{lng}_%{ns}.json`,
      allowMultiLoading: true,
      // loadPath: `${baseURL}/languages?language=%{lng}`,
      // customHeaders: {
      //   'XMF-Client-Access-Key': xmfClientAccessKey,
      //   'content-type': 'application/json',
      // },
      // parse: (
      //   data,
      //   key,
      //   // ns,
      // ) => {
      //   const parsed = JSON.parse(data);
      //   // console.log('parse', { data, key, ns, parsed });
      //   return parsed[key];
      // },
    };
  }

  // при запуске production сборки на среде разработки
  // языковые файлы будут подгружаться локально из папки /public/languages/*
  // эта папка добавлена в .gitignore, в связи с этим в нее необхоимо вручную скопировать актуальные файлы
  return {
    loadPath: `${PUBLIC_URL}/languages/%{lng}_%{ns}.json`,
    allowMultiLoading: false,
  };
})(process.env);

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    // https://www.i18next.com/overview/configuration-options
    lng: languageConst.en, // language to use (overrides language detection)
    fallbackLng: languageConst.ru, // language to use if translations in user language are not available.
    ns: 'tr', // string or array of namespaces to load
    defaultNS: 'tr', // default namespace used if not passed to translation function
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      prefix: '%{',
      suffix: '}',
    },
    backend: backendOptions,
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    // https://react.i18next.com/
    react: {
      bindI18n: 'languageChanged',
      // bindI18nStore: '',
      // transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
    saveMissing: true,
    missingKeyHandler: () => { },
  });

export default i18next;
