import React, { useState } from 'react';
import { getFilterUrl } from 'shared/elements/StyledSvg/helpers'
import { createGuid } from 'helpers/createGuid';

const FileManagerFolder = props => {
  // Хак для того, чтобы компонент не терял id в Safari в момент ререндера svg
  const [rectId] = useState(createGuid());
  const filter = getFilterUrl(rectId);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="77"
      height="64.6"
      viewBox="0 0 77 64.6"
      {...props}
    >
      <defs>
        <filter
          id={rectId}
          x="0"
          y="0"
          width="77"
          height="64.6"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood floodOpacity="0.071" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="fm_folder" transform="translate(-18.5 6.5)">
        <g
          transform="matrix(1, 0, 0, 1, 18.5, -6.5)"
          filter={filter}
        >
          <path
            id="Path_442-2"
            data-name="Path 442"
            d="M26.8,4H8.2a6.192,6.192,0,0,0-6.169,6.2L2,47.4a6.218,6.218,0,0,0,6.2,6.2H57.8A6.218,6.218,0,0,0,64,47.4v-31a6.218,6.218,0,0,0-6.2-6.2H33Z"
            transform="translate(5.5 2.5)"
            fill="#efa163"
          />
        </g>
      </g>
    </svg>
  );
};

export default FileManagerFolder;
