export default {
  REQUEST_GET_LINKS: 'REQUEST_GET_LINKS',
  REQUEST_GET_LINKS_SUCCESS: 'REQUEST_GET_LINKS_SUCCESS',
  REQUEST_GET_LINKS_FAIL: 'REQUEST_GET_LINKS_FAIL',

  REQUEST_GET_LINKS_SUBFOLDER: 'REQUEST_GET_LINKS_SUBFOLDER',
  REQUEST_GET_LINKS_SUBFOLDER_SUCCESS: 'REQUEST_GET_LINKS_SUBFOLDER_SUCCESS',
  REQUEST_GET_LINKS_SUBFOLDER_FAIL: 'REQUEST_GET_LINKS_SUBFOLDER_FAIL',

  REQUEST_GET_PUBLIC_LINKS: 'REQUEST_GET_PUBLIC_LINKS',
  REQUEST_GET_PUBLIC_LINKS_SUCCESS: 'REQUEST_GET_PUBLIC_LINKS_SUCCESS',
  REQUEST_GET_PUBLIC_LINKS_FAIL: 'REQUEST_GET_PUBLIC_LINKS_FAIL',

  DELETE_UNAUTH_ITEM: 'DELETE_UNAUTH_ITEM',
  DELETE_UNAUTH_ITEM_SUCCESS: 'DELETE_UNAUTH_ITEM_SUCCESS',
  DELETE_UNAUTH_ITEM_FAIL: 'DELETE_UNAUTH_ITEM_FAIL',

  REQUEST_GET_UNAUTH_PUBLIC_LINKS: 'REQUEST_GET_UNAUTH_PUBLIC_LINKS',
  REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUCCESS:
    'REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUCCESS',
  REQUEST_GET_UNAUTH_PUBLIC_LINKS_FAIL: 'REQUEST_GET_UNAUTH_PUBLIC_LINKS_FAIL',

  REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER:
    'REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER',
  REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER_SUCCESS:
    'REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER_SUCCESS',
  REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER_FAIL:
    'REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER_FAIL',

  REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA:
    'REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA',
  REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_SUCCESS:
    'REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_SUCCESS',
  REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_FAIL:
    'REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_FAIL',

  GET_LINK_INFO: 'GET_LINK_INFO',
  GET_LINK_INFO_SUCCESS: 'GET_LINK_INFO_SUCCESS',
  GET_LINK_INFO_FAIL: 'GET_LINK_INFO_FAIL',

  REQUEST_GET_OUTBOX_SUBFOLDER: 'REQUEST_GET_OUTBOX_SUBFOLDER',
  REQUEST_GET_OUTBOX_SUBFOLDER_SUCCESS: 'REQUEST_GET_OUTBOX_SUBFOLDER_SUCCESS',
  REQUEST_GET_OUTBOX_SUBFOLDER_FAIL: 'REQUEST_GET_OUTBOX_SUBFOLDER_FAIL',

  REQUEST_GET_INBOX_SUBFOLDER: 'REQUEST_GET_INBOX_SUBFOLDER',
  REQUEST_GET_INBOX_SUBFOLDER_SUCCESS: 'REQUEST_GET_INBOX_SUBFOLDER_SUCCESS',
  REQUEST_GET_INBOX_SUBFOLDER_FAIL: 'REQUEST_GET_INBOX_SUBFOLDER_FAIL',

  REQUEST_GET_INBOX_LINKS: 'REQUEST_GET_INBOX_LINKS',
  REQUEST_GET_INBOX_LINKS_SUCCESS: 'REQUEST_GET_INBOX_LINKS_SUCCESS',
  REQUEST_GET_INBOX_LINKS_FAIL: 'REQUEST_GET_INBOX_LINKS_FAIL',

  REQUEST_GET_OUTBOX_LINKS: 'REQUEST_GET_OUTBOX_LINKS',
  REQUEST_GET_OUTBOX_LINKS_SUCCESS: 'REQUEST_GET_OUTBOX_LINKS_SUCCESS',
  REQUEST_GET_OUTBOX_LINKS_FAIL: 'REQUEST_GET_OUTBOX_LINKS_FAIL',

  GET_ALL_LINKS: 'GET_ALL_LINKS',

  REQUEST_SEND_LINK: 'REQUEST_SEND_LINK',
  REQUEST_SEND_LINK_SUCCESS: 'REQUEST_SEND_LINK_SUCCESS',
  REQUEST_SEND_LINK_FAIL: 'REQUEST_SEND_LINK_FAIL',

  REQUEST_INNER_FILES: 'REQUEST_INNER_FILES',
  REQUEST_INNER_FILES_SUCCESS: 'REQUEST_INNER_FILES_SUCCESS',
  REQUEST_INNER_FILES_FAIL: 'REQUEST_INNER_FILES_FAIL',

  DELETE_SHARE_ITEM: 'DELETE_SHARE_ITEM',
};
