import React from 'react';

const VersionSingleControl = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.636"
    height="23.001"
    viewBox="0 0 14.636 23.001"
    {...props}
  >
    <path
      id="Subtraction_10"
      data-name="Subtraction 10"
      d="M-8197.978-640h-13.59a.525.525,0,0,1-.524-.524v-21.953a.522.522,0,0,1,.475-.524h13.64a.52.52,0,0,1,.521.475v22A.524.524,0,0,1-8197.978-640Zm-13.068-21.955v20.909h12.546v-20.909Z"
      transform="translate(8212.092 663.001)"
    />
  </svg>
);

export default VersionSingleControl;
