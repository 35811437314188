/* eslint-disable react/prop-types */
import React, { createContext, useState, useMemo } from 'react';

const SidePanelContext = createContext();
const sidePanelMinWidth = 380;

const SidePanelState = ({ children }) => {
  const initialSidePanelWidth = window.innerWidth * 0.2 > sidePanelMinWidth
    ? window.innerWidth * 0.209
    : sidePanelMinWidth;

  const [sidePanelWidth, setSidePanelWidth] = useState(initialSidePanelWidth);
  const [activeSidePanel, setActiveSidePanel] = useState(null);

  const value = useMemo(() => ({
    sidePanelMinWidth,
    sidePanelWidth,
    setSidePanelWidth,
    activeSidePanel,
    setActiveSidePanel,
  }), [
    activeSidePanel,
    sidePanelWidth,
  ]);

  return (
    <SidePanelContext.Provider value={value}>
      {children}
    </SidePanelContext.Provider>
  );
};

export { SidePanelContext as default, SidePanelState };
