import React from 'react';

const ModerationGridAwaiting = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <filter
          id="Ellipse_3"
          x="0"
          y="0"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="moderation_grid_awaiting" transform="translate(-662 -196.174)">
        <g transform="matrix(1, 0, 0, 1, 662, 196.17)" filter="url(#Ellipse_3)">
          <circle
            id="Ellipse_3-2"
            data-name="Ellipse 3"
            cx="8"
            cy="8"
            r="8"
            transform="translate(3 3)"
            fill="#e29f5d"
          />
        </g>
        <path
          id="Path_72"
          data-name="Path 72"
          d="M10.255,3.464,7.791,1V2.663a4.328,4.328,0,0,0,0,8.589V10.158a3.245,3.245,0,0,1,0-6.4V5.874Zm2.372,2.951a4.282,4.282,0,0,0-.877-2.107l-.769.769a3.144,3.144,0,0,1,.552,1.338ZM8.874,10.152v1.094a4.291,4.291,0,0,0,2.112-.872l-.78-.78a3.133,3.133,0,0,1-1.332.558Zm2.107-1.311.769.764A4.282,4.282,0,0,0,12.627,7.5H11.533A3.194,3.194,0,0,1,10.981,8.842Z"
          transform="translate(664.346 200.586)"
          fill="#fffefc"
        />
      </g>
    </svg>
  );
};

export default ModerationGridAwaiting;
