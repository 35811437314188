import React from 'react';

const Tasks = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 11.2"
      {...props}
    >
      <path
        id="tasks"
        d="M3.4,4A1.41,1.41,0,0,0,2,5.4v8.4a1.41,1.41,0,0,0,1.4,1.4H14.6A1.41,1.41,0,0,0,16,13.8V5.4A1.41,1.41,0,0,0,14.6,4Zm0,1.4H14.6v8.4H3.4v-.7h7V11.7h-7V10.3h9.8V8.9H3.4V7.5H6.2V6.1H3.4Z"
        transform="translate(-2 -4)"
      />
    </svg>
  );
};

export default Tasks;
