import React from 'react';

const TaskCompleted = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.4"
      height="16"
      viewBox="0 0 14.4 16"
      {...props}
    >
      <path
        id="task_completed"
        d="M15.8,2.6H12.453a2.386,2.386,0,0,0-4.506,0H4.6A1.6,1.6,0,0,0,3,4.2V15.4A1.6,1.6,0,0,0,4.6,17H15.8a1.6,1.6,0,0,0,1.6-1.6V4.2A1.6,1.6,0,0,0,15.8,2.6ZM7.566,8.434,9.4,10.269l3.434-3.434,1.131,1.131L9.4,12.531,6.434,9.566ZM10.2,2.6a.8.8,0,1,1-.8.8A.8.8,0,0,1,10.2,2.6Z"
        transform="translate(-3 -1)"
      />
    </svg>
  );
};

export default TaskCompleted;
