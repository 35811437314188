import * as authTypes from 'store/reducers/logout/types';

import * as clientNotificationsTypes from './types';

const initialState = [];

const ACTIONS_HANDLERS = {
  [clientNotificationsTypes.PUSH_NOTIFICATION]: (state, { notification }) => [...state, notification],

  [clientNotificationsTypes.CLEAR_NOTIFICATIONS]: (state, { ids }) => {
    if (!ids || !Array.isArray(ids)) {
      return [];
    }

    const nextState = state.filter(
      notification => !ids.includes(notification.id),
    );

    return nextState;
  },
  [authTypes.ON_LOGOUT]: () => initialState,
};

export default (state = initialState, action) => (ACTIONS_HANDLERS[action.type]
  ? ACTIONS_HANDLERS[action.type](state, action)
  : state);
