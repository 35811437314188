import React from 'react';

const TableView = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      {...props}
    >
      <path
        id="table_view"
        d="M4,14H7.765V10H4Zm0,5H7.765V15H4ZM4,9H7.765V5H4Zm4.706,5H20V10H8.706Zm0,5H20V15H8.706Zm0-14V9H20V5Z"
        transform="translate(-4 -5)"
      />
    </svg>
  );
};

export default TableView;
