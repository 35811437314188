import React from 'react';

const UploadAttribute = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.675"
      height="16"
      viewBox="0 0 15.675 16"
      {...props}
    >
      <path
        id="upload"
        d="M9.838,1.586,4.027,7.4h3.46v5.095a1.187,1.187,0,0,0,1.176,1.176h2.351a1.187,1.187,0,0,0,1.176-1.176V7.4h3.46Zm0,2.217,2.027,2.027H10.622V12.1H9.054V5.829H7.811ZM2,16.018v1.568H17.675V16.018Z"
        transform="translate(-2 -1.586)"
      />
    </svg>
  );
};

export default UploadAttribute;
