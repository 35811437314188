/* eslint-disable react/prop-types */
import React, { useState, createContext, useMemo } from 'react';

const TabsContext = createContext();

const TabsContextProvider = ({ children }) => {
  const [isSwipeAvailable, setIsSwipeAvailable] = useState(true);
  const value = useMemo(() => ({ isSwipeAvailable, setIsSwipeAvailable }), [isSwipeAvailable]);

  return (<TabsContext.Provider value={value}>{children}</TabsContext.Provider>);
};

export { TabsContext as default, TabsContextProvider };
