import * as authTypes from 'store/reducers/auth/types';

import types from './constants';
import initialState from './initialState';

const ACTION_HANDLERS = {
  [types.SET_SETTINGS_LAYOUT]: (state, { payload }) => ({
    ...state,
    currentSettingLayout: payload,
  }),
  [types.SET_NEED_RESET_SETTINGS]: (state, { payload }) => ({
    ...state,
    needResetSettings: payload,
  }),
  [types.REQUEST_COMMON_SETTINGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    common: payload,
    isConfigLoaded: true,
  }),
  [types.REQUEST_USER_SETTINGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    userSettings: payload,
    isSettingsLoaded: true,
  }),
  [types.REQUEST_DEVICES_LIST_SUCCESS]: state => state,
  [types.REQUEST_LOG_LIST_SUCCESS]: state => state,
  [types.REQUEST_USER_SETTINGS_UPDATE_SUCCESS]: (state, { payload, params }) => {
    if (!payload.success) {
      return state;
    }
    const languages = (state.common && state.common.public && state.common.public.languages) || [];
    const { language } = params;

    const newLanguage = language !== undefined && languages[language];

    return {
      ...state,
      userSettings: {
        ...state.userSettings,
        ...params,
        language: newLanguage || state.userSettings.language,
      },
    };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: (state) => {
    const publicSettings = state.common && state.common.public;

    if (!publicSettings) {
      return initialState;
    }

    return {
      ...initialState,
      common: {
        public: publicSettings,
      },
    };
  },
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

export const settings = (state = initialState, action) => (ACTION_HANDLERS[action.type]
  ? ACTION_HANDLERS[action.type](state, action)
  : state);
