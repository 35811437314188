import React from 'react';

const ListView = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="grid_view"
        d="M4.778,3A1.78,1.78,0,0,0,3,4.778V8.333a1.78,1.78,0,0,0,1.778,1.778H8.333a1.78,1.78,0,0,0,1.778-1.778V4.778A1.78,1.78,0,0,0,8.333,3Zm8.889,0a1.78,1.78,0,0,0-1.778,1.778V8.333a1.78,1.78,0,0,0,1.778,1.778h3.556A1.78,1.78,0,0,0,19,8.333V4.778A1.78,1.78,0,0,0,17.222,3ZM4.778,11.889A1.78,1.78,0,0,0,3,13.667v3.556A1.78,1.78,0,0,0,4.778,19H8.333a1.78,1.78,0,0,0,1.778-1.778V13.667a1.78,1.78,0,0,0-1.778-1.778Zm8.889,0a1.78,1.78,0,0,0-1.778,1.778v3.556A1.78,1.78,0,0,0,13.667,19h3.556A1.78,1.78,0,0,0,19,17.222V13.667a1.78,1.78,0,0,0-1.778-1.778Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export default ListView;
