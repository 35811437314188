import React from 'react';

const Delete = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        id="remove"
        d="M18,6.309,16.691,5,11.5,10.191,6.309,5,5,6.309,10.191,11.5,5,16.691,6.309,18,11.5,12.809,16.691,18,18,16.691,12.809,11.5Z"
        transform="translate(-5 -5)"
      />
    </svg>
  );
};

export default Delete;
