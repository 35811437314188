import React from 'react';

const SharedToMeEmpty = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113.002"
      height="113"
      viewBox="0 0 113.002 113"
      {...props}
    >
      <path
        id="Subtraction_66"
        data-name="Subtraction 66"
        d="M19130.5,13546a56.219,56.219,0,0,1-31.588-9.648,56.648,56.648,0,0,1-20.469-24.858,56.428,56.428,0,0,1,5.209-53.581,56.538,56.538,0,0,1,98.91,9.6,56.418,56.418,0,0,1-5.211,53.582,56.67,56.67,0,0,1-24.859,20.469A56.142,56.142,0,0,1,19130.5,13546Zm-15.586-72.263h-10.676a5.015,5.015,0,0,0-3.7,1.656,5.82,5.82,0,0,0-1.541,3.987v33.832a5.814,5.814,0,0,0,1.541,3.982,5.022,5.022,0,0,0,3.7,1.661h41.873a5,5,0,0,0,3.689-1.661,5.814,5.814,0,0,0,1.541-3.982v-3.048l-5.23-5.234v8.282h-41.873v-28.2h21.953Zm-9.5-15.149L19104,13460l57.98,57.981,1.416-1.411-31.555-31.555h0l-26.426-26.427Zm14.773,42.674h0l7.5,4.377a2.954,2.954,0,0,0-.092.693,3.115,3.115,0,1,0,1.016-2.264l-7.5-4.367a3.576,3.576,0,0,0,.092-.736,3.483,3.483,0,0,0-.092-.737l7.416-4.329a3.132,3.132,0,0,0,5.156-1.387l-3.937-3.934a3.132,3.132,0,0,0-2.23,3.019,3.583,3.583,0,0,0,.1.737l-7.424,4.329a3.118,3.118,0,0,0-2.152-.857,3.159,3.159,0,0,0,0,6.317,3.088,3.088,0,0,0,2.152-.861Zm34.352-.992h0v4.589h2.07a4.613,4.613,0,0,0,4.586-4.589v-27.51l-13.758-13.758H19129.1a4.611,4.611,0,0,0-4.588,4.585v9.062h4.588v-9.062h16.045v11.46h11.465v25.223Zm-22.7-15.255h14.268v14.258l5.23,5.229v-19.487a5.449,5.449,0,0,0-5.23-5.634h-19.9Z"
        transform="translate(-19074 -13432.998)"
        fill="#ececec"
      />
    </svg>
  );
};

export default SharedToMeEmpty;
