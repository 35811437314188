export default {
  data: {
    exchange: [],
    received: [],
    sent: [],
    folderControls: {},
    publiclink: [],
    unauthpubliclink: [],
  },
};
