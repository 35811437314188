import { createSelector } from 'reselect';

export const selectThemeType = ({ type }) => type;
export const selectCustomColorTheme = ({ custom = {} }) => custom.color;
export const selectCurrentColorTheme = createSelector(
  selectThemeType,
  selectCustomColorTheme,
  (type, color = {}) => (color[type]) || {},
);

export const selectCommonColors = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common) || {},
);

export const selectPrimary100 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.primary100),
);
export const selectPrimary500 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.primary500),
);
export const selectTertiary500 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.tertiary500),
);
export const selectTertiary600 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.tertiary600),
);
export const selectTertiary700 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.tertiary700),
);
export const selectGray600 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.gray600),
);
export const selectGray500Opacity = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.gray500Opacity),
);
export const selectNotification500 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.notification500),
);
export const selectError50 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.error50),
);
export const selectError500 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.error500),
);
export const selectSuccess500 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.success500),
);
export const selectWarning500 = createSelector(
  selectCurrentColorTheme, colorTheme => (colorTheme.common?.warning500),
);
