import React from 'react';

const ForwardNav = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.88"
      height="16"
      viewBox="0 0 9.88 16"
      {...props}
    >
      <path
        id="forward"
        d="M18.08,7.88,16.2,6l-8,8,8,8,1.88-1.88L11.973,14Z"
        transform="translate(18.08 22) rotate(180)"
      />
    </svg>
  );
};

export default ForwardNav;
