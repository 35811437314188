export const COPY = 'copy';
export const TRASH = 'trash';
export const DISK = 'disk';
export const REPLY = 'reply';
export const SEND = 'send';
export const SHARE = 'share';
export const UNAUTHLINK = 'unauthlink';
export const screens = {
  COPY,
  TRASH,
  DISK,
  REPLY,
  SEND,
  SHARE,
  UNAUTHLINK,
};
