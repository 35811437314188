import { generatePath } from 'react-router-dom';

import { getLabelWithLang } from 'helpers/getLabelWithLang';
import {
  baseURL,
  xmfClientAccessKey,
} from 'shared/utils/axiosInstance/helpers';
import {
  getItemFromLocalStorage,
  AUTH_TOKEN,
} from 'helpers/localStorage';

// TODO: добавить received и вложенные, sent, publiclink и другие
export const screensPaths = {
  main: '/',
  settings: '/settings',
  admin: '/admin',
  about: '/about',
  support: '/support',
  distribute: '/distribute',
  docs: '/docs',
  login: '/login',
  trash: '/trash',
  recoverPassword: '/recover',
  notFound: '/404',
  tutorial: '/tutorial',
  preview: '/preview',
  publicLinks: '/publiclink',
  // TODO: Alexey: rename shareId for App
  publicLink: '/publiclink/:shareId',
  activation: '/reg/:registrationToken',
  resetPassword: '/reset/:registrationToken',
  publiclinkPinAuth: '/link/:linkId',
  publiclinkGeneralPinAuth: '/link/:isGeneral/:linkId',
  unAuthLink: '/pbl/:linkId',
  unAuthLinkSubFolder: '/pbl/:linkId/:shareId',
  exchange: '/exchange',
  receivedFiles: '/received',
  receivedFolder: '/received/:shareId',
  sentFiles: '/sent',
  sentFolder: '/sent/:shareId',
  autoLogin: '/autologin/:token',
  sharedFlashes: '/shared/:id',
  folder: '/flash/:id/:subId',
  flash: '/flash/:id',
  online: '/online/:flashId/:fileId/:action',
  theme: '/theme',
  reply: '/reply',
  invite: '/invite',
  notifications: '/notifications',
  gui: {
    root: '/gui',
    flashCreate: '/gui/flash',
    flashEdit: '/gui/flash/:flashId',
    exchange: '/gui/exchange',
    about: '/gui/about',
    message: '/gui/message',
    authorization: '/gui/authorization/:hash/:wui',
    activation: '/gui/activation',
    reset: '/gui/reset',
    support: '/gui/support',
  },
  logs: '/logs',
  test: '/test',

  // MOBILE:
  send: '/send',
  sendModeration: '/send-moderation',
};

// inspired by https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
export function retry(fn, retriesLeft = 3, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }
          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const firstPathKeys = {
  ABOUT: 'about',
  ADMIN: 'admin',
  CONTACTS: 'contacts',
  DISTRIBUTE: 'distribute',
  DOCS: 'docs',
  EXCHANGE: 'exchange',
  FLASH: 'flash',
  GUI: 'gui',
  HELP: 'help',
  MAIN: '',
  ONLINE: 'online',
  PBL: 'pbl',
  PREVIEW: 'preview',
  PUBLICLINK: 'publiclink',
  RECEIVED: 'received',
  SEND: 'send',
  SENT: 'sent',
  SETTINGS: 'settings',
  SHARED: 'shared',
  SUPPORT: 'support',
  TASKS: 'tasks',
  TRASH: 'trash',
  INVITE: 'invite',
  NOTIFICATIONS: 'notifications',
  LOGS: 'logs',
  RESET: 'reset',
  LINK: 'link',
};

export const namedHeaderRoutes = [
  firstPathKeys.SETTINGS,
  firstPathKeys.DOCS,
  firstPathKeys.DISTRIBUTE,
  firstPathKeys.SUPPORT,
  firstPathKeys.ABOUT,
  firstPathKeys.LOGS,
];

export const namedHeaderWithSearchRoutes = [
  firstPathKeys.LOGS,
];

export const noContentPanelRoutes = [
  firstPathKeys.DOCS,
  firstPathKeys.DISTRIBUTE,
  firstPathKeys.SUPPORT,
  firstPathKeys.ABOUT,
  firstPathKeys.LOGS,
];

export const guestAllowedRoutes = [
  firstPathKeys.EXCHANGE,
  firstPathKeys.RECEIVED,
  firstPathKeys.SENT,
  firstPathKeys.SETTINGS,
  firstPathKeys.DOCS,
  firstPathKeys.SUPPORT,
  firstPathKeys.ABOUT,
];

export const linksRoutes = [
  firstPathKeys.EXCHANGE,
  firstPathKeys.RECEIVED,
  firstPathKeys.SENT,
  firstPathKeys.PUBLICLINK,
];

/** EXCHANGE / SENT / RECEIVED */
export const shareRoutes = [
  firstPathKeys.EXCHANGE,
  firstPathKeys.SENT,
  firstPathKeys.RECEIVED,
];

export const shareTable = [
  firstPathKeys.EXCHANGE,
  firstPathKeys.RECEIVED,
  firstPathKeys.SENT,
  firstPathKeys.PUBLICLINK,
  firstPathKeys.PBL,
];

export const publicUrl = process.env.PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL || '';

/** маршруты, где достуны кнопки вызова коллаборы */
export const collaboraRoutes = [
  firstPathKeys.FLASH,
];

/** маршруты, где не нужно запускать авторизованный пулинг */
export const restrictPollingRoutes = [
  firstPathKeys.PBL,
  firstPathKeys.GUI,
  firstPathKeys.ONLINE,
];

/** маршруты, где нужно выключить открытие меню по свайпу */
export const restrictSwipeRoutes = [
  firstPathKeys.ONLINE,
];

/** маршруты, где запрещено отправлять команду LASTPAGE */
export const restrictLastPageCommandRoutes = [
  firstPathKeys.PREVIEW,
  firstPathKeys.SEND,
];

export const buildPublicLink = (uri, link) => `${uri}/${firstPathKeys.PBL}/${link}` || getLabelWithLang('no_data', 'Н/д');

/** маршруты, где нужно выключить получение сообщений */
export const restrictMessagesRoutes = [
  firstPathKeys.ONLINE,
];

/** маршруты, где нужно выключить получение списка накопителей */
export const restrictFlashListRoutes = [
  firstPathKeys.ONLINE,
];

export const goBack = (history, flash, folder) => {
  if (flash && folder) {
    history.push(
      generatePath(screensPaths.folder, { id: flash, subId: folder }),
    );
  } else if (flash) {
    history.push(generatePath(screensPaths.flash, { id: flash }));
  } else {
    history.push(screensPaths.main);
  }
};

export const goToReport = (id) => {
  const authToken = getItemFromLocalStorage(AUTH_TOKEN);
  const url = `${baseURL}/folder/report/${id}?token=${authToken}&xmf-client-access-key=${xmfClientAccessKey}`;
  window.open(url, '_blank');
};

export const goToAdminBlock = (adminBlockLink = '') => {
  const authToken = getItemFromLocalStorage(AUTH_TOKEN);
  window.location.href = `${adminBlockLink}?sess=${authToken}`;
};
