/* eslint-disable react/prop-types */
import React, { createContext, useState, useMemo } from 'react';

const HeaderMainHeightContext = createContext();

const HeaderMainHeightState = ({ children }) => {
  const [headerMainHeight, setHeaderMainHeight] = useState(0);

  const value = useMemo(() => ({
    headerMainHeight,
    setHeaderMainHeight,
  }), [headerMainHeight, setHeaderMainHeight]);

  return (
    <HeaderMainHeightContext.Provider value={value}>
      {children}
    </HeaderMainHeightContext.Provider>
  );
};

export { HeaderMainHeightContext as default, HeaderMainHeightState };
