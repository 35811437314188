import React from 'react';

const UploadToCloud = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98.911"
      height="65.939"
      viewBox="0 0 98.911 65.939"
      {...props}
    >
      <path
        id="upload_to_cloud"
        d="M77.087,23.622a28.814,28.814,0,0,0-51.3-8.238A26.821,26.821,0,0,0,.272,46.017C2.152,59.361,14.2,68.939,27.67,68.939H45.333V44.212H36.411A1.718,1.718,0,0,1,35.2,41.278L48.239,28.234a1.719,1.719,0,0,1,2.431,0L63.714,41.278A1.718,1.718,0,0,1,62.5,44.212H53.576V68.939h21.7c11.708,0,22.2-8.461,23.491-20.095A22.7,22.7,0,0,0,77.087,23.622Z"
        transform="translate(0 -3)"
      />
    </svg>
  );
};

export default UploadToCloud;
