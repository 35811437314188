export const OPEN_PREVIEW = 'OPEN_PREVIEW';
export const CLOSE_PREVIEW = 'CLOSE_PREVIEW';

export const SELECT_PREVIEW_FILE = 'SELECT_PREVIEW_FILE';

export const PREPARE_PREVIEW_FILES = 'PREPARE_PREVIEW_FILES';
export const PREPARE_PREVIEW_FILES_SUCCESS = 'PREPARE_PREVIEW_FILES_SUCCESS';
export const PREPARE_PREVIEW_FILES_FAIL = 'PREPARE_PREVIEW_FILES_FAIL';

export const DOWNLOAD_PREVIEW_FILES = 'DOWNLOAD_PREVIEW_FILES';
export const DOWNLOAD_PREVIEW_FILES_SUCCESS = 'DOWNLOAD_PREVIEW_FILES_SUCCESS';
export const DOWNLOAD_PREVIEW_FILES_FAIL = 'DOWNLOAD_PREVIEW_FILES_FAIL';

export const SET_FOLDER_TYPE = 'SET_FOLDER_TYPE';

export const RESET_PREPARED_FILES = 'RESET_PREPARED_FILES';

export const UNAUTH_DOWNLOAD_PREVIEW_FILES = 'UNAUTH_DOWNLOAD_PREVIEW_FILES';
export const UNAUTH_DOWNLOAD_PREVIEW_FILES_SUCCESS = 'UNAUTH_DOWNLOAD_PREVIEW_FILES_SUCCESS';
export const UNAUTH_DOWNLOAD_PREVIEW_FILES_FAIL = 'UNAUTH_DOWNLOAD_PREVIEW_FILES_FAIL';
