import React from 'react';

const GuideInvite = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 21.002 20.578"
  >
    <defs>
      <filter
        id="guide_invite"
        x="0"
        y="0"
        width="21.002"
        height="20.578"
        filterUnits="userSpaceOnUse"
      >
        <feOffset input="SourceAlpha" />
        <feGaussianBlur stdDeviation="1.5" result="blur" />
        <feFlood floodColor="#d7baf9" floodOpacity="0.4" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#guide_invite)">
      <path
        id="guide_invite-2"
        data-name="guide_invite"
        d="M943.591,16096.577v-2.558h-2.557v-.853h2.557v-2.557h.852v2.557H947v.853h-2.557v2.558ZM935,16094.02v-1.692c0-1.479,2.937-2.254,4.512-2.254a8.266,8.266,0,0,1,2.743.537,3.16,3.16,0,0,0-1.127.755,6.465,6.465,0,0,0-1.616-.222c-1.676,0-3.438.819-3.438,1.184v.622h4.217a3.092,3.092,0,0,0-.065.643,2.959,2.959,0,0,0,.028.424v0Zm2.258-6.767a2.254,2.254,0,1,1,2.254,2.254A2.257,2.257,0,0,1,937.257,16087.253Zm1.071,0a1.184,1.184,0,1,0,1.184-1.184A1.183,1.183,0,0,0,938.328,16087.253Z"
        transform="translate(-930.5 -16080.5)"
      />
    </g>
  </svg>
);

export default GuideInvite;
