export default {
  SET_SETTINGS_LAYOUT: 'SET_SETTINGS_LAYOUT',

  REQUEST_COMMON_SETTINGS: 'REQUEST_COMMON_SETTINGS',
  REQUEST_COMMON_SETTINGS_SUCCESS: 'REQUEST_COMMON_SETTINGS_SUCCESS',
  REQUEST_COMMON_SETTINGS_FAIL: 'REQUEST_COMMON_SETTINGS_FAIL',

  REQUEST_USER_SETTINGS: 'REQUEST_USER_SETTINGS',
  REQUEST_USER_SETTINGS_SUCCESS: 'REQUEST_USER_SETTINGS_SUCCESS',
  REQUEST_USER_SETTINGS_FAIL: 'REQUEST_USER_SETTINGS_FAIL',

  REQUEST_LOG_LIST: 'REQUEST_LOG_LIST',
  REQUEST_LOG_LIST_SUCCESS: 'REQUEST_LOG_LIST_SUCCESS',
  REQUEST_LOG_LIST_FAIL: 'REQUEST_LOG_LIST_FAIL',

  REQUEST_DEVICES_LIST: 'REQUEST_DEVICES_LIST',
  REQUEST_DEVICES_LIST_SUCCESS: 'REQUEST_DEVICES_LIST_SUCCESS',
  REQUEST_DEVICES_LIST_FAIL: 'REQUEST_DEVICES_LIST_FAIL',

  REQUEST_USER_SHARE_LIST: 'REQUEST_USER_SHARE_LIST',
  REQUEST_USER_SHARE_LIST_SUCCESS: 'REQUEST_USER_SHARE_LIST_SUCCESS',
  REQUEST_USER_SHARE_LIST_FAIL: 'REQUEST_USER_SHARE_LIST_FAIL',

  REQUEST_USER_SETTINGS_UPDATE: 'REQUEST_USER_SETTINGS_UPDATE',
  REQUEST_USER_SETTINGS_UPDATE_SUCCESS: 'REQUEST_USER_SETTINGS_UPDATE_SUCCESS',
  REQUEST_USER_SETTINGS_UPDATE_FAIL: 'REQUEST_USER_SETTINGS_UPDATE_FAIL',

  SET_NEED_RESET_SETTINGS: 'SET_NEED_RESET_SETTINGS',

  REQUEST_INSTANCE_SETTINGS: 'REQUEST_INSTANCE_SETTINGS',
  REQUEST_INSTANCE_SETTINGS_SUCCESS: 'REQUEST_INSTANCE_SETTINGS_SUCCESS',
  REQUEST_INSTANCE_SETTINGS_FAIL: 'REQUEST_INSTANCE_SETTINGS_FAIL',
};
