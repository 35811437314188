import React from 'react';

const CheckAll = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      id="check_all"
      d="M3.6,2A1.6,1.6,0,0,0,2,3.6v9.6a1.6,1.6,0,0,0,1.6,1.6h9.6a1.6,1.6,0,0,0,1.6-1.6V3.6A1.6,1.6,0,0,0,13.2,2Zm0,1.6h9.6v9.6H3.6ZM16.4,5.2V16.4H5.2V18H16.4A1.6,1.6,0,0,0,18,16.4V5.2Zm-5.366.234L7.6,8.869,5.766,7.034,4.634,8.166,7.6,11.131l4.566-4.566Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default CheckAll;
