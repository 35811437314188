import React from 'react';

const WindowsDistribution = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" {...props}>
      <g id="windows" transform="translate(-5 -5)">
        <path
          id="Path_101"
          data-name="Path 101"
          d="M12.506,25.013,5.011,25v6.372l7.494,1.037Z"
          transform="translate(-0.006 -10)"
        />
        <path
          id="Path_102"
          data-name="Path 102"
          d="M22,25.03v7.533l10.5,1.452,0-8.967Z"
          transform="translate(-8.5 -10.015)"
        />
        <path
          id="Path_103"
          data-name="Path 103"
          d="M12.5,8.256,5,9.318l.007,6.31H12.5Z"
          transform="translate(0 -1.628)"
        />
        <path
          id="Path_104"
          data-name="Path 104"
          d="M22,6.487V14H32.5V5Z"
          transform="translate(-8.5)"
        />
      </g>
    </svg>
  );
};

export default WindowsDistribution;
