/* eslint-disable react/prop-types */
import React, { createContext, useState, useCallback, useMemo } from 'react';

const PopupNotificationContext = createContext();

const PopupNotificationState = ({ children }) => {
  const [notificationParams, setNotificationParams] = useState([]);

  const handleNotificationParams = useCallback((params) => {
    if (Array.isArray(params)) {
      setNotificationParams(params);
    }
    if (typeof params === 'object' && params.id) {
      return setNotificationParams([...notificationParams, params]);
    }
    if (params === null) {
      return setNotificationParams([]);
    }
  }, [notificationParams]);

  const value = useMemo(() => ({
    notificationParams,
    setNotificationParams: handleNotificationParams,
  }), [
    handleNotificationParams,
    notificationParams,
  ]);

  return (
    <PopupNotificationContext.Provider value={value}>
      {children}
    </PopupNotificationContext.Provider>
  );
};

export { PopupNotificationContext as default, PopupNotificationState };
