import { makeStyles } from '@material-ui/core';

export default makeStyles({
  iconSvg: {
    fill: ({ color }) => color,
    '&>path': {
      fill: ({ color }) => color,
    },
  },
}, { name: 'StyledSvg' });
