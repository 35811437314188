import React from 'react';

const Pencil = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      id="pencil"
      d="M11.386,2.023l-5.84,5.84-2.579,8.16,8.161-2.579L16.967,7.6,15.925,6.563,11.1,11.393a3.062,3.062,0,0,0-.322-.387,3.689,3.689,0,0,0-1.864-.948,3.567,3.567,0,0,0-.925-1.843h0A3.074,3.074,0,0,0,7.6,7.894l4.829-4.829ZM6.705,9.08a1.674,1.674,0,0,1,.237.177,2.843,2.843,0,0,1,.633,1.5l.076.538.536.086a3.063,3.063,0,0,1,1.545.663,1.68,1.68,0,0,1,.181.236l-3.59,1.133-.751-.751Z"
      transform="translate(-2.967 -2.023)"
    />
  </svg>
);

export default Pencil;
