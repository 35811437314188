export default {
  GET_NOTIFICATIONS_BY_ID: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES: 'GET_MESSAGES',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAIL: 'GET_MESSAGES_FAIL',
  // SWITCH_VIEWED_NOTIFICATIONS: 'SWITCH_VIEWED_NOTIFICATIONS',
  SET_MESSAGE_MARKED: 'SET_MESSAGE_MARKED',
  SET_MESSAGE_MARKED_SUCCESS: 'SET_MESSAGE_MARKED_SUCCESS',
  SET_MESSAGE_MARKED_FAIL: 'SET_MESSAGE_MARKED_FAIL',
  GET_NOTIFICATIONS_BY_FILTERS: 'GET_NOTIFICATIONS_BY_FILTERS',

  SEND_MESSAGE: 'SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAIL: 'SEND_MESSAGE_FAIL',
};
