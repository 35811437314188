import React from 'react';

const EmptyStorage = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="113"
    height="113"
    viewBox="0 0 113 113"
    {...props}
  >
    <g id="empty_storage" transform="translate(-1106 -297)">
      <circle
        id="Ellipse_30"
        data-name="Ellipse 30"
        cx="56.5"
        cy="56.5"
        r="56.5"
        transform="translate(1106 297)"
      />
      <g
        id="Group_760"
        data-name="Group 760"
        transform="translate(128.998 -3.242)"
      >
        <path
          id="Subtraction_15"
          data-name="Subtraction 15"
          d="M24781.518,17433.1h-2.064v-4.584h2.064v-25.225h-11.463v-11.463H24754v9.057h-4.59v-9.057a4.622,4.622,0,0,1,4.59-4.586h18.34l13.76,13.76v27.514a4.618,4.618,0,0,1-4.586,4.584Z"
          transform="translate(-23721.906 -17060)"
          fill="#f8f8f8"
        />
        <path
          id="icons8-opened-folder"
          d="M49.108,9.639H28.171L22.937,4H7.234A5.464,5.464,0,0,0,2,9.639V43.476a5.464,5.464,0,0,0,5.234,5.639H49.108a5.464,5.464,0,0,0,5.234-5.639v-28.2A5.464,5.464,0,0,0,49.108,9.639Zm0,33.837H7.234v-28.2H49.108Z"
          transform="translate(1001.001 336.978)"
          fill="#f8f8f8"
        />
      </g>
      <line
        id="Line_110"
        data-name="Line 110"
        x2="67"
        y2="67"
        transform="translate(1130.5 320.5)"
        fill="none"
        stroke="#ececec"
        strokeWidth="3"
      />
      <line
        id="Line_111"
        data-name="Line 111"
        x2="67"
        y2="67"
        transform="translate(1132.5 318.5)"
        fill="none"
        stroke="#f8f8f8"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default EmptyStorage;
