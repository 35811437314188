import React from 'react';

const Settings = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.774"
      height="13"
      viewBox="0 0 12.774 13"
      {...props}
    >
      <path
        id="icons8-settings"
        d="M7.045,2l-.32,1.64a5.189,5.189,0,0,0-1.46.839L3.69,3.936,2.174,6.564l1.261,1.1a4.833,4.833,0,0,0,0,1.681l-1.261,1.1L3.69,13.064l1.575-.543a5.189,5.189,0,0,0,1.46.839L7.045,15h3.032l.32-1.64a5.188,5.188,0,0,0,1.46-.839l1.575.543,1.516-2.628-1.261-1.1a4.832,4.832,0,0,0,0-1.681l1.261-1.1L13.432,3.936l-1.575.543A5.188,5.188,0,0,0,10.4,3.64L10.077,2ZM8.561,5.9a2.6,2.6,0,1,1-2.6,2.6A2.6,2.6,0,0,1,8.561,5.9Z"
        transform="translate(-2.174 -2)"
      />
    </svg>
  );
};

export default Settings;
