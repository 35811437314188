import React from 'react';

const Report = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      id="report1"
      // fill="#8E8B92"
      d="M7.4 8a.6.6 0 01.6-.6h3a.6.6 0 110 1.2H8a.6.6 0 01-.6-.6zM8 10.4a.6.6 0 100 1.2h8a.6.6 0 100-1.2H8zM7.4 14a.6.6 0 01.6-.6h8a.6.6 0 110 1.2H8a.6.6 0 01-.6-.6zM8 16.4a.6.6 0 100 1.2h8a.6.6 0 100-1.2H8z"
    />
    <path
      id="report2"
      // fill="#8E8B92"
      fillRule="evenodd"
      d="M6 22h12a2 2 0 002-2V8.828a2 2 0 00-.586-1.414l-4.828-4.828A2 2 0 0013.172 2H6a2 2 0 00-2 2v16a2 2 0 002 2zm-.2-2V4c0-.11.09-.2.2-.2h7.002v5.202H18.2V20a.2.2 0 01-.2.2H6a.2.2 0 01-.2-.2zM16.656 7.202l-1.854-1.854v1.854h1.854z"
      clipRule="evenodd"
    />
  </svg>
);

export default Report;
