import types from '../constants';

const initialState = {
  count: 0,
  isFetching: false,
};

const ACTION_HANDLERS = {
  // pbl
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS]: (state) => {
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      count: payload.count,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER]: (state) => {
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      count: payload.count,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },

  // publiclinks
  [types.REQUEST_GET_PUBLIC_LINKS]: (state) => {
    // console.log('REQUEST_GET_PUBLIC_LINKS');
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_SUCCESS]: (state, { payload }) => {
    // console.log('REQUEST_GET_PUBLIC_LINKS_SUCCESS', payload);
    return {
      ...state,
      count: payload.count,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA]: (state) => {
    // console.log('REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA');
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_SUCCESS]: (state, { payload }) => {
    // console.log('REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_SUCCESS', payload);
    return {
      ...state,
      count: payload.count,
      // count: 150,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },

  // exchange
  [types.REQUEST_GET_LINKS]: (state) => {
    // console.log('REQUEST_GET_LINKS');
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_LINKS_SUCCESS]: (state, { payload }) => {
    // console.log('REQUEST_GET_LINKS_SUCCESS', payload);
    return {
      ...state,
      count: payload.count,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_LINKS_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },

  // inbox
  [types.REQUEST_GET_INBOX_LINKS]: (state) => {
    // console.log('REQUEST_GET_INBOX_LINKS');
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_INBOX_LINKS_SUCCESS]: (state, { payload }) => {
    // console.log('REQUEST_GET_INBOX_LINKS_SUCCESS', payload);
    return {
      ...state,
      count: payload.count,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_INBOX_LINKS_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_INBOX_SUBFOLDER]: (state) => {
    // console.log('REQUEST_GET_INBOX_SUBFOLDER');
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_INBOX_SUBFOLDER_SUCCESS]: (state, { payload }) => {
    // console.log('REQUEST_GET_INBOX_SUBFOLDER_SUCCESS', payload);
    return {
      ...state,
      count: payload.count,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_INBOX_SUBFOLDER_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },

  // outbox
  [types.REQUEST_GET_OUTBOX_LINKS]: (state) => {
    // console.log('REQUEST_GET_OUTBOX_LINKS');
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_OUTBOX_LINKS_SUCCESS]: (state, { payload }) => {
    // console.log('REQUEST_GET_OUTBOX_LINKS_SUCCESS', payload);
    return {
      ...state,
      count: payload.count,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_OUTBOX_LINKS_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_OUTBOX_SUBFOLDER]: (state) => {
    // console.log('REQUEST_GET_OUTBOX_SUBFOLDER');
    return {
      ...state,
      isFetching: true,
    };
  },
  [types.REQUEST_GET_OUTBOX_SUBFOLDER_SUCCESS]: (state, { payload }) => {
    // console.log('REQUEST_GET_OUTBOX_SUBFOLDER_SUCCESS', payload);
    return {
      ...state,
      count: payload.count,
      isFetching: false,
    };
  },
  [types.REQUEST_GET_OUTBOX_LINKS_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false,
    };
  },
};

export const page = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default page;