import React from 'react';

const Shield = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.818"
      height="12"
      viewBox="0 0 9.818 12"
      {...props}
    >
      <path
        id="shield"
        d="M7.909,1,3,3.182V6.455A6.774,6.774,0,0,0,7.909,13a6.774,6.774,0,0,0,4.909-6.545V3.182Zm3.818,5.455a5.761,5.761,0,0,1-3.818,5.42,5.761,5.761,0,0,1-3.818-5.42V3.891l3.818-1.7,3.818,1.7Z"
        transform="translate(-3 -1)"
      />
    </svg>
  );
};

export default Shield;
