import React, { useState } from 'react';
import { getFilterUrl } from 'shared/elements/StyledSvg/helpers'
import { createGuid } from 'helpers/createGuid';

const User = (props) => {
  // Хак для того, чтобы компонент не терял id в Safari в момент ререндера svg
  const [rectId] = useState(createGuid());
  const filter = getFilterUrl(rectId);
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="71"
      height="71"
      viewBox="0 0 71 71"
      {...props}
    >
      <defs>
        <filter
          id={rectId}
          x="0"
          y="0"
          width="71"
          height="71"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood floodOpacity="0.122" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="fm_png" transform="translate(-19.498 6.5)">
        <g
          transform="matrix(1, 0, 0, 1, 19.5, -6.5)"
          filter={filter}
        >
          <rect
            id="Rectangle_270-2"
            dataname="Rectangle 270"
            width="56"
            height="56"
            rx="12"
            transform="translate(7.5 6.5)"
            fill="#e2a763"
          />
        </g>
        <text
          id="PNG"
          transform="translate(55.334 38)"
          fill="#fefefe"
          fontSize="11"
          fontFamily="SegoeUI-Bold, Roboto Bold"
          fontWeight="700"
          letterSpacing="0.033em"
        >
          <tspan x="-11.997" y="0">
            {props.extension.toUpperCase()}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default User;
