import * as authTypes from 'store/reducers/auth/types';
import flashTypes from 'store/reducers/flashes/constants';
import shareTypes from 'store/reducers/share/constants';
import * as filesTypes from 'store/reducers/files/constants';
import * as eventsTypes from 'store/reducers/events/types';
import directoriesTypes from 'store/reducers/directories/constants';
import { getTranslatedData } from 'shared/helpers/reduxStateTranslate';
import {
  getReducedCloudData,
  getReducedProfileData,
  getReducedFolderData,
  getReducedFileData,
  getReducedFilesData,
  getFolderData,
} from 'store/reducers/flashes/helpers';
import { getLabelWithLang } from 'helpers/getLabelWithLang';

import initialState from './initialState';

// TODO: rewrite all data spreading, if possible:
// нужно упростить по максимуму, перебор по итерациям
const ACTION_HANDLERS = {
  GET_DICT: state => {
    // TODO: Переписать на чистую функцию
    const translatedData = getTranslatedData(initialState.data, {
      ...state.data,
    });
    return {
      ...state,
      data: translatedData,
    };
  },
  [flashTypes.REQUEST_FLASH_LIST]: state => {
    const { isFirstTimeLoad } = state;
    return {
      ...state,
      isFetchingList: true,
      isLoaded: !isFirstTimeLoad,
    };
  },
  [flashTypes.REQUEST_FLASH_LIST_SUCCESS]: (state, { payload }) => {
    const data = payload;

    if (data.error) {
      return {
        ...state,
        isFetchingList: false,
        isFirstTimeLoad: false,
        isLoaded: true,
      };
    }

    const newData = {
      ...getReducedCloudData([...data.own]),
      ...getReducedProfileData(data.sharedToMe || {}),
    };

    const resultData = Object.entries(newData).reduce(
      (prev, [id, item]) => {
        prev[id] = {
          ...(prev[id] || {}),
          ...item,
        };

        return prev;
      },
      { ...state.data },
    );

    return {
      ...state,
      data: resultData,
      isFetchingList: false,
      isFirstTimeLoad: false,
      isLoaded: true,
      errorMessage: '',
    };
  },
  [flashTypes.REQUEST_FLASH_LIST_FAIL]: state => ({
    ...state,
    isFetchingList: false,
    errorMessage: getLabelWithLang(
      'request_flash_failed',
      'Запрос списка накопителей завершился с ошибкой!',
    ),
  }),
  [flashTypes.REQUEST_FLASH_CREATE_SUCCESS]: (state, { payload, params }) => {
    const { uuid } = payload;
    const { favorite, archive, trash } = params;

    if (payload.error || !uuid) {
      return state;
    }

    const flashData = params;
    flashData.code = uuid;
    flashData.root = uuid;
    flashData.subject = 'flash';
    flashData.properties = { favorite, archive, trash };
    flashData.permitions = {
      share: true,
      write: true,
      reshare: true,
    };
    flashData.my_flash = true;

    const reducedFlashData = getReducedCloudData([flashData]);

    const newData = {
      ...state.data,
      ...reducedFlashData,
    };

    return {
      ...state,
      data: newData,
    };
  },

  [flashTypes.REQUEST_FLASH_EDIT_SUCCESS]: (state, { payload, params }) => {
    const { flash } = payload;

    if (payload.error || !(flash && flash.code)) {
      return state;
    }

    const newData = {
      ...state.data,
    };

    newData[flash.code] = {
      ...newData[flash.code],
      ...flash,
    };

    return {
      ...state,
      data: newData,
    };
  },

  [flashTypes.FLASH_DELETE_REQUEST_SUCCESS]: (state, { payload }) => {
    const { uuid } = payload;

    if (payload.error || !uuid) {
      return state;
    }

    const newData = {
      ...state.data,
    };

    delete newData[uuid];

    return {
      ...state,
      data: newData,
    };
  },

  [flashTypes.REQUEST_FLASH_DIRECTION_LIST]: state => {
    return { ...state, isFetchingDirectionList: true };
  },
  [flashTypes.REQUEST_FLASH_DIRECTION_LIST_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;
    if (data.error) {
      return {
        ...state,
        isFetchingDirectionList: false,
      };
    }

    const { folders, files, count, sorting, breadcrumps } = data;

    return {
      ...state,
      data: Object.entries({
        ...getReducedFolderData(folders, params.uuid, state.data),
        ...getReducedFileData(files, params.uuid),
      }).reduce(
        (prev, [id, item]) => {
          prev[id] = item;
          return prev;
        },
        {
          ...state.data,
          [params.uuid]: {
            ...state.data[params.uuid],
            count,
            sorting,
            breadcrumps: breadcrumps.map(i => ({
              ...i,
              id: i.code,
              type: i.subject,
            })),
          },
        },
      ),
      isFetchingDirectionList: false,
      errorMessage: '',
    };
  },
  [flashTypes.REQUEST_FLASH_DIRECTION_LIST_FAIL]: state => {
    return {
      ...state,
      isFetchingDirectionList: false,
      errorMessage: getLabelWithLang(
        'request_file_folder_failed',
        'Запрос списка файлов и папок завершился с ошибкой!',
      ),
    };
  },
  [flashTypes.REQUEST_FLASH_SUBDIRECTION_LIST]: state => {
    return { ...state, isFetchingSubdirectionList: true };
  },
  [flashTypes.REQUEST_FLASH_SUBDIRECTION_LIST_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;

    if (data.error) {
      return {
        ...state,
        isFetchingSubdirectionList: false,
      };
    }

    const { folders, files, count, sorting, info, breadcrumps } = data;

    const folderInfoData = getFolderData(
      info,
      info.parent_folder_uuid,
      state.data,
    );

    const folderInfo = {
      [params.subUuid]: {
        ...folderInfoData,
        ...state.data[params.subUuid],
        count,
        sorting,
        breadcrumps: breadcrumps.map(i => ({
          ...i,
          id: i.code,
          type: i.subject,
        })),
      },
    };

    if (!folders.length && !files.length) {
      return {
        ...state,
        data: {
          ...state.data,
          ...folderInfo,
        },
        isFetchingSubdirectionList: false,
        errorMessage: '',
      };
    }

    const folderData = getReducedFolderData(
      folders,
      params.subUuid,
      state.data,
    );

    const fileData = getReducedFileData(files, params.subUuid);

    return {
      ...state,
      data: {
        ...state.data,
        ...folderData,
        ...fileData,
        ...folderInfo,
      },
      isFetchingSubdirectionList: false,
      errorMessage: '',
    };
  },
  [flashTypes.REQUEST_FLASH_SUBDIRECTION_LIST_FAIL]: state => {
    return {
      ...state,
      isFetchingSubdirectionList: false,
      errorMessage: getLabelWithLang(
        'request_file_folder_failed',
        'Запрос списка файлов и папок завершился с ошибкой!',
      ),
    };
  },

  [flashTypes.REQUEST_TRASH_LIST]: state => {
    return { ...state, isFetchingBasket: true };
  },
  [flashTypes.REQUEST_TRASH_LIST_SUCCESS]: (state, { payload }) => {
    const data = payload;

    if (data.error) {
      return {
        ...state,
        isFetchingBasket: false,
      };
    }

    const { folders, files, count, sorting } = data;

    return {
      ...state,
      data: Object.entries({
        ...getReducedFolderData(folders, 'basket', state.data),
        ...getReducedFileData(files, 'basket'),
      }).reduce(
        (prev, [id, item]) => {
          prev[id] = item;
          return prev;
        },
        {
          ...state.data,
          basket: {
            ...state.data.basket,
            count,
            sorting,
          },
        },
      ),
      isFetchingBasket: false,
      errorMessage: '',
    };
  },
  [flashTypes.REQUEST_TRASH_LIST_FAIL]: state => {
    return {
      ...state,
      isFetchingBasket: false,
      errorMessage: getLabelWithLang(
        'request_trash_failed',
        'Запрос списка файлов и папок корзины завершился с ошибкой!',
      ),
    };
  },
  [flashTypes.REQUEST_FLASH_DELETE]: state => {
    return { ...state, isFetchingDeleteFlash: true };
  },
  [flashTypes.REQUEST_SET_FLASH_VERSIONED_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    if (!payload.success) {
      return state;
    }

    const { property, uuid, value } = params;

    const newData = {
      ...state.data,
      [uuid]: {
        ...state.data[uuid],
        [property]: value,
      },
    };

    return { ...state, data: newData };
  },
  [flashTypes.RESTORE_VERSION_SUCCESS]: state => {
    return { ...state };
  },
  [flashTypes.DELETE_VERSION_SUCCESS]: state => {
    return { ...state };
  },
  [flashTypes.REQUEST_FLASH_DELETE_SUCCESS]: (state, { payload, params }) => {
    const data = payload;

    if ((data && data.error) || !params.uuid) {
      return state;
    }

    const newData = state.data;
    delete newData[params.uuid];

    return {
      ...state,
      data: newData,
      isFetchingDeleteFlash: false,
      errorMessage: '',
    };
  },
  [flashTypes.REQUEST_FLASH_FAIL]: state => {
    return {
      ...state,
      isFetchingDeleteFlash: false,
      errorMessage: getLabelWithLang(
        '',
        'Запрос удаления флешки завершился с ошибкой!',
      ),
    };
  },
  [flashTypes.RENAME_ITEM]: (state, { params }) => {
    const { itemId, name } = params;
    const newData = {
      ...state.data,
    };

    newData[itemId] = {
      ...newData[itemId],
      name,
    };

    return {
      ...state,
      data: newData,
    };
  },
  [shareTypes.REQUEST_GET_INBOX_LINKS_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const newData = state.data;

    if (!payload.list.length) {
      return state;
    }

    const { breadcrumps = [] } = payload;

    payload.list.forEach(item => {
      item.id = item.code;
      item.type = item.file ? 'file' : 'folder';
      // item.isLink = true;
      item.parentId = 'received';
      item.breadcrumps = breadcrumps;
    });

    return {
      ...state,
      data: { ...newData },
    };
  },
  [shareTypes.REQUEST_GET_OUTBOX_LINKS_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const newData = state.data;

    if (!payload.list.length) {
      return state;
    }

    const { breadcrumps = [] } = payload;

    payload.list.forEach(item => {
      item.id = item.code;
      item.type = item.file ? 'file' : 'folder';
      item.parentId = 'sent';
      item.breadcrumps = breadcrumps;
    });

    return {
      ...state,
      data: { ...newData },
    };
  },
  [shareTypes.REQUEST_GET_PUBLIC_LINKS_SUCCESS]: (state, { payload }) => {
    const newData = state.data;

    if (!payload.list.length) {
      return state;
    }

    const { breadcrumps = [] } = payload;

    payload.list.forEach(item => {
      item.id = item.code;
      item.type = item.subject || 'send';
      item.size = item.size || getLabelWithLang('no_data', 'Н/д');
      item.sent = item.sent || item.created;
      item.expires = item.expires || getLabelWithLang('no_data', 'Н/д');
      item.isLink = true;
      item.parentId = 'publiclink';
      item.breadcrumps = breadcrumps;
    });
    return { ...state, data: { ...newData } };
  },
  [shareTypes.REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;

    if (data.error) {
      return {
        ...state,
      };
    }
    const { list, breadcrumps = [] } = data;

    const lastBreadcrumps = { ...breadcrumps[breadcrumps.length - 1] };

    const resultObj = [...list, lastBreadcrumps].reduce((prev, item) => {
      item.id = item.code;
      item.type = item.subject || item.file ? 'file' : 'folder';
      item.parentId = 'publiclink';
      item.breadcrumps = breadcrumps;
      return {
        ...prev,
        [item.code]: item,
      };
    }, {});
    return {
      ...state,
      data: {
        ...state.data,
        ...resultObj,
        folderControls: data.actions,
      },
    };
  },

  [shareTypes.REQUEST_GET_OUTBOX_SUBFOLDER_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;
    if (data.error) {
      return {
        ...state,
      };
    }
    const { list, breadcrumps = [] } = data;

    const lastBreadcrumps = { ...breadcrumps[breadcrumps.length - 1] };

    const resultObj = [...list, lastBreadcrumps].reduce((prev, item) => {
      item.id = item.code;
      item.type = item.subject || item.file ? 'file' : 'folder';
      item.breadcrumps = breadcrumps;
      return {
        ...prev,
        [item.code]: item,
      };
    }, {});

    return {
      ...state,
      data: {
        ...state.data,
        ...resultObj,
        folderControls: data.actions,
      },
    };
  },
  [shareTypes.REQUEST_GET_INBOX_SUBFOLDER_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;
    if (data.error) {
      return state;
    }
    const { list, breadcrumps = [] } = data;
    const lastBreadcrumps = { ...breadcrumps[breadcrumps.length - 1] };
    const resultObj = [...list, lastBreadcrumps].reduce((prev, item) => {
      const newItem = { ...item };
      newItem.id = item.code;
      newItem.type = item.subject || item.file ? 'file' : 'folder';
      newItem.breadcrumps = breadcrumps;
      return {
        ...prev,
        [item.code]: newItem,
      };
    }, {});

    return {
      ...state,
      data: {
        ...state.data,
        ...resultObj,
        folderControls: data.actions,
      },
    };
  },
  [shareTypes.REQUEST_GET_LINKS_SUBFOLDER_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;
    if (data.error) {
      return state;
    }
    const { list, breadcrumps = [] } = data;
    const lastBreadcrumps = { ...breadcrumps[breadcrumps.length - 1] };

    const resultObj = [...list, lastBreadcrumps].reduce((prev, item) => {
      item.id = item.code;
      item.redirectCode = item.code;
      item.type = item.subject || item.file ? 'file' : 'folder';
      item.breadcrumps = breadcrumps;
      return {
        ...prev,
        [item.code]: item,
      };
    }, {});

    return {
      ...state,
      data: {
        ...state.data,
        ...resultObj,
        folderControls: data.actions,
      },
    };
  },

  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
  [flashTypes.SEND_LINK_MODERATION_SUCCESS]: (state, { payload, params }) => {
    const { items } = params;
    if (payload.success === true) {
      const newData = { ...state.data };

      items.forEach(id => {
        if (newData[id]) {
          newData[id].link_allowed = 2;
        }
      });

      return {
        ...state,
        data: newData,
      };
    }
    return state;
  },

  [filesTypes.FILES_UPLOAD_SUCCESS]: (
    state,
    { payload, folderId, flashId, parentType },
  ) => {
    const data = payload;

    if (data.error) {
      return state;
    }

    const parentId = parentType === 'folder' ? folderId : flashId;

    const { folders, files } = data.reduce(
      (currentChildren, child) => {
        if (child.subject === 'folder') {
          currentChildren.folders.push(child);

          return currentChildren;
        }

        currentChildren.files.push(child);

        return currentChildren;
      },
      {
        folders: [],
        files: [],
      },
    );
    return {
      ...state,
      data: Object.entries({
        ...getReducedFolderData(folders, parentId),
        ...getReducedFileData(files, parentId),
      }).reduce((prev, [id, item]) => {
        prev[id] = item;

        return prev;
      }, state.data),
    };
  },
  [flashTypes.UPDATE_FLASH_PROPERTY_SUCCESS]: (state, { payload, params }) => {
    const data = payload;

    if ((data && data.error) || !params.uuid) {
      return state;
    }

    const newData = state.data;

    if (newData[params.uuid]) {
      newData[params.uuid].properties.favorite = params.value;
    }

    return {
      ...state,
      data: newData,
    };
  },

  [flashTypes.COPY_FLASH_SUCCESS]: (state, { payload }) => {
    if (!payload.success || payload.error) {
      return state;
    }

    const newData = {
      ...state.data,
      [payload.flash.code]: payload.flash && {
        ...payload.flash,
        id: payload.flash.code,
        uuid: payload.flash.code,
        parentId: 'storage',
        icon: 'cloud',
        type: 'cloud',
      },
    };

    return {
      ...state,
      data: newData,
    };
  },
  [eventsTypes.POOL_DATA_SUCCESS]: (state, { payload }) => {
    const { files, folder: folders, drives } = payload;

    if (
      (!files || files.length === 0) &&
      (!folders || folders.length === 0) &&
      (!drives || drives.length === 0)
    ) {
      return state;
    }

    const nextDrives = drives.reduce((currentNextDrives, flash) => {
      const { code, owner, my_flash: myFlash } = flash;
      const {
        id: ownerId,
        fio: name,
        write_access: writeAccess,
        ...ownerRestProps
      } = owner;

      const nextProfile = {
        id: ownerId,
        name,
        writeAccess,
        ...ownerRestProps,
        type: 'profile',
        icon: 'profile',
        parentId: 'storage',
      };

      const nextFlashParentId = myFlash ? 'storage' : ownerId;

      const nextFlash = {
        id: code,
        ...flash,
        type: 'cloud',
        icon: 'cloud',
        parentId: nextFlashParentId,
      };

      currentNextDrives[ownerId] = nextProfile;
      currentNextDrives[code] = nextFlash;
      return currentNextDrives;
    }, {});

    const nextFolders = folders.reduce((currentNextFolders, folder) => {
      const {
        parent_folder_uuid: parentFolderId,
        flash_uuid: flashId,
        code,
        in_root: inRoot,
      } = folder;

      const parentId = inRoot ? flashId : parentFolderId;

      currentNextFolders[code] = {
        id: code,
        ...folder,
        type: 'folder',
        icon: 'folder',
        parentId,
      };

      return currentNextFolders;
    }, {});

    const nextData = Object.entries({
      ...nextDrives,
      ...nextFolders,
      ...getReducedFilesData(files),
    }).reduce(
      (prev, [id, item]) => {
        prev[id] = item;

        return prev;
      },
      { ...state.data },
    );

    return {
      ...state,
      data: nextData,
    };
  },

  [directoriesTypes.REQUEST_DIRECTORY_CREATE_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    if (!payload.success || payload.error) {
      return state;
    }

    const { isFlash, flash, folder, name, type } = params;
    const uuid = payload.success;

    const info = payload.object;

    const folderData = { ...info, code: uuid, name };
    const parentId = isFlash ? flash : folder;

    const getReducedData =
      type === 'folder' ? getReducedFolderData : getReducedFileData;

    const reducedFolderData = getReducedData([folderData], parentId);

    const newData = {
      ...state.data,
      ...reducedFolderData,
    };

    return {
      ...state,
      data: newData,
    };
  },
};

export const byId = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byId;
