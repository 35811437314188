import { createRequestAction } from 'store/utils/createRequestAction';
import { apiService, unauthApiService } from 'services';

import * as previewTypes from './types';

export const openPreview = files => ({
  type: previewTypes.OPEN_PREVIEW,
  files,
});

export const setParentFolderType = parentFolderType => ({
  type: previewTypes.SET_FOLDER_TYPE,
  parentFolderType,
});

export const closePreview = () => ({
  type: previewTypes.CLOSE_PREVIEW,
});

export const preparePreviewFiles = createRequestAction(params => ({
  types: [
    previewTypes.PREPARE_PREVIEW_FILES,
    previewTypes.PREPARE_PREVIEW_FILES_SUCCESS,
    previewTypes.PREPARE_PREVIEW_FILES_FAIL,
  ],
  apiRequest: () => apiService.files.downloadPrepare(params),
  data: params.group,
}));

export const unauthPreparePreviewFiles = createRequestAction(params => ({
  types: [
    previewTypes.PREPARE_PREVIEW_FILES,
    previewTypes.PREPARE_PREVIEW_FILES_SUCCESS,
    previewTypes.PREPARE_PREVIEW_FILES_FAIL,
  ],
  apiRequest: () => unauthApiService.files.downloadUnauthPrepare(params),
  data: params.group,
}));

export const downloadPreviewFile = createRequestAction((params) => {
  const { fileId, ...requestParams } = params;

  return {
    types: [
      previewTypes.DOWNLOAD_PREVIEW_FILES,
      previewTypes.DOWNLOAD_PREVIEW_FILES_SUCCESS,
      previewTypes.DOWNLOAD_PREVIEW_FILES_FAIL,
    ],
    apiRequest: () => apiService.files.previewDownload(requestParams),
    data: params,
  };
});

export const unauthDownloadPreviewFile = createRequestAction((params) => {
  const { fileId, ...requestParams } = params;
  return {
    types: [
      previewTypes.UNAUTH_DOWNLOAD_PREVIEW_FILES,
      previewTypes.UNAUTH_DOWNLOAD_PREVIEW_FILES_SUCCESS,
      previewTypes.UNAUTH_DOWNLOAD_PREVIEW_FILES_FAIL,
    ],
    apiRequest: () => unauthApiService.files.previewDownload(requestParams),
    data: params,
  };
});

export const selectPreviewFile = selectedFileId => ({
  type: previewTypes.SELECT_PREVIEW_FILE,
  selectedFileId,
});

export const resetPreparedFiles = () => ({
  type: previewTypes.RESET_PREPARED_FILES,
});

export const downloadPreviewVideoFile = fileData => ({
  type: previewTypes.DOWNLOAD_PREVIEW_FILES_SUCCESS,
  payload: {},
  data: { ...fileData },
});
