import constants from './constants';

const initialState = {
  token: {
    data: {},
    isFetching: false,
    isError: false,
  },
};

const ACTION_HANDLERS = {
  [constants.COLLABORA_INIT]: (state, { payload }) => {
    return {
      ...state,
      init: payload
    };
  },
  [constants.WEB_OFFICE_ACCESS_TOKEN]: (state, { payload }) => {
    return {
      ...state,
      token: {
        ...state.token,
        isFetching: true,
        isError: false,
      }
    };
  },
  [constants.WEB_OFFICE_ACCESS_TOKEN_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      token: {
        ...state.token,
        data: payload,
        isFetching: false,
      }
    };
  },
  [constants.WEB_OFFICE_ACCESS_TOKEN_FAIL]: (state, { payload }) => {
    return {
      ...state,
      token: {
        ...state.token,
        isFetching: false,
        isError: true,
      }
    };
  },
};

export const collabora = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};