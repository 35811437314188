import React from 'react';

const Devices = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 112 84"
      {...props}
    >
      <path
        id="devices"
        d="M18.667,4a9.4,9.4,0,0,0-9.333,9.333V60a9.4,9.4,0,0,0,9.333,9.333H0v9.333H60.667V60h-42V13.333h84A9.4,9.4,0,0,0,93.333,4ZM79.333,22.667A9.351,9.351,0,0,0,70,32V78.667A9.351,9.351,0,0,0,79.333,88h23.333A9.351,9.351,0,0,0,112,78.667V32a9.351,9.351,0,0,0-9.333-9.333Zm0,9.333h23.333V74H79.333Zm0,0"
        transform="translate(0 -4)"
        opacity="1"
      />
    </svg>
  );
};

export default Devices;
