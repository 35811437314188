import React from 'react';

const PreviewClose = props => {
  return (
    <svg
      id="close"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <rect
        id="Rectangle_174"
        data-name="Rectangle 174"
        width="16"
        height="16"
        rx="2"
        fill="#8e8b92"
      />
      <path
        id="Path_396"
        data-name="Path 396"
        d="M12.42,5.747,11.673,5,8.71,7.963,5.747,5,5,5.747,7.963,8.71,5,11.673l.747.747L8.71,9.457l2.963,2.963.747-.747L9.457,8.71Z"
        transform="translate(-0.71 -0.71)"
        fill="#fff"
      />
    </svg>
  );
};

export default PreviewClose;
