const treeRoute = {
  getTreeFoldersList: (params) => {
    const { uuid } = params;
    return {
      url: `/folder/${uuid}`,
      method: 'GET',
      params,
    };
  },
};

export default treeRoute;
