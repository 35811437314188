// import clientNotificationsStore from 'utils/clientNotifications';
import i18next from 'helpers/i18n';
import { createGuid } from 'helpers/createGuid';
import { REQUEST_ERROR } from 'services/apiService/notificationTypes';
// import { pushNotification } from 'utils/clientNotifications/reducers/clientNotifications/actions';
// import { emitLogoutOnError } from 'utils/clientNotifications/reducers/auth/actions';
import { getAcceptLanguageHeader } from 'helpers/language';
import apiClient from 'utils/axiosInstance';
import {
  setItemInLocalStorage,
  removeItemFromLocalStorage,
  AUTH_TOKEN,
  REFRESH_TOKEN,
} from 'helpers/localStorage';
import store from 'shared/store';
import { emitLogoutOnError } from 'shared/store/reducers/logout/actions';
import { pushNotification } from 'store/reducers/clientNotifications/actions';

const AUTHORIZATION = 'Authorization';
const XMF_DESTINATION_HOST = 'XMF-Destination-Host';

/**
 * @description отправка уведомления
 */
const dispatchAction = (({ dispatch }) => action => dispatch(action))(store);

export const setXmfDestinationHostHeader = (value = '') => {
  apiClient.defaults.headers.common[XMF_DESTINATION_HOST] = value;
};

export const clearXmfDestinationHostHeader = () => {
  delete apiClient.defaults.headers.common[XMF_DESTINATION_HOST];
};

export const setApiClientAuthorizationToken = (token = '') => {
  apiClient.defaults.headers.common[AUTHORIZATION] = `Bearer ${token}`;
};

export const setAuthorizationToken = (token) => {
  setItemInLocalStorage(AUTH_TOKEN, token.access_token);
  setItemInLocalStorage(REFRESH_TOKEN, token.refresh_token);
  document.cookie = `${encodeURIComponent('PHPSESSID')}=${encodeURIComponent(token.access_token)}`;
  setApiClientAuthorizationToken(token.access_token);
};

export const clearAuthorizationToken = () => {
  removeItemFromLocalStorage(AUTH_TOKEN);
  removeItemFromLocalStorage(REFRESH_TOKEN);
  document.cookie = `${encodeURIComponent('PHPSESSID')}=${encodeURIComponent('')}; max-age=-1`;
  setApiClientAuthorizationToken('');
};

const getNotification = (type, data, requestData) => {
  const notification = requestData
    ? {
      id: createGuid(),
      type,
      data,
      requestData,
    }
    : {
      id: createGuid(),
      type,
      data,
    };
  return pushNotification(notification);
};

const initRequest = (params) => {
  const {
    initializer,
    apiClient,
    shouldLogout,
  } = params;

  return (args) => {
    const { notifications = {}, ...requestConfig } = initializer(args);
    const { method, data, params } = requestConfig;
    const requestStartData = method === 'GET' ? params || {} : data || {};

    const {
      request: REQUEST_NOTIFICATION,
      success: SUCCESS_NOTIFICATION,
      fail: FAIL_NOTIFICATION,
      omitErrorNotification,
    } = notifications;

    if (REQUEST_NOTIFICATION) {
      dispatchAction(getNotification(REQUEST_NOTIFICATION, requestStartData));
    }

    const requestPromise = apiClient(requestConfig);

    requestPromise
      .then((response) => {
        const { data } = response;

        if (data.error) {
          if (omitErrorNotification) {
            return;
          }
          return dispatchAction(getNotification(REQUEST_ERROR, data, requestStartData));
        }

        if (SUCCESS_NOTIFICATION) {
          return dispatchAction(getNotification(SUCCESS_NOTIFICATION, data, requestStartData));
        }
      })
      .catch((error) => {
        if (FAIL_NOTIFICATION) {
          dispatchAction(getNotification(FAIL_NOTIFICATION, error, requestStartData));
        }

        if (error.response) {
          const { data, status, headers } = error.response;
          // eslint-disable-next-line no-console
          console.error(`The request was made and the server responded with a status code ${status}`, { data, headers });
          if (status === 401 && shouldLogout) {
            dispatchAction(emitLogoutOnError());
          }
        } else if (error.request) {
          // eslint-disable-next-line no-console
          console.error('The request was made but no response was received');
        } else {
          // eslint-disable-next-line no-console
          console.error('Error', error.message);
        }
      });

    return requestPromise;
  };
};

const initRouteRequests = (params) => {
  const {
    initializers,
    apiClient,
    shouldLogout,
  } = params;

  const result = Object
    .entries(initializers)
    .reduce((currentIntializers, initializerEntry) => {
      const [initializerKey, initializer] = initializerEntry;

      // eslint-disable-next-line no-param-reassign
      currentIntializers[initializerKey] = initRequest({
        initializer,
        apiClient,
        shouldLogout,
      });
      return currentIntializers;
    }, {});
  // console.log('initRouteRequests', result);
  return result;
};

/**
 * @param {Object} options - опции для создания сервиса работы с API-клиентом
 *
 * свойства у options:
 * @property {Array} routes -  вызова API-клиента с необходимыми аргументами
 * @property apiClient - используемый API-клиент (axios instance)
 * @property shouldLogout - флаг, указывающий, нужно ли разлогиниваться при статусе 401
 */
export const createService = (params) => {
  const {
    routes,
    apiClient,
    shouldLogout = false,
  } = params;

  const service = Object
    .entries(routes)
    .reduce((currentRoutes, routeEntry) => {
      const [routeEntryKey, routeInitializers] = routeEntry;

      // eslint-disable-next-line no-param-reassign
      currentRoutes[routeEntryKey] = initRouteRequests({
        initializers: routeInitializers,
        apiClient,
        shouldLogout,
      });

      return currentRoutes;
    }, {});
  return service;
};

/**
 * Добавить заголовок "Accept-Language"
 * @param request
 */
export const addAcceptLanguageHeader = (request) => {
  if (i18next && i18next.language) {
    const lngHeaders = {
      'Accept-Language': getAcceptLanguageHeader(i18next.language),
    };
    request.headers = {
      ...request.headers,
      ...lngHeaders,
    };
  }
  return request;
};
