import React from 'react';

const Tap = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g id="tap" fill="#fff" stroke="#d0d0d0" strokeWidth="2">
        <circle cx="10" cy="10" r="10" stroke="none" />
        <circle cx="10" cy="10" r="9" fill="none" />
      </g>
    </svg>
  );
};

export default Tap;
