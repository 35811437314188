import * as authTypes from 'store/reducers/auth/types';
import * as eventsTypes from 'store/reducers/events/types';
import flashTypes from 'store/reducers/flashes/constants';
import treeTypes from 'store/reducers/tree/constants';
import directoriesTypes from 'store/reducers/directories/constants';
import { getTranslatedData } from 'shared/helpers/reduxStateTranslate';

import {
  getReducedCloudData,
  getReducedProfileData,
  getReducedFolderData,
  getChildrenToArray,
} from 'store/reducers/tree/helpers';
import initialState from './initialState';

const ACTION_HANDLERS = {
  GET_DICT: state => {
    // TODO: Переписать на чистую функцию
    const translatedData = getTranslatedData(initialState, {
      ...state,
    });
    return {
      ...state,
      ...translatedData,
    };
  },
  [flashTypes.REQUEST_FLASH_LIST_SUCCESS]: (state, { payload }) => {
    const data = payload;

    if (data.error) {
      return state;
    }

    const { own, sharedToMe } = data;

    const cloudData = getReducedCloudData(own || [], {});
    const newData = getReducedProfileData(sharedToMe || {}, cloudData);

    return Object.entries(newData).reduce((prev, [id, item]) => {
      prev[id] = item;
      return prev;
    }, state);
  },

  [treeTypes.REQUEST_TREE_FOLDERS_LIST_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;

    if (
      data.error ||
      !data[0] ||
      !data[0].children ||
      !data[0].children.length
    ) {
      return state;
    }

    const newChildren = getChildrenToArray(data[0].children || [], params.uuid);

    return Object.entries(
      getReducedFolderData(newChildren, params.uuid),
    ).reduce((prev, [id, item]) => {
      prev[id] = item;
      return prev;
    }, state);
  },

  [flashTypes.COPY_FLASH_SUCCESS]: (state, { payload }) => {
    if (!payload.success || payload.error) {
      return state;
    }

    return {
      ...state,
      [payload.flash.code]: payload.flash && {
        ...payload.flash,
        id: payload.flash.code,
        uuid: payload.flash.code,
        parentId: 'storage',
        icon: 'cloud',
        type: 'cloud',
      },
    };
  },

  [flashTypes.COPY_FLASH_FAIL]: state => {
    return { ...state, isFetching: false, isError: true };
  },

  [flashTypes.UPDATE_FLASH_PROPERTY_SUCCESS]: (state, { payload, params }) => {
    const data = payload;

    if ((data && data.error) || !params.uuid) {
      return state;
    }

    const newData = {
      ...state,
    };

    newData[params.uuid].favorite = params.value;

    return {
      ...state,
      data: newData,
    };
  },

  [flashTypes.REQUEST_FLASH_CREATE_SUCCESS]: (state, { payload, params }) => {
    const { uuid } = payload;
    const { favorite, archive, trash } = params;

    if (payload.error || !uuid) {
      return state;
    }

    const flashData = params;
    flashData.code = uuid;
    flashData.root = uuid;
    flashData.properties = { favorite, archive, trash };

    const reducedFlashData = getReducedCloudData([flashData]);

    const newState = {
      ...state,
      ...reducedFlashData,
    };

    return newState;
  },

  [flashTypes.FLASH_DELETE_REQUEST_SUCCESS]: (state, { payload }) => {
    const { uuid } = payload;

    if (payload.error || !uuid) {
      return state;
    }

    const newState = {
      ...state,
    };

    delete newState[uuid];

    return newState;
  },

  [directoriesTypes.REQUEST_DIRECTORY_CREATE_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    if (!payload.success || payload.error) {
      return state;
    }

    const { isFlash, flash, folder, name, type } = params;

    if (type !== 'folder') {
      return state;
    }

    const uuid = payload.success;

    const info = payload.object;

    const folderData = { ...info, code: uuid, name };
    const parentId = isFlash ? flash : folder;

    const reducedFolderData = getReducedFolderData([folderData], parentId);

    return {
      ...state,
      ...reducedFolderData,
    };
  },
  [eventsTypes.POOL_DATA_SUCCESS]: (state, { payload }) => {
    const { folder: folders, drives } = payload;

    if (
      (!folders || folders.length === 0) &&
      (!drives || drives.length === 0)
    ) {
      return state;
    }

    const nextDrives = drives.reduce((currentNextDrives, flash) => {
      const { code, owner, my_flash: myFlash } = flash;
      const {
        id: ownerId,
        fio: name,
        write_access: writeAccess,
        ...ownerRestProps
      } = owner;

      const nextProfile = {
        id: ownerId,
        name,
        writeAccess,
        ...ownerRestProps,
        type: 'profile',
        icon: 'profile',
        parentId: 'storage',
      };

      const nextFlashParentId = myFlash ? 'storage' : ownerId;
      const nextFlash = {
        id: code,
        ...flash,
        type: 'cloud',
        icon: 'cloud',
        parentId: nextFlashParentId,
      };

      currentNextDrives[ownerId] = nextProfile;
      currentNextDrives[code] = nextFlash;

      return currentNextDrives;
    }, {});

    const nextFolders = folders.reduce((currentNextFolders, folder) => {
      const {
        code,
        parent_folder_uuid: parentFolderId,
        flash_uuid: flashId,
        in_root: inRoot,
      } = folder;
      const parentId = inRoot ? flashId : parentFolderId;

      const nextFolder = {
        id: code,
        ...folder,
        type: 'folder',
        icon: 'folder',
        parentId,
      };

      currentNextFolders[code] = nextFolder;

      return currentNextFolders;
    }, {});

    const nextState = Object.entries({
      ...nextDrives,
      ...nextFolders,
    }).reduce(
      (prev, [id, item]) => {
        prev[id] = item;

        return prev;
      },
      { ...state },
    );

    return nextState;
  },

  [directoriesTypes.REQUEST_DIRECTORY_CREATE_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    if (!payload.success || payload.error) {
      return state;
    }

    const { isFlash, flash, folder, name } = params;
    const uuid = payload.success;

    const info = payload.object;

    const folderData = { ...info, code: uuid, name };
    const parentId = isFlash ? flash : folder;

    const reducedFolderData = getReducedFolderData([folderData], parentId);

    const newData = {
      ...state.data,
      ...reducedFolderData,
    };

    return {
      ...state,
      data: newData,
    };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

export const byId = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byId;
