import React from 'react';

const VersionsControl = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.045"
    height="23"
    viewBox="0 0 24.045 23"
    {...props}
  >
    <path
      id="version_control"
      d="M11.883,3a.521.521,0,0,0-.474.523V25.477a.524.524,0,0,0,.523.523H25.523a.524.524,0,0,0,.523-.523v-22A.521.521,0,0,0,25.523,3H11.883Zm.572,1.045H25V24.955H12.454ZM6.606,5.091a.523.523,0,0,0-.425.523V23.386a.524.524,0,0,0,.523.523h3.659V22.864H7.227V6.136h3.136V5.091H6.606ZM2.425,7.182A.523.523,0,0,0,2,7.7V21.3a.524.524,0,0,0,.523.523H5.136V20.773H3.045V8.227H5.136V7.182H2.425Z"
      transform="translate(-2 -3)"
    />
  </svg>
);

export default VersionsControl;
