import { createSelector } from 'reselect';

import {
  selectCurrentColorTheme,
} from './common';

// {
//   enabled,
//   hover,
//   tabEnabled,
//   tabLine,
//   shadow,
// }
export const selectControls = createSelector(
  selectCurrentColorTheme, (colorTheme) => {
    const { controls } = colorTheme;
    return controls || {};
  },
);
