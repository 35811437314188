// FIXME: привести к общему виду импорты констант
import * as authTypes from 'store/reducers/auth/types';
import * as filesTypes from 'store/reducers/files/constants';
import shareTypes from 'store/reducers/share/constants';
import { getTranslatedData } from 'shared/helpers/reduxStateTranslate';

import {
  getReducedFolderData,
  getReducedFileData,
} from 'store/reducers/flashes/helpers';
import types from '../constants';

import initialState from './initialState';

// TODO: rewrite
export const dataReducer = type => (prevResultData, currentItem) => {
  const { code, file, folder, source, id, elcnt, ...otherParams } = currentItem;
  // item.source = 'received';
  const fileType = (file && 'file') || (folder && 'folder');

  const additionalType = type || source;
  prevResultData[
    `${additionalType}_${additionalType === 'exchange' ? elcnt : code}`
  ] = {
    id: code,
    type: fileType,
    elcnt,
    source,
    additionalType,
    ...otherParams,
  };

  return prevResultData;
};

// TODO: rewrite all data spreading, if possible:
// нужно упростить по максимуму, перебор по итерациям
const ACTION_HANDLERS = {
  GET_DICT: state => {
    // TODO: Переписать на чистую функцию
    const translatedData = getTranslatedData(initialState.data, {
      ...state.data,
    });
    return {
      ...state,
      data: translatedData,
    };
  },
  [types.REQUEST_GET_INBOX_LINKS_SUCCESS]: (state, { payload }) => {
    const data = payload;
    const { data: currentData } = state;

    // TODO: исправить
    data.list.forEach(item => {
      item.breadcrumps = data.breadcrumps || [];
    });

    const resultData = data.list.reduce(dataReducer(), currentData);

    resultData.received.count = data.count;
    resultData.received.sorting = data.sorting || [];

    return {
      ...state,
      data: resultData,
      folderControls: data.actions,
      isFetching: false,
      errorMessage: '',
    };
  },
  [types.REQUEST_GET_OUTBOX_LINKS_SUCCESS]: (state, { payload }) => {
    const data = payload;
    const { data: currentData } = state;

    // TODO: исправить
    data.list.forEach(item => {
      item.breadcrumps = data.breadcrumps || [];
    });

    const resultData = data.list.reduce(dataReducer(), currentData);

    resultData.sent.count = data.count;
    resultData.sent.sorting = data.sorting || [];

    return {
      ...state,
      data: resultData,
      folderControls: data.actions,
      isFetching: false,
      errorMessage: '',
    };
  },
  [types.REQUEST_GET_LINKS_SUCCESS]: (state, { payload }) => {
    const data = payload;

    const { data: currentData } = state;
    data.list.forEach(item => {
      item.redirectCode = item.code;
      item.breadcrumps = data.breadcrumps || [];
    });

    // const { source } = params;

    // if (source) {
    //   state.data.source = source;
    // }

    const resultData = data.list.reduce(dataReducer('exchange'), currentData);
    resultData.exchange.count = data.count;
    resultData.exchange.sorting = data.sorting || [];

    return {
      ...state,
      data: { ...resultData },
      folderControls: data.actions,
      isFetching: false,
      errorMessage: '',
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_SUCCESS]: (state, { payload }) => {
    const data = payload;
    const { data: currentData } = state;

    // TODO: исправить
    data.list.forEach(item => {
      item.breadcrumps = data.breadcrumps || [];
    });

    const resultData = data.list.reduce(dataReducer('publiclink'), currentData);

    resultData.publiclink.count = data.count;
    resultData.publiclink.sorting = data.sorting || [];

    return {
      ...state,
      data: resultData,
      folderControls: data.actions,
      isFetching: false,
      errorMessage: '',
    };
  },
  [shareTypes.REQUEST_GET_OUTBOX_SUBFOLDER_SUCCESS]: (state, { payload }) => {
    const { sorting } = payload;
    const { data } = state;
    data.sent.sorting = sorting || [];

    return {
      ...state,
      data,
    };
  },
  [shareTypes.REQUEST_GET_INBOX_SUBFOLDER_SUCCESS]: (state, { payload }) => {
    const { sorting } = payload;
    const { data } = state;
    data.received.sorting = sorting || [];
    return {
      ...state,
      data,
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUCCESS]: (state, { payload }) => {
    const data = payload;

    const { data: currentData } = state;

    // TODO: исправить
    data.list.forEach(item => {
      item.parentId = 'unauthpubliclink';
      item.breadcrumps = data.breadcrumps || [];
    });

    const resultData = data.list.reduce(
      dataReducer('unauthpubliclink'),
      currentData,
    );

    resultData.unauthpubliclink.count = data.count;
    resultData.unauthpubliclink.sorting = data.sorting || [];
    resultData.unauthpubliclink.breadcrumps = [];

    return {
      ...state,
      data: resultData,
      folderControls: data.actions,
      isFetching: false,
      errorMessage: '',
    };
  },
  [types.REQUEST_INNER_FILES_SUCCESS]: (state, { payload, params }) => {
    const { folders = [], files = [] } = payload;

    const folderData = getReducedFolderData(folders, params.uuid);
    const fileData = getReducedFileData(files, params.uuid);
    return {
      ...state,
      data: Object.entries({
        ...folderData,
        ...fileData,
      }).reduce((prev, [id, item]) => {
        prev[id] = item;

        return prev;
      }, state.data),
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const { list, actions, breadcrumps } = payload;
    // TODO: исправить
    const folders = list
      .filter(item => item.folder)
      .map(item => (item.breadcrumps = breadcrumps || []));

    const files = list.filter(item => item.file);
    const folderData = getReducedFolderData(folders, params.uuid);
    const fileData = getReducedFileData(files, params.uuid);

    const parent = {
      id: params.uuid,
      type: 'send',
      additionalType: 'publiclink',
      name: params.uuid,
      link: params.uuid,
      parentId: 'publiclink',
    };

    const newData = Object.entries({
      ...folderData,
      ...fileData,
    }).reduce((prev, [id, item]) => {
      prev[id] = item;

      return prev;
    }, state.data);

    if (!newData[`publiclink_${parent.id}`]) {
      newData[`publiclink_${parent.id}`] = parent;
    }

    return {
      ...state,
      folderControls: actions,
      data: newData,
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const { list, actions, breadcrumps = [], sorting } = payload;
    const folders = list.filter(item => item.folder);
    // TODO: исправить
    breadcrumps && folders.map(item => (item.breadcrumps = breadcrumps));

    const files = list.filter(item => item.file);
    const folderData = getReducedFolderData(folders, 'unauthpubliclink');
    const fileData = getReducedFileData(files, params.uuid);

    const resultData = Object.entries({
      ...folderData,
      ...fileData,
    }).reduce((prev, [id, item]) => {
      prev[id] = item;

      return prev;
    }, state.data);

    return {
      ...state,
      data: {
        ...state.data,
        ...resultData,
        unauthpubliclink: {
          ...state.data.unauthpubliclink,
          sorting: [...sorting],
          breadcrumps: [...breadcrumps],
        },
      },
      subFolderControls: actions,
    };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
  // [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_FAIL]: (state, { reject, params }) => {

  //   return {
  //     ...state,
  //     data: {
  //       [params.uuid]: {
  //         status: 'failed',
  //       },
  //     },
  //   };
  // },

  // [types.DELETE_SHARE_ITEM]: (state, { params }) => {
  //   const { data } = state;
  //   const { firstPath, itemsIds } = params;

  //   const newData = data[firstPath].items.map(
  //     item => item.code === data[firstPath][itemsIds[0]].code,
  //   );

  //   return {
  //     ...state,
  //   };
  // },
  [types.REQUEST_GET_LINKS_SUBFOLDER_SUCCESS]: (state, { payload, params }) => {
    const newData = state.data;

    newData.source = params.source;

    return {
      ...state,
      ...newData,
    };
  },
  [filesTypes.FILES_UNAUTH_UPLOAD_SUCCESS]: (state, { payload, params }) => {
    const data = payload;

    if (data.error) {
      return state;
    }

    const { folder: parentId } = params;

    const { folders, files } = data.reduce(
      (currentChildren, child) => {
        if (child.subject === 'folder') {
          currentChildren.folders.push(child);

          return currentChildren;
        }

        currentChildren.files.push(child);

        return currentChildren;
      },
      {
        folders: [],
        files: [],
      },
    );

    return {
      ...state,
      data: Object.entries({
        ...getReducedFolderData(folders, parentId),
        ...getReducedFileData(files, parentId),
      }).reduce((prev, [id, item]) => {
        prev[id] = item;

        return prev;
      }, state.data),
    };
  },
};

export const byId = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byId;
