import * as authTypes from 'store/reducers/auth/types';
import * as eventsTypes from 'store/reducers/events/types';
import flashTypes from 'store/reducers/flashes/constants';
import treeTypes from 'store/reducers/tree/constants';
import directoriesTypes from 'store/reducers/directories/constants';

import { handleReducerArray } from 'store/reducers/tree/helpers';

import initialState from './initialState';

const ACTION_HANDLERS = {
  [flashTypes.REQUEST_FLASH_LIST_SUCCESS]: (state, { params, payload }) => {
    const data = payload;

    // Если идет сортировка, не обрабатывать для дерева
    if (data.error || params.q) {
      return state;
    }

    const { created, favorite, available, ...otherProps } = state;
    const { own, favorites, sharedToMe } = data;

    return {
      favorite: (favorites || []).map(item => item.code),
      created: (own || data).map(item => item.code),
      available: handleReducerArray(Object.keys(sharedToMe || []), available),
      ...Object.entries(sharedToMe || []).reduce((prev, [code, { flashs }]) => {
        prev[code] = handleReducerArray(
          flashs.map(item => item.code),
          prev[code] || [],
        );
        return prev;
      }, otherProps || {}),
    };
  },

  [treeTypes.REQUEST_TREE_FOLDERS_LIST_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;
    if (
      data.error ||
      !data[0] ||
      !data[0].children ||
      !data[0].children.length
    ) {
      return state;
    }
    const getChildrenToArray = (children, parentId) => {
      const otherChildren = children.reduce((prev, item) => {
        if (!item.children) {
          return prev;
        }
        return {
          ...prev,
          ...getChildrenToArray(item.children, item.id),
        };
      }, {});
      return {
        [parentId]: children.map(item => item.id),
        ...otherChildren,
      };
    };

    const newChildren = getChildrenToArray(data[0].children, params.uuid);

    return {
      ...state,
      ...newChildren,
    };
  },

  [flashTypes.REQUEST_FLASH_DELETE_SUCCESS]: (state, { payload, params }) => {
    const data = payload;
    if (data && data.error) {
      return state;
    }

    return {
      ...state,
      created: state.created.filter(id => id !== params.uuid),
      favorite: state.favorite.filter(id => id !== params.uuid),
    };
  },
  [flashTypes.DELETE_ITEM]: (state, { params }) => {
    const { parentId, itemsIds } = params;

    const newState = { ...state };
    newState[parentId] = newState[parentId]
      ? newState[parentId].filter(id => !itemsIds.includes(id))
      : [];

    return newState;
  },

  [flashTypes.COPY_FLASH_SUCCESS]: (state, { payload }) => {
    const created = [...state.created];
    if (payload.flash) {
      created.push(payload.flash.code);
    }

    return {
      ...state,
      created,
    };
  },

  [directoriesTypes.REQUEST_DIRECTORY_CREATE_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    if (!payload.success || payload.error) {
      return state;
    }

    const { isFlash, flash, folder, type } = params;

    if (type !== 'folder') {
      return state;
    }

    const uuid = payload.success;

    const parentId = isFlash ? flash : folder;

    return {
      ...state,
      [parentId]: [...(state[parentId] || []), uuid],
    };
  },

  [directoriesTypes.REQUEST_DIRECTORY_DELETE_SUCCESS]: (state, action) => {
    const { payload, params } = action;

    if (payload.success === false || payload.error) {
      // Удаление завершено с ошибкой
      return state;
    }

    if (!params.itemId && !params.items.length) {
      // Обновление файлов после удаления завершено с ошибкой
      return state;
    }

    const { items, itemId } = params;

    if (!itemId) {
      return state;
    }

    const newState = { ...state };
    newState[itemId] = (state[itemId] || []).filter(id => !items.includes(id));

    return newState;
  },

  [flashTypes.UPDATE_FLASH_PROPERTY_SUCCESS]: (state, { payload, params }) => {
    const data = payload;

    if ((data && data.error) || !params.uuid) {
      return state;
    }

    const newData = {
      favorite: state.favorite,
      created: state.created,
    };

    if (params.value) {
      newData.favorite.push(params.uuid);
    }

    if (!params.value) {
      newData.favorite = newData.favorite.filter(id => id !== params.uuid);
    }

    return {
      ...state,
      ...newData,
    };
  },

  [flashTypes.REQUEST_FLASH_CREATE_SUCCESS]: (state, { payload, params }) => {
    const { uuid } = payload;
    const { favorite, user } = params;

    if (payload.error || !uuid) {
      return state;
    }

    const newState = {
      ...state,
      created: !user ? [...state.created, uuid] : state.created,
      favorite: favorite ? [...state.favorite, uuid] : state.favorite,
    };

    return newState;
  },

  [flashTypes.REQUEST_FLASH_EDIT_SUCCESS]: (state, { payload }) => {
    const { flash } = payload;

    if (payload.error || !(flash && flash.code)) {
      return state;
    }

    const getFavorite = flash => {
      if (flash.favorite) {
        if (state.favorite.includes(flash.code)) {
          return state.favorite;
        }

        return [...state.favorite, flash.code];
      }

      return state.favorite.filter(item => item !== flash.code);
    };

    const newState = {
      ...state,
      favorite: getFavorite(flash),
    };

    return newState;
  },

  [flashTypes.FLASH_DELETE_REQUEST_SUCCESS]: (state, { payload }) => {
    const { uuid } = payload;

    if (payload.error || !uuid) {
      return state;
    }

    const newState = {
      ...state,
      favorite: state.favorite.filter(id => id !== uuid),
      created: state.created.filter(id => id !== uuid),
    };

    return newState;
  },
  [eventsTypes.POOL_DATA_SUCCESS]: (state, { payload }) => {
    const { drives, folder: folders } = payload;

    if (
      (!drives || drives.length === 0) &&
      (!folders || folders.length === 0)
    ) {
      return state;
    }

    const updatedState = drives.reduce(
      (currentByFilters, flash) => {
        const { code, my_flash: myFlash, owner, favorite } = flash;

        if (favorite && !currentByFilters.favorite.includes(code)) {
          currentByFilters.favorite.push(code);
        }

        if (myFlash && !currentByFilters.created.includes(code)) {
          currentByFilters.created.push(code);
        }

        if (!myFlash) {
          const { id: ownerId } = owner;

          if (!currentByFilters.available.includes(ownerId)) {
            currentByFilters.available.push(ownerId);
          }

          if (
            !currentByFilters[ownerId] ||
            !currentByFilters[ownerId].includes(code)
          ) {
            const flashesByOwnerId = currentByFilters[ownerId]
              ? [...currentByFilters[ownerId], code]
              : [code];

            currentByFilters[ownerId] = flashesByOwnerId;
          }
        }

        return currentByFilters;
      },
      {
        ...state,
        created: [...state.created],
        favorite: [...state.favorite],
        available: [...state.available],
      },
    );

    const nextState = folders.reduce((currentNextState, folder) => {
      const {
        parent_folder_uuid: parentFolderId,
        flash_uuid: flashId,
        code,
        in_root: inRoot,
      } = folder;

      const parentId = typeof inRoot !== 'object' ? flashId : parentFolderId;
      if (
        !currentNextState[parentId] ||
        !currentNextState[parentId].includes(code)
      ) {
        const filesByParentId = currentNextState[parentId]
          ? [...currentNextState[parentId], code]
          : [code];

        currentNextState[parentId] = filesByParentId;
      }
      return currentNextState;
    }, updatedState);

    return nextState;
    // return state;
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

export const byFilters = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byFilters;
