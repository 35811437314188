const permitionActions = {
  share: {
    download: true,
    preview: true,
    info: true,
    link: true,
    copy: true,
    delete: false,
    upload: false,
    rename: false,
  },
  write: {
    download: true,
    preview: true,
    info: true,
    copy: true,
    delete: true,
    upload: true,
    link: true,
    rename: true,
  },
  reshare: {
    download: true,
    preview: true,
    info: true,
    copy: true,
    delete: true,
    upload: true,
    link: true,
    rename: true,
  },
};

export const getFlashPermitionType = (permitions) => {
  const isSharePermition = permitions.share && !permitions.write && !permitions.reshare;
  const isWritePermition = permitions.write && !permitions.reshare;
  const isResharePermition = permitions.reshare;
  if (isSharePermition) {
    return 'share';
  }
  if (isWritePermition) {
    return 'write';
  }
  if (isResharePermition) {
    return 'reshare';
  }
  return '';
};

export const getFlashActions = permitions => permitionActions[getFlashPermitionType(permitions)];
