/* eslint-disable react/prop-types */
import React, { createContext, useState, useMemo } from 'react';

const PopoverContext = createContext();

const PopoverContextProvider = ({ children }) => {
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const value = useMemo(() => ({
    setOpenedPopoverId,
    openedPopoverId,
  }), [openedPopoverId]);

  return (
    <PopoverContext.Provider value={value}>
      {children}
    </PopoverContext.Provider>
  );
};

export { PopoverContext as default, PopoverContextProvider };
