import React from 'react';

const Rename = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14.4"
      viewBox="0 0 16 14.4"
      {...props}
    >
      <path
        id="rename"
        d="M10,3V4.6a3.171,3.171,0,0,1,1.442.316,1.738,1.738,0,0,1,.158.092V15.392a1.75,1.75,0,0,1-.158.092A3.172,3.172,0,0,1,10,15.8v1.6a4.816,4.816,0,0,0,2.158-.484,1.376,1.376,0,0,0,.242-.166,1.376,1.376,0,0,0,.242.166A4.816,4.816,0,0,0,14.8,17.4V15.8a3.172,3.172,0,0,1-1.442-.316,1.75,1.75,0,0,1-.158-.092V5.008a1.738,1.738,0,0,1,.158-.092A3.171,3.171,0,0,1,14.8,4.6V3a4.817,4.817,0,0,0-2.158.484,1.38,1.38,0,0,0-.242.166,1.38,1.38,0,0,0-.242-.166A4.817,4.817,0,0,0,10,3ZM3.6,7A1.612,1.612,0,0,0,2,8.6v3.2a1.612,1.612,0,0,0,1.6,1.6H10V11.8H3.6V8.6H10V7ZM14.8,7V8.6h1.6v3.2H14.8v1.6h1.6A1.612,1.612,0,0,0,18,11.8V8.6A1.612,1.612,0,0,0,16.4,7Z"
        transform="translate(-2 -3)"
      />
    </svg>
  );
};

export default Rename;
