export const getLinkId = (returnLink) => {
  try {
    return returnLink
      .split('/')[2]
      .split('?')[0];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error occurred while parsing return link! returnLink=${returnLink}`, error);
  }
};
