import { createRequestAction } from 'store/utils/createRequestAction';
import { apiService, unauthApiService } from 'services';

import filesByIdTypes from './byId/constants';
import * as filesTypes from './constants';

export const requestFiles = filters => {
  return { type: filesByIdTypes.GET_FILES_BY_ID, payload: filters };

  // return { type: filesIdsByFilterTypes.GET_FILES_BY_FILTERS, payload: filters };
};

export const uploadFiles = createRequestAction(params => {
  const { firstPath, folder, flashId, parentType, ...restParams } = params;
  return {
    types: [
      filesTypes.FILES_UPLOAD,
      filesTypes.FILES_UPLOAD_SUCCESS,
      filesTypes.FILES_UPLOAD_FAIL,
    ],
    parentType,
    flashId,
    folderId: folder,
    firstPath,
    apiRequest: () => apiService.files.uploadFiles({ folder, ...restParams }),
  };
});

export const replyFiles = createRequestAction(params => ({
  types: [
    filesTypes.FILES_REPLY,
    filesTypes.FILES_REPLY_SUCCESS,
    filesTypes.FILES_REPLY_FAIL,
  ],
  apiRequest: () => apiService.files.uploadFiles(params),
}));

export const uploadUnauthFiles = createRequestAction(params => {
  const { folder, linkId, ...restParams } = params;

  return {
    types: [
      filesTypes.FILES_UNAUTH_UPLOAD,
      filesTypes.FILES_UNAUTH_UPLOAD_SUCCESS,
      filesTypes.FILES_UNAUTH_UPLOAD_FAIL,
    ],
    apiRequest: () =>
      unauthApiService.files.uploadUnauthFiles({
        linkId,
        folder,
        ...restParams,
      }),
    params,
  };
});
