import * as notificationTypes from 'services/apiService/notificationTypes';

const getNotificationsByProperty = (params) => {
  const { property, value } = params;

  if (property === 'favorite') {
    if (value === false) {
      return {
        success: notificationTypes.FLASH_REMOVE_FROM_FAVOURITE_REQUEST_SUCCESS,
      };
    }

    return {
      success: notificationTypes.FLASH_ADD_TO_FAVOURITE_REQUEST_SUCCESS,
    };
  }
};

const flashRoute = {
  getFlashList: params => ({
    url: '/flash/list',
    method: 'GET',
    params: { ...params, group: true },
  }),

  createFlash: (params) => {
    const { ...otherParams } = params;
    return {
      url: '/flash',
      method: 'POST',
      data: otherParams,
      notifications: {
        success: notificationTypes.CREATE_FLASH_REQUEST_SUCCESS,
        omitErrorNotification: true,
      },
    };
  },

  copyFlash: (params) => {
    const { uuid } = params;
    return {
      url: `flash/copy/${uuid}`,
      method: 'POST',
      notifications: {
        success: notificationTypes.FLASH_COPY_REQUEST_SUCCESS,
        omitErrorNotification: true,
      },
    };
  },

  editFlash: (params) => {
    const { uuid } = params;
    return {
      url: `/flash/${uuid}`,
      method: 'PUT',
      data: params,
      notifications: {
        success: notificationTypes.FLASH_EDIT_REQUEST_SUCCESS,
        omitErrorNotification: true,
      },
    };
  },

  getFlashParams: (params) => {
    const { uuid } = params;
    return {
      url: `/flash/info/${uuid}`,
      method: 'GET',
      params,
    };
  },

  getFlashDirectoryList: (params) => {
    const { uuid } = params;
    return {
      url: `/flash/${uuid}`,
      method: 'GET',
      params,
      uuid,
    };
  },

  getFlashSubDirectoryList: (params) => {
    const { uuid, subUuid } = params;
    return {
      url: `/flash/${uuid}/${subUuid}`,
      method: 'GET',
      params,
      uuid,
      subUuid,
    };
  },

  deleteFlash: (params) => {
    const { uuid } = params;
    return {
      url: `/flash/${uuid}`,
      method: 'DELETE',
      data: params,
      notifications: {
        success: notificationTypes.FLASH_DELETE_REQUEST_SUCCESS,
        omitErrorNotification: true,
      },
    };
  },

  getFlashInfo: params => ({
    url: '/flash/info',
    method: 'GET',
    params,
  }),

  setFlashVersioned: (params) => {
    const { uuid } = params;
    return {
      url: `/flash/updatebyproperty/${uuid}`,
      method: 'POST',
      data: params,
    };
  },

  updateFlashProperty: (params) => {
    const { uuid } = params;
    const notifications = getNotificationsByProperty(params);
    return {
      url: `/flash/updatebyproperty/${uuid}`,
      method: 'POST',
      data: params,
      notifications,
    };
  },

  restoreVersions: params => ({
    url: '/version/restore',
    method: 'POST',
    data: params,
    notifications: {
      success: notificationTypes.FILES_VERSIONS_RESTORE_REQUEST_SUCCESS,
    },
  }),

  deleteVersions: params => ({
    url: '/version/delete',
    method: 'DELETE',
    data: params,
    notifications: {
      success: notificationTypes.FILES_VERSIONS_DELETE_REQUEST_SUCCESS,
    },
  }),

  sendLinkModeration: params => ({
    url: '/action/moderation',
    method: 'POST',
    data: params,
  }),

  // setRegistrationSettings: ({ registrationToken, step, ...fields }) => ({
  //   url: '/registration/set',
  //   method: 'POST',
  //   data: {
  //     reg_token: registrationToken,
  //     step,
  //     ...fields,
  //   },
  // }),
  // login: ({ login, password, lang }) => ({
  //   url: '/auth',
  //   method: 'POST',
  //   data: {
  //     login,
  //     password,
  //     lang,
  //   },
  // }),
  // logout: () => ({
  //   url: '/logout',
  //   method: 'POST',
  // }),
  // logoutAll: () => ({
  //   url: '/logout/all',
  //   method: 'POST',
  // }),
};

export default flashRoute;
