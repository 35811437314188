import React from 'react';

const Favorite = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.848"
      height="37.091"
      viewBox="0 0 28.848 37.091"
      {...props}
    >
      <path
        id="fav"
        d="M29.727,3H9.121a4.116,4.116,0,0,0-4.1,4.121L5,40.091l14.424-6.182,14.424,6.182V7.121A4.133,4.133,0,0,0,29.727,3Zm0,30.909-10.3-4.492-10.3,4.492V7.121H29.727Z"
        transform="translate(-5 -3)"
      />
    </svg>
  );
};

export default Favorite;
