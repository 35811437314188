import * as authTypes from 'store/reducers/auth/types';

import types from 'store/reducers/devices/constants';

const initialState = {};
const ACTION_HANDLERS = {
  [types.GET_DEVICES_SUCCESS]: (state, { payload }) => {
    const data = payload;
    const devices = data.map(item => item.wui);

    return { ...state, devices };
  },
  [types.LOCAL_DELETE_DEVICE]: (state, { params }) => {
    const { itemsIds } = params;

    return {
      ...state,
      devices: state.devices.filter(id => !itemsIds.includes(id)),
    };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

export const byFilters = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byFilters;
