/* eslint-disable react/prop-types */
import React, { createContext, useState, useCallback, useMemo } from 'react';
import { useMediaQuery } from '@material-ui/core';

import { setLocalStorageItem } from 'helpers/setLocalStorageItem';
import { getLocalStorageItem } from 'helpers/getLocalStorageItem';

const LeftSidebarWidthParamsContext = createContext();

const defaultSidebarWidth = 378;
const WIDTH_SCROLL_PADDING = 9;
const WIDTH_LABEL_FLASH_DEFAULT = 218;

const LeftSidebarWidthParamsState = ({ children }) => {
  // TODO: refactor

  const smallWidth = useMediaQuery('(max-width:961px)');
  const mediumWidth = useMediaQuery('(max-width:1301px)');

  const initialSidebarWidth = getLocalStorageItem('leftSidebarWidth', defaultSidebarWidth);

  /**
   * @description ширина левой панели
   */
  const [leftSidebarWidth, setLeftSidebarWidth] = useState(initialSidebarWidth);

  /**
   * @description максимальная ширина элемента в дереве левой панели
   */
  const [treeItemLabelWidth, setTreeItemLabelWidth] = useState(WIDTH_LABEL_FLASH_DEFAULT);

  /**
   * @description ширина кнопок левой панели (3 точки, синхронизация, процент загрузки)
   */
  const [treeItemDieWidth, setTreeItemDieWidth] = useState(16);

  /**
   * @description максимальная ширина кнопок левой панели
   * (3 точки, синхронизация, процент загрузки)
   */
  const [maxWidthOfDie, setMaxWidthOfDie] = useState(16);

  /**
   * @description раскрытые элементы в дереве
   */
  const [expanded, setExpanded] = useState(getLocalStorageItem('treeIds', []));

  const [isOpenedBar, setOpenedBar] = useState(false);

  const minWidth = treeItemLabelWidth + treeItemDieWidth + WIDTH_SCROLL_PADDING;

  const handleLeftSidebarWidth = useCallback((newWidth) => {
    let resultWidth = 100;
    const maxWidth = Math.min(window.innerWidth * 0.7, 800);
    if (smallWidth && !isOpenedBar) {
      setLeftSidebarWidth(0);
      return;
    }

    if (!mediumWidth || isOpenedBar) {
      resultWidth = newWidth > maxWidth ? maxWidth : newWidth;
      if (newWidth < minWidth) {
        resultWidth = minWidth;
      }
    }

    setLocalStorageItem('leftSidebarWidth', resultWidth);
    setLeftSidebarWidth(resultWidth);
  }, [
    minWidth,
    smallWidth,
    mediumWidth,
    isOpenedBar,
    setLeftSidebarWidth,
  ]);

  const refreshLeftSidebarWidth = useCallback(() => {
    const maxRowWidth = treeItemLabelWidth + treeItemDieWidth;
    const updatedWidthCondition = leftSidebarWidth > maxRowWidth;
    const updatedWidth = updatedWidthCondition ? leftSidebarWidth : maxRowWidth;
    handleLeftSidebarWidth(updatedWidth);
  }, [
    handleLeftSidebarWidth,
    leftSidebarWidth,
    treeItemDieWidth,
    treeItemLabelWidth,
  ]);

  const toggleBar = actualBarState => setOpenedBar(actualBarState);

  const value = useMemo(() => ({
    leftSidebarWidth,
    refreshLeftSidebarWidth,
    setLeftSidebarWidth: handleLeftSidebarWidth,
    treeItemLabelWidth,
    setTreeItemLabelWidth,
    treeItemDieWidth,
    setTreeItemDieWidth,
    maxWidthOfDie,
    setMaxWidthOfDie,
    expanded,
    setExpanded,
    toggleBar,
    isOpenedBar,
  }), [
    expanded,
    handleLeftSidebarWidth,
    isOpenedBar,
    leftSidebarWidth,
    maxWidthOfDie,
    refreshLeftSidebarWidth,
    treeItemDieWidth,
    treeItemLabelWidth,
  ]);

  return (
    <LeftSidebarWidthParamsContext.Provider value={value}>
      {children}
    </LeftSidebarWidthParamsContext.Provider>
  );
};

export {
  LeftSidebarWidthParamsContext as default,
  LeftSidebarWidthParamsState,
};
