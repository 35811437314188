import types from '../constants';

const initialState = {
  count: 0,
  new: [],
  old: [],
};

const ACTION_HANDLERS = {
  [types.GET_MESSAGES_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
};

export const byFilters = (state = initialState, action) => (ACTION_HANDLERS[action.type]
  ? ACTION_HANDLERS[action.type](state, action)
  : state);

export default byFilters;
