import * as authTypes from 'store/reducers/auth/types';
import * as eventsTypes from 'store/reducers/events/types';
import flashesTypes from 'store/reducers/flashes/constants';
import {
  getSelectedFileId,
  getNextSelectedFileId,
  getPrevSelectedFileId,
} from './selectors';
import { getPrevFileId, getNextFileId } from './helpers';
import * as previewTypes from './types';

const initialState = {
  files: [],
  preparedFiles: {},
  selectedFileId: null,
  parentFolderType: 'flash',
};

const ACTION_HANDLERS = {
  [previewTypes.RESET_PREPARED_FILES]: state => ({
    ...state,
    preparedFiles: {},
  }),
  [previewTypes.OPEN_PREVIEW]: (state, { files = [] }) => {
    const selectedFileId = files.length > 0 ? files[0] : null;
    return {
      ...state,
      preparedFiles: {},
      selectedFileId,
      files,
    };
  },
  [previewTypes.SET_FOLDER_TYPE]: (state, { parentFolderType }) => ({
    ...state, parentFolderType,
  }),
  [previewTypes.CLOSE_PREVIEW]: () => initialState,
  [previewTypes.SELECT_PREVIEW_FILE]: (state, { selectedFileId }) => ({
    ...state,
    selectedFileId,
  }),
  [previewTypes.PREPARE_PREVIEW_FILES]: (state, { data }) => {
    const { preparedFiles } = state;
    const selectedFileId = getSelectedFileId({ previews: state });
    const nextFileId = getNextSelectedFileId({ previews: state });
    const prevFileId = getPrevSelectedFileId({ previews: state });
    const availableFiles = [selectedFileId, nextFileId, prevFileId];
    const reducedPreparedFiles = availableFiles.reduce(
      (availablePreviewFiles, currentFileId) => {
        const tmpAvaliableFiles = availablePreviewFiles;
        if (preparedFiles[currentFileId]) {
          tmpAvaliableFiles[currentFileId] = preparedFiles[currentFileId];
        }
        return tmpAvaliableFiles;
      },
      {},
    );
    const nextPreparedFiles = data.reduce(
      (currentPreparedFiles, preparingFile) => {
        const { file: fileId } = preparingFile;

        if (currentPreparedFiles[fileId]) {
          return currentPreparedFiles;
        }

        // eslint-disable-next-line no-param-reassign
        currentPreparedFiles[fileId] = {
          fileId,
          linkId: null,
          isFetching: true,
          isReady: false,
          isLoaded: false,
          isError: false,
        };

        return currentPreparedFiles;
      },
      { ...reducedPreparedFiles },
    );

    return {
      ...state,
      preparedFiles: nextPreparedFiles,
    };
  },
  [previewTypes.PREPARE_PREVIEW_FILES_SUCCESS]: (state, { payload }) => {
    const { preparedFiles } = state;

    const nextPreparedFiles = Object.entries(payload).reduce(
      (currentPreparedFiles, preparedFileEntry) => {
        const [fileId, fileData] = preparedFileEntry;
        const { uuid: linkId, error } = fileData;

        const preparedFileData = currentPreparedFiles[fileId] || {};

        // eslint-disable-next-line no-param-reassign
        currentPreparedFiles[fileId] = {
          ...preparedFileData,
          fileId,
          linkId,
          isError: !!error,
          isFetching: false,
          isLoaded: false,
          isReady: false,
        };

        return currentPreparedFiles;
      },
      { ...preparedFiles },
    );

    return { ...state, preparedFiles: nextPreparedFiles };
  },
  [previewTypes.PREPARE_PREVIEW_FILES_FAIL]: (state, { data }) => {
    const { preparedFiles } = state;

    const nextPreparedFiles = data.reduce(
      (currentPreparedFiles, loadingFile) => {
        const { file } = loadingFile;

        // eslint-disable-next-line no-param-reassign
        currentPreparedFiles[file] = {
          fileId: file,
          linkId: null,
          isError: true,
          isFetching: false,
          isLoaded: false,
          isReady: false,
        };

        return currentPreparedFiles;
      },
      { ...preparedFiles },
    );

    return { ...state, preparedFiles: nextPreparedFiles };
  },
  [eventsTypes.UNAUTH_POOL_DATA_SUCCESS]: (state, { payload }) => {
    const { preparedFiles } = state;
    const { previews } = payload;

    if (!previews || previews.length === 0) {
      return state;
    }

    // eslint-disable-next-line no-console
    // console.log('UNAUTH_POOL_DATA_SUCCESS', payload);

    // TODO: refactor

    /**
     * получение fileId для пришедших linkId при условии,
     * что файлы не загружены и загружаются в данный момент
     */
    const readyFilesData = previews.reduce((currentReadyFilesData, linkId) => {
      const preparedFileData = Object.values(preparedFiles).find(
        preparedFile => linkId === preparedFile.linkId,
      );

      if (
        !preparedFileData
        || preparedFileData.isLoaded
        || preparedFileData.isFetching
        || preparedFileData.isError
      ) {
        return currentReadyFilesData;
      }

      currentReadyFilesData.push({
        linkId: preparedFileData.linkId,
        fileId: preparedFileData.fileId,
      });

      return currentReadyFilesData;
    }, []);

    if (readyFilesData.length === 0) {
      return state;
    }

    /**
     * обновление информации для linkId (готовы к загрузке)
     */
    const nextPreparedFiles = readyFilesData.reduce(
      (currentPreparedFiles, readyFileData) => {
        const { fileId } = readyFileData;

        const preparedFileData = currentPreparedFiles[fileId];

        // eslint-disable-next-line no-param-reassign
        currentPreparedFiles[fileId] = {
          ...preparedFileData,
          isFetching: false,
          isReady: true,
          isError: false,
        };

        return currentPreparedFiles;
      },
      { ...preparedFiles },
    );

    return { ...state, preparedFiles: nextPreparedFiles };
  },
  [eventsTypes.POOL_DATA_SUCCESS]: (state, { payload }) => {
    const { preparedFiles } = state;
    const { previews } = payload;

    if (!previews || previews.length === 0) {
      return state;
    }

    // eslint-disable-next-line no-console
    // console.log('POOL_DATA_SUCCESS', payload);

    // TODO: refactor

    /**
     * получение fileId для пришедших linkId при условии,
     * что файлы не загружены и загружаются в данный момент
     */
    const readyFilesData = previews.reduce((currentReadyFilesData, linkId) => {
      const preparedFileData = Object.values(preparedFiles).find(
        preparedFile => linkId === preparedFile.linkId,
      );

      if (
        !preparedFileData
        || preparedFileData.isLoaded
        || preparedFileData.isFetching
        || preparedFileData.isError
      ) {
        return currentReadyFilesData;
      }

      currentReadyFilesData.push({
        linkId: preparedFileData.linkId,
        fileId: preparedFileData.fileId,
      });

      return currentReadyFilesData;
    }, []);

    if (readyFilesData.length === 0) {
      return state;
    }

    /**
     * обновление информации для linkId (готовы к загрузке)
     */
    const nextPreparedFiles = readyFilesData.reduce(
      (currentPreparedFiles, readyFileData) => {
        const { fileId } = readyFileData;

        const preparedFileData = currentPreparedFiles[fileId];

        // eslint-disable-next-line no-param-reassign
        currentPreparedFiles[fileId] = {
          ...preparedFileData,
          isFetching: false,
          isReady: true,
          isError: false,
        };

        return currentPreparedFiles;
      },
      { ...preparedFiles },
    );

    return { ...state, preparedFiles: nextPreparedFiles };
  },
  [previewTypes.DOWNLOAD_PREVIEW_FILES]: (state, { data }) => {
    const { preparedFiles } = state;
    const { fileId } = data;

    const preparedFile = preparedFiles[fileId];

    if (!preparedFile) {
      return state;
    }

    const nextPreparedFiles = {
      ...preparedFiles,
      [fileId]: {
        ...preparedFile,
        isFetching: true,
        isReady: false,
        isError: false,
        isLoaded: false,
      },
    };

    return {
      ...state,
      preparedFiles: nextPreparedFiles,
    };
  },
  [previewTypes.DOWNLOAD_PREVIEW_FILES_SUCCESS]: (state, { payload, data }) => {
    // eslint-disable-next-line no-console
    // console.log('DOWNLOAD_PREVIEW_FILES_SUCCESS', { payload, data });

    const { preparedFiles } = state;
    const { fileId } = data;

    const preparedFile = preparedFiles[fileId];

    if (!preparedFile) {
      return state;
    }

    const nextPreparedFiles = {
      ...preparedFiles,
      [fileId]: {
        ...preparedFile,
        isError: !!payload.error,
        isLoaded: !payload.error,
        isFetching: false,
        isReady: false,
      },
    };

    return { ...state, preparedFiles: nextPreparedFiles };
  },
  [previewTypes.DOWNLOAD_PREVIEW_FILES_FAIL]: (state, { data }) => {
    const { preparedFiles } = state;
    const { fileId } = data;

    const preparedFile = preparedFiles[fileId];

    if (!preparedFile) {
      return state;
    }

    const nextPreparedFiles = {
      ...preparedFiles,
      [fileId]: {
        ...preparedFile,
        isError: true,
        isLoaded: false,
        isFetching: false,
        isReady: false,
      },
    };

    return { ...state, preparedFiles: nextPreparedFiles };
  },
  [previewTypes.UNAUTH_DOWNLOAD_PREVIEW_FILES]: (state, { data }) => {
    const { preparedFiles } = state;
    const { fileId } = data;

    const preparedFile = preparedFiles[fileId];

    if (!preparedFile) {
      return state;
    }

    const nextPreparedFiles = {
      ...preparedFiles,
      [fileId]: {
        ...preparedFile,
        isFetching: true,
        isReady: false,
        isError: false,
        isLoaded: false,
      },
    };

    return {
      ...state,
      preparedFiles: nextPreparedFiles,
    };
  },
  [previewTypes.UNAUTH_DOWNLOAD_PREVIEW_FILES_SUCCESS]: (state, { payload, data }) => {
    // eslint-disable-next-line no-console
    console.log('UNAUTH_DOWNLOAD_PREVIEW_FILES_SUCCESS', { payload, data });

    const { preparedFiles } = state;
    const { fileId } = data;

    const preparedFile = preparedFiles[fileId];

    if (!preparedFile) {
      return state;
    }

    const nextPreparedFiles = {
      ...preparedFiles,
      [fileId]: {
        ...preparedFile,
        isError: !!payload.error,
        isLoaded: !payload.error,
        isFetching: false,
        isReady: false,
      },
    };

    return { ...state, preparedFiles: nextPreparedFiles };
  },
  [previewTypes.UNAUTH_DOWNLOAD_PREVIEW_FILES_FAIL]: (state, { data }) => {
    const { preparedFiles } = state;
    const { fileId } = data;

    const preparedFile = preparedFiles[fileId];

    if (!preparedFile) {
      return state;
    }

    const nextPreparedFiles = {
      ...preparedFiles,
      [fileId]: {
        ...preparedFile,
        isError: true,
        isLoaded: false,
        isFetching: false,
        isReady: false,
      },
    };

    return { ...state, preparedFiles: nextPreparedFiles };
  },
  [flashesTypes.DELETE_ITEM]: (state, { params }) => {
    const { itemsIds } = params;
    if (!itemsIds || itemsIds.length === 0) {
      return state;
    }

    const { files, selectedFileId } = state;

    if (files.length === 0) {
      return state;
    }

    // фильтруем все удаленные id, кроме текущего выделенного файла
    const updatedFiles = files.filter(
      fileId => !itemsIds.includes(fileId) || fileId === selectedFileId,
    );

    // текущий выделенный не удален, тогда возвращаем обновленный массив
    if (!itemsIds.includes(selectedFileId)) {
      return {
        ...state,
        files: updatedFiles,
      };
    }

    // текущий выбранный файл удален - выбираем следующий или предыдущий файл,
    // затемудаляем предыдущий выбранный
    const nextSelectedFileId = getNextFileId(updatedFiles, selectedFileId)
      || getPrevFileId(updatedFiles, selectedFileId)
      || null;

    const resultFiles = updatedFiles.filter(
      fileId => fileId !== selectedFileId,
    );

    return { ...state, files: resultFiles, selectedFileId: nextSelectedFileId };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

export default (state = initialState, action) => (ACTION_HANDLERS[action.type]
  ? ACTION_HANDLERS[action.type](state, action)
  : state);
