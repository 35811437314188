import { LOCATION_CHANGE } from 'connected-react-router';

const initialstate = {
  backward: [],
  forward: [],
};

const ACTION_HANDLERS = {
  [LOCATION_CHANGE]: (state, { payload }) => {
    if (payload.isFirstRendering) {
      return state;
    }

    const { location, action } = payload;
    const { key = 'defaultKey' } = location;

    if (action === 'PUSH') {
      const { backward } = state;

      return {
        ...state,
        forward: [],
        backward: [...backward, key],
      };
    }

    if (action === 'POP') {
      const { backward, forward } = state;

      const forwardIndex = forward.indexOf(key);
      if (forwardIndex !== -1) {
        const nextKey = forward[forwardIndex];

        const nextForward = forward
          .slice(0, forwardIndex)
          .concat(forward.slice(forwardIndex + 1));

        const nextBackward = [...backward, nextKey];

        return {
          ...state,
          backward: nextBackward,
          forward: nextForward,
        };
      }

      const backwardIndex = backward.indexOf(key);

      if (backwardIndex !== -1) {
        const nextBackward = backward.slice(0, backwardIndex);

        const nextForward = forward
          .slice()
          .concat(backward.slice(backwardIndex + 1));

        return {
          ...state,
          backward: nextBackward,
          forward: nextForward,
        };
      }

      return {
        ...state,
        backward: [],
        forward: forward.slice().concat(backward.slice()),
      };
    }

    return state;
  },
};

export default (state = initialstate, action) => (ACTION_HANDLERS[action.type]
  ? ACTION_HANDLERS[action.type](state, action)
  : state);
