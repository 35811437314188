import React from 'react';

const Settings = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.794"
      height="12"
      viewBox="0 0 11.794 12"
      {...props}
    >
      <path
        id="settings"
        d="M6.668,2,6.374,3.514a4.771,4.771,0,0,0-1.346.775l-1.454-.5-1.4,2.426L3.337,7.225A4.8,4.8,0,0,0,3.269,8a4.881,4.881,0,0,0,.068.775h0L2.172,9.788l1.4,2.425,1.453-.5a4.771,4.771,0,0,0,1.348.773L6.668,14h2.8l.294-1.514a4.766,4.766,0,0,0,1.347-.775l1.454.5,1.4-2.425L12.8,8.775a4.441,4.441,0,0,0,0-1.548h0l1.165-1.014-1.4-2.425-1.453.5a4.771,4.771,0,0,0-1.348-.773L9.469,2Zm.989,1.2H8.48l.233,1.2.626.237a3.564,3.564,0,0,1,1.01.579l.518.424,1.153-.4.411.711-.921.8.105.66h0a3.242,3.242,0,0,1,0,1.165l-.107.66.921.8-.411.713-1.152-.4-.519.425a3.559,3.559,0,0,1-1.009.579h0l-.626.237-.233,1.2H7.657l-.233-1.2L6.8,11.363a3.566,3.566,0,0,1-1.01-.579L5.27,10.36l-1.153.4-.411-.711.922-.8-.107-.657h0A3.7,3.7,0,0,1,4.469,8a3.608,3.608,0,0,1,.053-.582l.107-.66-.922-.8.411-.713,1.153.4.518-.425A3.565,3.565,0,0,1,6.8,4.637L7.424,4.4Zm.411,2.4a2.4,2.4,0,1,0,2.4,2.4A2.41,2.41,0,0,0,8.069,5.6Zm0,1.2A1.2,1.2,0,1,1,6.869,8,1.195,1.195,0,0,1,8.069,6.8Z"
        transform="translate(-2.172 -2)"
      />
    </svg>
  );
};

export default Settings;
