import * as notificationTypes from 'services/apiService/notificationTypes';

const filesRoute = {
  uploadUnauthFiles: ({ files, folder, linkId, onUploadProgress }) => ({
    url: `/public/upload/${linkId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      folder,
    },
    data: files,
    onUploadProgress,
  }),

  replyUnauthFiles: ({ files, linkId }) => ({
    url: `/public/upload/${linkId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: files,
  }),

  downloadUnauthPrepare: ({ ids, type, group, linkId }) => ({
    url: `/public/download/prepare/${linkId}`,
    method: 'POST',
    data: group ? { group } : { file: ids, type },
    notifications: {
      success: notificationTypes.DOWNLOAD_PREPARE_SUCCESS,
    },
  }),

  download: ({ id, type, linkId }) => ({
    url: `/public/download/${linkId}/${id}`,
    method: 'GET',
    params: type ? { type } : {},
    responseType: 'blob',
    notifications: {
      request: notificationTypes.DOWNLOAD_FILES_REQUEST,
      success: notificationTypes.DOWNLOAD_FILES_REQUEST_SUCCESS,
    },
  }),

  previewDownload: ({ id, type, linkId }) => ({
    url: `/public/download/${linkId}/${id}`,
    method: 'GET',
    params: type ? { type } : {},
    responseType: 'blob',
  }),
};

export default filesRoute;
