import React, { useState } from 'react';
import { getFilterUrl } from 'shared/elements/StyledSvg/helpers'
import { createGuid } from 'helpers/createGuid';

const FileManagerPreview = props => {
  // Хак для того, чтобы компонент не терял id в Safari в момент ререндера svg
  const [rectId] = useState(createGuid());
  const filter = getFilterUrl(rectId);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="71"
      height="71"
      viewBox="0 0 71 71"
      {...props}
    >
      <defs>
        <filter
          id={rectId}
          x="0"
          y="0"
          width="71"
          height="71"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood floodOpacity="0.122" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Small_RGB_Primary_RT_Symbol"
          x="0"
          y="0"
          width="71"
          height="71"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-2" />
          <feFlood floodOpacity="0.122" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <clipPath id="clipPath">
          <g
            transform="matrix(1, 0, 0, 1, 0, 0)"
            filter={filter}
          >
            <rect
              id="Rectangle_272-2"
              data-name="Rectangle 272"
              width={props.style.width}
              height={props.style.height}
              rx="12"
              transform="translate(3 3.42)"
              fill="#fff"
            />
          </g>
        </clipPath>
        <pattern
          id="pattern"
          preserveAspectRatio="none"
          width="100%"
          height="100%"
          viewBox={`0 0 ${props.style.width} ${props.style.height}`}
        >
          <image
            width={props.style.width}
            height={props.style.height}
            href={props.image}
          />
        </pattern>
      </defs>
      <g id="fm_prev" transform="translate(-22.498 6.296)">
        <text
          id={props.extension}
          transform="translate(58.335 35.787)"
          fill="#fefefe"
          fontSize="11"
          fontFamily="SegoeUI-Bold, Roboto Bold"
          fontWeight="700"
          letterSpacing="0.033em"
        >
          <tspan x="-10.512" y="0">
            {props.extension}
          </tspan>
        </text>
        <g
          transform="matrix(1, 0, 0, 1, 22.5, -6.3)"
          filter="url(#Small_RGB_Primary_RT_Symbol)"
        >
          <rect
            id="Small_RGB_Primary_RT_Symbol-2"
            data-name="Small_RGB_Primary_RT_Symbol"
            width="56"
            height="56"
            rx="12"
            transform="translate(7.5 6.5)"
            fill="#fff"
          />
        </g>
        <g
          id="Mask_Group_14"
          data-name="Mask Group 14"
          transform="translate(26.998 -3.212)"
          clipPath="url(#clip-path)"
        >
          <rect
            id="Image_16"
            data-name="Image 16"
            x="0"
            y="0"
            width={props.style.width}
            height={props.style.height}
            transform="translate(-3 3.416)"
            fill="url(#pattern)"
          />
        </g>
      </g>
    </svg>
  );
};

export default FileManagerPreview;
