import React, { Suspense, lazy } from 'react';
// https://github.com/duskload/react-device-detect#selectors-and-views
import { isMobileOnly } from 'react-device-detect'; // returns true if device type is mobile (not tablet)

import { retry } from 'routing/helpers';
import Spinner from 'components/Spinner';

const MobileRouter = lazy(() => retry(() => import('mobile/screens/Router')));
const DesktopRouter = lazy(() => retry(() => import('web/screens/Router')));

const RouterApp = () => (
  <Suspense fallback={<Spinner isHidden={false} />}>
    {isMobileOnly ? <MobileRouter /> : <DesktopRouter />}
  </Suspense>
);

export default RouterApp;
