export const getReducedCloudData = (
  data,
  parentId = 'storage',
  accumulator = {},
) => {
  return data.reduce((currentData, item) => {
    const {
      code,
      name,
      root,
      properties,
      permitions,
      my_flash,
      my_sync,
      locks,
      root_folders_cnt: rootFoldersCount,
      subject,
    } = item;

    const nextData = {
      id: code,
      root,
      name,
      type: 'cloud',
      icon: 'cloud',
      parentId,
      my_sync,
      locks,
      favorite: properties.favorite,
      permitions,
      my_flash,
      rootFoldersCount,
      subject,
    };

    currentData[code] = nextData;

    return currentData;
  }, accumulator);
};

export const getReducedFolderData = (data, parentId, accumulator = {}) => {
  return data.reduce((currentData, { code, name, ...otherInfo }) => {
    currentData[code] = {
      id: code,
      name,
      type: 'folder',
      icon: 'folder',
      parentId,
      ...otherInfo,
    };

    return currentData;
  }, accumulator);
};

export const getReducedProfileData = (data, accumulator = {}) => {
  return Object.entries(data).reduce((currentData, [code, user]) => {
    const { fio: name, flashs, write_access: writeAccess } = user;

    currentData[code] = {
      id: code,
      name,
      writeAccess,
      type: 'profile',
      icon: 'profile',
      parentId: 'storage',
    };

    return getReducedCloudData(flashs, code, currentData);
  }, accumulator);
};

export const handleReducerArray = (newData, oldData) => {
  return newData.reduce((prev, id) => {
    if (prev.includes(id)) {
      return prev;
    }
    return [...prev, id];
  }, oldData);
};

export const getChildrenToArray = children => {
  const otherChildren = children.reduce((prev, item) => {
    if (!item.children) {
      return prev;
    }

    return [...prev, ...getChildrenToArray(item.children, item.id)];
  }, []);

  return [
    ...children.map(item => ({
      code: item.id,
      name: item.label,
    })),
    ...otherChildren,
  ];
};