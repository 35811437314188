import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import {
  VIDEO_TYPE,
  checkIsImage,
  checkIsVideo,
} from 'shared/components/Preview/helpers';
import {
  FLASH,
  UNAUTH_PUBLICLINK,
  UNAUTH_PUBLICLINK_SUBFOLDER,
} from 'helpers/routes/folderTypes';
// import { LIGHT, DARK } from 'helpers/theme/type';

import { getLinkId } from './helpers';

const DEFAULT_SIZE_VIEW = 100;
const PBL_TYPES = [UNAUTH_PUBLICLINK, UNAUTH_PUBLICLINK_SUBFOLDER];

const PreviewContext = createContext();

const PreviewContextProvider = (props) => {
  const {
    children,

    // state
    preparedFiles, // информация по подготавливаемым файлам (готов/ошибка/в процессе)
    selectedFileId, // выделенный файл
    availableFilesIds, // = [selectedFileId, nextFileId, prevFileId];
    previewFilesData, // данные по файлам, которые подготовлены для предпросмотра(скачивания)
    parentFolderType, // информация для возврата (goBack())

    // dispatch
    openPreview,
    closePreview,
    preparePreviewFiles,
    downloadPreviewFile,
    setParentFolderType,
    unauthPreparePreviewFiles,
    unauthDownloadPreviewFile,
    downloadPreviewVideoFile,
  } = props;

  const [returnLink, setReturnLink] = useState(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const [previewFiles, setPreviewFiles] = useState({}); // готовые к предпросмотру файлы
  const [sizeView, setSizeView] = useState(DEFAULT_SIZE_VIEW);
  // const [theme, setTheme] = useState(LIGHT);

  // -------------
  // common
  // -------------

  const openFullScreen = useCallback(() => {
    setSizeView(DEFAULT_SIZE_VIEW);
    if (selectedFileId) {
      setFullScreen(true);
    }
  }, [
    setFullScreen,
    selectedFileId,
    setSizeView,
  ]);

  const closeFullScreen = useCallback(() => {
    setFullScreen(false);
    setSizeView(DEFAULT_SIZE_VIEW);
  }, [
    setFullScreen,
    setSizeView,
  ]);

  const emitOpenPreview = useCallback((
    fileIds,
    returnLink = null,
    parentFolderType = FLASH,
  ) => {
    // console.log('PreviewContext_emitOpenPreview', { fileIds, returnLink, parentFolderType });
    setReturnLink(returnLink);
    setParentFolderType(parentFolderType);

    if (fileIds.length > 0) {
      // openPreview(fileIds, returnLink);
      openPreview(fileIds);
    }
  }, [
    setReturnLink,
    setParentFolderType,
    openPreview,
  ]);

  const emitClosePreview = useCallback(() => {
    setReturnLink(null);
    setParentFolderType(null);
    setPreviewFiles({});
    closeFullScreen();
    closePreview();
  }, [
    closeFullScreen,
    setPreviewFiles,
    setReturnLink,
    setParentFolderType,
    closePreview,
  ]);

  // const changeTheme = useCallback(() => {
  //   setTheme((prevTheme) => (prevTheme === LIGHT ? DARK : LIGHT));
  // }, [
  //   setTheme,
  // ]);

  // -------------
  // prepare
  // -------------

  const getFilesToPrepare = useCallback(() => {
    const result = availableFilesIds
      .reduce((currentLoadingFiles, fileId) => {
        const preparedData = preparedFiles[fileId];
        /**
         * проверка, нужно ли отправлять на подготовку к загрузке файл
         * отправлять только при условии, если файл не был загружен
         */
        const shouldLoad = !preparedData;
        if (!shouldLoad) {
          return currentLoadingFiles;
        }

        const loadingFile = previewFilesData
          .find(fileData => fileData && fileData.id === fileId);

        if (!loadingFile) {
          return currentLoadingFiles;
        }

        const { id, preview_type: previewType } = loadingFile;

        currentLoadingFiles.push({
          file: id,
          type: 'preview',
          option: previewType,
        });

        return currentLoadingFiles;
      }, []);

    // console.log('getFilesForPrepare', { result, preparedFiles, previewFilesData });

    return result;
  }, [
    availableFilesIds,
    preparedFiles,
    previewFilesData,
  ]);

  const emitPrepareFiles = useCallback(() => {
    const loadingFiles = getFilesToPrepare();

    // console.log('emitPrepareFiles', { loadingFiles });

    if (loadingFiles.length === 0) {
      return;
    }

    if (PBL_TYPES.includes(parentFolderType)) {
      unauthPreparePreviewFiles({
        group: loadingFiles,
        linkId: getLinkId(returnLink),
      });
    } else {
      preparePreviewFiles({
        group: loadingFiles,
      });
    }
  }, [
    getFilesToPrepare,
    parentFolderType,
    preparePreviewFiles,
    unauthPreparePreviewFiles,
    returnLink,
  ]);

  // -------------
  // download
  // -------------

  const reducePreviewFilesData = useCallback(() => {
    const newPreviewFiles = availableFilesIds
      .reduce((availablePreviewFiles, currentFileId) => {
        const tmpAvaliableFiles = availablePreviewFiles;
        if (previewFiles[currentFileId]) {
          tmpAvaliableFiles[currentFileId] = previewFiles[currentFileId];
        }
        return tmpAvaliableFiles;
      }, {});

    setPreviewFiles(newPreviewFiles);
  }, [
    availableFilesIds,
    previewFiles,
  ]);

  const getDownloadFunc = useCallback((fileData) => {
    // eslint-disable-next-line no-console
    // console.log('getDownloadFunc', fileData);

    const { option } = fileData;

    if (option === VIDEO_TYPE) {
      // хак для эмуляции загрузки видео-файла
      // нужно, чтобы файл подготовился, но при этом не загружался заранее
      return async () => {
        downloadPreviewVideoFile(fileData);
        if (PBL_TYPES.includes(parentFolderType)) {
          return {
            ...fileData,
            linkId: getLinkId(returnLink),
          };
        }
        return { ...fileData };
      };
    }

    if (PBL_TYPES.includes(parentFolderType)) {
      const request = {
        ...fileData,
        linkId: getLinkId(returnLink),
      };
      return () => unauthDownloadPreviewFile(request);
    }

    return () => downloadPreviewFile(fileData);
  }, [
    parentFolderType,
    unauthDownloadPreviewFile,
    returnLink,
    downloadPreviewFile,
    downloadPreviewVideoFile,
  ]);

  const downloadFiles = useCallback((filesToDownload) => {
    // eslint-disable-next-line no-console
    // console.log('downloadFiles', filesToDownload);

    if (filesToDownload.length === 0) {
      return;
    }

    reducePreviewFilesData();

    filesToDownload.forEach((fileData) => {
      const { fileId } = fileData;

      const downloadFunction = getDownloadFunc(fileData);

      downloadFunction()
        .then((payload) => {
          // eslint-disable-next-line no-console
          // console.log('downloadFiles_payload', payload);
          if (payload.error) {
            return;
          }

          const isImage = checkIsImage(payload.type) || false;
          const isVideo = checkIsVideo(payload.option) || false;

          if (isImage || isVideo) {
            return setPreviewFiles(prevPreviewFiles => ({
              ...prevPreviewFiles,
              [fileId]: payload,
            }));
          }

          return new Response(payload)
            .arrayBuffer()
            .then((buffer) => {
              setPreviewFiles(prevPreviewFiles => ({
                ...prevPreviewFiles,
                [fileId]: new Uint8Array(buffer),
              }));
            });
        });
    });
  }, [
    reducePreviewFilesData,
    getDownloadFunc,
  ]);

  const getFilesToDownload = useCallback((readyFilesData) => {
    /**
     * поиск по готовым к скачиванию файлам информации для скачивания
     * и фильтрация файлов (скачивание только выбранного для
     * предпросмотра файла и двух соседних)
     */
    const result = readyFilesData
      .reduce((currentDownloadFilesData, fileIds) => {
        const { linkId, fileId } = fileIds;

        const previewFileData = previewFilesData
          .find(fileData => fileData && fileData.id === fileId);

        if (!previewFileData) {
          return currentDownloadFilesData;
        }

        currentDownloadFilesData.push({
          id: linkId,
          fileId,
          type: 'preview',
          option: previewFileData.preview_type,
        });

        return currentDownloadFilesData;
      }, []);

    // eslint-disable-next-line no-console
    // console.log('getFilesForDownload', { result, readyFilesData, previewFilesData });

    return result;
  }, [
    previewFilesData,
  ]);

  const emitDownloadPreviewFiles = useCallback(() => {
    /** Поиск готовых к скачиванию файлов
     *
     * readyFilesData - массив вида
     * [{fileId, linkId}]
     */
    const readyFiles = Object.values(preparedFiles)
      .reduce((currentReadyFilesData, preparedFileData) => {
        const {
          fileId,
          linkId,
          isReady,
          isFetching,
          isLoaded,
          isError,
        } = preparedFileData;

        if (isReady && !isFetching && !isLoaded && !isError) {
          currentReadyFilesData.push({
            fileId,
            linkId,
          });
        }

        return currentReadyFilesData;
      }, []);

    if (readyFiles.length === 0) {
      return;
    }

    // eslint-disable-next-line no-console
    // console.log('emitDownloadPreviewFiles', readyFiles);

    const filesToDownload = getFilesToDownload(readyFiles);

    if (filesToDownload.length === 0) {
      return;
    }

    downloadFiles(filesToDownload);
  }, [
    getFilesToDownload,
    downloadFiles,
    preparedFiles,
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    // console.log('preview_useEffect', preparedFiles);
    emitDownloadPreviewFiles();
  }, [
    emitDownloadPreviewFiles,
    preparedFiles,
  ]);

  const selectedFile = useMemo(() => previewFiles[selectedFileId], [
    previewFiles,
    selectedFileId,
  ]);

  // console.log('PreviewContextProvider', { props });

  const value = useMemo(() => ({
    returnLink,
    isFullScreen,
    openFullScreen,
    closeFullScreen,
    emitOpenPreview,
    emitClosePreview,
    emitPrepareFiles,
    selectedFile,
    sizeView,
    setSizeView,
    // theme,
    // changeTheme,
  }), [
    returnLink,
    isFullScreen,
    openFullScreen,
    closeFullScreen,
    emitOpenPreview,
    emitClosePreview,
    emitPrepareFiles,
    selectedFile,
    sizeView,
    setSizeView,
    // theme,
    // changeTheme,
  ]);

  return (
    <PreviewContext.Provider value={value}>
      {children}
    </PreviewContext.Provider>
  );
};

PreviewContextProvider.propTypes = {
  selectedFileId: PropTypes.string,
  openPreview: PropTypes.func,
  closePreview: PropTypes.func,
  preparePreviewFiles: PropTypes.func,
  downloadPreviewFile: PropTypes.func,
  children: PropTypes.any,
  availableFilesIds: PropTypes.arrayOf(PropTypes.string),
  previewFilesData: PropTypes.array,
  parentFolderType: PropTypes.string,
  unauthDownloadPreviewFile: PropTypes.func,
  setParentFolderType: PropTypes.func,
  unauthPreparePreviewFiles: PropTypes.func,
  preparedFiles: PropTypes.shape(),
  downloadPreviewVideoFile: PropTypes.func,
};

PreviewContextProvider.defaultProps = {
  selectedFileId: null,
  openPreview: () => { },
  closePreview: () => { },
  preparePreviewFiles: () => { },
  downloadPreviewFile: () => { },
  unauthDownloadPreviewFile: () => { },
  setParentFolderType: () => { },
  unauthPreparePreviewFiles: () => { },
  downloadPreviewVideoFile: () => { },
};

export { PreviewContext as default, PreviewContextProvider };

/**

emitPrepareFiles - вызывается компонентом - отправляет
  новые файлы на подготовку

  accumulateLoadingFiles - возвращает файлы на подготовку,
    если они не подготовлены

-----------------------------------------------------------

mobile/components/FileList/FileList.js
  emitOpenPreview

mobile/modules/PreviewModule/PreviewModule.js
  emitPrepareFiles,
  emitClosePreview,
  openFullScreen,
  isFullScreen,

mobile/modules/PreviewModule/components/Preview/Preview.js
  closeFullScreen,
  openFullScreen,
  isFullScreen,
  selectedFile,

mobile/modules/PreviewModule/components/Preview/components/ImagePreview.js
  isFullScreen,
  selectedFile,

-----------------------------------------------------------

web/components/Popover/Popover.js
  emitOpenPreview

web/modules/ContentControlPanel/buttons/PreviewButton.js
  emitOpenPreview

web/modules/FileManager/components/Stub/Stub.js
  returnLink

web/modules/Preview/Preview.js
  selectedFile,
  sizeView,
  isFullScreen,
  theme,

web/modules/Preview/components/CloseFullScreenButton/CloseFullScreenButton.js
  closeFullScreen

web/modules/Preview/components/PreviewContentControlPanel/
PreviewContentControlPanel.js
  sizeView,
  setSizeView,
  theme = LIGHT,
  changeTheme,

web/modules/Preview/components/PreviewContentHeader/PreviewContentHeader.js
  openFullScreen,
  returnLink,
  theme = LIGHT,

web/screens/PreviewScreen/PreviewScreen.js
  theme,
  emitPrepareFiles,
  setSizeView,
  emitClosePreview,
  isFullScreen,

*/
