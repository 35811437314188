import React from 'react';

const ArrowLeftSide = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.004"
      height="16.002"
      viewBox="0 0 18.004 16.002"
      {...props}
    >
      <path
        id="Union_5"
        data-name="Union 5"
        d="M-438.418,22473.58l5.59-5.578H-445v6.5h-2v-15h2v6.5h12.172l-5.584-5.588,1.41-1.41,8,8-8,8Z"
        transform="translate(447.003 -22459)"
      />
    </svg>
  );
};

export default ArrowLeftSide;
