import React from 'react';

const OfficeNew = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      id="office_new"
      // fill="#8E8B92"
      d="M2 2v15.555h6.666V4.223h4.445V2H2zm2.223 2.223h2.222v2.222H4.222V4.222zm6.666 2.222V22H22V6.445H10.889zM4.223 8.666h2.222v2.223H4.222V8.666zm8.888 0h2.223v2.223H13.11V8.666zm4.445 0h2.223v2.223h-2.224l.001-2.223zM4.222 13.111h2.223v2.223H4.222V13.11zm8.89 0h2.222v2.223H13.11V13.11zm4.444 0h2.223v2.223h-2.224l.001-2.223zm-4.445 4.445h2.223v2.223H13.11v-2.223zm4.445 0h2.223v2.223h-2.224l.001-2.223z"
    />
  </svg>
);

export default OfficeNew;
