import React from 'react';

const Basket = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.6"
      height="12"
      viewBox="0 0 9.6 12"
      {...props}
    >
      <path
        id="trash"
        d="M7.6,2,7,2.6H4V3.8h.6v9A1.209,1.209,0,0,0,5.8,14h6A1.209,1.209,0,0,0,13,12.8v-9h.6V2.6h-3L10,2ZM5.8,3.8h6v9h-6ZM7,5v6.6H8.2V5ZM9.4,5v6.6h1.2V5Z"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};

export default Basket;
