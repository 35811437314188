import * as authTypes from './types';

const defaultState = {
  user: {},
  isAuthorized: false,
  isFetching: false,
};

// TODO: decompose for inner reducers with combineReducers
const ACTION_HANDLERS = {
  [authTypes.AUTHORIZE]: state => ({
    ...state,
    isAuthorized: true,
  }),
  [authTypes.DEAUTHORIZE]: state => ({
    ...state,
    isAuthorized: false,
  }),
  [authTypes.CHECK_USER]: state => ({
    ...state,
    isFetching: true,
  }),
  [authTypes.CHECK_USER_SUCCESS]: (state, { payload }) => ({
    ...state,
    user: payload.user,
    isUserLoaded: true,
    isFetching: false,
  }),
  [authTypes.CHECK_USER_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),
  [authTypes.LOGIN]: state => ({
    ...state,
    isFetching: true,
  }),
  [authTypes.LOGIN_SUCCESS]: (state, { payload }) => {
    if (!payload || !payload.user) {
      return {
        ...state,
        isFetching: false,
      };
    }

    return {
      ...state,
      user: payload.user,
      isFetching: false,
    };
  },
  [authTypes.LOGIN_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),
  [authTypes.LOGOUT]: state => ({
    ...state,
    isFetching: true,
  }),
  [authTypes.LOGOUT_ALL]: state => ({
    ...state,
    isFetching: true,
  }),
  [authTypes.AUTO_LOGIN_SUCCESS]: () => defaultState,
  [authTypes.AUTO_LOGIN_FAIL]: () => defaultState,
  [authTypes.LOGOUT_SUCCESS]: () => defaultState,
  [authTypes.AUTO_LOGIN_SUCCESS]: () => defaultState,
  [authTypes.LOGOUT_FAIL]: () => defaultState,
  // [authTypes.LOGOUT_ALL_SUCCESS]: () => defaultState,
  // [authTypes.LOGOUT_ALL_FAIL]: () => defaultState,
  [authTypes.AUTO_LOGIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    user: payload.user,
    isFetching: false,
  }),
  [authTypes.CLEAR_USER]: state => ({ ...state, user: {} }),
  [authTypes.LOGIN_INSTANCE]: state => ({ ...state, isFetching: true }),
  [authTypes.LOGIN_INSTANCE_SUCCESS]: state => ({ ...state, isFetching: false }),
  [authTypes.LOGIN_INSTANCE_FAIL]: state => ({ ...state, isFetching: false }),
};

export default (state = defaultState, action) => (
  ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state
);
