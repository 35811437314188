import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';

import { LIGHT, DARK } from 'helpers/theme/type';

import {
  defaultPartialTheme,
  loadTheme,
  initialCustomTheme,
  normalizeCustomTheme,
} from './helpers';

const withTheme = Component => (props) => {
  const [type, setType] = useState(LIGHT);
  const [customTheme, setCustomTheme] = useState(initialCustomTheme);

  const toggleType = useCallback(() => {
    setType(prevType => (prevType === LIGHT ? DARK : LIGHT));
  }, [setType]);

  const theme = useMemo(() => createTheme({
    ...defaultPartialTheme,
    custom: normalizeCustomTheme(customTheme),
    isMobile: isMobileOnly,
    type,
    toggleType,
  }), [
    customTheme,
    type,
    toggleType,
  ]);

  useEffect(() => {
    const load = async () => {
      const result = await loadTheme();
      setCustomTheme(result);
    };
    load();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withTheme;

/**

import ThemeContext from 'contexts/ThemeContext';
const value = useMemo(() => ({
  type,
  toggleType,
}), [
  type,
  toggleType,
]);

return (
  <ThemeContext.Provider value={value}>
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  </ThemeContext.Provider>
);

*/
