import * as notificationTypes from 'services/apiService/notificationTypes';

const defaultNotifications = {
  success: notificationTypes.COMMAND_SET_REQUEST_SUCCESS,
};

const clientRoute = {
  pushClientCommand: ({ notifications, ...params }) => ({
    url: '/command/set',
    method: 'PUT',
    data: params,
    notifications: notifications || defaultNotifications,
  }),

  /** новая версия отправки команд */
  setClientCommand: ({
    command,
    notifications = defaultNotifications,
    ...params
  }) => ({
    url: `/command/set/${command}`,
    method: 'PUT',
    // DEBUG
    // url: '/auth',
    // method: 'POST',
    data: params,
    notifications,
  }),
};

export default clientRoute;
