import React from 'react';

const Sync = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      viewBox="0 0 9 12"
      {...props}
    >
      <path
        id="sync"
        d="M7.5,0l-2,2,2,2V2.5a3.491,3.491,0,0,1,3.266,4.748L11.523,8A4.491,4.491,0,0,0,7.5,1.5ZM3.477,4A4.491,4.491,0,0,0,7.5,10.5V12l2-2-2-2V9.5A3.491,3.491,0,0,1,4.234,4.752Z"
        transform="translate(-3)"
      />
    </svg>
  );
};

export default Sync;
