import * as notificationTypes from 'services/apiService/notificationTypes';

const shareRoute = {
  getUnauthPublicLinks: (params) => {
    const { uuid } = params;
    return {
      url: `/public/${uuid}`,
      method: 'POST',
      data: params,
    };
  },

  getUnauthPublicLinksSubFolder: (params) => {
    const { uuid, shareId } = params;
    return {
      url: `/public/${uuid}/${shareId}`,
      method: 'POST',
      data: params,
    };
  },

  deleteUnauthLink: (params) => {
    const { uuid } = params;
    return {
      url: `/public/delete/${uuid}`,
      method: 'DELETE',
      data: params,
      notifications: {
        success: notificationTypes.PUBLIC_LINK_DELETE_REQUEST_SUCCESS,
        omitErrorNotification: true,
      },
    };
  },

  getLinkInfo: (params) => {
    const { uuid } = params;
    return {
      url: `public/info/${uuid}`,
      method: 'POST',
      data: params,
    };
  },
};

export default shareRoute;
