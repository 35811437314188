import React from 'react';

const Access = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14.4"
      viewBox="0 0 16 14.4"
      {...props}
    >
      <path
        id="icons8-access"
        d="M5.2,3a3.192,3.192,0,0,0-.8,6.284V17.4h4V15.8H6V14.2H7.6V12.6H6V9.284A3.192,3.192,0,0,0,5.2,3Zm0,1.6A1.6,1.6,0,1,1,3.6,6.2,1.588,1.588,0,0,1,5.2,4.6Zm4.8.8v4h8v-4ZM10,11v4h8V11Z"
        transform="translate(-2 -3)"
      />
    </svg>
  );
};

export default Access;
