import * as authTypes from 'store/reducers/auth/types';

import types from './constants';

const initialState = {
  currentLayout: 'first_enter_invite',
};
const ACTION_HANDLERS = {
  [types.SET_ENTRANCE_LAYOUT]: (state, { payload }) => {
    return { ...state, currentLayout: payload };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
  // [authTypes.LOGOUT_ALL_SUCCESS]: () => initialState,
  // [authTypes.LOGOUT_ALL_FAIL]: () => initialState,
};

export const entranceReducer = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};
