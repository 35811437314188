import React from 'react';

const ImageSvg = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113"
      height="113"
      viewBox="0 0 113 113"
      {...props}
    >
      <path
        id="image"
        d="M116,116V3H3V116ZM37.528,68.917l15.694,18.9L75.194,59.5l28.25,37.667H15.556Z"
        transform="translate(-3 -3)"
        opacity="0.1"
      />
    </svg>
  );
};

export default ImageSvg;
