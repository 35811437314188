import React from 'react';

const Recover = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.545"
    height="16"
    viewBox="0 0 14.545 16"
    {...props}
  >
    <path
      id="recover"
      d="M5.455,2A1.456,1.456,0,0,0,4,3.455V15.091a1.456,1.456,0,0,0,1.455,1.455H9.148a5.759,5.759,0,0,1-.594-1.455h-3.1V3.455h5.091V7.091h3.636v.727a5.812,5.812,0,0,1,1.455.19V6.364L11.273,2Zm8.727,7.273a4.356,4.356,0,0,0-3.423,7.06l-.94.94h2.909V14.364l-.933.933c-.012-.017-.025-.033-.037-.05a2.892,2.892,0,0,1-.484-1.611,2.952,2.952,0,0,1,.058-.588,2.91,2.91,0,1,1,2.851,3.5V18a4.364,4.364,0,0,0,0-8.727Z"
      transform="translate(-4 -2)"
    />
  </svg>
);

export default Recover;
