import * as notificationTypes from 'services/apiService/notificationTypes';

const generalRoute = {
  pooling: (params) => {
    const { uuid } = params;
    return {
      url: `/public/pooling/${uuid}`,
      method: 'GET',
      notifications: {
        success: notificationTypes.POOL_EVENT_SUCCESS,
      },
    };
  },
};

export default generalRoute;
