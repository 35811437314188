import { createSelector } from 'reselect';

export const selectShare = ({ share }) => share;

export const selectLinkById = ({ share }, id) => {
  return share.byId.data[id];
};

const selectIds = (state, ids) => ids;

const selectId = (state, id) => id;

export const selectLinkByUuid = createSelector(
  selectShare,
  selectId,
  (share, id) => {
    return Object.values(share.byId.data).find(
      shareItem => shareItem.id === id,
    );
  },
);

export const selectReceivedSubfolderById = ({ flashes }, id) => {
  return flashes.byId.data[id];
};

export const selectLinksByIds = createSelector(
  selectShare,
  selectIds,
  (share, ids) => {
    return ids.map(flashId => share.byId.data[flashId]);
  },
);

const getGroupData = ({ share }, groupKey) => share.byId.data[groupKey] || {};
const getShareIds = ({ share }, groupKey) =>
  share.byFilters.data[groupKey] || [];

export const getLinksByGroupKey = createSelector(
  selectShare,
  getGroupData,
  getShareIds,
  (share, groupData, shareIds) => {
    const groupDataItems = shareIds.map(flashId => {
      return share.byId.data[flashId];
    });
    return { ...groupData, items: groupDataItems };
  },
);

export const getFolderControls = ({ share }) => {
  return share.byId.folderControls;
};

export const getSubFolderControls = ({ share }) => {
  return share.byId.subFolderControls;
};

export const selectSubFolderSource = ({ share }) => {
  return share.byId.source;
};

export const selectOutboxLinksLength = ({ share }) => {
  return share.byFilters.data.sent.length;
};

export const selectInboxLinksLength = ({ share }) => {
  return share.byFilters.data.sent.length;
};

export const selectPublicLinksLength = ({ share }) => {
  return share.byFilters.data.publiclink.length;
};

export const selectCount = ({ share }) => {
  return share.page.count;
};

export const selectIsFetching = ({ share }) => {
  return share.page.isFetching;
};
