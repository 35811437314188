import React from 'react';

const BackNav = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.88"
      height="16"
      viewBox="0 0 9.88 16"
      {...props}
    >
      <path
        id="back"
        d="M18.08,7.88,16.2,6l-8,8,8,8,1.88-1.88L11.973,14Z"
        transform="translate(-8.2 -6)"
      />
    </svg>
  );
};

export default BackNav;
