export const languageConst = {
  ru: 'ru',
  en: 'en',
};

export const acceptLanguageHeaders = {
  [languageConst.ru]: 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7',
  [languageConst.en]: 'en-US,en;q=0.9,en-US;q=0.8,en;q=0.7',
};

export const getAcceptLanguageHeader = code => acceptLanguageHeaders[code] || acceptLanguageHeaders[languageConst.en];
