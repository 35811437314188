import * as authTypes from 'store/reducers/auth/types';
import { POLLING_CANCEL_MESSAGE } from 'helpers/constants/polling';
import * as eventsTypes from './types';

const initialState = {
  outspace: false,
  client: false,
  isFetching: false,
  isUnauthFetching: false,
  isError: false,
  mobile: false,
};

const ACTION_HANDLERS = {
  [eventsTypes.POOL_DATA]: (state) => ({
    ...state,
    isFetching: true,
    isError: false,
  }),
  [eventsTypes.POOL_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    outspace: payload.outspace || false,
    client: payload.client || false,
    // client: true, // DEBUG ONLY
  }),
  [eventsTypes.POOL_DATA_FAIL]: (state, { error }) => {
    const newState = {
      ...state,
      isFetching: false,
      isError: true,
    };
    const { message } = error;
    // отмена запроса ошибкой не считается
    if (message === POLLING_CANCEL_MESSAGE) {
      newState.isError = false;
    }
    return newState;
  },
  [eventsTypes.GET_CLIENT_STATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    client: payload.client || false,
    mobile: payload.mobile,
    // mobile: true,  // DEBUG ONLY
    // client: true,  // DEBUG ONLY
  }),
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
  [authTypes.AUTHORIZE]: (state) => ({
    ...state,
    isError: false,
  }),
  // [authTypes.LOGOUT_ALL_SUCCESS]: () => initialState,
  // [authTypes.LOGOUT_ALL_FAIL]: () => initialState,
  [eventsTypes.UNAUTH_POOL_DATA]: (state) => ({
    ...state,
    isFetching: true,
    isError: false,
  }),
  [eventsTypes.UNAUTH_POOL_DATA_SUCCESS]: (state) => ({
    ...state,
  }),
  [eventsTypes.UNAUTH_POOL_DATA_FAIL]: (state, { error }) => {
    const newState = {
      ...state,
      isFetching: false,
      isError: true,
    };
    const { message } = error;
    // отмена запроса ошибкой не считается
    if (message === POLLING_CANCEL_MESSAGE) {
      newState.isError = false;
    }
    return newState;
  },
};

const events = (state = initialState, action) => {
  const nextState = ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;

  return nextState;
};

export default events;
