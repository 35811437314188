import React from 'react';

const Documents = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 21 22.895"
      {...props}
    >
      <defs>
        <filter
          id="documents"
          x="0"
          y="0"
          width="21"
          height="22.895"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood floodColor="#d7baf9" floodOpacity="0.4" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#documents)">
        <path
          id="documents-2"
          data-name="documents"
          d="M4.263,1A1.267,1.267,0,0,0,3,2.263v8.842H4.263V2.263h7.579V1ZM6.789,3.526A1.267,1.267,0,0,0,5.526,4.789v8.842a1.267,1.267,0,0,0,1.263,1.263h6.947A1.267,1.267,0,0,0,15,13.632V6.684L11.842,3.526ZM8.053,8.579h4.421V9.842H8.053Zm0,2.526h4.421v1.263H8.053Z"
          transform="translate(1.5 3.5)"
        />
      </g>
    </svg>
  );
};

export default Documents;
