import React from 'react';

const About = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 5.6 14"
      {...props}
    >
      <path
        id="about"
        d="M10.8,2a2.1,2.1,0,1,0,2.1,2.1A2.1,2.1,0,0,0,10.8,2ZM8.7,7.6A.7.7,0,1,0,8.7,9h.7v5.6H8.7a.7.7,0,1,0,0,1.4h4.2a.7.7,0,1,0,0-1.4h-.7V8.3a.7.7,0,0,0-.7-.7H8.7Z"
        transform="translate(-8 -2)"
      />
    </svg>
  );
};

export default About;
