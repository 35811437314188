import { SUCCESS_NOTIFICATION } from 'services/apiService/notificationTypes';
import * as clientNotificationsTypes from './types';

export const pushNotification = notification => ({
  type: clientNotificationsTypes.PUSH_NOTIFICATION,
  notification,
});

export const clearNotifications = clearIds => ({
  type: clientNotificationsTypes.CLEAR_NOTIFICATIONS,
  clearIds,
});

export const pushSuccessNotification = (message) => {
  const notification = {
    id: Date.now(),
    type: SUCCESS_NOTIFICATION,
    data: { message },
  };
  return pushNotification(notification);
};
