import React from 'react';

const ReceivedFolder = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.335"
    height="20.146"
    viewBox="0 0 25.335 20.146"
    {...props}
  >
    <g id="folder_received" transform="translate(-18.502 -39.5)">
      <path
        id="Subtraction_19"
        data-name="Subtraction 19"
        d="M18285.016,18957.646h-9a2.52,2.52,0,0,1-2.514-2.518v-15.111a2.52,2.52,0,0,1,2.514-2.518h7.555l2.523,2.518h10.068a2.525,2.525,0,0,1,2.523,2.52v2.578a12.119,12.119,0,0,0-1.686-.117c-.256,0-.529.008-.838.029v-2.49h-20.146v12.592h9.129a12.154,12.154,0,0,0-.146,1.871c0,.23.008.467.018.645Z"
        transform="translate(-18255 -18898)"
      />
      <path
        id="Назад"
        d="M15.2,7.332,13.868,6,8.2,11.668l5.668,5.668L15.2,16l-4.327-4.336Z"
        transform="translate(26.5 67.699) rotate(-90)"
        fill="#377dcb"
      />
    </g>
  </svg>
);

export default ReceivedFolder;
