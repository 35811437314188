export default {
  currentSettingLayout: 'common',
  userSettings: {
    login: '',
    fio: '',
    email: '',
    language: {},
    items_per_page: null,
    outlook: 0,
    roles: null,
    space: { used: 0 },
    properties: {},
  },
  common: null,
  isSettingsLoaded: false,
  isConfigLoaded: false,
  needResetSettings: false,
};
