import * as authTypes from 'store/reducers/auth/types';
import * as eventsTypes from 'store/reducers/events/types';
import flashTypes from 'store/reducers/flashes/constants';
import shareTypes from 'store/reducers/share/constants';
import trashTypes from 'store/reducers/trash/constants';
import directoriesTypes from 'store/reducers/directories/constants';
import * as filesTypes from 'store/reducers/files/constants';

import initialState from './initialState';

// TODO: rewrite all data spreading, if possible:
// нужно упростить по максимуму, перебор по итерациям
const ACTION_HANDLERS = {
  [flashTypes.REQUEST_FLASH_LIST_SUCCESS]: (state, { payload }) => {
    const data = payload;

    if (data.error) {
      return state;
    }

    const { created, favorite, available, ...otherState } = state;
    const newFavorite = (data.favorites || []).map(item => item.code);
    const newAvailable = Object.entries(data.sharedToMe || []).reduce(
      (prev, [key, item]) => {
        if (item.flashs && Boolean(item.flashs.length)) {
          prev.push(key);
          return prev;
        }

        return prev;
      },
      [],
    );

    return {
      favorite: newFavorite,
      created: (data.own || data).map(item => item.code),
      available: newAvailable,
      ...Object.entries(data.sharedToMe || []).reduce(
        (prev, [code, { flashs }]) => {
          prev[code] = flashs.map(item => item.code);
          return prev;
        },
        otherState,
      ),
    };
  },
  [flashTypes.REQUEST_FLASH_CREATE_SUCCESS]: (state, { payload, params }) => {
    const { uuid } = payload;
    const { favorite, user } = params;

    if (payload.error || !uuid) {
      return state;
    }

    const newState = {
      ...state,
      created: !user ? [...state.created, uuid] : state.created,
      favorite: favorite ? [...state.favorite, uuid] : state.favorite,
    };

    return newState;
  },
  [flashTypes.REQUEST_FLASH_EDIT_SUCCESS]: (state, { payload, params }) => {
    const { flash } = payload;

    if (payload.error || !(flash && flash.code)) {
      return state;
    }

    const getFavorite = flash => {
      if (flash.favorite) {
        if (state.favorite.includes(flash.code)) {
          return state.favorite;
        }

        return [...state.favorite, flash.code];
      }

      return state.favorite.filter(item => item !== flash.code);
    };

    const newState = {
      ...state,
      favorite: getFavorite(flash),
    };

    return newState;
  },
  [flashTypes.FLASH_DELETE_REQUEST_SUCCESS]: (state, { payload }) => {
    const { uuid } = payload;

    if (payload.error || !uuid) {
      return state;
    }

    const newState = {
      ...state.data,
      favorite: state.favorite.filter(id => id !== uuid),
      created: state.created.filter(id => id !== uuid),
    };

    return newState;
  },
  [flashTypes.REQUEST_FLASH_DIRECTION_LIST_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;

    if (data.error) {
      return state;
    }

    const { folders, files } = data;
    const initialReduceState =
      params.page && state[params.uuid] ? [...state[params.uuid]] : [];

    return {
      ...state,
      [params.uuid]: [...folders, ...files].reduce((prev, item) => {
        if (prev.includes(item.code)) return prev;

        prev.push(item.code);
        return prev;
      }, initialReduceState),
    };
  },
  [flashTypes.REQUEST_FLASH_SUBDIRECTION_LIST_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;

    if (data.error) {
      return state;
    }

    const { folders, files } = data;

    const folderId = params.subUuid;
    const folderIds = [
      ...folders.map(item => item.code),
      ...files.map(item => item.code),
    ];
    const initialReduceState =
      params.page && Array.isArray(state[folderId]) ? [...state[folderId]] : [];

    return {
      ...state,
      [folderId]: folderIds.reduce((prev, id) => {
        if (prev.includes(id)) {
          return prev;
        }
        return [...prev, id];
      }, initialReduceState),
    };
  },
  [directoriesTypes.REQUEST_DIRECTORY_CREATE_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    if (!payload.success || payload.error) {
      return state;
    }

    const { isFlash, flash, folder } = params;
    const uuid = payload.success;

    const parentId = isFlash ? flash : folder;

    return {
      ...state,
      [parentId]: [...(state[parentId] || []), uuid],
    };
  },

  [directoriesTypes.REQUEST_DIRECTORY_DELETE_SUCCESS]: (state, action) => {
    const { payload, params } = action;
    if (payload.success === false || payload.error) {
      return state;
    }

    if (!params.itemId && !params.items.length) {
      return state;
    }

    // {itemId, items}
    const { items, itemId } = params;

    if (!itemId) {
      return state;
    }

    const newState = { ...state };

    newState[itemId] = (state[itemId] || []).filter(id => !items.includes(id));

    return newState;
  },

  [flashTypes.REQUEST_TRASH_LIST_SUCCESS]: (
    state,
    { payload, params = {} },
  ) => {
    const data = payload;

    if (data.error) {
      return state;
    }

    const { folders, files } = data;

    const initialReduceState = params.page && state.basket ? state.basket : [];

    return {
      ...state,
      basket: [...folders, ...files].reduce((prev, item) => {
        if (prev.includes(item.code)) return prev;

        prev.push(item.code);
        return prev;
      }, initialReduceState),
    };
  },
  [flashTypes.REQUEST_FLASH_DELETE_SUCCESS]: (state, { payload, params }) => {
    const data = payload;
    if (data && data.error) {
      return state;
    }

    return {
      ...state,
      created: state.created.filter(id => id !== params.uuid),
      favorite: state.favorite.filter(id => id !== params.uuid),
    };
  },
  [flashTypes.DELETE_ITEM]: (state, { params }) => {
    const { parentId, itemsIds } = params;
    const newState = { ...state };
    newState[parentId] = newState[parentId]
      ? newState[parentId].filter(id => !itemsIds.includes(id))
      : [];

    return newState;
  },

  [flashTypes.COPY_FLASH_SUCCESS]: (state, { payload, params }) => {
    const created = [...state.created];
    if (payload.flash) {
      created.push(payload.flash.code);
    }
    // created.push(params.uuid);

    return {
      ...state,
      created,
    };
  },

  [trashTypes.REQUEST_CLEAR_TRASH_SUCCESS]: state => {
    const newState = { ...state };
    newState.basket = [];

    return newState;
  },

  [shareTypes.REQUEST_GET_INBOX_LINKS_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload;

    const initialReduceState =
      params.page && state.received ? state.received : [];

    const received = data.list.map(item => item.code);

    return {
      ...state,
      received: [...initialReduceState, ...received],
    };
  },
  [shareTypes.REQUEST_GET_OUTBOX_LINKS_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload.list;

    const initialReduceState = params.page && state.sent ? state.sent : [];

    const sent = data.map(item => item.code);

    return {
      ...state,
      sent: [...initialReduceState, ...sent],
    };
  },
  [shareTypes.REQUEST_GET_PUBLIC_LINKS_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload.list;

    const initialReduceState =
      params.page && state.publiclink ? state.publiclink : [];

    const publiclink = data.map(item => item.elcnt);

    return {
      ...state,
      publiclink: [...initialReduceState, ...publiclink],
    };
  },

  [shareTypes.REQUEST_GET_LINKS_SUBFOLDER_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload.list;

    const initialReduceState =
      params.page && state.exchangeSubFolder ? state.exchangeSubFolder : [];

    const exchangeSubFolder = data.map(item => item.code);

    return {
      ...state,
      exchangeSubFolder: [...initialReduceState, ...exchangeSubFolder],
    };
  },
  [shareTypes.REQUEST_GET_OUTBOX_SUBFOLDER_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload.list;

    const initialReduceState =
      params.page && state.sentSubFolder ? state.sentSubFolder : [];

    const sentSubFolder = data.map(item => item.code);

    return {
      ...state,
      sentSubFolder: [...initialReduceState, ...sentSubFolder],
    };
  },
  [shareTypes.REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload.list;

    const initialReduceState =
      params.page && state.publiclinkSubFolder ? state.publiclinkSubFolder : [];

    const publiclinkSubFolder = data.map(item => item.code);

    return {
      ...state,
      publiclinkSubFolder: [...initialReduceState, ...publiclinkSubFolder],
    };
  },
  [shareTypes.REQUEST_GET_INBOX_SUBFOLDER_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const data = payload.list;

    const initialReduceState =
      params.page && state.receivedSubFolder ? state.receivedSubFolder : [];

    const receivedSubFolder = data.map(item => item.code);

    return {
      ...state,
      receivedSubFolder: [...initialReduceState, ...receivedSubFolder],
    };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
  [filesTypes.FILES_UPLOAD_SUCCESS]: (
    state,
    { payload, folderId, flashId, parentType, firstPath },
  ) => {
    const data = payload;

    if (data.error) {
      return state;
    }
    const correctSubfolderName = firstPath === 'pbl' ? 'publiclink' : firstPath;
    // TODO: отредактировать загрузку в зависимости от области загрузки
    const parentId =
      (parentType === 'folder' ? folderId : flashId) ||
      `${correctSubfolderName}SubFolder`;

    const nextData = data.reduce((prev, item) => {
      if (prev.includes(item.code)) return prev;

      prev.push(item.code);
      return prev;
    }, state[parentId] || []);

    return {
      ...state,
      [parentId]: nextData,
    };
  },
  [flashTypes.UPDATE_FLASH_PROPERTY_SUCCESS]: (state, { payload, params }) => {
    const data = payload;

    if ((data && data.error) || !params.uuid) {
      return state;
    }

    const newData = {
      favorite: state.favorite,
      created: state.created,
    };

    if (params.value) {
      newData.favorite.push(params.uuid);
    }

    if (!params.value) {
      newData.favorite = newData.favorite.filter(id => id !== params.uuid);
    }

    return {
      ...state,
      ...newData,
    };
  },
  [eventsTypes.POOL_DATA_SUCCESS]: (state, { payload }) => {
    const { drives, folder: folders, files } = payload;

    if (
      (!drives || drives.length === 0) &&
      (!folders || folders.length === 0) &&
      (!files || files.length === 0)
    ) {
      return state;
    }

    const updatedState = drives.reduce(
      (currentByFilters, flash) => {
        const { code, my_flash: myFlash, owner, favorite } = flash;

        if (favorite && !currentByFilters.favorite.includes(code)) {
          currentByFilters.favorite.push(code);
        }

        if (myFlash && !currentByFilters.created.includes(code)) {
          currentByFilters.created.push(code);
        }

        if (!myFlash) {
          const { id: ownerId } = owner;
          if (!currentByFilters.available.includes(ownerId)) {
            currentByFilters.available.push(ownerId);
          }

          if (
            !currentByFilters[ownerId] ||
            !currentByFilters[ownerId].includes(code)
          ) {
            const flashesByOwnerId = currentByFilters[ownerId]
              ? [...currentByFilters[ownerId], code]
              : [code];

            currentByFilters[ownerId] = flashesByOwnerId;
          }
        }

        return currentByFilters;
      },
      {
        ...state,
        created: [...state.created],
        favorite: [...state.favorite],
        available: [...state.available],
      },
    );

    const updatedWithFolders = folders.reduce((currentNextState, folder) => {
      const {
        parent_folder_uuid: parentFolderId,
        flash_uuid: flashId,
        code,
        in_root: inRoot,
      } = folder;
      const parentId = inRoot ? flashId : parentFolderId;

      if (
        !currentNextState[parentId] ||
        !currentNextState[parentId].includes(code)
      ) {
        const foldersByParentId = currentNextState[parentId]
          ? [...currentNextState[parentId], code]
          : [code];

        currentNextState[parentId] = foldersByParentId;
      }

      return currentNextState;
    }, updatedState);

    const nextState = files.reduce((currentNextState, file) => {
      const {
        parent_folder_uuid: parentFolderId,
        flash_uuid: flashId,
        code,
        in_root: inRoot,
      } = file;
      const parentId = inRoot ? flashId : parentFolderId;

      if (
        !currentNextState[parentId] ||
        !currentNextState[parentId].includes(code)
      ) {
        const filesByParentId = currentNextState[parentId]
          ? [...currentNextState[parentId], code]
          : [code];

        currentNextState[parentId] = filesByParentId;
      }

      return currentNextState;
    }, updatedWithFolders);

    return nextState;
  },
};

export const byFilters = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byFilters;
