import React from 'react';

const CopyGroup = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="copy"
        d="M3.6,2A1.6,1.6,0,0,0,2,3.6V14.8H3.6V3.6H14.8V2ZM6.8,5.2A1.6,1.6,0,0,0,5.2,6.8v9.6A1.6,1.6,0,0,0,6.8,18h9.6A1.6,1.6,0,0,0,18,16.4V6.8a1.6,1.6,0,0,0-1.6-1.6Zm0,1.6h9.6v9.6H6.8Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export default CopyGroup;
