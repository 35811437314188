import React from 'react';

const OutlinedCloud = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="42.667"
    viewBox="0 0 64 42.667"
    {...props}
  >
    <path
      id="cloud"
      d="M51.6,20.107a19.98,19.98,0,0,0-37.333-5.333A15.993,15.993,0,0,0,16,46.667H50.667a13.3,13.3,0,0,0,.933-26.56Zm-.933,21.227H16A10.667,10.667,0,0,1,16,20h1.893a14.664,14.664,0,0,1,28.773,4v1.333h4a8,8,0,1,1,0,16Z"
      transform="translate(0 -4)"
    />
  </svg>
);

export default OutlinedCloud;
