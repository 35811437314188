import React from 'react';

const Cloud = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.565"
      height="21.71"
      viewBox="0 0 32.565 21.71"
      {...props}
    >
      <path
        id="cloud_fill"
        d="M26.256,12.2a10.167,10.167,0,0,0-19-2.714A8.138,8.138,0,0,0,8.141,25.71h17.64A6.765,6.765,0,0,0,26.256,12.2Z"
        transform="translate(0 -4)"
      />
    </svg>
  );
};

export default Cloud;
