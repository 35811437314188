import React from 'react';

const ViewMore = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8.909"
      viewBox="0 0 14 8.909"
      {...props}
    >
      <path
        id="Path_11"
        data-name="Path 11"
        d="M10.864,10.091A7.628,7.628,0,0,0,8,10.727a7.507,7.507,0,0,0-2.864-.636C3.755,10.091,1,10.778,1,12.159v1.75H15v-1.75C15,10.778,12.245,10.091,10.864,10.091ZM8.318,12.955H1.955v-.8c0-.344,1.629-1.114,3.182-1.114s3.182.77,3.182,1.114Zm5.727,0H9.273v-.8a1.158,1.158,0,0,0-.331-.776,6.139,6.139,0,0,1,1.922-.337c1.553,0,3.182.77,3.182,1.114Zm-8.909-3.5A2.227,2.227,0,1,0,2.909,7.227,2.23,2.23,0,0,0,5.136,9.455Zm0-3.5A1.273,1.273,0,1,1,3.864,7.227,1.276,1.276,0,0,1,5.136,5.955Zm5.727,3.5A2.227,2.227,0,1,0,8.636,7.227,2.23,2.23,0,0,0,10.864,9.455Zm0-3.5A1.273,1.273,0,1,1,9.591,7.227,1.276,1.276,0,0,1,10.864,5.955Z"
        transform="translate(-1 -5)"
      />
    </svg>
  );
};

export default ViewMore;
