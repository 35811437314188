import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

const StorageRoutingContext = createContext();

const StorageRoutingState = ({ children }) => {
  const [activeFlashesIds, setActiveFlashesIds] = useState([]);
  const [activeRenameIds, setActiveRenameIds] = useState([]);
  const [folderControls, setFolderControls] = useState({});
  const [deviceStatus, setDeviceStatus] = useState(false);
  const [customOnBlur, setCustomOnBlur] = useState({ onBlur: null });
  // for pagination
  const [page, setPage] = useState({});

  const handleSetPage = useCallback(
    (count, id) => {
      if (!id || (!count && count !== 0)) {
        return;
      }
      setPage({
        [id]: count,
      });
    },
    [setPage],
  );

  const handleGetPage = useCallback(id => page[id] || 0, [page]);

  const resetActiveFlashesState = useCallback(() => {
    setActiveFlashesIds((activeFlashesIds) => {
      if (activeFlashesIds.length) {
        return [];
      }

      return activeFlashesIds;
    });
  }, []);

  const handleActiveRename = (ids, onBlur) => {
    if (onBlur && typeof onBlur === 'function') {
      setCustomOnBlur({ onBlur });
    }

    return setActiveRenameIds(ids);
  };

  const value = useMemo(() => ({
    activeFlashesIds,
    setActiveFlashesIds,
    resetActiveFlashesState,
    activeRenameIds,
    setActiveRenameIds: handleActiveRename,
    page,
    setPage: handleSetPage,
    getPage: handleGetPage,
    folderControls,
    setFolderControls,
    deviceStatus,
    setDeviceStatus,
    customOnBlur,
    setCustomOnBlur,
  }), [
    activeFlashesIds,
    resetActiveFlashesState,
    activeRenameIds,
    page,
    handleSetPage,
    handleGetPage,
    folderControls,
    deviceStatus,
    customOnBlur,
  ]);

  return (
    <StorageRoutingContext.Provider value={value}>
      {children}
    </StorageRoutingContext.Provider>
  );
};

StorageRoutingState.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};

StorageRoutingState.defaultProps = {
  children: [],
};

export { StorageRoutingContext as default, StorageRoutingState };
