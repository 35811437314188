import React from 'react';

const Preview = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      {...props}
    >
      <g id="radiobutton_empty" fill="#f8f8f8" stroke="#dadada" strokeWidth="1">
        <rect width="19" height="19" rx="9.5" stroke="none" />
        <rect x="0.5" y="0.5" width="18" height="18" rx="9" fill="none" />
      </g>
    </svg>
  );
};

export default Preview;
