const settingsRoute = {
  getCommonSettings: () => ({
    url: '/config',
    // DEBUG
    // url: '/config?twofa=1',
    method: 'GET',
  }),

  getUserSettings: () => ({
    url: '/user',
    method: 'GET',
  }),

  updateUserSettings: params => ({
    url: '/user/update',
    method: 'POST',
    data: params,
  }),

  getTheme: () => ({
    baseURL: process.env.PUBLIC_URL,
    // url: '/styles/styles.json',
    url: '/styles/design-tokens.json',
    method: 'GET',
  }),
};

export default settingsRoute;
