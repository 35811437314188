import React from 'react';

const Help = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      id="help"
      d="M9,2a7,7,0,1,0,7,7A7.011,7.011,0,0,0,9,2ZM9,3.4A5.6,5.6,0,1,1,3.4,9,5.589,5.589,0,0,1,9,3.4ZM9,4.8A2.8,2.8,0,0,0,6.2,7.6H7.6a1.4,1.4,0,1,1,2.8,0c0,1.4-2.1,1.657-2.1,3.5H9.7c0-1.156,2.1-1.75,2.1-3.5A2.8,2.8,0,0,0,9,4.8Zm-.7,7v1.4H9.7V11.8Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default Help;
