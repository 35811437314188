import React from 'react';

const SelectNone = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    {...props}
  >
    <path
      id="select_none"
      d="M4.591,4A.591.591,0,0,0,4,4.591V6.364H5.182V5.182H6.364V4ZM7.545,4V5.182H9.909V4Zm3.545,0V5.182h2.364V4Zm3.545,0V5.182h1.182V6.364H17V4.591A.591.591,0,0,0,16.409,4ZM4,7.545V9.909H5.182V7.545Zm11.818,0V9.909H17V7.545ZM4,11.091v2.364H5.182V11.091Zm11.818,0v2.364H17V11.091ZM4,14.636v1.773A.591.591,0,0,0,4.591,17H6.364V15.818H5.182V14.636Zm11.818,0v1.182H14.636V17h1.773A.591.591,0,0,0,17,16.409V14.636ZM7.545,15.818V17H9.909V15.818Zm3.545,0V17h2.364V15.818Z"
      transform="translate(-4 -4)"
    />
  </svg>
);

export default SelectNone;
