import { createSelector } from 'reselect';

export const getLayout = ({ settings }) => settings.currentSettingLayout;

export const getNeedResetSettings = ({ settings }) => settings.needResetSettings;

export const selectUserSettings = ({ settings }) => settings.userSettings;

export const selectCommonSettings = ({ settings }) => settings.common;

export const getActivationCodes = ({ settings }) => ({
  pc:
    settings
    && settings.common
    && settings.common.private
    && settings.common.private.activation,
  mobile:
    settings
    && settings.common
    && settings.common.private
    && settings.common.private.activation,
});

export const getUserQuota = ({ settings }) => (
  settings
  && settings.common
  && settings.common.private
  && settings.common.private.user
  && settings.common.private.user.space
  && settings.common.private.user.space.quota
);

export const getAppVersions = ({ settings }) => (
  settings
  && settings.common
  && settings.common.private
  && settings.common.private.client
);

export const getDomainsData = ({ settings }) => {
  const recievers = (settings
    && settings.common
    && settings.common.private
    && settings.common.private.links
    && settings.common.private.links.receivers)
    || {};
  return {
    isWhite: !!recievers.allowed,
    domainsList: recievers.domains,
    emailsList: recievers.emails,
    // DEBUG
    // emailsList: ['greg@yandex.ru'],
  };
};

/** получение массива инструкций для пользователя, который приходит
 * по запросу api_v2/config */
export const getUserDocumentation = ({ settings }) => (settings
  && settings.common
  && settings.common.private
  && settings.common.private.documentation
) || [];

const selectAllRoles = ({ settings }) => settings && settings.userSettings && settings.userSettings.roles;

export const getUserRoles = createSelector(selectAllRoles, (rolesData) => {
  const roles = Object.entries(rolesData || {}).reduce(
    (oldRoles, [key, value]) => {
      if (value) {
        oldRoles.push(key);
      }
      return oldRoles;
    },
    [],
  );
  return roles;
});

export const getIsUserAdmin = ({ settings }) => {
  const { common } = settings;

  if (!common || !common.private) {
    return false;
  }

  return false;
};

export const getAdminBlockLink = ({ settings }) => {
  const { common } = settings;

  if (!common) {
    return '';
  }

  const commonAdminLink = common.admin;

  if (commonAdminLink) {
    return commonAdminLink;
  }

  if (common.private) {
    const adminLink = common.private.user
      ? common.private.user.admin || ''
      : '';

    return adminLink;
  }

  return '';
};

export const selectUserItemsPerPage = ({ settings }) => settings.userSettings.items_per_page;

export const getAdditionalInputs = ({ settings }) => settings.userSettings.properties;

export const selectModeration = ({ settings }) => settings.common
  && settings.common.private
  && settings.common.private.links
  && settings.common.private.links.moderation;

export const selectActivationCode = ({ settings }) => settings.common
  && settings.common.private
  && settings.common.private.activation;

export const selectSupportEmail = ({ settings }) => (
  settings.common
  && settings.common.public
  && settings.common.public.support_email
);

export const selectUri = ({ settings }) => (
  settings.common
  && settings.common.public
  && settings.common.public.uri
);

export const selectPcId = ({ settings }) => (
  settings.common
  && settings.common.private
  && settings.common.private.pcid
) || null;

export const selectLogStatus = ({ settings }) => (
  settings.common
  && settings.common.private
  && settings.common.private.log
  && settings.common.private.log.status
) || 0;

export const selectLogo = ({ settings }) => settings.public && settings.public.logo;

export const selectUserSpace = ({ settings }) => settings.userSettings.space;

export const selectUserOutlook = ({ settings }) => settings.userSettings.outlook;

export const selectUserFullName = ({ settings }) => settings.userSettings.fio;

export const selectHas2fa = ({ settings }) => settings.common
  && settings.common.private
  && settings.common.private['2fa'];

export const getIsGuestUser = ({ settings }) => {
  const { roles } = settings.userSettings;

  return roles && roles.guest;
};

export const getIsSettingsLoaded = ({ settings }) => {
  const { isSettingsLoaded } = settings;

  return isSettingsLoaded;
};

export const getIsConfigLoaded = ({ settings }) => {
  const { isConfigLoaded } = settings;

  return isConfigLoaded;
};

export const getInterfaceToRedirect = ({ settings }) => (
  settings.common
  && settings.common.private
  && settings.common.private.interface
);

export const getReceivers = ({ settings }) => {
  const receivers = settings.common
    && settings.common.private
    && settings.common.private.links.guest
    && settings.common.private.links.guest.senders;

  return receivers || null;
};

export const getIsReplyAvailable = ({ settings }) => {
  const isReplyAvailable = settings.common
    && settings.common.private
    && settings.common.private.links.guest
    && settings.common.private.links.guest.reply;

  return isReplyAvailable || false;
};

export const getIsGuestDeleteAvailable = ({ settings }) => {
  const isGuestDeleteAvailable = settings.common
    && settings.common.private
    && settings.common.private.links.guest
    && settings.common.private.links.guest.delete;

  return isGuestDeleteAvailable || false;
};

export const getIsTwofaEnabled = ({ settings }) => {
  const twofaEnabled = settings.common && settings.common.public && settings.common.public.twofa;

  return !!twofaEnabled;
};

export const getIsSsoEnabled = ({ settings }) => {
  const isSsoEnabled = settings.common && settings.common.public && settings.common.public.sso;
  return isSsoEnabled;
};

export const selectDiskCleanUp = ({ settings }) => {
  const isDiskCleanUp = settings.userSettings && settings.userSettings.disk_cleanup;

  return isDiskCleanUp || false;
};

export const selectUserSettingsLanguage = ({ settings }) => settings.userSettings && settings.userSettings.language;

export const selectUserLanguage = ({ settings }) => (
  (settings.common
    && settings.common.private
    && settings.common.private.user
    && settings.common.private.user.language)
  || null
);

export const selectDefaultLanguage = ({ settings }) => (
  (settings.common
    && settings.common.public
    && settings.common.public.default_language)
  || {}
);

export const selectLanguagesArray = ({ settings }) => (
  (settings.common
    && settings.common.public
    && settings.common.public.languages)
  || []
);
export const selectLanguages = createSelector(
  selectLanguagesArray,
  languagesArray => languagesArray.reduce((prev, item) => {
    // eslint-disable-next-line no-param-reassign
    prev[item.code] = item;
    return prev;
  }, {}),
);

export const getSystemTitle = ({ settings }) => (
  (settings.common
    && settings.common.public
    && settings.common.public.title)
  || null
);

export const selectClientVersion = ({ settings }) => (
  (settings.common
    && settings.common.public
    && settings.common.public.version)
  || null
);

export const selectMessagesPeriod = ({ settings }) => (
  (settings.common
    && settings.common.private
    && settings.common.private.messages
    && settings.common.private.messages.period)
  || null
);

export const getLinksStatus = ({ settings }) => (
  settings.common
  && settings.common.private
  && settings.common.private.links
  && settings.common.private.links.status
);

/** получить типы ссылок для выпадающего списка в мобальном окне "Отправить" */
export const getLinkTypes = ({ settings }) => (
  settings.common
  && settings.common.private
  && settings.common.private.links
  && settings.common.private.links.type
) || [];

/** ограничение на кол-во скачиваний, устанавливаемое при отправке ссылки */
export const getLinksDownloadLimit = ({ settings }) => (
  settings.common
  && settings.common.private
  && settings.common.private.links
  && settings.common.private.links.download_limit
);

/** получение информации о ссылках на Logo из api_v2/config */
export const getLogoLinks = ({ settings }) => (settings
  && settings.common
  && settings.common.public
  && settings.common.public.logo
) || {};

export const selectInstances = ({ settings }) => (
  settings.common
  && settings.common.private
  && settings.common.private.instances
) || [];

export const selectUserProperties = createSelector(selectUserSettings, userSettings => (userSettings.properties || {}));
