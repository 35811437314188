import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

const ChangePasswordContext = createContext();

const changeSteps = ['validatePassword', 'validateTwofa'];
const MAX_SECONDS_DELAY = 10;

const ChangePasswordState = ({ children }) => {
  const [stepId, setStepId] = useState(0);
  const [changePasswordStep, setChangePasswordStep] = useState(
    changeSteps[stepId],
  );
  const [dataCache, setDataCache] = useState({});
  const [sendPinDelay, setSendPinDelay] = useState(0);

  useEffect(() => {
    setChangePasswordStep(changeSteps[stepId]);
  }, [changePasswordStep, stepId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (sendPinDelay === 0) {
        return;
      }
      setSendPinDelay(prev => prev - 1);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [sendPinDelay]);

  const startSendPinDelayCountdown = useCallback(() => {
    if (sendPinDelay === 0) {
      setSendPinDelay(MAX_SECONDS_DELAY);
    }
  }, [sendPinDelay]);

  const stepForward = useCallback(
    (data = {}) => {
      if (stepId < changeSteps.length - 1) {
        setDataCache(data);
        return setStepId(prev => prev + 1);
      }
    },
    [stepId, setDataCache],
  );
  const stepBackward = useCallback(() => {
    if (stepId > 0) {
      return setStepId(prev => prev - 1);
    }
  }, [stepId]);
  const value = useMemo(() => {
    return {
      changePasswordStep,
      setChangePasswordStep,
      stepForward,
      stepBackward,
      startSendPinDelayCountdown,
      sendPinDelay,
      setSendPinDelay,
      dataCache,
    };
  }, [
    dataCache,
    changePasswordStep,
    sendPinDelay,
    startSendPinDelayCountdown,
    stepBackward,
    stepForward,
  ]);

  return (
    <ChangePasswordContext.Provider value={value}>
      {children}
    </ChangePasswordContext.Provider>
  );
};

export { ChangePasswordContext as default, ChangePasswordState };
