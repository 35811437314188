import * as notificationTypes from 'services/apiService/notificationTypes';

const userRoute = {
  getLogInfo: () => ({
    url: '/auth/log',
    method: 'GET',
  }),

  getDevicesInfo: params => ({
    url: '/device',
    method: 'GET',
    params,
  }),

  allowDevice: (params) => {
    const { wui, status } = params;
    return {
      url: `/device/activity/${wui}`,
      method: 'POST',
      data: { status },
    };
  },

  clearDevice: (params) => {
    const { wui } = params;
    return {
      url: `/device/clear/${wui}`,
      method: 'POST',
      notifications: {
        success: notificationTypes.DEVICE_CLEARED_SUCCESS,
      },
    };
  },

  deleteDevice: (params) => {
    const { wui } = params;
    return {
      url: `/device/${wui}`,
      method: 'DELETE',
    };
  },

  renameDevice: (params) => {
    const { wui, name } = params;
    return {
      url: `/device/rename/${wui}`,
      method: 'POST',
      data: {
        name,
      },
    };
  },

  getUserShareInfo: params => ({
    url: '/user/share',
    method: 'GET',
    params,
  }),

  getUserSendInfo: params => ({
    url: '/user/send',
    method: 'GET',
    params,
  }),

  getMessages: params => ({
    url: '/messages',
    method: 'GET',
    params,
    notifications: {
      omitErrorNotification: true,
    },
  }),

  userInvite: data => ({
    url: '/user/invite',
    method: 'POST',
    data,
    notifications: {
      success: notificationTypes.USER_INVITE_REQUEST_SUCCESS,
      omitErrorNotification: true,
    },
  }),

  markMessages: params => ({
    url: '/messages/mark',
    method: 'POST',
    data: params,
  }),

  updateUserSettings: params => ({
    url: '/user/update',
    method: 'POST',
    data: params,
    notifications: {
      success: notificationTypes.SETTINGS_UPDATED_REQUEST_SUCCESS,
    },
  }),

  sendMessage: params => ({
    url: '/messages',
    method: 'PUT',
    data: params,
  }),

  getUserEventsLog: params => ({
    url: '/user/events_log',
    method: 'GET',
    params,
  }),
};

export default userRoute;
