import * as authTypes from 'store/reducers/auth/types';

import types from 'store/reducers/devices/constants';

const initialState = { devices: {} };

const ACTION_HANDLERS = {
  [types.GET_DEVICES_SUCCESS]: (state, { payload }) => {
    if (!payload.length) {
      return state;
    }

    const nextDevices = { ...state.devices };

    payload.forEach(item => {
      nextDevices[item.wui] = item;
      nextDevices[item.wui].id = item.wui;
    });

    return { ...state, devices: nextDevices };
  },
  [types.ALLOW_DEVICES_SUCCESS]: (state, { payload, params }) => {
    const newData = { ...state };
    const { wui } = params;

    newData.devices[wui].status = !newData.devices[wui].status;

    return { ...state };
  },
  [types.RENAME_DEVICE_SUCCESS]: (state, { payload, params }) => {
    const newData = { ...state };
    const { wui, name } = params;

    ((newData.devices || {})[wui] || {}).name = name;

    return { ...state };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

/* TODO: move all mocks to special file 'passwordChangeMock.js'
  DONE in reducers
*/

export const byId = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byId;
