import React from 'react';

const EmptyLinks = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113.002"
      height="113"
      viewBox="0 0 113.002 113"
      {...props}
    >
      <path
        id="Subtraction_66"
        data-name="Subtraction 66"
        d="M19130.5,13546a56.219,56.219,0,0,1-31.588-9.648,56.648,56.648,0,0,1-20.469-24.858,56.436,56.436,0,0,1,5.209-53.584,56.664,56.664,0,0,1,24.855-20.47,56.429,56.429,0,0,1,53.582,5.209,56.787,56.787,0,0,1,15.262,15.261,56.47,56.47,0,0,1-6.9,71.544,56.622,56.622,0,0,1-17.961,12.107A56.142,56.142,0,0,1,19130.5,13546Zm19.844-44.05h0l15.068,7.676,1.363-2.678-14.066-7.165a13.555,13.555,0,0,0,1.8-2.624,13.753,13.753,0,0,0-12.184-20.124h-11v5.5h11a8.237,8.237,0,0,1,6.424,13.406,8.329,8.329,0,0,1-1.2,1.214l-7.979-4.064v-5.056h-9.92l-10.791-5.5h6.963v-5.5h-11a13.644,13.644,0,0,0-4.953.92l-15.266-7.776-1.357,2.673,13.443,6.852-.006,0,6.068,3.093h.016l10.275,5.234h-.02l10.8,5.5h.018l10.373,5.282c-.01,0-.01,0-.014,0l6.148,3.135,0,0Zm-19.023-2.913h0v5.494h11a13.746,13.746,0,0,0,4.791-.857l-9.105-4.637Zm-26.977-17.152a13.744,13.744,0,0,0,10.473,22.646h11v-5.494h-11a8.242,8.242,0,0,1-5.33-14.533Zm13.229,6.735h0v4.912h9.635l-9.635-4.912Z"
        transform="translate(-19074 -13432.998)"
      />
    </svg>
  );
};

export default EmptyLinks;
