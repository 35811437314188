import React from 'react';

const EmptySearch = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113.9"
      height="135.804"
      viewBox="0 0 113.9 135.804"
      {...props}
    >
      <g id="empty_search" transform="translate(-1109.55 -286.598)">
        <path
          id="Path_452"
          data-name="Path 452"
          d="M71.809,63.552H67.46l-1.541-1.486a35.835,35.835,0,1,0-3.853,3.853l1.486,1.541v4.349L91.075,99.277l8.2-8.2Zm-33.028,0A24.771,24.771,0,1,1,63.552,38.781,24.738,24.738,0,0,1,38.781,63.552Z"
          transform="translate(1115.178 302.861)"
        />
        <line
          id="Line_88"
          data-name="Line 88"
          x2="101"
          y2="124"
          transform="matrix(0.996, -0.087, 0.087, 0.996, 1111.199, 296.098)"
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="2"
        />
        <line
          id="Line_69"
          data-name="Line 69"
          x2="101"
          y2="124"
          transform="matrix(0.996, -0.087, 0.087, 0.996, 1110.378, 298.177)"
          fill="none"
          stroke="#ececec"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default EmptySearch;
