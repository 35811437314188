/* eslint-disable react/prop-types */
import React, { createContext, useState, useMemo } from 'react';

const ModalParamsContext = createContext();

const ModalParamsProvider = ({ children }) => {
  const [modalParams, setModalParams] = useState(null);
  const value = useMemo(() => ({
    modalParams,
    setModalParams,
  }), [modalParams]);

  return (
    <ModalParamsContext.Provider value={value}>
      {children}
    </ModalParamsContext.Provider>
  );
};

export { ModalParamsContext as default, ModalParamsProvider };
