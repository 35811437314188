import React, { useState } from 'react';
import { getFilterUrl } from 'shared/elements/StyledSvg/helpers'
import { createGuid } from 'helpers/createGuid';

const FileManagerDOCX = props => {
  // Хак для того, чтобы компонент не терял id в Safari в момент ререндера svg
  const [intersectionId] = useState(createGuid());
  const [secondIntersectionId] = useState(createGuid());
  const intersectionFilter = getFilterUrl(intersectionId);
  const secondIntersectionFilter = getFilterUrl(secondIntersectionId);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="58.998"
      height="71.004"
      viewBox="0 0 58.998 71.004"
      {...props}
    >
      <defs>
        <filter
          id={intersectionId}
          x="0"
          y="0"
          width="58.961"
          height="71"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood floodOpacity="0.122" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id={secondIntersectionId}
          x="0"
          y="0.002"
          width="58.998"
          height="71.002"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-2" />
          <feFlood floodOpacity="0.122" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="fm_docx" transform="translate(-27.502 6.5)">
        <g
          transform="matrix(1, 0, 0, 1, 27.5, -6.5)"
          filter={intersectionFilter}
        >
          <path
            id="Intersection_4-2"
            data-name="Intersection 4"
            d="M5,56a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0h24.91L43.962,13.34V51.63A5,5,0,0,1,39,56Z"
            transform="translate(7.5 6.5)"
            fill="#788297"
          />
        </g>
        <g
          transform="matrix(1, 0, 0, 1, 27.5, -6.5)"
          filter={secondIntersectionFilter}
        >
          <path
            id="Intersection_3-2"
            data-name="Intersection 3"
            d="M22512,20626a5,5,0,0,1-5-5v-46a5,5,0,0,1,5-5h25.451l13.549,13.051V20621a5,5,0,0,1-5,5Z"
            transform="translate(-22499.5 -20563.5)"
            fill="#5176de"
          />
        </g>
        <path
          id="Path_440"
          data-name="Path 440"
          d="M39.32,14.82H29V4.5Z"
          transform="translate(37.347 -2.287)"
          fill="#c0d0de"
        />
        <text
          id="DOCX"
          transform="translate(57 38.418)"
          fill="#fefefe"
          fontSize="11"
          fontFamily="SegoeUI-Bold, Roboto Bold"
          fontWeight="700"
          letterSpacing="0.033em"
        >
          <tspan x="-15.806" y="0">
            DOCX
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default FileManagerDOCX;
