import React from 'react';

const Check = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.64"
      height="12.669"
      viewBox="0 0 16.64 12.669"
      {...props}
    >
      <path
        id="check"
        d="M8.695,15.622,4.724,11.651,3.4,12.974l5.295,5.295L20.04,6.924,18.716,5.6Z"
        transform="translate(-3.4 -5.6)"
      />
    </svg>
  );
};

export default Check;
