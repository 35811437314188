import React from 'react';

const InviteUser = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.456"
      height="15.873"
      viewBox="0 0 16.456 15.873"
      {...props}
    >
      <g id="add_contact" transform="translate(-326 -89)">
        <path
          id="Subtraction_9"
          data-name="Subtraction 9"
          d="M7.2,12.366H0V10.048c0-2.03,4.025-3.091,6.183-3.091a11.383,11.383,0,0,1,3.763.735A4.435,4.435,0,0,0,8.4,8.729a8.882,8.882,0,0,0-2.216-.3c-2.3,0-4.714,1.126-4.714,1.623V10.9H7.255A4.445,4.445,0,0,0,7.2,12.365ZM6.183,6.184A3.092,3.092,0,1,1,9.275,3.092,3.1,3.1,0,0,1,6.183,6.184Zm0-4.715A1.623,1.623,0,1,0,7.806,3.092,1.625,1.625,0,0,0,6.183,1.469Z"
          transform="translate(326 88.999)"
        />
        <path
          id="Path_365"
          data-name="Path 365"
          d="M13.181,9.675H9.675v3.506H8.506V9.675H5V8.506H8.506V5H9.675V8.506h3.506Z"
          transform="translate(329.275 91.692)"
        />
      </g>
    </svg>
  );
};

export default InviteUser;
