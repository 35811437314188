import container from './container';
import FilesTransferContext, {
  FilesTransferContextProvider,
} from './FilesTransferContext';

const WrappedFilesTransferContextProvider = container(FilesTransferContextProvider);

export {
  FilesTransferContext as default,
  WrappedFilesTransferContextProvider as FilesTransferContextProvider,
};
