import { screens } from 'mobile/helpers/screens';
import { FOLDER } from 'helpers/constants/subjects';
import { firstPathKeys } from 'routing/helpers';

export const FLASH = 'flash';
export const PUBLICLINK = 'publiclink';
export const UNAUTH_PUBLICLINK = 'unauthpubliclink';
export const BASKET = 'basket';
export const UNAUTH_PUBLICLINK_SUBFOLDER = 'unauthpubliclinkSubfolder';
export const SHARE = 'share';
export const SHARE_SENT = 'share_sent';
export const SHARE_RECEIVED = 'share_received';
export const SHARE_EXCHANGE = 'share_exchange';

export const STORAGE = 'storage';
export const PROFILE = 'profile';
export const SENT = 'sent';
export const EXCHANGE = 'exchange';
export const RECEIVED = 'received';

const parentFolderTypes = {
  disk: FLASH,
  basket: BASKET,
  share: SHARE,
  shareexchange: SHARE_EXCHANGE,
  sharereceived: SHARE_RECEIVED,
  sharesent: SHARE_SENT,
  publiclink: PUBLICLINK,
  unauthlink: UNAUTH_PUBLICLINK,
  unauthpubliclinkSubfolder: UNAUTH_PUBLICLINK_SUBFOLDER,
};

export const getMobileParentFolderType = (params) => {
  // console.log('getMobileParentFolderType', params);
  const {
    screen,
    currentTabName,
    currentDirectory = {},
  } = params;

  const {
    type,
    subject,
  } = currentDirectory;

  // признак того, что мы в подпапке в обмене файлов
  if (screen === screens.SHARE && subject === FOLDER) {
    return FLASH;
  }

  // признак того, что мы в подпапке в публичных ссылках
  if (screen === screens.UNAUTHLINK && type === FOLDER) {
    return UNAUTH_PUBLICLINK_SUBFOLDER;
  }

  return parentFolderTypes[`${screen}${currentTabName || ''}`];
};

export const getPopoverParentFolderType = (params) => {
  // console.log('getPopoverParentFolderType', params);
  const {
    firstPath,
    currentElement,
    isShareType,
  } = params;

  let result = 'flash';

  const isPublicLink = firstPath === 'publiclink';
  const isUnauthPublicLink = firstPath === 'pbl' && currentElement.parentId === 'unauthpubliclink';
  const isUnauthPublicLinkSubfolder = firstPath === 'pbl' && currentElement.parentId !== 'unauthpubliclink';
  if (
    currentElement
    && currentElement.parentId
    && currentElement.parentId === 'basket'
  ) {
    result = 'basket';
  } else if (isShareType) {
    result = `share_${firstPath}`;
  } else if (isPublicLink) {
    result = 'publiclink';
  } else if (isUnauthPublicLink) {
    result = 'unauthpubliclink';
  } else if (isUnauthPublicLinkSubfolder) {
    result = 'unauthpubliclinkSubfolder';
  }

  return result;
};

// FIXME: нужно отредактировать логику размещения данных
// в зависимости от блока в Redux и обновить selects
export const getButtonParentFolderType = (params) => {
  // console.log('getButtonParentFolderType', params);
  const {
    firstPath,
    currentFolder,
    shareId,
    isShare,
    isPublicLink,
    isUnauthPublicLink,
    isUnauthPublicLinkSubfolder,
  } = params;

  if (currentFolder.type === BASKET) {
    return BASKET;
  }

  if (isShare || firstPath === firstPathKeys.RECEIVED || firstPath === firstPathKeys.SENT) {
    return shareId ? FLASH : `${SHARE}_${firstPath}`;
  }

  if (isPublicLink) {
    return PUBLICLINK;
  }

  if (isUnauthPublicLink) {
    return UNAUTH_PUBLICLINK;
  }

  if (isUnauthPublicLinkSubfolder) {
    return UNAUTH_PUBLICLINK_SUBFOLDER;
  }

  return FLASH;
};
