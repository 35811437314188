export const POOL_DATA = 'POOL_DATA';
export const POOL_DATA_SUCCESS = 'POOL_DATA_SUCCESS';
export const POOL_DATA_FAIL = 'POOL_DATA_FAIL';

export const UNAUTH_POOL_DATA = 'UNAUTH_POOL_DATA';
export const UNAUTH_POOL_DATA_SUCCESS = 'UNAUTH_POOL_DATA_SUCCESS';
export const UNAUTH_POOL_DATA_FAIL = 'UNAUTH_POOL_DATA_FAIL';

export const GET_CLIENT_STATE = 'GET_CLIENT_STATE';
export const GET_CLIENT_STATE_SUCCESS = 'GET_CLIENT_STATE_SUCCESS';
export const GET_CLIENT_STATE_FAIL = 'GET_CLIENT_STATE_FAIL';
