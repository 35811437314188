import React from 'react';

const PreviewFullScreen = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11.52"
      viewBox="0 0 16 11.52"
      {...props}
    >
      <path
        id="fullscreen"
        d="M15.04,7H.96A.961.961,0,0,0,0,7.96v9.6a.961.961,0,0,0,.96.96H15.04a.961.961,0,0,0,.96-.96V7.96A.961.961,0,0,0,15.04,7ZM3.84,17.24H1.28V14.68a.32.32,0,1,1,.64,0V16.6H3.84a.32.32,0,0,1,0,.64Zm0-8.32H1.92v1.92a.32.32,0,1,1-.64,0V8.28H3.84a.32.32,0,1,1,0,.64Zm10.88,8.32H12.16a.32.32,0,0,1,0-.64h1.92V14.68a.32.32,0,0,1,.64,0Zm0-6.4a.32.32,0,1,1-.64,0V8.92H12.16a.32.32,0,0,1,0-.64h2.56Z"
        transform="translate(0 -7)"
        fill="#8e8b92"
      />
    </svg>
  );
};

export default PreviewFullScreen;
