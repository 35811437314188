import React from 'react';

const Share = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.8"
      height="12"
      viewBox="0 0 10.8 12"
      {...props}
    >
      <path
        id="share"
        d="M12,2a1.809,1.809,0,0,0-1.8,1.8,1.776,1.776,0,0,0,.034.335l-4.281,2.5A1.781,1.781,0,0,0,4.8,6.2a1.8,1.8,0,1,0,0,3.6,1.782,1.782,0,0,0,1.153-.432l4.281,2.5a1.778,1.778,0,0,0-.034.336A1.8,1.8,0,1,0,12,10.4a1.78,1.78,0,0,0-1.153.434l-4.281-2.5a1.67,1.67,0,0,0,0-.67l4.281-2.5A1.781,1.781,0,0,0,12,5.6,1.8,1.8,0,1,0,12,2Zm0,1.2a.6.6,0,1,1-.6.6A.591.591,0,0,1,12,3.2ZM4.8,7.4a.6.6,0,1,1-.6.6A.591.591,0,0,1,4.8,7.4ZM12,11.6a.6.6,0,1,1-.6.6A.591.591,0,0,1,12,11.6Z"
        transform="translate(-3 -2)"
      />
    </svg>
  );
};

export default Share;
