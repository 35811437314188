import * as notificationTypes from 'services/apiService/notificationTypes';

const actionsRoute = {
  deleteWebOfficeFileLock: (params) => {
    const { fileId } = params;
    return {
      url: `/web_office_file_lock/${fileId}`,
      method: 'DELETE',
      notifications: {
        request: notificationTypes.DELETE_WEB_OFFICE_FILE_LOCK,
        success: notificationTypes.DELETE_WEB_OFFICE_FILE_LOCK_SUCCESS,
        fail: notificationTypes.DELETE_WEB_OFFICE_FILE_LOCK_FAIL,
        omitErrorNotification: false,
      },
    };
  },

  getWebOfficeCheckMyLock: (params) => {
    const { fileId } = params;
    return {
      url: `/web_office_check_my_lock/${fileId}`,
      method: 'GET',
      notifications: {
        fail: notificationTypes.GET_WEB_OFFICE_CHECK_MY_LOCK_FAIL,
        omitErrorNotification: false,
      },
    };
  },

  getFileActionsFlags: (params) => {
    const { fileId } = params;
    return {
      url: `/action/file_flags/${fileId}`,
      method: 'GET',
      notifications: {
        fail: notificationTypes.REQUEST_ERROR,
      },
    };
  },

  webOfficeAccessToken: (params) => {
    const { flashId, fileId, action } = params;
    return {
      url: `/web_office_access_token/${flashId}/${fileId}/${action}`,
      method: 'POST',
      notifications: {
        fail: notificationTypes.GET_WEB_OFFICE_ACCESS_TOKEN_FAIL,
        omitErrorNotification: false,
      },
    };
  },
};

export default actionsRoute;
