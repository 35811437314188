export default {
  REQUEST_GET_TRASH: 'REQUEST_GET_TRASH',
  REQUEST_GET_TRASH_SUCCESS: 'REQUEST_GET_TRASH_SUCCESS',
  REQUEST_GET_TRASH_FAIL: 'REQUEST_GET_TRASH_FAIL',

  REQUEST_CLEAR_TRASH: 'REQUEST_CLEAR_TRASH',
  REQUEST_CLEAR_TRASH_SUCCESS: 'REQUEST_CLEAR_TRASH_SUCCESS',
  REQUEST_CLEAR_TRASH_FAIL: 'REQUEST_CLEAR_TRASH_FAIL',

  REQUEST_TRASH_DELETE_FILE: 'REQUEST_TRASH_DELETE_FILE_FILE',
  REQUEST_TRASH_DELETE_FILE_SUCCESS: 'REQUEST_TRASH_DELETE_FILE_SUCCESS',
  REQUEST_TRASH_DELETE_FILE_FAIL: 'REQUEST_TRASH_DELETE_FILE_FAIL',

  REQUEST_TRASH_RESTORE_FILE: 'REQUEST_TRASH_RESTORE_FILE',
  REQUEST_TRASH_RESTORE_FILE_SUCCESS: 'REQUEST_TRASH_RESTORE_FILE_SUCCESS',
  REQUEST_TRASH_RESTORE_FILE_FAIL: 'REQUEST_TRASH_RESTORE_FILE_FAIL',
};
