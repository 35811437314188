import { makeStyles } from '@material-ui/core';

export default makeStyles({
  loaderWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: ({ isHidden }) => (isHidden ? -100 : 0),
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: ({ isHidden, fullscreen }) => {
      if (isHidden) {
        return -1;
      }
      return fullscreen ? 1000 : 2;
    },
    opacity: ({ isHidden }) => (isHidden ? 0 : 1),
    visibility: ({ isHidden }) => (isHidden ? 'hidden' : 'visible'),
    transition: 'all 0.1s ease',
  },
});
