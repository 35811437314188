import * as notificationTypes from 'services/apiService/notificationTypes';

const actionsRoute = {
  create: params => ({
    url: '/action/create',
    method: 'POST',
    data: params,
    notifications: {
      success:
        params.type === 'file'
          ? notificationTypes.CREATE_FILE_REQUEST_SUCCESS
          : notificationTypes.CREATE_FOLDER_REQUEST_SUCCESS,
      omitErrorNotification: true,
    },
  }),

  copyOrMove: params => ({
    url: '/action/copy_move',
    method: 'POST',
    data: params,
    notifications: {
      success: params.copy
        ? notificationTypes.FILES_COPY_REQUEST_SUCCESS
        : notificationTypes.FILES_MOVE_REQUEST_SUCCESS,
      omitErrorNotification: true,
    },
  }),

  rename: params => ({
    url: '/action/rename',
    method: 'POST',
    data: params,
  }),

  delete: (params) => {
    const { type, ...otherParams } = params;
    const getType = (type) => {
      const types = {
        public: 'PUBLIC_LINK_DELETE_REQUEST_SUCCESS',
        share: 'SHARE_DELETE_REQUEST_SUCCESS',
        toTrash: 'FILES_MOVE_TO_TRASH_REQUEST_SUCCESS',
        default: 'FILES_DELETE_REQUEST_SUCCESS',
      };

      return types[type] || types.default;
    };
    return {
      url: '/action/delete',
      method: 'POST',
      data: otherParams,
      notifications: {
        success: notificationTypes[getType(type)],
        omitErrorNotification: true,
      },
    };
  },

  fileInfo: params => ({
    url: '/action/info',
    method: 'POST',
    data: params,
  }),

  fileVersions: params => ({
    url: '/version',
    method: 'POST',
    data: params,
  }),
};

export default actionsRoute;
