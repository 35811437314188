export default {
  REQUEST_DIRECTORY_CREATE: 'REQUEST_DIRECTORY_CREATE',
  REQUEST_DIRECTORY_CREATE_SUCCESS: 'REQUEST_DIRECTORY_CREATE_SUCCESS',
  REQUEST_DIRECTORY_CREATE_FAIL: 'REQUEST_DIRECTORY_CREATE_FAIL',

  REQUEST_DIRECTORY_COPY_MOVE: 'REQUEST_DIRECTORY_COPY_MOVE',
  REQUEST_DIRECTORY_COPY_MOVE_SUCCESS: 'REQUEST_DIRECTORY_COPY_MOVE_SUCCESS',
  REQUEST_DIRECTORY_COPY_MOVE_FAIL: 'REQUEST_DIRECTORY_COPY_MOVE_FAIL',

  REQUEST_FILE_INFO: 'REQUEST_FILE_INFO',
  REQUEST_FILE_INFO_SUCCESS: 'REQUEST_FILE_INFO_SUCCESS',
  REQUEST_FILE_INFO_FAIL: 'REQUEST_FILE_INFO_FAIL',

  REQUEST_FILE_VERSION: 'REQUEST_FILE_VERSION',
  REQUEST_FILE_VERSION_SUCCESS: 'REQUEST_FILE_VERSION_SUCCESS',
  REQUEST_FILE_VERSION_FAIL: 'REQUEST_FILE_VERSION_FAIL',

  REQUEST_DIRECTORY_DELETE: 'REQUEST_DIRECTORY_DELETE',
  REQUEST_DIRECTORY_DELETE_SUCCESS: 'REQUEST_DIRECTORY_DELETE_SUCCESS',
  REQUEST_DIRECTORY_DELETE_FAIL: 'REQUEST_DIRECTORY_DELETE_FAIL',
  REQUEST_DIRECTORY_RENAME: 'REQUEST_DIRECTORY_RENAME',
  REQUEST_DIRECTORY_RENAME_SUCCESS: 'REQUEST_DIRECTORY_RENAME_SUCCESS',
  REQUEST_DIRECTORY_RENAME_FAIL: 'REQUEST_DIRECTORY_RENAME_FAIL',
};
