import React from 'react';

const LongTap = props => {
  return (
    <svg
      id="Component_165_1"
      data-name="Component 165 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g
        id="Ellipse_53"
        dataname="Ellipse 53"
        fill="#fff"
        stroke="#d0d0d0"
        strokeWidth="2"
      >
        <circle cx="10" cy="10" r="10" stroke="none" />
        <circle cx="10" cy="10" r="9" fill="none" />
      </g>
      <text
        id="_3s"
        data-name="3s"
        transform="translate(10 14)"
        fill="gray"
        fontSize="11"
        fontFamily="Roboto-Light, Roboto"
        fontWeight="300"
      >
        <tspan x="-5.833" y="0">
          3s
        </tspan>
      </text>
    </svg>
  );
};

export default LongTap;
