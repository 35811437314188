import React from 'react';

const Clock = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      id="clock"
      d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm2.634,11.766L9.2,10.331V5.2h1.6V9.669l2.966,2.966Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default Clock;
