import React from 'react';

const PreviewTheme = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.197"
      height="16"
      viewBox="0 0 12.197 16"
      {...props}
    >
      <path
        id="dark_theme"
        d="M11.091,2.47a1.3,1.3,0,0,0-1.985,0C7.378,4.5,4,8.864,4,11.912a6.1,6.1,0,0,0,12.2,0C16.2,8.864,12.818,4.5,11.091,2.47ZM10.1,16.486a4.525,4.525,0,0,1-4.574-4.574c0-2.138,2.48-5.78,4.574-8.268Z"
        transform="translate(-4 -2.01)"
        fill="#8e8b92"
      />
    </svg>
  );
};

export default PreviewTheme;
