import React from 'react';

const Downloads = props => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        id="bellow"
        d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,12.331,4.234,8.566,5.366,7.434,7.2,9.269V4H8.8V9.269l1.834-1.834,1.131,1.131Z"
        transform="translate(16 16) rotate(180)"
      />
    </svg>
  );
};

export default Downloads;
