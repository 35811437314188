import * as notificationTypes from 'services/apiService/notificationTypes';

const shareRoute = {
  getLinks: params => ({
    url: '/link',
    method: 'POST',
    data: params,
  }),

  getInboxLinks: params => ({
    url: '/link/inbox',
    method: 'POST',
    data: params,
  }),

  getOutboxLinks: params => ({
    url: '/link/outbox',
    method: 'POST',
    data: params,
  }),

  sendLink: params => ({
    url: '/link/send',
    method: 'POST',
    data: params,
    notifications: {
      success: params.public
        ? notificationTypes.PUBLIC_LINKS_SEND_REQUEST_SUCCESS
        : notificationTypes.FILES_SEND_REQUEST_SUCCESS,
      omitErrorNotification: true,
    },
  }),

  getInnerFiles: (params) => {
    const { uuid } = params;
    return {
      url: `/folder/${uuid}`,
      method: 'GET',
    };
  },

  getPublicLinks: params => ({
    url: '/link/public',
    method: 'GET',
    params,
  }),

  getPublicLinkSubFolder: (params) => {
    const { uuid } = params;
    return {
      url: `link/public/${uuid}`,
      method: 'POST',
      data: params,
    };
  },

  getOutboxSubFolder: (params) => {
    const { uuid } = params;
    return {
      url: `/link/outbox/${uuid}`,
      method: 'POST',
      data: params,
    };
  },

  getInboxSubFolder: (params) => {
    const { uuid } = params;
    return {
      url: `/link/inbox/${uuid}`,
      method: 'POST',
      data: params,
    };
  },

  getLinksSubFolder: (params) => {
    const { uuid } = params;
    return {
      url: `/link/${uuid}`,
      method: 'POST',
      data: params,
    };
  },
};

export default shareRoute;
