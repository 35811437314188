export const getUser = (auth) => {
  const { user = null } = auth;

  return user;
};

export const getUserName = (auth) => {
  const { user } = auth;
  if (!user) {
    return '';
  }

  return user.fio;
};

export const getIsFetching = (auth) => {
  const { isFetching } = auth;

  return isFetching;
};

export const getIsAuthorized = (auth) => {
  const { isAuthorized } = auth;

  return isAuthorized;
};
