import { getLabelWithLang } from 'helpers/getLabelWithLang';

export default {
  data: {
    exchange: {
      id: 2,
      type: 'exchange',
      translateKey: 'exchange',
      name: getLabelWithLang('exchange', 'Обмен файлами'),
      icon: 'share',
      parentId: null,
    },
    received: {
      id: 7,
      type: 'received',
      name: getLabelWithLang('mailbox_inbox', 'Полученные'),
      translateKey: 'mailbox_inbox',
      icon: 'arrow',
      parentId: 'exchange',
    },
    sent: {
      id: 8,
      type: 'sent',
      name: getLabelWithLang('mailbox_outbox', 'Отправленные'),
      translateKey: 'mailbox_outbox',
      icon: 'arrow',
      parentId: 'exchange',
    },
    folderControls: {},
    subFolderControls: {},
    publiclink: {
      id: 14,
      type: 'publiclink',
      name: getLabelWithLang('public_link', 'Публичная ссылка'),
      translateKey: 'public_link',
      icon: 'send',
      parentId: null,
    },
    unauthpubliclink: {
      id: 15,
      type: 'unauthpubliclink',
      name: getLabelWithLang('public_link', 'Публичная ссылка'),
      translateKey: 'public_link',
      icon: 'send',
      parentId: null,
    },
  },
};
