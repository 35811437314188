import React from 'react';

const LockedBySync = props => {
  return (
    <svg
      id="Component_6_1"
      data-name="Component 6 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <circle
        id="Ellipse_46"
        data-name="Ellipse 46"
        cx="7"
        cy="7"
        r="7"
        fill="#516081"
      />
      <path
        id="icons8-lock"
        d="M7.32,1A2.484,2.484,0,0,0,4.83,3.49V3.9a.832.832,0,0,0-.83.83V8.884a.832.832,0,0,0,.83.83H9.809a.832.832,0,0,0,.83-.83V4.734a.832.832,0,0,0-.83-.83V3.49A2.484,2.484,0,0,0,7.32,1Zm0,.83a1.625,1.625,0,0,1,1.66,1.66V3.9H5.66V3.49A1.625,1.625,0,0,1,7.32,1.83Zm0,4.149a.83.83,0,1,1-.83.83A.832.832,0,0,1,7.32,5.979Z"
        transform="translate(-0.286 1.35)"
        fill="#8d9ab5"
      />
    </svg>
  );
};

export default LockedBySync;
