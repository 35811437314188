import React from 'react';

const Speed = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.556"
    height="16"
    viewBox="0 0 19.556 16"
    {...props}
  >
    <path
      id="speed"
      d="M10.778,2A9.778,9.778,0,0,0,3.236,18H18.321A9.778,9.778,0,0,0,10.778,2Zm2.786,3.175a.889.889,0,1,1,.325,1.214A.889.889,0,0,1,13.564,5.175Zm-2.786-1.4a.889.889,0,1,1-.889.889A.889.889,0,0,1,10.778,3.778Zm-8,8a.889.889,0,1,1,.889.889A.889.889,0,0,1,2.778,11.778ZM5.064,16.1a.889.889,0,1,1,.325-1.214A.889.889,0,0,1,5.064,16.1Zm.325-7.436a.889.889,0,1,1-.325-1.214A.889.889,0,0,1,5.389,8.667ZM7.667,6.389a.889.889,0,1,1,.325-1.214A.889.889,0,0,1,7.667,6.389Zm3.111,6.722a1.333,1.333,0,1,1,.409-2.6l5.306-3.063.889,1.54-5.3,3.059A1.332,1.332,0,0,1,10.778,13.111Zm6.928,2.667a.889.889,0,1,1-.325-1.214A.889.889,0,0,1,17.706,15.778Zm.183-3.111a.889.889,0,1,1,.889-.889A.889.889,0,0,1,17.889,12.667Z"
      transform="translate(-1 -2)"
    />
  </svg>
);

export default Speed;
