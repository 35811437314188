import initAxiosInstance from './initAxiosInstance';
import { createBaseUrl } from './helpers';

const axiosInstance = ((envs) => {
  const {
    REACT_APP_API_HOST: host,
    REACT_APP_API_PORT: port,
    REACT_APP_API_PATH: path,
    REACT_APP_XMF_CLIENT_ACCESS_KEY: xmfClientAccessKey,
  } = envs;

  const instance = initAxiosInstance({
    baseURL: createBaseUrl(host, port, path),
    headers: {
      common: {
        'XMF-Client-Access-Key': xmfClientAccessKey,
      },
      'content-type': 'application/json',
    },
  });

  return instance;
})(process.env);

const unauthAxiosInstance = ((envs) => {
  const {
    REACT_APP_API_HOST: host,
    REACT_APP_API_PORT: port,
    REACT_APP_API_PATH: path,
    REACT_APP_XMF_CLIENT_ACCESS_KEY: xmfClientAccessKey,
  } = envs;

  const instance = initAxiosInstance({
    baseURL: createBaseUrl(host, port, path),
    headers: {
      common: {
        'XMF-Client-Access-Key': xmfClientAccessKey,
      },
      'content-type': 'application/json',
    },
  });

  return instance;
})(process.env);

const noXmfAxiosInstance = ((envs) => {
  const {
    REACT_APP_API_HOST: host,
    REACT_APP_API_PORT: port,
    REACT_APP_API_PATH: path,
  } = envs;

  const instance = initAxiosInstance({
    baseURL: createBaseUrl(host, port, path),
    headers: {
      'content-type': 'application/json',
    },
  });

  return instance;
})(process.env);

export { axiosInstance as default, unauthAxiosInstance, noXmfAxiosInstance };
