import React from 'react';

const ErrorCloud = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="113"
    height="71.084"
    viewBox="0 0 113 71.084"
    {...props}
  >
    <path
      id="icons8-sad-cloud"
      d="M95.211,39.534c.018-.433.035-.856.035-1.271A29.7,29.7,0,0,0,38.906,25.117,15.845,15.845,0,0,0,14.381,37.265a21.84,21.84,0,0,0,7.477,42.366H92.881a20.116,20.116,0,0,0,2.331-40.1ZM70.06,38.951a4.52,4.52,0,1,1-4.52,4.52A4.517,4.517,0,0,1,70.06,38.951Zm-27.12,0a4.52,4.52,0,1,1-4.52,4.52A4.517,4.517,0,0,1,42.94,38.951ZM78.314,67.784a2.232,2.232,0,0,1-1.474.547,2.265,2.265,0,0,1-1.713-.786c-.062-.071-7.354-8.254-18.627-8.254-11.353,0-18.557,8.175-18.627,8.254a2.263,2.263,0,1,1-3.425-2.957c.353-.4,8.66-9.817,22.053-9.817s21.7,9.42,22.053,9.826A2.264,2.264,0,0,1,78.314,67.784Z"
      transform="translate(0 -8.547)"
    />
  </svg>
);

export default ErrorCloud;
