import React from 'react';

const ReportError = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="168"
    height="168"
    fill="none"
    viewBox="0 0 168 168"
    {...props}
  >
    <circle
      cx="84"
      cy="84"
      r="70"
      fill="#E7E7E7"
    />
    <path
      fill="#B8B8B8"
      fillRule="evenodd"
      d="M53.065 48.114a1.75 1.75 0 00-2.475 2.475L56 56l42 42 3.5 3.501L112 112l5.411 5.411a1.75 1.75 0 002.475-2.475L112 107.05V70L91 49H63a7.01 7.01 0 00-5.863 3.187l-4.072-4.073zM56 112V60.95L86.05 91H80.5v17.5h7V92.45l7 7v9.05h7v-2.051l9.363 9.363A7.008 7.008 0 01105 119H63c-3.85 0-7-3.15-7-7zm17.5-3.5h-7V84h7v24.5zm14-35V54.25l19.25 19.25H87.5z"
      clipRule="evenodd"
    />
  </svg>
);

export default ReportError;
