import React from 'react';

const More = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="38"
      height="18"
      viewBox="0 0 38 18"
      {...props}
    >
      <defs>
        <filter
          id="more"
          x="0"
          y="0"
          width="38"
          height="18"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feFlood floodColor="#cbcbcb" floodOpacity="0.349" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#more)">
        <path
          id="more-2"
          data-name="more"
          d="M5,12a3,3,0,1,0,3,3A3,3,0,0,0,5,12Zm10,0a3,3,0,1,0,3,3A3,3,0,0,0,15,12Zm10,0a3,3,0,1,0,3,3A3,3,0,0,0,25,12Z"
          transform="translate(4 -9)"
          // fill="#747e92"
        />
      </g>
    </svg>
  );
};

export default More;
