import React from 'react';

const StatusEnabled = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="icons8-ok"
        d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM7.333,11.609,4.2,8.471l.943-.943,2.2,2.2L12.2,4.862l.943.943Z"
        opacity="0.7"
      />
    </svg>
  );
};

export default StatusEnabled;
