import React from 'react';

const Help = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.365"
    height="17.104"
    viewBox="0 0 18.365 17.104"
    {...props}
  >
    <g
      id="Group_866"
      data-name="Group 866"
      transform="translate(-228.24 90.354)"
    >
      <path
        id="icons8-help"
        d="M10,2a8,8,0,1,0,8,8A8.012,8.012,0,0,0,10,2Zm0,1.6A6.4,6.4,0,1,1,3.6,10,6.388,6.388,0,0,1,10,3.6Zm0,1.6A3.2,3.2,0,0,0,6.8,8.4H8.4a1.6,1.6,0,0,1,3.2,0c0,1.6-2.4,1.894-2.4,4h1.6c0-1.321,2.4-2,2.4-4A3.2,3.2,0,0,0,10,5.2Zm-.8,8v1.6h1.6V13.2Z"
        transform="translate(228 -92)"
      />
      <line
        id="Line_119"
        data-name="Line 119"
        x2="16.396"
        y2="16.396"
        transform="translate(228.593 -90)"
        fill="none"
        stroke="#f8f8f8"
        strokeWidth="1"
      />
      <line
        id="Line_120"
        data-name="Line 120"
        x2="16.396"
        y2="16.396"
        transform="translate(229.854 -90)"
        fill="none"
        stroke="#8e8b92"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Help;
