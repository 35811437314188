import React from 'react';

const Device = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.333"
      height="25"
      viewBox="0 0 33.333 25"
      {...props}
    >
      <path
        id="multiple_devices"
        d="M5.556,4A2.8,2.8,0,0,0,2.778,6.778V20.667a2.8,2.8,0,0,0,2.778,2.778H0v2.778H18.056V20.667H5.556V6.778h25A2.8,2.8,0,0,0,27.778,4ZM23.611,9.556a2.778,2.778,0,0,0-2.778,2.778V26.222A2.778,2.778,0,0,0,23.611,29h6.944a2.778,2.778,0,0,0,2.778-2.778V12.333a2.778,2.778,0,0,0-2.778-2.778Zm0,2.778h6.944v12.5H23.611Z"
        transform="translate(0 -4)"
      />
    </svg>
  );
};

export default Device;
