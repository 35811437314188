import { createSelector } from 'reselect';
import {
  getFileById,
  selectFlashes,
  selectFlashById,
  getFlashControls,
} from 'store/reducers/flashes/selectors';
import {
  selectLinkById,
  selectShare,
  getFolderControls,
  getSubFolderControls,
} from 'store/reducers/share/selectors';

import { getFlashActions } from 'shared/helpers/flashPermitions';
import { getPrevFileId, getNextFileId } from './helpers';

export const getPreviewFilesIds = ({ previews }) => {
  const { files = [] } = previews;
  return files;
};

export const getSelectedFileId = ({ previews }) => {
  const { selectedFileId } = previews;
  return selectedFileId;
};

export const getNextSelectedFileId = createSelector(
  getPreviewFilesIds,
  getSelectedFileId,
  (files, selectedFileId) => {
    const nextSelectedFileId = getNextFileId(files, selectedFileId);
    return nextSelectedFileId;
  },
);

export const getPrevSelectedFileId = createSelector(
  getPreviewFilesIds,
  getSelectedFileId,
  (files, selectedFileId) => {
    const prevSelectedFileId = getPrevFileId(files, selectedFileId);
    return prevSelectedFileId;
  },
);

export const getPreparedFiles = ({ previews }) => {
  const { preparedFiles = {} } = previews;
  return preparedFiles;
};

export const getReturnLink = ({ previews }) => {
  const { returnLink = null } = previews;
  return returnLink;
};

export const getParentFolderType = ({ previews }) => previews.parentFolderType;

export const getFileByKey = (state, id, parentFolderType) => {
  const folderType = parentFolderType || getParentFolderType(state);
  const [folderTypeName, folderTypeAdditional] = (folderType || '').split('_');
  if (folderTypeName === 'flash') {
    return getFileById(state, id);
  }
  if (folderTypeName === 'share') {
    return selectLinkById(state, `${folderTypeAdditional}_${id}`);
  }
};

const selectFileListByFolderType = (state) => {
  const { previews } = state;
  const [folderTypeName] = (previews.parentFolderType || '').split('_');

  if (folderTypeName === 'flash' || folderTypeName === 'basket') {
    return selectFlashes(state);
  }

  if (
    [
      'share',
      'publiclink',
      'unauthpubliclink',
      'unauthpubliclinkSubfolder',
    ].includes(folderTypeName)
  ) {
    return selectShare(state);
  }
};

const selectIds = (state, ids) => ids;

export const getFilesByKeys = createSelector(
  selectFileListByFolderType,
  selectIds,
  getParentFolderType,
  (fileList, ids, parentFolderType) => {
    const [folderTypeName] = (parentFolderType || '').split('_');

    const { data: filesData } = fileList.byId;
    let files = [];

    if (
      ['flash', 'basket', 'publiclink', 'unauthpubliclinkSubfolder'].includes(
        folderTypeName,
      )
    ) {
      files = ids.map(fileId => (fileId && filesData[fileId]) || null);
    }
    if (['unauthpubliclink'].includes(folderTypeName)) {
      files = ids.map(
        fileId => (fileId && filesData[`${folderTypeName}_${fileId}`]) || null,
      );
    }

    if (folderTypeName === 'share') {
      const shareFiles = Object.values(filesData);

      files = ids.map(
        fileId => (fileId && shareFiles.find(file => file.id === fileId))
          || null,
      );
    }
    return files;
  },
);

const getSelectedFile = (state, selectedFile) => selectedFile;

const getState = state => state;

export const getActionPermitions = createSelector(
  getParentFolderType,
  getSelectedFile,
  getState,
  (parentFolderType, selectedFile, state) => {
    if (!selectedFile) {
      return {};
    }
    if (parentFolderType === 'flash' && !selectedFile.source) {
      const parentFlash = selectFlashById(
        state,
        (selectedFile || {}).flash_uuid,
      );
      return getFlashActions(parentFlash.permitions);
    }
    if (parentFolderType === 'flash' && selectedFile.source) {
      return getFlashControls(state);
    }
    if (parentFolderType === 'unauthpubliclinkSubfolder') {
      return getSubFolderControls(state);
    }
    return getFolderControls(state);
  },
);
