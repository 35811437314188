import React from 'react';

const IosDistribution = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      {...props}
    >
      <path
        id="ios"
        d="M9.182,3A5.186,5.186,0,0,0,4,8.182v8.636A5.186,5.186,0,0,0,9.182,22h8.636A5.186,5.186,0,0,0,23,16.818V8.182A5.186,5.186,0,0,0,17.818,3ZM7.722,9.508a.5.5,0,1,1-.53.5A.5.5,0,0,1,7.722,9.508Zm4.5.246c1.576,0,2.548,1.1,2.548,2.867s-.963,2.872-2.548,2.872-2.561-1.106-2.561-2.872S10.65,9.754,12.221,9.754Zm5.584,0a1.787,1.787,0,0,1,1.973,1.571h-.829a1.046,1.046,0,0,0-1.157-.82c-.674,0-1.118.319-1.118.816,0,.389.285.613,1,.782l.6.147c1.127.263,1.589.717,1.589,1.542,0,1.049-.812,1.705-2.112,1.705-1.213,0-2.029-.626-2.09-1.606h.843c.073.518.583.85,1.312.85.682,0,1.183-.354,1.183-.842,0-.415-.315-.665-1.041-.837l-.708-.169c-.989-.233-1.442-.712-1.442-1.516C15.81,10.4,16.609,9.754,17.806,9.754Zm-5.584.79c-1.032,0-1.688.807-1.688,2.076s.634,2.081,1.688,2.081,1.68-.816,1.68-2.081S13.262,10.544,12.221,10.544Zm-4.905.687h.812v4.133H7.316Z"
        transform="translate(-4 -3)"
      />
    </svg>
  );
};

export default IosDistribution;
