import React from 'react';

const Search = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      id="search"
      d="M13.006,11.805h-.632l-.224-.216a5.211,5.211,0,1,0-.56.56l.216.224v.632l4,3.994L17,15.807Zm-4.8,0a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,8.2,11.805Z"
      transform="translate(-3 -3)"
    />
  </svg>
);

export default Search;
