import {
  orange,
  deepPurple,
  blueGrey,
  common,
  indigo,
} from '@material-ui/core/colors';
import { merge } from 'lodash';

import { apiService } from 'services';
import { LIGHT, DARK } from 'helpers/theme/type';

/** первоначальная структура, для того, чтобы селекторы не падали */
export const initialCustomTheme = {
  color: {
    [LIGHT]: {},
    [DARK]: {},
  },
};

export const defaultPartialTheme = {
  palette: {
    spacing: 4,
    primary: deepPurple,
    secondary: blueGrey,
    action: indigo,
    text: {
      primary: common.black,
    },
    type: LIGHT,
  },
  status: {
    danger: orange[500],
  },
  typography: {
    fontFamily: ['-apple-system', 'Roboto', 'sans-serif'].join(','),
  },
};

export const loadTheme = async () => {
  try {
    const result = await apiService.settings.getTheme();
    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading custom Theme', error);
    // throw Error('request of flash folder is bad');
  }
  return null;
};

/** дополнить недостающие цвета в темной теме из светлой, для достижения одинаковой структуры
 *  http://localhost:3000/test/layout/json */
export const normalizeCustomTheme = (loadedCustomTheme) => {
  if (!loadedCustomTheme) {
    return initialCustomTheme;
  }
  const normalizedCustomTheme = { ...initialCustomTheme, ...loadedCustomTheme };
  normalizedCustomTheme.color.dark = merge({}, normalizedCustomTheme.color.light, normalizedCustomTheme.color.dark);
  return normalizedCustomTheme;
};
