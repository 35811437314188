export const GET_REGISTRATION_SETTINGS = 'GET_REGISTRATION_SETTINGS';
export const GET_REGISTRATION_SETTINGS_SUCCESS = 'GET_REGISTRATION_SETTINGS_SUCCESS';
export const GET_REGISTRATION_SETTINGS_FAIL = 'GET_REGISTRATION_SETTINGS_FAIL';

export const SET_REGISTRATION_SETTINGS = 'SET_REGISTRATION_SETTINGS';
export const SET_REGISTRATION_SETTINGS_SUCCESS = 'SET_REGISTRATION_SETTINGS_SUCCESS';
export const SET_REGISTRATION_SETTINGS_FAIL = 'SET_REGISTRATION_SETTINGS_FAIL';

export const CHECK_REGISTRATION = 'CHECK_REGISTRATION';
export const CHECK_REGISTRATION_SUCCESS = 'CHECK_REGISTRATION_SUCCESS';
export const CHECK_REGISTRATION_FAIL = 'CHECK_REGISTRATION_FAIL';
