import React from 'react';

const Hide = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.567"
    height="11.912"
    viewBox="0 0 16.567 11.912"
    {...props}
  >
    <g
      id="Group_819"
      data-name="Group 819"
      transform="translate(-1682.217 -901)"
    >
      <path
        id="Subtraction_13"
        data-name="Subtraction 13"
        d="M-7251.5-3929.091a7.6,7.6,0,0,1-5.8-2.727,9.933,9.933,0,0,1-1.7-2.727,10.128,10.128,0,0,1,2.3-3.345l2.277,1.595a3.4,3.4,0,0,0-.483,1.75,3.413,3.413,0,0,0,3.409,3.408,3.4,3.4,0,0,0,2.645-1.259l2.185,1.53A7.311,7.311,0,0,1-7251.5-3929.091Zm5.57-2.479h0l-2.412-1.689a3.388,3.388,0,0,0,.251-1.287,3.413,3.413,0,0,0-3.409-3.409,3.4,3.4,0,0,0-2.288.883l-2.121-1.485A7.283,7.283,0,0,1-7251.5-3940a7.6,7.6,0,0,1,5.8,2.727,9.936,9.936,0,0,1,1.7,2.728,10.017,10.017,0,0,1-1.93,2.975Zm-5.57-.93a2.048,2.048,0,0,1-2.046-2.046,2.051,2.051,0,0,1,.242-.965l3.328,2.33A2.052,2.052,0,0,1-7251.5-3932.5Zm1.991-1.575h0l-3.113-2.179a2.037,2.037,0,0,1,1.123-.336,2.048,2.048,0,0,1,2.046,2.046,2.039,2.039,0,0,1-.054.469Z"
        transform="translate(8942 4841)"
      />
      <line
        id="Line_103"
        data-name="Line 103"
        x2="16"
        y2="11"
        transform="translate(1682.5 901.5)"
        fill="none"
        stroke="#8e8b92"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Hide;
