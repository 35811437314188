import React from 'react';

const ViewMore = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="10.4"
      viewBox="0 0 13 10.4"
      {...props}
    >
      <path
        id="folder"
        d="M7.2,4H3.3A1.3,1.3,0,0,0,2.007,5.3L2,13.1a1.3,1.3,0,0,0,1.3,1.3H13.7A1.3,1.3,0,0,0,15,13.1V6.6a1.3,1.3,0,0,0-1.3-1.3H8.5Z"
        transform="translate(-2 -4)"
      />
    </svg>
  );
};

export default ViewMore;
