import React from 'react';

const SyncReading = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="15" height="16" fill="none" />
        </clipPath>
      </defs>
      <g id="sync_read" clipPath="url(#clip-path)">
        <path
          id="Subtraction_5"
          data-name="Subtraction 5"
          d="M5,13.916H5A6.009,6.009,0,0,1,0,8,5.927,5.927,0,0,1,.635,5.326L1.646,6.335A4.623,4.623,0,0,0,1.333,8,4.7,4.7,0,0,0,5,12.559v1.356ZM11.915,9H10.555a4.576,4.576,0,0,0,.112-1A4.672,4.672,0,0,0,6,3.333v2L3.334,2.667,6,0V2a6.007,6.007,0,0,1,6,6,5.942,5.942,0,0,1-.085,1Z"
          transform="translate(0 0)"
        />
        <g id="Group_67" data-name="Group 67" transform="translate(6 10)">
          <path
            id="icons8-eye-filled"
            d="M4.9,4A4.334,4.334,0,0,0,1,6.258,4.334,4.334,0,0,0,4.9,8.517,4.334,4.334,0,0,0,8.8,6.258,4.334,4.334,0,0,0,4.9,4Zm0,.847A1.624,1.624,0,0,1,6.673,6.258,1.624,1.624,0,0,1,4.9,7.67,1.624,1.624,0,0,1,3.127,6.258,1.624,1.624,0,0,1,4.9,4.847Zm0,.565a.974.974,0,0,0-1.064.847A.974.974,0,0,0,4.9,7.105a.974.974,0,0,0,1.064-.847A.974.974,0,0,0,4.9,5.412Z"
            transform="translate(-1 -4)"
          />
          <ellipse
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="1.5"
            cy="1"
            rx="1.5"
            ry="1"
            transform="translate(2.4 1.306)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SyncReading;
