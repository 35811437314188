export default {
  GET_DEVICES: 'GET_DEVICES',
  GET_DEVICES_SUCCESS: 'GET_DEVICES_SUCCESS',
  GET_DEVICES_FAIL: 'GET_DEVICES_FAIL',

  DELETE_DEVICES: 'DELETE_DEVICES',
  DELETE_DEVICES_SUCCESS: 'DELETE_DEVICES_SUCCESS',
  DELETE_DEVICES_FAIL: 'DELETE_DEVICES_FAIL',

  ALLOW_DEVICES: 'ALLOW_DEVICES',
  ALLOW_DEVICES_SUCCESS: 'ALLOW_DEVICES_SUCCESS',
  ALLOW_DEVICES_FAIL: 'ALLOW_DEVICES_FAIL',

  CLEAR_DEVICES: 'CLEAR_DEVICES',
  CLEAR_DEVICES_SUCCESS: 'CLEAR_DEVICES_SUCCESS',
  CLEAR_DEVICES_FAIL: 'CLEAR_DEVICES_FAIL',

  RENAME_DEVICE: 'RENAME_DEVICE',
  RENAME_DEVICE_SUCCESS: 'RENAME_DEVICE_SUCCESS',
  RENAME_DEVICE_FAIL: 'RENAME_DEVICE_FAIL',

  LOCAL_DELETE_DEVICE: 'LOCAL_DELETE_DEVICE',
};
