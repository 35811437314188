import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import entrance from './entrance';
import files from './files';
import devices from './devices';
import sessions from './sessions';
import settings from './settings';
import notifications from './notifications';
import flashes from './flashes';
import directories from './directories';
import share from './share';
import trash from './trash';
import events from './events';
import tree from './tree';
import client from './client';
import transitionHistory from './transitionHistory';
import previews from './previews';
import collabora from './collabora';
import logout from './logout';
import clientNotifications from './clientNotifications';
import instances from './instances';
import logs from './logs';

const createRootReducer = history => combineReducers({
  auth,
  entrance,
  files,
  devices,
  sessions,
  settings,
  notifications,
  flashes,
  directories,
  share,
  trash,
  events,
  tree,
  client,
  transitionHistory,
  previews,
  collabora,
  router: connectRouter(history),
  logout,
  clientNotifications,
  instances,
  logs,
});

export default createRootReducer;
