export default {
  topLevel: ['storage', 'exchange'],
  storage: ['favorite', 'created', 'available', 'basket'],
  exchange: [],
  exchangeSubFolder: [],
  received: [],
  receivedSubFolder: [],
  sent: [],
  sentSubFolder: [],
  favorite: [],
  created: [],
  available: [],
  basket: [],
  folderControls: {},
  publiclink: [],
  publiclinkSubFolder: [],
};
