import React from 'react';

const SelectEmpty = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="117"
    height="95"
    viewBox="0 0 117 95"
    {...props}
  >
    <g
      id="Group_823"
      data-name="Group 823"
      transform="translate(-1673 -394.983)"
    >
      <g
        id="Group_821"
        data-name="Group 821"
        transform="translate(1674.504 397)"
      >
        <rect
          id="Rectangle_318"
          data-name="Rectangle 318"
          width="32"
          height="33"
          transform="translate(0.496 -0.017)"
          fill="none"
          stroke="#e3e4e3"
          strokeWidth="4"
        />
        <path
          id="Path_467"
          data-name="Path 467"
          d="M20.548,5.281,9.453,16.314,4.833,11.72,3.281,13.262l5.4,5.365.776.738.776-.738L22.1,6.824Z"
          transform="translate(4.073 3.955)"
        />
      </g>
      <g
        id="Group_822"
        data-name="Group 822"
        transform="translate(1673 455.43)"
      >
        <rect
          id="Rectangle_318-2"
          data-name="Rectangle 318"
          width="33"
          height="33"
          transform="translate(2 -0.446)"
          fill="none"
          stroke="#e3e4e3"
          strokeWidth="4"
        />
      </g>
      <rect
        id="Rectangle_319"
        data-name="Rectangle 319"
        width="69"
        height="10"
        transform="translate(1721 466.983)"
      />
      <rect
        id="Rectangle_320"
        data-name="Rectangle 320"
        width="69"
        height="10"
        transform="translate(1721 408.983)"
      />
    </g>
  </svg>
);

export default SelectEmpty;
