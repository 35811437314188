import React from 'react';

const Folder = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.522"
      height="21.218"
      viewBox="0 0 26.522 21.218"
      {...props}
    >
      <path
        id="folder"
        d="M25.87,6.652H15.261L12.609,4H4.652A2.66,2.66,0,0,0,2,6.652V22.566a2.66,2.66,0,0,0,2.652,2.652H25.87a2.66,2.66,0,0,0,2.652-2.652V9.3A2.66,2.66,0,0,0,25.87,6.652Zm0,15.913H4.652V9.3H25.87Z"
        transform="translate(-2 -4)"
      />
    </svg>
  );
};

export default Folder;
