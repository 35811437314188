import React from 'react';

const Extra = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.429"
      height="11"
      viewBox="0 0 9.429 11"
      {...props}
    >
      <path
        id="additional"
        d="M7.213,4A.262.262,0,0,0,7,4.262V14.738A.262.262,0,0,0,7.262,15h8.9a.262.262,0,0,0,.262-.262V4.262A.262.262,0,0,0,16.167,4H7.213Zm.311.524H15.9v9.952H7.524ZM8.833,6.1v.524h.786V6.1Zm1.571,0v.524h4.452V6.1ZM8.833,8.19v.524h.786V8.19Zm1.571,0v.524h4.452V8.19Zm-1.571,2.1v.524h.786v-.524Zm1.571,0v.524h4.452v-.524Zm-1.571,2.1V12.9h.786v-.524Zm1.571,0V12.9h4.452v-.524Z"
        transform="translate(-7 -4)"
      />
    </svg>
  );
};

export default Extra;
