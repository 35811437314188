import React from 'react';

const LinkExpired = props => {
  return (
    <svg
      id="Component_70_1"
      data-name="Component 70 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="86.008"
      height="45.803"
      viewBox="0 0 86.008 45.803"
      {...props}
    >
      <path
        id="Union_82"
        data-name="Union 82"
        d="M-1685.089,10943.295l-.005.007-1.446-.736.006-.007-1.731-.882-.007.006-2.407-1.226-.022-.022-7-3.565,0,.007-1.454-.739,0-.008-12.94-6.594h-.018l-10.79-5.5h.015l-7.955-4.054-.007.006-5.28-2.69.005-.006-18.878-9.619,1.363-2.674,20.037,10.209a13.825,13.825,0,0,1,1.919-1.027,13.62,13.62,0,0,1,5.5-1.148h11v5.5h-11q-.406,0-.807.039l10.717,5.46h14.844v5.5h-4.051l7.746,3.947a8.07,8.07,0,0,1,1.438-1.394,7.931,7.931,0,0,1,4.793-1.6,8.02,8.02,0,0,1,8.011,8.011,8.016,8.016,0,0,1-.277,2.1l14.777,7.529-1.363,2.674Zm-.146-3.443a6.426,6.426,0,0,0,.144-1.352,6.359,6.359,0,0,0-1.874-4.534,6.362,6.362,0,0,0-4.537-1.874,6.406,6.406,0,0,0-4.776,2.132l3.979,2.028v-2.559h1.6v3.375Zm-11.638,4.582a8,8,0,0,1-2.592-5.092l1.8.92a6.45,6.45,0,0,0,2.267,3.331,6.354,6.354,0,0,0,3.894,1.316,6.455,6.455,0,0,0,2.2-.387l1.8.915a7.993,7.993,0,0,1-4,1.074A7.986,7.986,0,0,1-1696.874,10944.434Zm-5.527-3.9h-7.277v-5.5h1.753l5.483,2.795c-.038.385-.06.78-.06,1.171a11.481,11.481,0,0,0,.1,1.533h0l0,0Zm-12.782,0h-11a13.763,13.763,0,0,1-13.744-13.75,13.724,13.724,0,0,1,1.954-7.062l4.922,2.509a8.152,8.152,0,0,0-1.382,4.554,8.187,8.187,0,0,0,2.424,5.824,8.183,8.183,0,0,0,5.826,2.424h11v5.5Zm-8.248-11v-2.4l4.713,2.4,0,0Zm32.966-2.022c.019-.236.033-.48.033-.729a8.183,8.183,0,0,0-2.424-5.826,8.183,8.183,0,0,0-5.826-2.421h-11v-5.5h11a13.766,13.766,0,0,1,13.75,13.752,13.591,13.591,0,0,1-.2,2.322v0A11.516,11.516,0,0,0-1690.465,10927.513Z"
        transform="translate(1755 -10904.999)"
        opacity="0.648"
      />
    </svg>
  );
};

export default LinkExpired;
