import React from 'react';

const ModerationGridApproved = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <filter
          id="Ellipse_3"
          x="0"
          y="0"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="moderation_grid_approved" transform="translate(-662 -196.174)">
        <g transform="matrix(1, 0, 0, 1, 662, 196.17)" filter="url(#Ellipse_3)">
          <circle
            id="Ellipse_3-2"
            data-name="Ellipse 3"
            cx="8"
            cy="8"
            r="8"
            transform="translate(3 3)"
            fill="#9de144"
          />
        </g>
        <path
          id="Path_70"
          data-name="Path 70"
          d="M12.053,5.281l-5.637,5.6L4.069,8.552l-.788.784,2.741,2.726.394.375.394-.375,6.031-6Z"
          transform="translate(664.719 197.893)"
          fill="#fbfffb"
        />
      </g>
    </svg>
  );
};

export default ModerationGridApproved;
