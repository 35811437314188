/* eslint-disable no-param-reassign */
import * as authTypes from 'store/reducers/auth/types';
import types from '../constants';

const initialState = {
  comments: [],
  notifications: [],
};

const ACTION_HANDLERS = {
  [types.GET_MESSAGES_SUCCESS]: (state, { payload }) => {
    if (!payload.new || !payload.old) {
      return state;
    }

    payload.new.forEach((item) => {
      item.viewed = false;
      item.timestamp *= 1000;
      item.hidden = true;
    });
    payload.old.forEach((item) => {
      item.viewed = true;
      item.timestamp *= 1000;
      item.hidden = true;
    });
    return {
      ...state,
      notifications: [
        ...payload.new.map(item => item.id),
        ...payload.old.map(item => item.id),
      ],
    };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,
};

export const byFilters = (state = initialState, action) => (ACTION_HANDLERS[action.type]
  ? ACTION_HANDLERS[action.type](state, action)
  : state);

export default byFilters;
