import React from 'react';

const Alert = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.316"
      height="14"
      viewBox="0 0 14.316 14"
      {...props}
    >
      <path
        id="alert"
        d="M9.188,16.5a1.44,1.44,0,0,0,1.436-1.436H7.752A1.44,1.44,0,0,0,9.188,16.5ZM13.5,12.192V8.6a4.367,4.367,0,0,0-3.231-4.537V3.577a1.077,1.077,0,1,0-2.154,0v.488A4.369,4.369,0,0,0,4.88,8.6v3.59L3.444,13.628v.718H14.932v-.718Zm-1.436.718H6.316V8.6A2.944,2.944,0,0,1,9.188,5.372,2.944,2.944,0,0,1,12.06,8.6ZM6.015,3.634,4.988,2.608A7.485,7.485,0,0,0,2.03,8.244H3.466A6.063,6.063,0,0,1,6.015,3.634Zm8.9,4.609h1.436a7.53,7.53,0,0,0-2.958-5.636L12.368,3.634A6.1,6.1,0,0,1,14.91,8.244Z"
        transform="translate(-2.03 -2.5)"
        fill="#0c0c0c"
        opacity="0.58"
      />
    </svg>
  );
};

export default Alert;
