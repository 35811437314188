import React from 'react';

const SelectedItem = props => (
  <svg
    height="448pt"
    viewBox="0 0 448 448.55357"
    width="448pt"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m224.550781 0c-123.667969 0-224.273437 100.605469-224.273437 224.277344 0 123.667968 100.605468 224.277344 224.273437 224.277344 123.664063 0 224.269531-100.609376 224.269531-224.277344 0-123.671875-100.605468-224.277344-224.269531-224.277344zm0 430.269531c-113.582031 0-205.988281-92.414062-205.988281-205.992187 0-113.582032 92.40625-205.992188 205.988281-205.992188 113.578125 0 205.984375 92.410156 205.984375 205.992188 0 113.578125-92.40625 205.992187-205.984375 205.992187zm0 0" />
    <path d="m340.378906 134.847656-159.46875 159.464844-72.191406-72.1875c-3.570312-3.570312-9.355469-3.570312-12.929688 0-3.570312 3.570312-3.570312 9.355469 0 12.929688l78.65625 78.652343c1.785157 1.785157 4.125 2.675781 6.464844 2.675781s4.679688-.890624 6.464844-2.675781l165.933594-165.929687c3.570312-3.570313 3.570312-9.359375 0-12.929688-3.570313-3.570312-9.359375-3.570312-12.929688 0zm0 0" />
  </svg>
);

export default SelectedItem;
