import React from 'react';
import PropTypes from 'prop-types';
import PauseIcon from '@material-ui/icons/Pause';
import ErrorIcon from '@material-ui/icons/Error';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import Settings from 'elements/StyledSvg/components/Settings';
import MenuSettings from 'elements/StyledSvg/components/Settings1';
import User from 'elements/StyledSvg/components/User';
import Devices from 'elements/StyledSvg/components/Devices';
import Device from 'elements/StyledSvg/components/Device';
import Shield from 'elements/StyledSvg/components/Shield';
import NonSync from 'elements/StyledSvg/components/NonSync';
import Help from 'elements/StyledSvg/components/Help';
import Sort from 'elements/StyledSvg/components/Sort';
import About from 'elements/StyledSvg/components/About';
import Admin from 'elements/StyledSvg/components/Admin';
import Contacts from 'elements/StyledSvg/components/Contacts';
import Distribute from 'elements/StyledSvg/components/Distribute';
import Exit from 'elements/StyledSvg/components/Exit';
import Tasks from 'elements/StyledSvg/components/Tasks';
import Documents from 'elements/StyledSvg/components/Documents';
import StyledReversedCheckbox from 'elements/StyledReversedCheckbox/StyledReversedCheckbox';
import EmptyDevices from 'elements/StyledSvg/components/EmptyDevices';
import EmptyLinks from 'elements/StyledSvg/components/EmptyLinks';
import LinkExpired from 'elements/StyledSvg/components/LinkExpired';
import LinkPin from 'elements/StyledSvg/components/LinkPin';
import StatusDisabled from 'elements/StyledSvg/components/StatusDisabled';
import StatusEnabled from 'elements/StyledSvg/components/StatusEnabled';
import { ReactComponent as PlayEnabled } from 'shared/assets/icons/play-enabled.svg';
import Add from './components/Add';
import Load from './components/Load';
import Download from './components/Download';
import Rename from './components/Rename';
import Delete from './components/Delete';
import Preview from './components/Preview';
import EmptyPreview from './components/EmptyPreview';
import Downloads from './components/Downloads';
import Sync from './components/Sync';
import SyncReading from './components/SyncReading';
import ListView from './components/ListView';
import TableView from './components/TableView';
import CloseSidePanel from './components/CloseSidePanel';
import OpenSidePanel from './components/OpenSidePanel';
import Loader from './components/Loader';
import BackNav from './components/BackNav';
import ForwardNav from './components/ForwardNav';
import UpLevelNav from './components/UpLevelNav';
import Versions from './components/Versions';
import VersionsEmptyControl from './components/VersionsEmptyControl';
import VersionsControl from './components/VersionsControl';
import VersionsSingleControl from './components/VersionsSingleControl';
import SelectEmpty from './components/SelectEmpty';
import ErrorCloud from './components/ErrorCloud';
import SelectNone from './components/SelectNone';
import TaskComplete from './components/TaskComplete';
import Upload from './components/Upload';
import Speed from './components/Speed';
import Clock from './components/Clock';
import SimpleArrowUp from './components/SimpleArrowUp';
import CheckAll from './components/CheckAll';
import Check from './components/Check';
import EmptyCheckbox from './components/EmptyCheckbox';
import Recover from './components/Recover';
import File from './components/File';
import TaskCompleted from './components/TaskCompleted';
import Folder from './components/Folder';
import Eye from './components/Eye';
import Hide from './components/Hide';
import EmptyUpload from './components/EmptyUpload';
import FileManagerFile from './components/FileManagerFile';
import FileManagerFolder from './components/FileManagerFolder';
import FileManagerPDF from './components/FileManagerPDF';
import FileManagerImage from './components/FileManagerImage';
import FileManagerTXT from './components/FileManagerTXT';
import FileManagerPSD from './components/FileManagerPSD';
import FileManagerDOCX from './components/FileManagerDOCX';
import FileManagerPreview from './components/FileManagerPreview';
import Access from './components/Access';
import EditCloud from './components/EditCloud';
import ViewMore from './components/ViewMore';
import Once from './components/Once';
import Alert from './components/Alert';
import UploadAttribute from './components/UploadAttribute';
import WindowsDistribution from './components/WindowsDistribution';
import IosDistribution from './components/IosDistribution';
import InviteUser from './components/InviteUser';
import NotificationEmpty from './components/NotificationEmpty';
import Notification from './components/Notification';
import Copy from './components/Copy';
import CopyGroup from './components/CopyGroup';
import CopyContext from './components/CopyContext';
import SettingsContext from './components/SettingsContext';
import Favorite from './components/Favorite';
import AddContext from './components/AddContext';
import Send from './components/Send';
import Level from './components/Level';
import People from './components/People';
import Storage from './components/Storage';
import Basket from './components/Basket';
import FolderContext from './components/FolderContext';
import Share from './components/Share';
import Cloud from './components/Cloud';
import MoreVert from './components/MoreVert';
import Office from './components/Office';
import UploadToCloud from './components/UploadToCloud';
import SentFile from './components/SentFile';
import SentFolder from './components/SentFolder';
import receivedFile from './components/ReceivedFile';
import receivedFolder from './components/ReceivedFolder';
import ContactsList from './components/ContactsList';
import EmptyStorage from './components/EmptyStorage';
import Pencil from './components/Pencil';
import Rotate from './components/Rotate';
import Extra from './components/Extra';
import LogoGrey from './components/LogoGrey';
import HelpClose from './components/HelpClose';
import MenuSandwich from './components/MenuSandwich';
import Search from './components/Search';
import EmptySearch from './components/EmptySearch';
import OutlinedCloud from './components/OutlinedCloud';
import SelectedItem from './components/SelectedItem';
import Apple from './components/Apple';
import ArrowBack from './components/ArrowBack';
import ArrowForward from './components/ArrowForward';
import ArrowDown from './components/ArrowDown';
import ArrowLeftSide from './components/ArrowLeftSide';
import Tap from './components/Tap';
import LongTap from './components/LongTap';
import Trash from './components/Trash';
import EmptyTrash from './components/EmptyTrash';
import SendPdf from './components/SendPdf';
import SubFiles from './components/SubFiles';
import SubFolders from './components/SubFolders';
import UserStorageRoot from './components/UserStorageRoot';
import ModerationDenied from './components/ModerationDenied';
import ModerationApproved from './components/ModerationApproved';
import ModerationAwaiting from './components/ModerationAwaiting';
import RadioButtonEmpty from './components/RadioButtonEmpty';
import RadioButtonChecked from './components/RadioButtonChecked';
import Lock from './components/Lock';
import ModerationGridDenied from './components/ModerationGridDenied';
import ModerationGridApproved from './components/ModerationGridApproved';
import ModerationGridAwaiting from './components/ModerationGridAwaiting';
import LockedBySync from './components/LockedBySync';
import CloseSync from './components/CloseSync';
import FullScreen from './components/FullScreen';
import PreviewFullScreen from './components/PreviewFullScreen';
import PreviewClose from './components/PreviewClose';
import PreviewTheme from './components/PreviewTheme';
import PreviewFileListArrow from './components/PreviewFileListArrow';
import More from './components/More';
import SharedToMeEmpty from './components/SharedToMeEmpty';
import ImageSvg from './components/ImageSvg';
import FatalError from './components/FatalError';
import ColorTheme from './components/ColorTheme';
import GuideInvite from './components/GuideInvite';
import GuideSettings from './components/GuideSettings';
import useStyles from './styles';
import Contactes from './components/Contactes';
import Pin from './components/Pin';
import Report from './components/Report';
import OfficeNew from './components/OfficeNew';
import Reports from './components/Reports';
import ReportError from './components/ReportError';

import { ReactComponent as VideoIndicatorMobile } from '../../assets/icons/video_indicator_mobile.svg';
import { ReactComponent as EditFileLeftPanelIcon } from '../../assets/icons/edit_file_left_panel.svg';
import { ReactComponent as EditFileStatusGridIcon } from '../../assets/icons/edit_file_status_grid.svg';
import { ReactComponent as FullEditFileLeftPanelIcon } from '../../assets/icons/full_edit_file_left_panel.svg';
import { ReactComponent as OfficeCloseIcon } from '../../assets/icons/office_close.svg';
import { ReactComponent as OfficeEditIcon } from '../../assets/icons/office_edit.svg';
import { ReactComponent as OfficeReadonlyIcon } from '../../assets/icons/office_readonly.svg';
import { ReactComponent as CollaboraOpenIcon } from '../../assets/icons/open.svg';

const renderIcon = {
  // icon from line of ContentControlPanel buttons
  add: Add,
  load: Load,
  download: Download,
  send: Send,
  rename: Rename,
  delete: Delete,
  preview: Preview,
  emptyPreview: EmptyPreview,
  downloads: Downloads,
  nonsync: NonSync,
  sync: Sync,
  sort: Sort,
  help: Help,
  syncReading: SyncReading,
  listView: ListView,
  tableView: TableView,
  closeSidePanel: CloseSidePanel,
  openSidePanel: OpenSidePanel,
  settings: Settings,
  user: User,
  shield: Shield,
  devices: Devices,
  emptyDevices: EmptyDevices,
  about: About,
  admin: Admin,
  documents: Documents,
  contacts: Contacts,
  contacts2: Contactes,
  distribute: Distribute,
  exit: Exit,
  tasks: Tasks,

  // icons from header nav buttons
  backNav: BackNav,
  forwardNav: ForwardNav,
  upLevelNav: UpLevelNav,
  count: TaskCompleted,

  // SidePanel
  versions: Versions,
  versionsControl: VersionsControl,
  versionsSingleControl: VersionsSingleControl,
  versionsEmptyControl: VersionsEmptyControl,
  selectEmpty: SelectEmpty,

  errorCloud: ErrorCloud,
  selectNone: SelectNone,

  taskComplete: TaskComplete,
  upload: Upload,
  emptyUpload: EmptyUpload,
  speed: Speed,
  clock: Clock,
  eye: Eye,
  hide: Hide,
  // spinner icon
  loader: Loader,

  // sort arrow
  simpleArrowUp: SimpleArrowUp,

  // notifications/versions icons
  notification: Notification,
  notificationEmpty: NotificationEmpty,
  checkAll: CheckAll,
  emptyCheckbox: EmptyCheckbox,
  recover: Recover,

  // files icons
  file: File,
  folder: Folder,
  received_file: receivedFile,
  received_folder: receivedFolder,
  sent_folder: SentFolder,
  sent_file: SentFile,

  // icons in FileManager
  fileManagerFile: FileManagerFile,
  fileManagerFolder: FileManagerFolder,
  fileManagerPDF: FileManagerPDF,
  fileManagerImage: FileManagerImage,
  fileManagerTXT: FileManagerTXT,
  fileManagerPSD: FileManagerPSD,
  fileManagerPreview: FileManagerPreview,
  fileManagerDOCX: FileManagerDOCX,
  access: Access,
  editCloud: EditCloud,

  // more icon
  viewMore: ViewMore,
  more: More,

  // attribute icons
  once: Once,
  alert: Alert,
  notice: Alert,
  uploadAttribute: UploadAttribute,
  windowsDistribution: WindowsDistribution,
  iosDistribution: IosDistribution,
  office: Office,
  pencil: Pencil,
  extra: Extra,
  pin: LinkPin,

  // inviteUser icon
  inviteUser: InviteUser,

  // context menu icons
  copy: Copy,
  copyGroup: CopyGroup,
  copyContext: CopyContext,
  settingsContext: SettingsContext,
  favorite: Favorite,
  addContext: AddContext,
  people: People,

  // icons on leftsidebar
  level: Level,
  storage: Storage,
  basket: Basket,
  folderContext: FolderContext,
  share: Share,
  cloud: Cloud,
  moreVert: MoreVert,

  // icons on leftsidebar menu
  contactsList: ContactsList,
  menuSettings: MenuSettings,

  // inputs icons
  rotate: Rotate,

  // send icons
  sendPdf: SendPdf,
  subFiles: SubFiles,
  subFolders: SubFolders,
  pdf: SendPdf,
  subfolders: SubFolders,
  files: SubFiles,

  // tutorial icons
  arrowBack: ArrowBack,
  arrowForward: ArrowForward,
  arrowDown: ArrowDown,
  arrowLeftSide: ArrowLeftSide,
  tap: Tap,
  longTap: LongTap,

  // moderation icons
  moderationApproved: ModerationApproved,
  moderationAwaiting: ModerationAwaiting,
  moderationDenied: ModerationDenied,
  moderationGridApproved: ModerationGridApproved,
  moderationGridAwaiting: ModerationGridAwaiting,
  moderationGridDenied: ModerationGridDenied,

  // others icons
  menuSandwich: MenuSandwich,
  colorTheme: ColorTheme,
  trash: Trash,
  emptyTrash: EmptyTrash,
  apple: Apple,
  selectedItem: SelectedItem,
  search: Search,
  emptySearch: EmptySearch,
  uploadToCloud: UploadToCloud,
  outlinedCloud: OutlinedCloud,
  emptyStorage: EmptyStorage,
  check: Check,
  reversed_check: StyledReversedCheckbox,
  logoGrey: LogoGrey,
  helpClose: HelpClose,
  userStorageRoot: UserStorageRoot,
  radioEmpty: RadioButtonEmpty,
  radioChecked: RadioButtonChecked,
  lock: Lock,
  lockedBySync: LockedBySync,
  closeSync: CloseSync,
  device: Device,
  fullScreen: FullScreen,
  previewFullScreen: PreviewFullScreen,
  previewClose: PreviewClose,
  previewTheme: PreviewTheme,
  previewFileListArrow: PreviewFileListArrow,
  sharedToMeEmpty: SharedToMeEmpty,
  image: ImageSvg,
  guideInvite: GuideInvite,
  guideSettings: GuideSettings,
  pinCode: Pin,
  // stubs
  statusEnabled: StatusEnabled,
  statusDisabled: StatusDisabled,
  linkExpired: LinkExpired,
  emptyLinks: EmptyLinks,
  fatalError: FatalError,

  // Collabora
  editFileLeftPanelIcon: EditFileLeftPanelIcon,
  editFileStatusGridIcon: EditFileStatusGridIcon,
  fullEditFileLeftPanelIcon: FullEditFileLeftPanelIcon,
  officeCloseIcon: OfficeCloseIcon,
  officeEditIcon: OfficeEditIcon,
  officeReadonlyIcon: OfficeReadonlyIcon,
  collaboraOpenIcon: CollaboraOpenIcon,
  videoIndicatorMobile: VideoIndicatorMobile,

  pause: PauseIcon,
  warning: ReportProblemOutlinedIcon,
  error: ErrorIcon,
  info: InfoOutlinedIcon,
  playEnabled: PlayEnabled,
  report: Report,
  officeNew: OfficeNew,
  reports: Reports,
  reportError: ReportError,
};

// TODO: rewrite all components like StyledImgIcon to StyledSVg, whitch use assets/icons

const StyledSvg = ({
  icon,
  iconSize,
  color,
  className,
  cursor,
  image,
  extension,
  onClick,
}) => {
  const customClasses = useStyles({ color });

  const SwitchedIcon = renderIcon[icon] || null;

  if (!SwitchedIcon) {
    return null;
  }

  return (
    <SwitchedIcon
      image={image}
      extension={extension}
      className={`${customClasses.iconSvg} ${className}`}
      style={{
        width: iconSize,
        height: iconSize,
        cursor,
      }}
      onClick={onClick}
    />
  );
};

StyledSvg.defaultProps = {
  cursor: 'default',
  className: '',
  image: '',
  extension: '',
  color: '',
  onClick: () => { },
  iconSize: 14,
};

StyledSvg.propTypes = {
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  cursor: PropTypes.string,
  image: PropTypes.string,
  extension: PropTypes.string,
  onClick: PropTypes.func,
};

export default StyledSvg;
