import { Checkbox } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { getCheckboxStyle } from 'shared/helpers/theme/styles/checkbox';

export default styled(Checkbox)(({ theme, params }) => {
  const {
    enabled,
    shadow,
  } = getCheckboxStyle(theme);

  const {
    // iconSize = 18,
    // wrapSize = 20,
    borderRadius = 3,
    // backgroundColor = '#7800FF',
    backgroundColor = 'inherit',
    // checkIconColor = '#FFFFFF',
    top = '5px',
  } = params || {};

  const boxShadow = `inset 0px 0px 0 1px ${shadow}`;

  return {
    position: 'relative',
    top,
    borderRadius,
    opacity: '1',
    width: '100%',
    height: '100%',
    backgroundColor,

    '&:hover': {
      backgroundColor,
    },

    '&.MuiButtonBase-root': {
      top: 0,
      // boxShadow: 'inset 0px 0px 0 1px #7800FF1C',
      boxShadow,

      '&.Mui-checked': {
        // boxShadow: 'inset 0px 0px 0 1px #7800FF1C',
        boxShadow,

        '&:hover': {
          backgroundColor,
        },
      },
    },

    '& .MuiSvgIcon-root': {
      width: 32,
      height: 32,
      // color: checkIconColor,
      color: enabled,
    },
  };
}, { name: 'StyledReversedCheckbox' });
