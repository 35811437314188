export const iconsColors = {
  // кнопка поиска в строке поиска
  search: '#787878',
};

/** цвета иконок */
export const icons = {
  primary: '#7800FF',
  secondary: '#DADADA',
  default: '#8E8B92',
  disabled: '#ECECEC',
  /** заливка иконок в Stub компонентах */
  stub: '#ECECEC',

  // '#FF4F12' #FF5011 : '#1181D2' "#A2ADC3"  "#7800FF" "#DADADA" "#E1E1E1"
  // параметры URL ?type=success&title_code=sync_path&code=auth_user_name
  // type: danger/warning/info/success
  guiSuccess: '#81C784',
  guiInfo: '#4791DB',
  guiWarning: '#FFB74D',
  guiDanger: '#E57373',

  /** цвет иконок в окне о системе GUI клиента */
  guiAbout: '#555555',

  /** иконка в файловом менеджере (когда вклюяена модерация showLock) */
  fileElement: '#8D9AB5',

  /** цвет иконки в атрибутах таблицы */
  attribute: '#0C0C0C94',

  navBarIconColor: '#000000',

  navBarIconHoverColor: '#8E8B92',

  navBarIconPressedColor: '#8E8B92CF',

  tutorialTextIcon: '#D0D0D0',

  sidePanelTabActive: '#300D58',
  sidePanelTab: '#DADAD9',
  sideBarTree: '#ABB2C2',
};

/** цвета иконок в мобильной версии */
export const mobileIcons = {
  default: '#283C67',
  /** иконки версий файлов в мобильной версии */
  grey: '#CECECE',
  /** цвет заливки иконтки в заголовке моб. версии (на темном фоне) */
  header: '#A2ADC3',
  add: '#8E8B92',
  progress: '#3A7FE7',
  textIcon: '#555555',
};

/** основные цвета */
export const main = {
  /** основной цвет кнопок
   * в мобильной версии тоже
  */
  buttonPrimary: '#7800FF',
  buttonPrimaryBoxShadow: '#D7BAF9',

  borderPrimary: '#663AB7',

  /** цвет для ссылок */
  link: '#1181D2',

  /** валидация форм */
  error: '#f44336',

  /** белый цвет
   * для фона спиннера
   */
  white: '#00000000',

  /** фон спиннера при создании флешки */
  spinnerBackground: '#FBFBFB',

  /** Custom Inputs */
  selectContainerBackground: '#F8F8F8',

  tooltipBackground: '#919191',

  draggedTargetBackground: '#D2D2D2',

  notificationWrapperBoxShadow: '#CCCCCC',

  notificationWrapperBorder: '#EBEBEB',

  /** Custom Inputs */
  optionSelectBoxShadow: '#EBEBEB',
  optionSelectBorder: '#D2D2D2',

  checkBoxBoxShadow: '#DADADA',
};

/** основные цвета текста */
export const text = {
  default: '#232323',
  default1: '#1E1E1E',

  label: '#A5A5A5',

  // main:
  /** заголовок в доп спедениях мобильной версии */
  mobileFileInfoTitle: '#3E4A64',

  /** цвет в контейнере сведений о файле в мобильной версии
   * twofaResendCodeHintText: текст подсказки при вводе кода подтверждения
    */
  mobileFileInfoContianer: '#686868',

  link: '#1181D2',

  tooltip: '#FDFDFD',

  notification: '#3E3E3E',
  notificationError: '#CF552A',
};

// /** цвета прогресс бара (для квоты) */
// export const progress = {
//   used: '#FF4F12',
//   free: '#1181D2',
// };

export const validation = {
  weak: '#E22A2A',
  regular: '#FFA012',
  strong: '#15D629',
};
