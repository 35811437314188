import React from 'react';

const Settings = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.01"
      height="14"
      viewBox="0 0 14.01 14"
      {...props}
    >
      <path
        id="settings"
        d="M11.21,2.062l-1.333.524.238,1.152c-.093.093-.192.2-.3.314L8.676,3.8,8.114,5.11l.971.648a3.669,3.669,0,0,0,0,.486l-1,.61L8.61,8.186l1.124-.229a2.592,2.592,0,0,0,.333.333L9.81,9.415l1.324.562.648-.971a3.536,3.536,0,0,0,.476,0l.619,1,1.333-.524-.257-1.219a2.6,2.6,0,0,0,.324-.333l1.162.3.571-1.333L15,6.272a3.821,3.821,0,0,0,0-.467l1-.619-.524-1.333-1.162.257a2.6,2.6,0,0,0-.333-.333l.257-1.124-1.324-.562-.619.971a2.553,2.553,0,0,0-.476-.01Zm.848,2.724a1.219,1.219,0,1,1-1.219,1.219A1.218,1.218,0,0,1,12.057,4.786ZM5.514,7.529l-.19,1.219a3.042,3.042,0,0,0-.5.219L3.8,8.224,2.733,9.291l.7,1.029a3.9,3.9,0,0,0-.21.5L2,11.053v1.486l1.219.219a3.033,3.033,0,0,0,.219.514l-.743.99,1.067,1.067,1.029-.7a3.905,3.905,0,0,0,.5.21l.19,1.229h1.5L7.2,14.843a2.994,2.994,0,0,0,.514-.219l1.019.743L9.8,14.3l-.733-1.029a4.013,4.013,0,0,0,.2-.5l1.267-.229v-1.5l-1.248-.19a3,3,0,0,0-.219-.5L9.8,9.329l-1.057-1.1L7.7,8.967a3.878,3.878,0,0,0-.5-.21l-.19-1.229Zm.752,3.048A1.219,1.219,0,1,1,5.048,11.8,1.223,1.223,0,0,1,6.267,10.577Z"
        transform="translate(-2 -2.063)"
      />
    </svg>
  );
};

export default Settings;
