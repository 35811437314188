import React from 'react';

const Upload = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15.667"
    viewBox="0 0 16 15.667"
    {...props}
  >
    <path
      id="upload"
      d="M8,.5l-3.667,5H6.667v6H9.333v-6h2.333ZM0,14.833v1.333H16V14.833Z"
      transform="translate(0 -0.5)"
    />
  </svg>
);

export default Upload;
