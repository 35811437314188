import React from 'react';

const ModerationDenied = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="moderation_table_denied"
        d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm3.594,10.286-1.307,1.307L8,9.307,5.714,11.594,4.406,10.286,6.693,8,4.406,5.714,5.714,4.406,8,6.693l2.286-2.286,1.307,1.307L9.307,8Z"
        opacity="0.7"
      />
    </svg>
  );
};

export default ModerationDenied;
