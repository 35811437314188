import * as notificationTypes from 'services/apiService/notificationTypes';

const actionsRoute = {
  getTrash: params => ({
    url: '/trash',
    method: 'GET',
    params,
  }),

  clearTrash: params => ({
    url: '/trash/clear',
    method: 'DELETE',
    data: params,
    notifications: {
      success: notificationTypes.TRASH_CLEAN_REQUEST_SUCCESS,
      omitErrorNotification: true,
    },
  }),

  deleteFileFromTrash: params => ({
    url: '/trash/delete',
    method: 'DELETE',
    data: params,
    notifications: {
      success: notificationTypes.FILES_DELETE_REQUEST_SUCCESS,
      omitErrorNotification: true,
    },
  }),

  restoreFileFromTrash: params => ({
    url: '/trash/restore',
    method: 'POST',
    data: params,
    notifications: {
      success: notificationTypes.FILES_RESTORE_REQUEST_SUCCESS,
    },
  }),
};

export default actionsRoute;
