import React from 'react';

const Add = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="add"
        d="M21,14.143H14.143V21H11.857V14.143H5V11.857h6.857V5h2.286v6.857H21Z"
        transform="translate(-5 -5)"
      />
    </svg>
  );
};

export default Add;
