import React from 'react';

const Contactes = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="36.909"
      viewBox="0 0 58 36.909"
      {...props}
    >
      <path
        id="contacts"
        d="M41.864,26.091A31.6,31.6,0,0,0,30,28.727a31.1,31.1,0,0,0-11.864-2.636C12.415,26.091,1,28.938,1,34.659v7.25H59v-7.25C59,28.938,47.585,26.091,41.864,26.091ZM31.318,37.955H4.955v-3.3c0-1.424,6.749-4.614,13.182-4.614s13.182,3.19,13.182,4.614Zm23.727,0H35.273v-3.3A4.8,4.8,0,0,0,33.9,31.443a25.434,25.434,0,0,1,7.962-1.4c6.433,0,13.182,3.19,13.182,4.614Zm-36.909-14.5a9.227,9.227,0,1,0-9.227-9.227A9.237,9.237,0,0,0,18.136,23.455Zm0-14.5a5.273,5.273,0,1,1-5.273,5.273A5.288,5.288,0,0,1,18.136,8.955Zm23.727,14.5a9.227,9.227,0,1,0-9.227-9.227A9.237,9.237,0,0,0,41.864,23.455Zm0-14.5a5.273,5.273,0,1,1-5.273,5.273A5.288,5.288,0,0,1,41.864,8.955Z"
        transform="translate(-1 -5)"
        fill="#e0e0e2"
      />
    </svg>
  );
};

export default Contactes;
