import React from 'react';

const MenuSandwich = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10.667"
      viewBox="0 0 16 10.667"
      {...props}
    >
      <path
        id="menu_sandwich"
        d="M3,16.667H19V14.889H3Zm0-4.444H19V10.444H3ZM3,6V7.778H19V6Z"
        transform="translate(-3 -6)"
      />
    </svg>
  );
};

export default MenuSandwich;
