import * as authTypes from 'store/reducers/auth/types';
import * as filesTypes from 'store/reducers/files/constants';

import types from '../constants';
import initialState from './initialState';

// TODO: rewrite all data spreading, if possible:
// нужно упростить по максимуму, перебор по итерациям
const ACTION_HANDLERS = {
  [types.REQUEST_GET_INBOX_LINKS_SUCCESS]: (state, { payload, params }) => {
    const { data: currentData } = state;
    const data = payload;

    const initialReduceState =
      params.page && currentData.received ? currentData.received : [];

    const received = data.list.map(item => `received_${item.code}`);
    return {
      actions: data.actions,
      data: {
        ...currentData,
        received: [...initialReduceState, ...received],
      },
    };
  },
  [types.REQUEST_GET_OUTBOX_LINKS_SUCCESS]: (state, { payload, params }) => {
    const { data: currentData } = state;
    const data = payload;

    const initialReduceState =
      params.page && currentData.sent ? currentData.sent : [];

    const sent = data.list.map(item => `sent_${item.code}`);
    return {
      actions: data.actions,
      data: {
        ...currentData,
        sent: [...initialReduceState, ...sent],
      },
    };
  },
  [types.REQUEST_GET_LINKS_SUCCESS]: (state, { payload, params }) => {
    const { data: currentData } = state;
    const data = payload;

    const initialReduceState =
      params.page && currentData.exchange ? [...currentData.exchange] : [];

    const exchange = data.list.reduce((currExchange, item) => {
      currExchange.push(`exchange_${item.elcnt}`);

      return currExchange;
    }, initialReduceState);

    return {
      actions: data.actions,
      data: {
        ...currentData,
        exchange,
      },
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_SUCCESS]: (state, { payload, params }) => {
    const { data: currentData, actions } = state;
    const data = payload;

    const initialReduceState =
      params.page && currentData.publiclink ? currentData.publiclink : [];

    const publiclink = data.list.map(item => `publiclink_${item.code}`);

    return {
      actions,
      linkActions: data.actions,
      data: {
        ...currentData,
        publiclink: [...initialReduceState, ...publiclink],
      },
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const { data: currentData, actions } = state;
    const data = payload;

    const initialReduceState =
      params.page && currentData.unauthpubliclink
        ? currentData.unauthpubliclink
        : [];

    const unauthpubliclink = data.list.map(
      item => `unauthpubliclink_${item.code}`,
    );

    return {
      actions,
      linkActions: data.actions,
      data: {
        ...currentData,
        unauthpubliclink: [...initialReduceState, ...unauthpubliclink],
      },
    };
  },
  [types.REQUEST_INNER_FILES_SUCCESS]: (state, { payload, params }) => {
    const { data: currentData, actions, linkActions } = state;
    const { folders = [], files = [] } = payload;

    const initialReduceState =
      params.page && currentData[params.uuid] ? currentData[params.uuid] : [];

    const innerFiles =
      [...folders, ...files].map(({ item, code }) => item || code) || [];

    return {
      actions,
      linkActions,
      data: {
        ...currentData,
        [params.uuid]: [...initialReduceState, ...innerFiles],
      },
    };
  },
  [types.REQUEST_GET_PUBLIC_LINKS_SUBFOLDER_DATA_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const { data: currentData, actions, linkActions } = state;
    const { list = [] } = payload;
    const folders = list.filter(item => item.folder);
    const files = list.filter(item => item.file);

    const initialReduceState =
      params.page && currentData[params.uuid] ? currentData[params.uuid] : [];

    const pblSubfolder =
      [...folders, ...files].map(({ item, code }) => item || code) || [];

    return {
      actions,
      linkActions,
      data: {
        ...currentData,
        [params.uuid]: [...initialReduceState, ...pblSubfolder],
      },
    };
  },
  [types.REQUEST_GET_UNAUTH_PUBLIC_LINKS_SUBFOLDER_SUCCESS]: (
    state,
    { payload, params },
  ) => {
    const { data: currentData, actions, linkActions } = state;
    const { list = [] } = payload;
    const folders = list.filter(item => item.folder);
    const files = list.filter(item => item.file);

    const initialReduceState =
      params.page && currentData[params.uuid] ? currentData[params.uuid] : [];
    const unauthPblSubfolder =
      [...folders, ...files].map(({ item, code }) => item || code) || [];

    return {
      actions,
      linkActions,
      data: {
        ...currentData,
        [params.uuid]: [...initialReduceState, ...unauthPblSubfolder],
      },
    };
  },
  [authTypes.AUTO_LOGIN_SUCCESS]: () => initialState,
  [authTypes.AUTO_LOGIN_FAIL]: () => initialState,
  [authTypes.LOGOUT_SUCCESS]: () => initialState,
  [authTypes.LOGOUT_FAIL]: () => initialState,

  [types.DELETE_SHARE_ITEM]: (state, { params }) => {
    const { firstPath, itemsIds } = params;
    const currentIds = itemsIds.map(id => `${firstPath}_${id}`);
    const newData = state.data;

    newData[firstPath] = newData[firstPath].filter(
      id => !currentIds.includes(id),
    );

    return {
      ...state,
      data: newData,
    };
  },

  [types.DELETE_UNAUTH_ITEM_SUCCESS]: (state, { params }) => {
    const { items: itemsIds, shareId, linkId } = params;

    const currentIds = (itemsIds || []).map(
      id => `${!shareId ? 'unauthpubliclink_' : ''}${id}`,
    );

    const newData = state.data;

    if (shareId) {
      newData[linkId] = newData[linkId].filter(id => !currentIds.includes(id));

      return {
        ...state,
        data: newData,
      };
    }

    newData.unauthpubliclink = newData.unauthpubliclink.filter(
      id => !currentIds.includes(id),
    );

    return {
      ...state,
      data: newData,
    };
  },

  [filesTypes.FILES_UNAUTH_UPLOAD_SUCCESS]: (state, { payload, params }) => {
    const data = payload;
    if (data.error) {
      return state;
    }

    const { linkId } = params;

    const newData = data.reduce((prev, item) => {
      if (prev[linkId].includes(item.code)) return prev;

      prev[linkId].push(item.code);
      return prev;
    }, state.data);

    return {
      ...state,
      ...newData,
    };
  },
};

export const byFilters = (state = initialState, action) => {
  return ACTION_HANDLERS[action.type]
    ? ACTION_HANDLERS[action.type](state, action)
    : state;
};

export default byFilters;
