import React, { useMemo } from 'react';
import { Helmet } from "react-helmet";
import { publicUrl } from 'routing/helpers';

const DEFAULT_TITLE = 'Диск';

const withTitle = Component => props => {
  const {
    systemTitle,
    logos,
  } = props;

  const {
    favicon,
    logo_192,
    // logo_512,
  } = logos;

  // useEffect(() => {
  //   document.title = systemTitle || DEFAULT_TITLE;
  // }, [systemTitle]);

  const meta = useMemo(() => <Helmet>
    {favicon && <link rel="icon" href={`${publicUrl}${favicon}`} />}
    <title>{systemTitle || DEFAULT_TITLE}</title>
    {logo_192 && <link rel="apple-touch-icon" href={`${publicUrl}${logo_192}`} />}
  </Helmet>, [systemTitle, logos]);

  return <>
    {meta}
    <Component {...props} />
  </>;
};

export default withTitle;
